import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Modal from "react-awesome-modal";
import { createRecommend, updateRecommend } from "./lib";
import { btnStyle } from "../../helpers/utils";

export default function CreateRecommendDialog({
  open,
  handleClose,
  onSubmitCB,
  previousTitles = [],
  productId,
  isEdit,
  value: preValue,
  recommendId,
}) {
  const [title, setTitle] = useState(isEdit ? preValue : "You May Like");
  const [isLoading, setIsLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const addTitle = async () => {
    if (!title) {
      setErrorMsg("Title is required");
      return;
    }

    if (isEdit && title.toLowerCase() == preValue.toLowerCase()) {
      setErrorMsg("Title is same");
      return;
    }
    let isAlreadyTaken = previousTitles.map((s) => s.toLowerCase());

    if (isEdit) {
      isAlreadyTaken = previousTitles.filter(
        (p) => p.toLowerCase() != preValue.toLowerCase()
      );
    }

    isAlreadyTaken = isAlreadyTaken.filter((p) => {
      return p.toLowerCase() == title.toLowerCase() ? true : false;
    });

    isAlreadyTaken = !!isAlreadyTaken.length;
    if (isAlreadyTaken) {
      setErrorMsg("This title already taken");
      return;
    }
    setIsLoading(true);
    // return;
    try {
      if (isEdit) await updateRecommend(recommendId, title);
      else await createRecommend(productId, title);
      onSubmitCB && onSubmitCB(title);
      handleClose();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={open && true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div
            style={{
              height: 30,
              width: "100%",
            }}
          />
          <p
            style={{
              margin: 0,
              padding: 0,
              fontSize: 28 || 30,
              fontWeight: 500,
              color: "#565656",
            }}
          >
            {isEdit ? "Edit " : "Add "} Recommendation
          </p>
        </DialogTitle>
        <DialogContent
          style={{
            minWidth: 700,
            // border: "1px solid red",
          }}
        >
          <label
            htmlFor="name"
            style={{
              margin: 0,
              padding: 0,
              fontSize: 18 || 23,
              fontWeight: 400 || 500,
              marginBottom: 12,
              display: "block",
              color: "#000000",
            }}
          >
            Recommendation Title
          </label>
          <input
            type="text"
            onKeyDown={(event) => event.key === "Enter" && addTitle()}
            id="name"
            value={title}
            onChange={(e) => {
              errorMsg && setErrorMsg("");
              setTitle(e.target.value);
            }}
            style={{
              // border: "1px solid red",
              width: "100%",
              outline: "none",
              boxSizing: "border-box",
              // padding: "10px 0",
              height: 40,
              boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
              border: 0,
              borderRadius: 5,
              padding: "0 10px",
              fontSize: 16,
              fontWeight: 300,
            }}
          />
          <p
            style={{
              margin: 0,
              padding: 0,
              fontSize: 18,
              // lineHeight: "32px",
              fontWeight: 400,
              marginTop: 8,
              height: 20,
              color: "rgba(255,0,0,0.7)",
              fontStyle: "italic",
            }}
          >
            {errorMsg}
          </p>
        </DialogContent>
        <div
          style={{
            // border: "1px solid blue",
            width: "100%",
            // padding: 8,
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 0 ?? "30px 0px",
            marginBottom: 40,
          }}
        >
          <button
            style={{
              ...btnStyle,
              color: "#000000",
              marginRight: 20,
              padding: 10,
              width: 230,
              fontSize: 22,
            }}
            disabled={isLoading}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            style={{
              ...btnStyle,
              background: "#BDBDBD",
              color: "white",
              marginLeft: 20,
              padding: 10,
              width: 230,
              fontSize: 22,
            }}
            onClick={() => {
              addTitle();
            }}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </Dialog>
    </div>
  );
}
