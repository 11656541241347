import { AccordionDetails, CircularProgress, Grid } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";

import API from "../../App/Variables/Constants";
import ProductCard from "../../App/Main/UserPortal/ProductCard";
import modelImg1 from "../../Assets/Images/modelImg1.png";

const AllProductPaginated = ({
  products,
  deleteProduct,
  stateProps,
  indexProps,
  singleCate,
  loadingProducts,
  perPage,
  productStatus,
  query,
  page: pageProps,
  userId,
  getMetaData,
}) => {
  const containerRef = useRef();

  const isLast = () => {
    const triggerHeight =
      containerRef.current.scrollTop + containerRef.current.offsetHeight;
    // console.log("triggerHeight ", triggerHeight);
    // console.log("scrollHeight ", containerRef.current.scrollHeight);
    return triggerHeight + 200 >= containerRef.current.scrollHeight;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [newProducts, setProducts] = useState([]);

  const pageRef = useRef(pageProps);

  const fetchProducts = async () => {
    if (isEnd) return;
    pageRef.current = pageRef.current + 1;
    const page = pageRef.current;

    // console.log(`page `, page);
    try {
      setIsLoading(true);
      const url =
        API.getUrls.getProductsByUser +
        "?page=" +
        page +
        "&limit=" +
        perPage +
        "&catId=" +
        singleCate._id +
        "&q=" +
        query +
        "&status=" +
        productStatus +
        "&userId=" +
        userId;

      let url2 =
        API.getUrls.getProductsByUser +
        "?page=" +
        page +
        "&limit=" +
        perPage +
        "&catId=" +
        singleCate._id +
        "&q=" +
        query +
        "&status=" +
        productStatus +
        "&user=" +
        userId;

      const { data } = await axios.get(url2);

      if (!data.hasNextPage) {
        setIsEnd(true);
      }
      setProducts((preProds) => [...preProds, ...data.docs]);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const isHeightNotMax = () => {
    const st = window.getComputedStyle(containerRef.current);

    return st.maxHeight > st.height;
  };

  const setLast = async () => {
    if (isHeightNotMax() && !isEnd) {
      await fetchProducts();
      setLast();
    }
  };

  useEffect(() => {
    setLast();
  }, []);

  return (
    <AccordionDetails>
      <div
        ref={containerRef}
        className="cardWrapper"
        style={{
          border: "0px solid blue",
          // maxHeight: 200,
        }}
        onScroll={async (e) => {
          // console.log("onSroll");

          if (isEnd) {
            // console.log("onSroll 1");
            return;
          }

          if (isLast()) {
            // console.log("onSroll 2");
            await fetchProducts();
          }
        }}
      >
        {loadingProducts ? (
          <Loading />
        ) : !products?.length ? null : (
          <Grid
            container
            spacing={2}
            style={
              {
                // border: "1px solid red",
              }
            }
          >
            {products.map((ele, ind) => (
              <Grid
                item
                sm={3}
                key={ele?._id}
                style={
                  {
                    // border: "1px solid blue",
                  }
                }
              >
                <ProductCard
                  imgSrc={
                    ele.poster_image_url
                      ? API.imageUrl + ele.poster_image_url
                      : modelImg1
                  }
                  id={ele._id}
                  title={ele.title}
                  views={ele.views}
                  publishStatus={ele.status}
                  catId={singleCate._id}
                  CardIndex={indexProps}
                  page={stateProps.page}
                  limit={stateProps.perPage}
                  status={stateProps.productStatus}
                  query={stateProps.query}
                  onDelete={deleteProduct}
                  getMetaData={getMetaData}
                  userId={userId}
                />
              </Grid>
            ))}
            {newProducts.map((ele, ind) => (
              <Grid item sm={3} key={ele?._id}>
                <ProductCard
                  imgSrc={
                    ele.poster_image_url
                      ? API.imageUrl + ele.poster_image_url
                      : modelImg1
                  }
                  id={ele._id}
                  title={ele.title}
                  views={ele.views}
                  publishStatus={ele.status}
                  catId={singleCate._id}
                  CardIndex={indexProps}
                  page={stateProps.page}
                  limit={stateProps.perPage}
                  status={stateProps.productStatus}
                  query={stateProps.query}
                  onDelete={deleteProduct}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </AccordionDetails>
  );
};

export default AllProductPaginated;
const Loading = () => (
  <div
    style={{
      textAlign: "center",
      marginTop: "80px",
    }}
  >
    <CircularProgress size={40} color={"info"} />
  </div>
);
