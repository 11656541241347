import React, { useEffect, useState } from 'react';
import CategoryBox from './CategoryBox';
import RightContentBox from './RightContentBox';
import { spacing } from './const';

import axios from 'axios';
import Constants from '../../App/Variables/Constants';

const Templates = ({ }) => {
  const [category, setCategory] = useState('');
  const user_id = window.location.pathname.split("/")[3]

  const handleCategoryCheck = (id) => {

    // setCategoy(id == category ? '' : id);
    if (category.includes(id)) {
      const cats = category.filter(ca => ca != id);
      setCategory(cats)
    } else {
      setCategory([...category, id])
    }
  };

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(Constants.getUrls.Categories);
        setCategories(data.categories.docs);
      } catch (err) { }
    })();
  }, []);
  return (
    <div
      style={{
        padding: spacing * 2,
        minHeight: '80vh',
        maxWidth: '1200px',
        margin: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          // border: '1px solid red',
          overflow: 'hidden',
          justifyContent: 'center',
        }}
      >
        <CategoryBox
          category={category}
          handleCategoryCheck={handleCategoryCheck}
          categories={categories}
        />
        <RightContentBox category={category} user_id={user_id} />
      </div>
    </div>
  );
};

export default Templates;
