import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
  Snackbar,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import init from "../helpers/WindowToken";
import { Form } from "reactstrap";

export default class AddSuggestionModel extends Component {
  state = {
    title: "",
    description: "",
    loading: false,
    poster_image: "",
    glb_file: "",
    notification: false,
    message: "",
  };
  handleFile = (e) => {
    console.log(e.target.files);
    this.setState(
      {
        [e.target.name]: e.target.files[0],
      },
      () => {
        console.log(this.state.glb_file);
      }
    );
  };
  addcats = (e) => {
    e.preventDefault();
    // let catLoad = {
    //   title: this.state.title,
    //   description: this.state.description,
    // };
    let fd = new FormData();
    fd.append("title", this.state.title);
    fd.append("description", this.state.description);
    fd.append("posterImage", this.state.poster_image);
    fd.append("model_file",this.state.glb_file);
    for (const entry of fd.entries()) {
        console.log(entry);
      }
    this.setState({
      loading: true,
    });

    if (init() === "success") {
      console.log("success");
      axios
        .post(Constants.getUrls.getSuggestionModels, fd)
        .then((resp) => {
          console.log(resp);
          if (resp.data.status === "success") {
            this.setState({
              loading: false,
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                redirect: true,
              });
            }, 1500);
          } else {
            this.setState({
              loading: false,
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                // redirect: true,
              });
            }, 1500);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/suggestionmodels" />;
    } else {
      return (
        // <div>JHello</div>
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">Add Suggestion Model</Typography>
            </Grid>
          </Grid>
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"green"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          <div
            style={{
              padding: "20px",
              border: "1px solid #afa2a2",
              borderRadius: "7px",
              position: "relative",
              // background:"#3f51b530"
            }}
            // style={{
            //   background: "#5b5757",
            //   padding: "20px",
            //   borderRadius: "10px",
            //   // color: rgba(0, 0, 0, 0.87);
            //   width: "100%",
            //   border: "0",
            //   display: "flex",
            //   position: "relative",
            //   fontSize: ".875rem",
            //   minWidth: "0",
            //   wordWrap: " break-word",
            //   /* background: #FFF; */
            //   boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            //   marginTop: "30px",
            //   borderRadius: "6px",
            //   marginBottom: "30px",
            //   flexDirection: "column",
            //   textAlign: "left",
            // }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, #3f51b5cf, #f500579e) #f500579e)",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>
            <span
              style={{
                color: "#afa3a3",
                marginTop: "-8px",
                marginLeft: "26px",
                marginBottom: "35px",
                fontSize: "17px",
                display: "flex",
              }}
            >
              Add Category
            </span>

            <Grid item sm={12} style={{ textAlign: "left", marginTop: "30px" }}>
              {/* <CardHeader> */}
              {/* <Typography>
                    Add Category
                </Typography> */}
              {/* </CardHeader> */}
              <Form onSubmit={this.addcats}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="Title"
                  name="title"
                  required={true}
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  label="Description"
                  onChange={this.handleInput}
                  name="description"
                  style={{ marginRight: "20px", width: "30%" }}
                  variant="outlined"
                />
                <TextField
                  type="file"
                  id="outlined-basic"
                  label="Select Image"
                  onChange={this.handleFile}
                  inputProps={{ accept: ".jpg, .png, .jpeg" }}
                  name="poster_image"
                  focused
                  style={{ marginRight: "20px", width: "30%" }}
                  variant="outlined"
                />
                <TextField
                  type="file"
                  id="outlined-basic"
                  label="Select Model"
                  onChange={this.handleFile}
                  inputProps={{ accept: ".glb" }}
                  name="glb_file"
                  focused
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginTop: "20px",
                  }}
                  variant="outlined"
                  required
                />
                <br />
                <Button
                  variant="contained"
                  type="submit"
                  Size={15}
                  color="primary"
                  style={{ marginRight: "20px", marginTop: "35px" }}
                >
                  {this.state.loading ? "Adding.." : "Add Suggestion Model"}
                </Button>
                <Link
                  to={{
                    pathname: "/suggestionmodels",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    Size={15}
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Form>
            </Grid>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Fade in={this.state.open}>
              <div
                style={{
                  background: "#fff",
                  width: "50%",
                  padding: "32px",
                }}
              >
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">
                  react-transition-group animates me.
                </p>
              </div>
            </Fade>
          </Modal>
        </Container>
      );
    }
  }
}
