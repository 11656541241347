import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { spacing } from "./const";

const SearchBox = ({ handleChangeQuery, query }) => {
  return (
    <div
      style={{
        width: "100",
        boxShadow:
          "inset 0px -2px 6px rgba(0, 0, 0, 0.25), inset 0px 4px 5px -1px rgba(0, 0, 0, 0.25)",
        borderRadius: 30,
        display: "flex",
        height: 55,
        overflow: "hidden",
        alignItems: "center",
        marginBottom: 31
      }}
    >
      <input
        type="search"
        style={{
          background: "transparent",
          margin: 0,
          padding: 0,
          height: 53,
          display: "block",
          boxSizing: "border-box",
          flex: 1,
          border: 0,
          outline: "none",
          paddingLeft: spacing * 2,
          fontSize: 20,
        }}
        placeholder="Search Products"
        value={query}
        onChange={(e) => handleChangeQuery(e.target.value)}
      />
      <div
        style={{
          width: 80,
          height: 55,
          // border: '1px solid red',
          display: "grid",
          placeItems: "center",
        }}
      >
        <SearchIcon fontSize="medium" />
      </div>
    </div>
  );
};

export default SearchBox;
