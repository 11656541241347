import {
  Button,
  Container,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constants";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import Modal from "react-awesome-modal";
const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));
function createData(name, calories, fat) {
  return { name, calories, fat };
}
const rowsPerPage = 5;
const page = 1;
const adminToken = localStorage.getItem("feel_xr_auth_token");

export default class Users extends Component {
  state = {
    users: [],
    open: false,
    getBlogs: [],
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "20",
    searchValue: "",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    deleteId: "",
    theme: "light",
    checkActuser: "",
    feUserId: "",

    // PAGINATION STATES
    search: "",
    firstpage: 0,
    page: 1,
    secondpage: 0,
    totalPage: 0,
    limit: 10,
    nextPage: false,
    prevPage: false,
    pagingCounter: 1,
    totalDocs: 0,
    forshow: 0,
    fesearch: "",

    // PAGINATION
  };
  componentDidMount() {
    this.getUsers();
  }
  getUsers = () => {
    if (init() === "success") {
      axios
        .get(
          Constants.getUrls.UsersList +
            "?page=" +
            this.state.page +
            "&limit=" +
            this.state.perPage +
            "&q=" +
            this.state.searchValue +
            "&role=user" +
            "&status=" +
            this.state.userStatusQuery
        )
        //   "&is_delete=" +
        // this.state.fesearch +
        .then((resp) => {
          // console.log("resp.data.users.docs ", resp.data.users.docs);
          this.setState({
            users: resp.data.users.docs,
            // PAGINATION
            firstpage: resp.data.users.prevPage,
            secondpage: resp.data.users.nextPage,
            page: resp.data.users.page,
            totalPage: resp.data.users.totalPages,
            nextPage: resp.data.users.hasNextPage,
            prevPage: resp.data.users.hasPrevPage,
            forshow: resp.data.users.prevPage,
            limit: resp.data.users.limit,
            pagingCounter: resp.data.users.pagingCounter,
            totalDocs: resp.data.users.totalDocs,

            // PAGINATION
          });
          // let btnIdArray = [];
          // for (let i = 1; i <= resp.data.users.totalPages; i++) {
          //   btnIdArray.push(i);
          // }
          // this.setState(
          //   {
          //     fromInc: 1,
          //     active: 1,
          //     toInc: 5,
          //     btnIdArray,
          //     users: resp.data.users.docs,
          //     total: resp.data.users.totalDocs,
          //     from: 1,
          //   },
          //   () => {
          //     // console.log(this.state.getBlogs);
          //     let filteredBtnIdArray = this.state.btnIdArray.filter(
          //       (v) => v >= this.state.fromInc && v <= this.state.toInc
          //     );
          //     this.setState({
          //       filteredBtnIdArray,
          //     });
          //   }
          // );
        });
    }
  };
  // handleRows start

  handleRows = (value) => {
    this.setState(
      {
        limit: value,
        page: 1,
      },
      () => {
        this.getUsers();
      }
    );
  };

  // handleRows end

  // PAGINATION BUTTONS

  handleNext = () => {
    // console.log("click");
    if (this.state.nextPage === true) {
      // console.log(this.state.nextPage);

      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.getUsers();
        }
      );
    }
  };

  handlePrev = () => {
    if (this.state.prevPage === true)
      this.setState(
        {
          page: this.state.page - 1,
        },
        () => {
          this.getUsers();
        }
      );
  };

  btnCilck = (i) => {
    // console.log(i);
    this.setState(
      {
        page: i,
      },
      () => {
        this.getUsers();
      }
    );
  };
  deleteFromFrontEgg = (userId) => {
    let payload = {
      clientId: "ebc309f5-c1cc-4ff6-b900-ca1b47069d78",
      secret: "b8f22056-568d-43ad-982a-55bca06d2c49",
    };
    axios
      .post("https://api.frontegg.com/auth/vendor/", payload)
      .then((resp) => {
        // console.log("resp from post front egg ", resp.data);
        // console.log(resp.data.token);
        let authTokenVendor = resp.data.token;
        console.log("authTokenVendor ", authTokenVendor);
        const options = {
          method: "DELETE",
          url: "https://api.frontegg.com/identity/resources/users/v1/" + userId,
          headers: { Authorization: "Bearer " + authTokenVendor },
        };

        axios
          .request(options)
          .then((resp) => {
            console.log("response from delete fromntegg ", resp.data);
          })
          .catch((err) => {
            if (err) {
              console.error(err.response);
            }
          });
      });
  };
  deleteUser = (id, userId) => {
    if (init() == "success") {
      this.setState({ delLoading: true });
      // let payloadDelete = {
      //   is_delete:true,
      // }
      axios.delete(Constants.postUrls.deleteUserByAdmin + id).then((res) => {
        // console.log(res);
        this.setState({
          notifyDelete: true,
          delLoading: false,
          delMsg: res.data.message,
        });
        this.deleteFromFrontEgg(userId);
        setTimeout(() => {
          this.setState(
            { notifyDelete: false, visible: false, searchValue: "", page: 1 },
            () => {
              this.getUsers();
            }
          );
        }, 2000);
      });
    }
  };

  deleteUserFormDatabase = (id, userId) => {
    // this.setState({ searchValue: "", page: 1 });
    // return;
    if (init() == "success") {
      axios.delete(Constants.postUrls.deleteUserByDatabase + id).then((res) => {
        // console.log(res);
        this.setState({
          delMsg: res.data.message,
          searchValue: "",
          page: 1,
          notifyDelete: false,
          visible: false,
        });
        this.deleteFromFrontEgg(userId);

        this.getUsers();
        // setTimeout(() => {
        //   this.setState({ notifyDelete: false, visible: false }, () => {
        //     this.getUsers();
        //   });
        // }, 600);
      });
    }
  };
  handleChange = (event) => {
    // console.log(event.target.value);
    this.setState(
      {
        fesearch: event.target.value,
        userStatusQuery:
          event.target.value == "no"
            ? "active"
            : event.target.value == "yes"
            ? "draft"
            : "",
        page: 1,
      },
      () => {
        this.getUsers();
      }
    );
  };
  handleSearch = (e) => {
    this.setState({ searchValue: e.target.value, page: 1 });
    // console.log(this.state.searchValue);
    if (e.target.value.length > 1) {
      this.setState({ searching: true });
      axios
        .get(
          Constants.getUrls.UsersList +
            "?page=" +
            this.state.page +
            "&limit=" +
            this.state.perPage +
            "&is_delete=" +
            this.state.fesearch +
            "&q=" +
            e.target.value +
            // this.state.name
            "&role=user"
        )
        .then((resp) => {
          // console.log(resp);
          this.setState({
            users: resp.data.users.docs,
            // PAGINATION
            firstpage: resp.data.users.prevPage,
            secondpage: resp.data.users.nextPage,
            page: resp.data.users.page,
            totalPage: resp.data.users.totalPages,
            nextPage: resp.data.users.hasNextPage,
            prevPage: resp.data.users.hasPrevPage,
            forshow: resp.data.users.prevPage,
            limit: resp.data.users.limit,
            pagingCounter: resp.data.users.pagingCounter,
            totalDocs: resp.data.users.totalDocs,

            // PAGINATION
          });
        });
    } else {
      this.getUsers();
    }
  };
  handleStatus = (e, id, userId) => {
    // alert(this.state.status + " " + id);
    this.setState({
      checkActuser: e.target.checked,
    });
    const payload = {
      status: e.target.checked === true ? "active" : "draft",
    };
    // console.log(payload);
    axios.post(Constants.statusUrl + id + "?type=user", payload).then((res) => {
      // console.log(res);
      this.setState(
        {
          notifyDelete: true,
          delMsg: res.data.message,
        },
        () => {
          let payload = {
            clientId: "ebc309f5-c1cc-4ff6-b900-ca1b47069d78",
            secret: "b8f22056-568d-43ad-982a-55bca06d2c49",
          };
          axios
            .post("https://api.frontegg.com/auth/vendor/", payload)
            .then((resp) => {
              // console.log(resp);
              // console.log(resp.data.token);
              let authTokenVendor = resp.data.token;
              this.state.checkActuser === true
                ? // for user unlock code
                  axios
                    .post(
                      "https://api.frontegg.com/identity/resources/users/v1/" +
                        userId +
                        "/unlock",
                      null,
                      {
                        headers: { Authorization: "Bearer " + authTokenVendor },
                      }
                    )
                    .then((resp) => {
                      // console.log(resp);
                    })
                    .catch((err) => {
                      if (err) {
                        console.error(err.response);
                      }
                    })
                : // for user lock code
                  axios
                    .post(
                      "https://api.frontegg.com/identity/resources/users/v1/" +
                        userId +
                        "/lock",
                      null,
                      {
                        headers: { Authorization: "Bearer " + authTokenVendor },
                      }
                    )
                    .then((resp) => {
                      // console.log(resp);
                    })
                    .catch((err) => {
                      if (err) {
                        console.error(err.response);
                      }
                    });
            });
        }
      );
      setTimeout(() => {
        this.setState({
          notifyDelete: false,
        });
        this.getUsers();
        // window.location.reload();
      }, 2000);
    });
  };
  render() {
    return (
      <Container>
        {/* <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          color={"s"}
          autoHideDuration={6000}
          message="I love snacks"
        ></Snackbar> */}
        {this.state.notifyDelete ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            color={"s"}
            autoHideDuration={6000}
            message={this.state.delMsg}
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
          <Grid item xs={6}>
            <Typography
              variant="h6"
              style={{ color: this.state.theme === "light" ? "#000" : "#fff" }}
            >
              {this.props.title}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-end">
              <FormControl
                variant="outlined"
                size="small"
                style={{ marginRight: 8, width: 150 }}
              >
                <Select
                  id="demo-simple-select-outlined"
                  value={this.state.fesearch}
                  onChange={this.handleChange}
                  displayEmpty
                >
                  <MenuItem value="">All Users</MenuItem>
                  <MenuItem value={"no"}>Active Users</MenuItem>
                  <MenuItem value={"yes"}>Deactive Users</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ marginRight: 8 }}>
                <TextField
                  id="outlined-basic"
                  placeholder={"Search " + this.props.title}
                  size="small"
                  variant="outlined"
                  value={this.state.searchValue}
                  onChange={this.handleSearch}
                />
              </FormControl>
              {/* <Link to="/admin/addservice">Add Service</Link> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.no</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Portal</TableCell>
                    <TableCell>Permission</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.users.map((user, i) => (
                    <TableRow key={user._id}>
                      <TableCell>{this.state.pagingCounter + i}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          padding: "15px",
                          color: "#f50057",
                          textTransform: "capitalize",
                        }}
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ padding: "15px" }}
                      >
                        {user.email}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        style={{ padding: "15px" }}
                      >
                        {user.role}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ padding: "15px" }}
                      >
                        <a
                          href={"/admin/allProducts/" + user._id}
                          // href={"http://localhost:3000/admin/allProducts/" + user._id}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          <Button
                            variant={"outlined"}
                            color="primary"
                            // style={{ marginRight: 4, backgroundColor:"#f0eded", padding:"7px" }}
                            size={"small"}
                            id="signInUser"
                          >
                            Sign in as a user
                          </Button>
                        </a>
                      </TableCell>
                      <TableCell align="left" style={{ padding: "17px" }}>
                        <div>
                          Terms:{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            <b> {user.is_news || "No"}</b>
                          </span>{" "}
                        </div>
                        <div>
                          Mail:{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            <b> {user.is_term_accepted || "No"}</b>
                          </span>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <Switch
                          color="primary"
                          name="checkedB"
                          checked={user.status === "active" ? true : false}
                          // value={user.status}
                          onChange={(e) =>
                            this.handleStatus(e, user._id, user.fe_user_id)
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>

                      <TableCell align="center" style={{ padding: "17px" }}>
                        {/* <Link
                          to={{
                            pathname: "/admin/updateuser/" + user._id,
                            // state: { data: cat },
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            variant={"outlined"}
                            color="default"
                            // style={{ marginRight: 4, backgroundColor:"#f0eded" }}
                            size={"small"}
                            id="updateUser"
                          >
                            Update
                          </Button>
                        </Link> */}
                        <Button
                          size={"small"}
                          variant={"outlined"}
                          id="deleteUser"
                          color="secondary"
                          onClick={() => {
                            this.setState({
                              visible: true,
                              deleteId: user._id,
                              feUserId: user.fe_user_id,
                            });
                            // this.deletecat(cat._id);
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                      {/* delete user form database */}
                      {/* <TableCell align="center" style={{padding:"17px"}}>
                        <Button
                          size={"small"}
                          variant={"outlined"}
                          id="deleteUser"
                          color="secondary"
                          onClick={() => {
                             this.deleteUserFormDatabase(user._id);
                          }}
                        >
                          Delete From Database 
                        </Button>
                      </TableCell> */}
                      {/* delete user form database */}
                    </TableRow>
                  ))}
                </TableBody>
                {/* <TableFooter>
                  <TableRow style={{ position: "relative" }}>
                    {this.state.users.length === 0 ? (
                      <div>
                        <h6
                          style={{
                            textAlign: "center",
                            marginTop: "30px",
                            fontSize: "14px",
                          }}
                        >
                          No Data Found!
                        </h6>
                      </div>
                    ) : this.state.empty ? null : (
                      <div style={{ marginTop: "20px", marginBottom: "70px" }}>
                        <div style={{ float: "left", marginLeft: "20px" }}>
                          Showing {this.state.from} to {this.state.to} of{" "}
                          {this.state.total} entries.
                        </div>

                        <div
                          style={{
                            float: "right",
                            position: "absolute",
                            right: "47px",
                            top: "5px",
                          }}
                        >
                          <Pagination
                            style={{
                              listStyle: "none",
                              display: "inline-flex",
                            }}>
                            <PaginationItem>
                              <PaginationLink
                                onClick={this.prevPages}
                                tabIndex="-1"
                                style={{
                                  background: "#dedddd",
                                  border: "none",
                                  borderRadius: "23px",
                                  height: "34px",
                                  width: "35px",
                                  marginRight: "10px",
                                  cursor:"pointer",
                                }}
                              >
                                {/* <i className="fa fa-angle-left" /> */}
                {/* <span className="sr-only">Previous</span> */}
                {/* <ArrowBackIosRoundedIcon
                                  style={{
                                    color: "dimgray",
                                    fontSize: "19px",
                                    marginTop: "2px",
                                    cursor:"pointer",
                                  }}
                                />
                              </PaginationLink>
                            </PaginationItem>
                            {this.state.filteredBtnIdArray.map((v, i) => (
                              <PaginationItem
                                className={
                                  this.state.active === v ? "active" : ""
                                }
                                style={{cursor:"pointer"}}
                                key={i}
                              >
                                <PaginationLink
                                  onClick={() => {
                                    this.currentPage(v);
                                  }}
                                  style={{
                                    background: this.state.active === v ? "#9fa8da" : "#dedddd",
                                    border: "none",
                                    borderRadius: "23px",
                                    height: "34px",
                                    width: "35px",
                                    marginRight: "10px",
                                    cursor:"pointer",
                                  }}
                                >
                                  {v}
                                </PaginationLink>
                              </PaginationItem>
                            ))}
                            <PaginationItem>
                              <PaginationLink onClick={this.nextPages} style={{
                                background: "#dedddd",
                                border: "none",
                                borderRadius: "23px",
                                height: "34px",
                                width: "35px",
                                marginRight: "10px",
                                cursor:"pointer",
                              }}>
                                {/* <i className="fa fa-angle-right" />
                                <span className="sr-only">Next</span> */}
                {/* <ArrowForwardIosRoundedIcon
                                  style={{
                                    color: "dimgray",
                                    fontSize: "19px",
                                    marginTop: "2px",
                                  }}
                                />
                              </PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </div>
                      </div>
                    )} */}
                {/* <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: -1 },
                          ]}
                          colSpan={6}
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                        /> */}
                {/* </TableRow>

                </TableFooter> */}
              </Table>

              {/* PAGINATION TABLE START */}
              {this.state.totalDocs !== 0 ? (
                <TableRow
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  {this.state.theme === "light" ? (
                    <div style={{ marginTop: 20 }}>
                      <p>
                        Showing {this.state.pagingCounter} to{" "}
                        {this.state.totalDocs >
                        this.state.limit * this.state.page
                          ? this.state.limit + this.state.pagingCounter - 1
                          : this.state.pagingCounter +
                            (this.state.totalDocs -
                              this.state.limit * this.state.forshow) -
                            1}{" "}
                        of {this.state.totalDocs} Entries
                      </p>
                    </div>
                  ) : (
                    <div style={{ marginTop: 20 }}>
                      <p style={{ color: "#fff" }}>
                        Showing {this.state.pagingCounter} to{" "}
                        {this.state.totalDocs >
                        this.state.limit * this.state.page
                          ? this.state.limit + this.state.pagingCounter - 1
                          : this.state.pagingCounter +
                            (this.state.totalDocs -
                              this.state.limit * this.state.forshow) -
                            1}{" "}
                        of {this.state.totalDocs} Entries
                      </p>
                    </div>
                  )}

                  <TableCell></TableCell>
                  <TableCell>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-5"
                        size="small"
                        style={{
                          margin: 3,
                          marginTop: 10,
                          height: 30,
                          width: 20,
                          // borderRadius: "50%",
                        }}
                        disabled={this.state.prevPage === false}
                        onClick={this.handlePrev}
                        // Hidden
                      >
                        <ArrowBackIos size="small" />
                      </Button>{" "}
                      {this.state.totalPage < 6
                        ? [...Array(this.state.totalPage)].map((data, i) => {
                            return (
                              <Button
                                variant={
                                  this.state.page === i + 1
                                    ? "contained"
                                    : "outlined"
                                }
                                color={
                                  this.state.page === i + 1
                                    ? "primary"
                                    : "inherit"
                                }
                                size="small"
                                value={i + 1}
                                onClick={() => this.btnCilck(i + 1)}
                                style={{
                                  margin: 3,
                                  marginTop: 10,
                                  height: 30,
                                  width: 20,
                                  // borderRadius: "100%",
                                  padding: 2,
                                }}
                              >
                                {i + 1}
                              </Button>
                            );
                          })
                        : (this.state.firstpage > 0 ? (
                            <Button
                              variant={
                                this.state.firstpage === this.state.page
                                  ? "contained"
                                  : "outlined"
                              }
                              color={
                                this.state.firstpage === this.state.page
                                  ? "primary"
                                  : "inherit"
                              }
                              size="small"
                              value={this.state.firstpage}
                              onClick={() =>
                                this.btnCilck(this.state.firstpage)
                              }
                              style={{
                                margin: 3,
                                marginTop: 10,
                                height: 30,
                                width: 20,
                                // borderRadius: "100%",
                                padding: 2,
                              }}
                            >
                              {this.state.firstpage}
                            </Button>
                          ) : null)(
                            <Button
                              variant={
                                this.state.page ? "contained" : "outlined"
                              }
                              color={this.state.page ? "primary" : "inherit"}
                              size="small"
                              value={this.state.page}
                              onClick={() => this.btnCilck(this.state.page)}
                              style={{
                                margin: 3,
                                marginTop: 10,
                                height: 30,
                                width: 20,
                                // borderRadius: "100%",
                                padding: 2,
                              }}
                            >
                              {this.state.page}
                            </Button>
                          )(
                            this.state.secondpage > 0 ? (
                              <Button
                                variant={
                                  this.state.secondpage === this.state.page
                                    ? "contained"
                                    : "outlined"
                                }
                                color={
                                  this.state.secondpage === this.state.page
                                    ? "primary"
                                    : "inherit"
                                }
                                size="small"
                                value={this.state.secondpage}
                                onClick={() =>
                                  this.btnCilck(this.state.secondpage)
                                }
                                style={{
                                  margin: 3,
                                  marginTop: 10,
                                  height: 30,
                                  width: 20,
                                  // borderRadius: "100%",
                                  padding: 2,
                                }}
                              >
                                {this.state.secondpage}
                              </Button>
                            ) : null
                          )(
                            this.state.page + 1 ===
                              this.state.totalPage ? null : this.state
                                .nextPage === false ? null : (
                              <Button
                                // variant={"outlined"}
                                color={"inherit"}
                                size="small"
                                value={this.state.secondpage + 1}
                                onClick={() =>
                                  this.btnCilck(this.state.secondpage + 1)
                                }
                                style={{
                                  margin: 3,
                                  marginTop: 10,
                                  height: 30,
                                  width: 20,
                                  fontSize: 30,
                                  // borderRadius: "100%",
                                  padding: 2,
                                }}
                              >
                                ...
                              </Button>
                            )
                          )({
                            /* {this.state.totalPage === this.state.page ? null : (
                      <Button
                        variant={
                          this.state.totalPage === this.state.page
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          this.state.totalPage === this.state.page
                            ? "primary"
                            : "inherit"
                        }
                        size="small"
                        value={this.state.totalPage}
                        onClick={() => this.btnCilck(this.state.totalPage)}
                        style={{
                          margin: 3,
                          marginTop: 10,
                          height: 30,
                          width: 20,
                          // borderRadius: "100%",
                          padding: 2,
                        }}
                      >
                        {this.state.totalPage}
                      </Button>
                    )} */
                          })}
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-5"
                        disabled={this.state.nextPage === false}
                        size="small"
                        style={{
                          margin: 3,
                          marginTop: 10,
                          height: 30,
                          width: 20,
                          // borderRadius: "50%",
                        }}
                        onClick={this.handleNext}
                      >
                        <ArrowForwardIos size="small" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <div style={{ padding: 8 }}>Data not available</div>
              )}
              {/* PAGINATION TABLE END */}
            </TableContainer>
          </Paper>

          <Modal
            visible={this.state.visible}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() => {
              this.setState({ visible: false });
            }}
          >
            <div className="ModalWrapper">
              <Typography className="Typo">
                {" "}
                Are you sure you want to delete <br /> this user ?
              </Typography>

              <Button
                size="medium"
                onClick={() => {
                  // this.deleteUser(this.state.deleteId, this.state.feUserId);
                  this.deleteUserFormDatabase(
                    this.state.deleteId,
                    this.state.feUserId
                  );
                }}
                variant="contained"
                color="secondary"
                className="Delbutton"
              >
                Delete
              </Button>

              <Button
                size="medium"
                onClick={() => {
                  this.setState({ visible: false });
                }}
                variant="contained"
                color="primary"
                className="Delbutton"
              >
                {" "}
                Cancel{" "}
              </Button>
              <Typography className="DeleteMsg">
                {" "}
                {this.state.notifyDelete ? this.state.delMsg : ""}{" "}
              </Typography>
            </div>
          </Modal>
        </Grid>
      </Container>
    );
  }
}
