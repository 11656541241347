import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal
} from "@material-ui/core";
import {
  ArrowBackIos,
  ArrowForwardIos
} from "@material-ui/icons";
import axios from "axios";
import React, { Component } from "react";
import Constants from "../Variables/Constants";
const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));
function createData(name, calories, fat) {
  return { name, calories, fat };
}
const rowsPerPage = 5;
const page = 1;

export default class SuggestionModel extends Component {
  state = {
    open: false,
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    pagingCounter: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    adminProductData: [],
    open: false,
    delMsg: "",
    visible: false,
    theme: "light",

    // PAGINATION STATES
    search: "",
    firstpage: 0,
    page: 1,
    secondpage: 0,
    totalPage: 0,
    limit: 10,
    nextPage: false,
    prevPage: false,
    pagingCounter: 1,
    totalDocs: 0,
    forshow: 0,
    filteredModels: [],
    open: false
    // PAGINATION
  };
  componentDidMount() {
    this.getAllSuggestionmodels();
  }
  getAllSuggestionmodels = () => {
    axios
      .get(
        Constants.getUrls.getSuggestionModels +
        "?page=" +
        this.state.page +
        "&limit=" +
        this.state.perPage
      )
      .then((resp) => {
        console.log(resp);
        this.setState({
          adminProductData: resp.data.models?.docs,
          firstpage: resp.data.models.prevPage,
          secondpage: resp.data.models.nextPage,
          page: resp.data.models.page,
          totalPage: resp.data.models.totalPages,
          nextPage: resp.data.models.hasNextPage,
          prevPage: resp.data.models.hasPrevPage,
          forshow: resp.data.models.prevPage,
          limit: resp.data.models.limit,
          pagingCounter: resp.data.models.pagingCounter,
          totalDocs: resp.data.models.totalDocs,
        });
      });
  };

  handleDelete = () => {
    axios.delete(Constants.getUrls.getSuggestionModels + '/' + this.state.selectedModel).then(res => {
      console.log(res)
      this.setState({ notification: true, message: 'Deleted Successfully', open: false }, () => {
        this.getAllSuggestionmodels()
      });
      setTimeout(() => {
        this.setState({ notification: false })
      }, 3000);
    }).catch(err => {
      if (err) console.log(err)
    })
  }
  render() {
    return (
      <Container maxWidth="xl">
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            color={"green"}
            autoHideDuration={6000}
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
          <Grid item xs={3}>
            <Typography
              variant="h6"
              style={{ color: this.state.theme === "light" ? "#000" : "#fff" }}
            >
              Suggestions Models
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid container justify="flex-end">
              {/* <FormControl style={{ marginRight: 8 }}>
                <TextField
                  id="outlined-basic"
                  placeholder={"Search " + this.props.title}
                  size="small"
                  variant="outlined"
                  onChange={this.handleSearch}
                />
                
              </FormControl> */}
              <a
                href={"/admin/product-create?suggestion_model=true"}
                style={{ textDecoration: "none" }}
              >
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add Suggestion Model
                </Button>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.no</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Poster Image</TableCell>
                    {/* <TableCell align="right">Status</TableCell> */}
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.adminProductData.map((adpro, i) => (
                    <TableRow key={adpro._id}>
                      <TableCell width={100}>
                        {this.state.pagingCounter + i}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {adpro.title}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <img
                          src={Constants.imageUrl + adpro.poster_image_url}
                          alt="Model Image"
                          style={{ width: "80px" }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant={"outlined"}
                          color="primary"
                          // style={{ marginRight: 4, backgroundColor:"#f0eded", padding:"7px" }}
                          size={"small"}
                          id="signInUser"
                          onClick={() => {
                            window.location.href = "/admin/product-create?suggestion_model=true&model=" + adpro._id
                          }}
                        >
                          Edit Product
                        </Button>
                        <Button
                          variant={"contained"}
                          color="secondary"
                          size={"small"}
                          style={{ padding: '8px', marginLeft: 12 }}
                          onClick={() => {
                            this.setState({ selectedModel: adpro._id, open: true })
                          }}
                        >
                          Delete Product
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* PAGINATION TABLE START */}
              {this.state.totalDocs !== 0 ? (
                <TableRow
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  {this.state.theme === "light" ? (
                    <div style={{ marginTop: 20 }}>
                      <p>
                        Showing {this.state.pagingCounter} to{" "}
                        {this.state.totalDocs >
                          this.state.limit * this.state.page
                          ? this.state.limit + this.state.pagingCounter - 1
                          : this.state.pagingCounter +
                          (this.state.totalDocs -
                            this.state.limit * this.state.forshow) -
                          1}{" "}
                        of {this.state.totalDocs} Entries
                      </p>
                    </div>
                  ) : (
                    <div style={{ marginTop: 20 }}>
                      <p style={{ color: "#fff" }}>
                        Showing {this.state.pagingCounter} to{" "}
                        {this.state.totalDocs >
                          this.state.limit * this.state.page
                          ? this.state.limit + this.state.pagingCounter - 1
                          : this.state.pagingCounter +
                          (this.state.totalDocs -
                            this.state.limit * this.state.forshow) -
                          1}{" "}
                        of {this.state.totalDocs} Entries
                      </p>
                    </div>
                  )}

                  <TableCell></TableCell>
                  <TableCell>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-5"
                        size="small"
                        style={{
                          margin: 3,
                          marginTop: 10,
                          height: 30,
                          width: 20,
                          // borderRadius: "50%",
                        }}
                        disabled={this.state.prevPage === false}
                        onClick={this.handlePrev}
                      // Hidden
                      >
                        <ArrowBackIos size="small" />
                      </Button>{" "}
                      {this.state.totalPage < 6
                        ? [...Array(this.state.totalPage)].map((data, i) => {
                          return (
                            <Button
                              variant={
                                this.state.page === i + 1
                                  ? "contained"
                                  : "outlined"
                              }
                              color={
                                this.state.page === i + 1
                                  ? "primary"
                                  : "inherit"
                              }
                              size="small"
                              value={i + 1}
                              onClick={() => this.btnCilck(i + 1)}
                              style={{
                                margin: 3,
                                marginTop: 10,
                                height: 30,
                                width: 20,
                                // borderRadius: "100%",
                                padding: 2,
                              }}
                            >
                              {i + 1}
                            </Button>
                          );
                        })
                        : (this.state.firstpage > 0 ? (
                          <Button
                            variant={
                              this.state.firstpage === this.state.page
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              this.state.firstpage === this.state.page
                                ? "primary"
                                : "inherit"
                            }
                            size="small"
                            value={this.state.firstpage}
                            onClick={() =>
                              this.btnCilck(this.state.firstpage)
                            }
                            style={{
                              margin: 3,
                              marginTop: 10,
                              height: 30,
                              width: 20,
                              // borderRadius: "100%",
                              padding: 2,
                            }}
                          >
                            {this.state.firstpage}
                          </Button>
                        ) : null)(
                          <Button
                            variant={
                              this.state.page ? "contained" : "outlined"
                            }
                            color={this.state.page ? "primary" : "inherit"}
                            size="small"
                            value={this.state.page}
                            onClick={() => this.btnCilck(this.state.page)}
                            style={{
                              margin: 3,
                              marginTop: 10,
                              height: 30,
                              width: 20,
                              // borderRadius: "100%",
                              padding: 2,
                            }}
                          >
                            {this.state.page}
                          </Button>
                        )(
                          this.state.secondpage > 0 ? (
                            <Button
                              variant={
                                this.state.secondpage === this.state.page
                                  ? "contained"
                                  : "outlined"
                              }
                              color={
                                this.state.secondpage === this.state.page
                                  ? "primary"
                                  : "inherit"
                              }
                              size="small"
                              value={this.state.secondpage}
                              onClick={() =>
                                this.btnCilck(this.state.secondpage)
                              }
                              style={{
                                margin: 3,
                                marginTop: 10,
                                height: 30,
                                width: 20,
                                // borderRadius: "100%",
                                padding: 2,
                              }}
                            >
                              {this.state.secondpage}
                            </Button>
                          ) : null
                        )(
                          this.state.page + 1 ===
                            this.state.totalPage ? null : this.state
                              .nextPage === false ? null : (
                            <Button
                              // variant={"outlined"}
                              color={"inherit"}
                              size="small"
                              value={this.state.secondpage + 1}
                              onClick={() =>
                                this.btnCilck(this.state.secondpage + 1)
                              }
                              style={{
                                margin: 3,
                                marginTop: 10,
                                height: 30,
                                width: 20,
                                fontSize: 30,
                                // borderRadius: "100%",
                                padding: 2,
                              }}
                            >
                              ...
                            </Button>
                          )
                        )}
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-5"
                        disabled={this.state.nextPage === false}
                        size="small"
                        style={{
                          margin: 3,
                          marginTop: 10,
                          height: 30,
                          width: 20,
                          // borderRadius: "50%",
                        }}
                        onClick={this.handleNext}
                      >
                        <ArrowForwardIos size="small" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <div style={{ padding: 8 }}>Data not available</div>
              )}
              {/* PAGINATION TABLE END */}
            </TableContainer>
          </Paper>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={() => {
            this.setState({ open: false })
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Fade in={this.state.open}>
            <div
              style={{
                background: "#fff",
                width: "50%",
                padding: "32px",
              }}
            >
              <h2 id="transition-modal-title">Are you sure you want to delete this model?</h2>
              <p id="transition-modal-description">
                By deleting this model, it won't affect the user if he/she used this model for suggestion.
              </p>

              <Button variant="outlined" size="small" color="default" onClick={() => {
                this.setState({ open: false })
              }}>Cancel</Button>
              <Button variant="contained" size="small" color="secondary" onClick={this.handleDelete} style={{ marginLeft: 12 }}>Delete</Button>
            </div>
          </Fade>
        </Modal>
      </Container>
    );
  }
}
