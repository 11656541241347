import React from "react";
import { useParams } from "react-router-dom";
import ModalDynamic from "./ModalDynamic";

export default function FunctionalModalDynamic() {
  let id = useParams();
  // console.log(id);
  return (
    <div>
      <ModalDynamic matchparams={id} />
    </div>
  );
}
