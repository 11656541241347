import React from "react";
import Drawer from "../Drawer";
import ContentStyleWrapper from "../ContentStyleWrapper";
const HotspotContentDrawer = ({ open, onClose, title, description, style }) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      style={{
        height: "100%",
        ...style,
      }}
    >
      <div
        style={{
          border: "0px solid red",
          paddingRight: "1rem",
          paddingBottom: "2rem",
        }}
      >
        <div
          style={{
            fontSize: "2.5rem",
            fontWeight: 600,
            color: "#2E2E2E",
            margin: "0.5rem 0",
          }}
        >
          {title}
        </div>

        <ContentStyleWrapper content={description} />
      </div>
    </Drawer>
  );
};

export default HotspotContentDrawer;
