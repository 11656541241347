import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import RemoveIcon from "@material-ui/icons/Remove";
// import right from "../Assets/Images/Right.png";
// import wrong from "../Assets/Images/Wrong.png";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
const ForUI = ({ propState: state, addExtraPoint, deletePoint }) => {
  useEffect(() => setPoints(state), [state]);

  const [propState, setPoints] = useState({});

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      <Typography variant="h6" fontStyle="italic" align="center">
        Preview
      </Typography>
      <Divider />
      <Typography variant="h4" align="center" gutterBottom>
        {propState?.title || "Title"}
      </Typography>
      <Typography
        variant="h6"
        style={{
          fontWeight: 800,
        }}
        align="center"
        gutterBottom
      >
        {propState?.item_price || "Price"}
      </Typography>
      <Typography variant="h5" align="center" gutterBottom>
        {propState?.status == "monthly" ? "/month" : "/year"}
      </Typography>{" "}
      <Typography variant="h6" align="center" gutterBottom>
        {propState?.sub_title || "Sub Title"}
      </Typography>
      {propState?.forUI?.points?.map((field) => (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {field.show ? (
            <CheckCircleIcon fontSize="small" size="12px" />
          ) : (
            <CancelIcon fontSize="small" size="12px" />
          )}
          <Typography
            variant="body1"
            style={{
              marginLeft: 10,
            }}
          >
            {field.value}
          </Typography>
        </Box>
      ))}
      <Divider
        style={{
          margin: "5px 0",
        }}
      />
      {propState.forUI?.extraPoints?.map(({ light, bold, id }) => (
        <Point
          light={light}
          bold={bold}
          key={id}
          onClick={() => deletePoint(id)}
          showFunction={true}
        />
      ))}
      <ExtraPoint addExtraPoint={addExtraPoint} />
    </div>
  );
};

const ExtraPoint = ({ addExtraPoint }) => {
  let resetPoint = {
    light: "",
    bold: "",
  };
  const [value, setValue] = useState(resetPoint);

  return (
    <Box
      style={{
        padding: 5,
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        border: "1px solid #ddd",
      }}
    >
      <Point light={value.light} bold={value.bold} />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          margin: "5px 0",
        }}
      >
        <TextField
          name="extraPoint"
          label="Add Light"
          variant="standard"
          size="small"
          value={value.light}
          onChange={(e) => {
            let light = e.target.value;
            setValue((p) => ({ ...p, light }));
          }}
          style={{
            marginRight: 5,
          }}
        />
        <TextField
          name="extraPoint1"
          label="Add Bold"
          variant="standard"
          size="small"
          value={value.bold}
          onChange={(e) => {
            let bold = e.target.value;
            setValue((p) => ({ ...p, bold }));
          }}
          style={{
            marginRight: 5,
          }}
        />
      </Box>
      <Flex>
        <Button
          style={{ flex: 0.5, marginRight: "0.2rem" }}
          color="primary"
          variant="contained"
          onClick={() => {
            addExtraPoint(value);

            setValue(resetPoint);
          }}
        >
          Add
        </Button>
        <Button
          style={{ flex: 0.5, marginLeft: "0.2rem" }}
          color="secondary"
          variant="contained"
          onClick={() => setValue(resetPoint)}
        >
          Cancel
        </Button>
      </Flex>
    </Box>
  );
};
export default ForUI;

const Flex = ({ children }) => (
  <Box
    style={{
      display: "flex",
      flexWrap: "wrap",
    }}
  >
    {children}
  </Box>
);

const Point = ({ light, bold, onClick, showFunction = false }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <Box
      style={{
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
        margin: "8px 0",
        padding: "5px 0",
      }}
      onMouseOver={() => setIsHover(!!showFunction && true)}
      onMouseLeave={() => setIsHover(!!showFunction && !true)}
    >
      {showFunction && isHover && (
        <Box
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 10,
            background: "rgba(0,0,0,0.1)",
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          <IconButton onClick={() => onClick?.()}>
            <DeleteIcon color="secondary" />
          </IconButton>
        </Box>
      )}
      <Typography
        style={{
          marginRight: 5,
        }}
      >
        {light}
      </Typography>

      <Typography>
        <b>{bold}</b>
      </Typography>
    </Box>
  );
};
