import React, { useEffect, useRef, useState } from "react";
import image from "../../../Assets/Images/pattern.jpg";
import * as THREE from "three";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { SVGLoader } from "three/addons/loaders/SVGLoader.js";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import axios from "axios";
import Constants from "../../Variables/Constants";

const width = window.screen.width;
const ModelTemplate = () => {
  const location = useLocation();
  const navigate = useHistory();

  const [data, setData] = useState(() => queryString.parse(location.search));
  const [file, setFile] = useState(image);
  // const [depth, setDepth] = useState(0.01);
  const [modelDimensions, setModelDimensions] = useState({
    height: data.type === "rugs" ? 0.01 : 0,
    width: 0,
    depth: 0.01,
  });
  const [fileName, setFileName] = useState("scene");
  const [scene, setScene] = useState(new THREE.Scene());
  const [isLoading, setIsLoading] = useState(false);
  const [isFileGenerating, setIsFileGenerating] = useState(false);
  const [dimensionUnit, setDimensionUnit] = useState("inches");

  const renderModelWithTextture = () => {
    scene.clear();
    setIsLoading(true);
    document.getElementsByTagName("canvas")[0]?.remove();
    const canvasWrapper = document.getElementById("canvasWrapper");

    const camera = new THREE.PerspectiveCamera();
    camera.position.z = 2;
    // camera.position.z = 5;

    const renderer = new THREE.WebGLRenderer({
      // alpha: true,
      antialias: true,
      //   gammaOutput: true,
      //   toneMapping: THREE.ReinhardToneMapping,
    });
    renderer.setClearColor(0x000000, 0);
    renderer.localClippingEnabled = false;

    // const ambientLight = new THREE.AmbientLight(0x404040);
    // scene.add(ambientLight)
    // document.body?.appendChild(renderer.domElement);
    // renderer.setSize(window.innerWidth, window.innerWidth);
    renderer.setSize(canvasWrapper.offsetWidth, canvasWrapper.offsetHeight);
    camera.aspect = canvasWrapper.offsetWidth / canvasWrapper.offsetHeight;
    camera.updateProjectionMatrix();
    canvasWrapper.appendChild(renderer.domElement);
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.update();
    new THREE.TextureLoader().load(file, (texture) => {
      //   texture.wrapS = THREE.ClampToEdgeWrapping;
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.minFilter = THREE.LinearFilter; // or THREE.NearestFilter
      texture.magFilter = THREE.LinearFilter;
      const objForFront = {
        color: "#fefefe",
        map: texture,
        side: THREE.DoubleSide,
      };

      const objForTop = {
        color: "#fefefe",
        map: texture,
        side: THREE.DoubleSide,
      };

      if (data.type === "rugs") {
        delete objForFront.map;
        delete objForTop.color;
      } else {
        delete objForFront.color;
        delete objForTop.map;
      }

      const material = [
        new THREE.MeshStandardMaterial({ color: "#fefefe" }),
        new THREE.MeshStandardMaterial({ color: "#fefefe" }),
        new THREE.MeshStandardMaterial(objForTop),
        new THREE.MeshStandardMaterial({ color: "#fefefe" }),
        new THREE.MeshStandardMaterial(objForFront),
        new THREE.MeshStandardMaterial({ color: "#fefefe" }),
      ];

      const hasDirectionalLight = () => {
        for (const object of scene.children) {
          if (object instanceof THREE.DirectionalLight) {
            return true; // Directional light already exists
          }
        }
        return false; // No directional light found
      };

      // Usage
      if (!hasDirectionalLight()) {
        const directionalLight = new THREE.DirectionalLight(0xffffff, 5);
        directionalLight.position.set(1, 1, 1).normalize();
        scene.add(directionalLight);
        const backDirectionalLight = new THREE.DirectionalLight(0xffffff, 5);
        backDirectionalLight.position.set(-1, -1, -1).normalize();
        scene.add(backDirectionalLight);
      }
      const modelWidth =
        dimensionUnit === "inches"
          ? modelDimensions.width * 0.0254
          : modelDimensions.width / 100;
      const modelHeight =
        dimensionUnit === "inches"
          ? modelDimensions.height * 0.0254
          : modelDimensions.height / 100;
      const modelDepth =
        dimensionUnit === "inches"
          ? modelDimensions.depth * 0.0254
          : modelDimensions.depth / 100;
      console.log(modelWidth, modelHeight, modelDepth);
      const geometry = new THREE.BoxGeometry(
        modelWidth,
        modelHeight,
        modelDepth
      );
      const cube = new THREE.Mesh(geometry, material);
      cube.castShadow = false;
      if (data.type === "rugs") {
        scene.rotation.x = 0.2;
      }
      scene.add(cube);
      setIsLoading(false);
      const animate = () => {
        requestAnimationFrame(animate);
        // cube.rotation.x += 0.01;
        // cube.rotation.y += 0.01;
        renderer.render(scene, camera);
      };
      animate();
    });
  };

  const setCubeSizeFromImage = (imgUrl) => {
    const img = new Image();
    img.src = imgUrl;
    console.log(img);
    img.onload = () => {
      const imgHeight = img.height;
      const imgWeight = img.width;
      const dpi = 96;
      // let cubeHeight = imgHeight * 0.000265;
      // let cubeWidth = imgWeight * 0.000265;

      let cubeHeightInches = (imgHeight / dpi).toFixed(2);
      let cubewidthInches = (imgWeight / dpi).toFixed(2);
      // console.log(imgHeight, cubeHeight, imgWeight, cubeWidth);
      setModelDimensions({
        width: cubewidthInches,
        height:
          data.type === "rugs" ? modelDimensions.height : cubeHeightInches,
        depth: data.type === "rugs" ? cubeHeightInches : modelDimensions.depth,
      });
    };
  };

  useEffect(() => {
    setCubeSizeFromImage(image);
  }, []);

  useEffect(() => {
    renderModelWithTextture();
  }, [modelDimensions, dimensionUnit]);

  const updateModel = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setFile(reader.result);
      setCubeSizeFromImage(reader.result);
    };
  };

  const submit = () => {
    setIsFileGenerating(true);
    const exporter = new GLTFExporter();
    // scene.rotation.x = 0
    exporter.parse(
      scene,
      (gltf) => {
        console.log(gltf);
        saveArrayBuffer(gltf, fileName + ".glb");
      },
      (err) => {
        console.log(err);
      },
      { binary: true }
    );
  };

  function saveArrayBuffer(buffer, filename) {
    save(new Blob([buffer], { type: "application/octet-stream" }), filename);
  }
  // const link = document.createElement('a');
  // link.style.display = 'none';
  // document.body.appendChild(link);

  async function save(blob, filename) {
    // link.href = URL.createObjectURL(blob);
    // link.download = filename;
    // link.click();
    // setIsFileGenerating(false)
    try {
      console.log(data);

      const formData = new FormData();
      formData.append("model_file", blob);
      formData.append("product", data.product);
      if (typeof data.materials === "object") {
        data.materials.forEach((m) => {
          formData.append("materials[]", m);
        });
      }
      if (typeof data.materials === "string") {
        formData.append("materials[]", data.materials);
      }

      let axiosRef = axios;

      let url = Constants.postUrls.addVariants;
      if (data.variant != "undefined") {
        axiosRef = axiosRef.put;
        url += "/" + data.variant;
      } else {
        axiosRef = axiosRef.post;
        url += "/" + data.product;
      }
      const resp = await axiosRef(url, formData);
      if (resp.data.status === "success") {
        navigate.goBack();
      }
    } catch (err) {
      if (err) console.log(err);
      // console.log("error from catych block ", err);
    } finally {
      // navigate(-1)
      setIsFileGenerating(false);
    }
  }

  return (
    <>
      <div className="modelTemplateWrapper" style={{ marginTop: "24px" }}>
        <Container className="containerSpacingdashBoard" maxWidth={"lg"}>
          <Grid
            direction="row"
            container
            spacing={3}
            style={{ minHeight: "70vh" }}
          >
            <Grid item md={8}>
              <div
                className="newShadow"
                style={{ height: "100%", position: "relative" }}
              >
                <div id="canvasWrapper"></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: 20,
                    width: "100%",
                  }}
                >
                  <div
                    className="newShadow uploadImageBtn"
                    style={{
                      width: "360px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "48px",
                      padding: 12,
                      textAlign: "center",
                      position: "relative",
                      background: "#fff",
                    }}
                  >
                    <input
                      type="file"
                      id="file"
                      style={{
                        opacity: 0,
                        height: "48px",
                        width: "100%",
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 2,
                      }}
                      onChange={updateModel}
                    />
                    <span style={{ position: "absolute", zIndex: 1 }}>
                      Upload Image of your{" "}
                      {data.type === "rugs" ? "rug" : "artwork"}
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              <div
                className="newShadow dimensionWrapper"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h4
                    style={{
                      textAlign: "center",
                      marginBottom: "35px",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Adjust the dimensions of your{" "}
                    {data.type === "rugs" ? "rug" : "artwork"}
                  </h4>
                  <div>
                    <label>Select your dimension unit</label>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        value={dimensionUnit}
                        onChange={(e) => setDimensionUnit(e.target.value)}
                      >
                        <FormControlLabel
                          style={{ width: "100px" }}
                          value="inches"
                          control={<Radio color="primary" />}
                          label="inches"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          style={{ width: "100px" }}
                          value="cms"
                          control={<Radio color="primary" />}
                          label="cms"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <label>Width</label>
                    <input
                      className={"formEle"}
                      style={{ border: "none", outline: "none" }}
                      value={modelDimensions.width}
                      onChange={(e) =>
                        setModelDimensions({
                          ...modelDimensions,
                          width: e.target.value,
                        })
                      }
                    />
                    {data.type === "rugs" ? (
                      <>
                        <div>
                          <label>{"Length"}</label>
                          <input
                            className={"formEle"}
                            style={{ border: "none", outline: "none" }}
                            value={modelDimensions.depth}
                            onChange={(e) =>
                              setModelDimensions({
                                ...modelDimensions,
                                depth: e.target.value,
                              })
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <label>{"Height"}</label>
                        <div>
                          <input
                            className={"formEle"}
                            style={{ border: "none", outline: "none" }}
                            value={modelDimensions.height}
                            onChange={(e) =>
                              setModelDimensions({
                                ...modelDimensions,
                                height: e.target.value,
                              })
                            }
                          />
                        </div>
                      </>
                    )}
                    {data.type === "rugs" ? (
                      <>
                        <label>{"Thickness"}</label>
                        <div>
                          <input
                            className={"formEle"}
                            style={{ border: "none", outline: "none" }}
                            value={modelDimensions.height}
                            onChange={(e) =>
                              setModelDimensions({
                                ...modelDimensions,
                                height: e.target.value,
                              })
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <label>{"Length"}</label>
                          <input
                            className={"formEle"}
                            style={{ border: "none", outline: "none" }}
                            value={modelDimensions.depth}
                            onChange={(e) =>
                              setModelDimensions({
                                ...modelDimensions,
                                depth: e.target.value,
                              })
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "rgba(46, 46, 46, 1)",
                    }}
                  >
                    * Dimension units are in {dimensionUnit} *
                  </p>
                </div>
                {/* <input style={{ border: 'none', outline: 'none' }} value={fileName} onChange={(e) => setFileName(e.target.value)} /> */}
              </div>
              <button
                disabled={isFileGenerating}
                onClick={submit}
                className="uploadBtn"
              >
                {isFileGenerating ? "Uploading..." : "Save"}
              </button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default ModelTemplate;
