import {
  Button,
  Container,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constants";
import Modal from "react-awesome-modal";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import ContactInfoDialog from "../../Components/ContactInfoDialog";
import { useEffect } from "react";
// import { KeyboardDatePicker } from "@material-ui/pickers";
const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));
function createData(name, calories, fat) {
  return { name, calories, fat };
}
const rowsPerPage = 5;
const page = 1;

export default class Contacts extends Component {
  state = {
    contacts: [],
    open: false,
    getBlogs: [],
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    theme: "light",

    // PAGINATION STATES
    search: "",
    firstpage: 0,
    page: 1,
    secondpage: 0,
    totalPage: 0,
    limit: 10,
    nextPage: false,
    prevPage: false,
    pagingCounter: 1,
    totalDocs: 0,
    forshow: 0,

    // PAGINATION
  };
  componentDidMount() {
    this.getContacts();
  }
  serializeContacts = (contacts) =>
    contacts.map((c, index) => ({
      ...c,
      serNo: index + 1 + (this.state.page - 1) * this.state.perPage,
    }));
  getContacts = () => {
    if (init() === "success") {
      axios
        .get(
          Constants.getUrls.contacts +
            "?page=" +
            this.state.page +
            "&limit=" +
            this.state.perPage +
            "&is_delete=false"
        )
        .then((resp) => {
          this.setState({ contacts: [] });
          // console.log(resp);
          this.setState({
            contacts: resp.data.contacts.docs,

            // PAGINATION
            firstpage: resp.data.contacts.prevPage,
            secondpage: resp.data.contacts.nextPage,
            page: resp.data.contacts.page,
            totalPage: resp.data.contacts.totalPages,
            nextPage: resp.data.contacts.hasNextPage,
            prevPage: resp.data.contacts.hasPrevPage,
            forshow: resp.data.contacts.prevPage,
            limit: resp.data.contacts.limit,
            pagingCounter: resp.data.contacts.pagingCounter,
            totalDocs: resp.data.contacts.totalDocs,
          });
        });
    }
  };
  // handleRows start

  handleRows = (value) => {
    this.setState(
      {
        limit: value,
        page: 1,
      },
      () => {
        this.getContacts();
      }
    );
  };

  // handleRows end
  // PAGINATION BUTTONS

  handleNext = () => {
    // console.log("click");
    if (this.state.nextPage === true) {
      // console.log(this.state.nextPage);

      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.getContacts();
        }
      );
    }
  };

  handlePrev = () => {
    if (this.state.prevPage === true)
      this.setState(
        {
          page: this.state.page - 1,
        },
        () => {
          this.getContacts();
        }
      );
  };

  btnCilck = (i) => {
    // console.log(i);
    this.setState(
      {
        page: i,
      },
      () => {
        this.getContacts();
      }
    );
  };

  deleteUser = (id) => {
    if (init() == "success") {
      this.setState({ delLoading: true });
      axios.delete(Constants.postUrls.deleteContact + "/" + id).then((res) => {
        // console.log(res);
        this.setState({
          notifyDelete: true,
          delLoading: false,
          delMsg: res.data.message,
        });
        setTimeout(() => {
          this.setState({ notifyDelete: false, visible: false }, () => {
            this.getContacts();
          });
        }, 2000);
      });
    }
  };
  handleSearch = (value, isDate = false) => {
    const clearSearching = () => {
      this.setState({ searching: false });
      this.getContacts();
      return;
    };
    this.setState({ searching: true });
    let query = "";
    if (isDate) {
      if (!value && !this.state.searchValue) {
        clearSearching();
        return;
      }
      query = `q=${this.state.searchValue ?? ""}&date=${value}`;
    } else {
      if (!value && !this.state.dateValue) {
        clearSearching();
        return;
      }

      query = `q=${value}&date=${this.state.dateValue ?? ""}`;
    }
    // return;
    axios
      .get(
        Constants.getUrls.contacts +
          "?page=1" +
          "&limit=" +
          this.state.perPage +
          "&" +
          query
        // this.state.name
      )
      .then((resp) => {
        // console.log("resp froms earcg ", resp.data.contacts.docs);
        this.setState({ contacts: [] });
        this.setState({
          contacts: resp.data.contacts.docs,

          // PAGINATION
          firstpage: resp.data.prevPage,
          secondpage: resp.data.nextPage,
          page: resp.data.age,
          totalPage: resp.data.totalPages,
          nextPage: resp.data.hasNextPage,
          prevPage: resp.data.hasPrevPage,
          forshow: resp.data.revPage,
          limit: resp.data.imit,
          pagingCounter: resp.data.agingCounter,
          totalDocs: resp.data.otalDocs,
        });
      });
  };
  render() {
    return (
      <Container>
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
          <Grid item xs={6}>
            <Typography
              variant="h6"
              style={{ color: this.state.theme === "light" ? "#000" : "#fff" }}
            >
              {this.props.title}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-end">
              <DatePicker
                onChange={(dateValue) => {
                  this.setState({ dateValue });
                  this.handleSearch(dateValue, true);
                }}
              />
              <FormControl style={{ marginRight: 8 }}>
                <TextField
                  id="outlined-basic"
                  value={this.state.searchValue}
                  placeholder={"Search " + this.props.title}
                  size="small"
                  variant="outlined"
                  onChange={(e) => {
                    this.setState({ searchValue: e.target.value });
                    this.handleSearch(e.target.value, false);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Ref No.</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Content</TableCell>

                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.contacts.map((user, userIndex) => (
                    <TableRow key={user.name + userIndex}>
                      <TableCell component="th" scope="row">
                        {user.refNo}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.email}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.createdAt.substring(0, 10)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <ContactInfoDialog user={user} />
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          size={"small"}
                          variant={"outlined"}
                          color="secondary"
                          onClick={() => {
                            this.setState({
                              visible: true,
                              deleteId: user._id,
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* PAGINATION TABLE START */}
              {this.state.totalDocs !== 0 ? (
                <TableRow
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  {this.state.theme === "light" ? (
                    <div style={{ marginTop: 20 }}>
                      <p>
                        Showing {this.state.pagingCounter} to{" "}
                        {this.state.totalDocs >
                        this.state.limit * this.state.page
                          ? this.state.limit + this.state.pagingCounter - 1
                          : this.state.pagingCounter +
                            (this.state.totalDocs -
                              this.state.limit * this.state.forshow) -
                            1}{" "}
                        of {this.state.totalDocs} Entries
                      </p>
                    </div>
                  ) : (
                    <div style={{ marginTop: 20 }}>
                      <p style={{ color: "#fff" }}>
                        Showing {this.state.pagingCounter} to{" "}
                        {this.state.totalDocs >
                        this.state.limit * this.state.page
                          ? this.state.limit + this.state.pagingCounter - 1
                          : this.state.pagingCounter +
                            (this.state.totalDocs -
                              this.state.limit * this.state.forshow) -
                            1}{" "}
                        of {this.state.totalDocs} Entries
                      </p>
                    </div>
                  )}
                  <TableCell></TableCell>
                  <TableCell>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-5"
                        size="small"
                        style={{
                          margin: 3,
                          marginTop: 10,
                          height: 30,
                          width: 20,
                          // borderRadius: "50%",
                        }}
                        disabled={this.state.prevPage === false}
                        onClick={this.handlePrev}
                        // Hidden
                      >
                        <ArrowBackIos size="small" />
                      </Button>{" "}
                      {this.state.totalPage < 6
                        ? [...Array(this.state.totalPage)].map((data, i) => {
                            return (
                              <Button
                                key={Math.random()}
                                variant={
                                  this.state.page === i + 1
                                    ? "contained"
                                    : "outlined"
                                }
                                color={
                                  this.state.page === i + 1
                                    ? "primary"
                                    : "inherit"
                                }
                                size="small"
                                value={i + 1}
                                onClick={() => this.btnCilck(i + 1)}
                                style={{
                                  margin: 3,
                                  marginTop: 10,
                                  height: 30,
                                  width: 20,

                                  padding: 2,
                                }}
                              >
                                {i + 1}
                              </Button>
                            );
                          })
                        : null}
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-5"
                        disabled={this.state.nextPage === false}
                        size="small"
                        style={{
                          margin: 3,
                          marginTop: 10,
                          height: 30,
                          width: 20,
                        }}
                        onClick={this.handleNext}
                      >
                        <ArrowForwardIos size="small" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <div style={{ padding: 8 }}>Data not available</div>
              )}
              {/* PAGINATION TABLE END */}
            </TableContainer>
          </Paper>
        </Grid>
        <Modal
          visible={this.state.visible}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div className="ModalWrapper">
            <Typography className="Typo">
              {" "}
              Are you sure you want to delete <br /> this service ?
            </Typography>

            <Button
              size="medium"
              onClick={() => {
                this.deleteUser(this.state.deleteId);
              }}
              variant="contained"
              color="secondary"
              className="Delbutton"
            >
              Delete
            </Button>

            <Button
              size="medium"
              onClick={() => {
                this.setState({ visible: false });
              }}
              variant="contained"
              color="primary"
              className="Delbutton"
            >
              {" "}
              Cancel{" "}
            </Button>
            <Typography className="DeleteMsg">
              {" "}
              {this.state.notifyDelete ? this.state.delMsg : ""}{" "}
            </Typography>
          </div>
        </Modal>
      </Container>
    );
  }
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const DatePicker = ({ onChange }) => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  // useEffect(() => {
  // // console.log(new Date().toISOString().substring(0, 10));
  // }, []);
  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        type="date"
        size="small"
        variant="outlined"
        value={selectedDate}
        // defaultValue="2017-05-24"
        className={classes.textField}
        inputProps={{
          max: new Date().toISOString().substring(0, 10),
        }}
        onChange={(e) => {
          // console.log("chnage ");
          setSelectedDate(e.target.value);
          onChange(e.target.value);
          // console.log("first ", e.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onClick={(e) => {
          // console.log("first");
        }}
      />
    </form>
  );
  // return (
  //   <KeyboardDatePicker
  //     disableToolbar
  //     variant="inline"
  //     format="MM/dd/yyyy"
  //     margin="normal"
  //     id="date-picker-inline"
  //     label="Date picker inline"
  //     value={selectedDate}
  //     onChange={handleDateChange}
  //     KeyboardButtonProps={{
  //       "aria-label": "change date",
  //     }}
  //   />
  // );
};
