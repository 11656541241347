import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import { IconButton, Typography } from "@material-ui/core";
import { deleteCombination, deleteRecommend, getAllCombinations } from "./lib";
import ProductSearchDialog from "./ProductSearchDialog";
import Constants from "../../App/Variables/Constants";
import CreateRecommendDialog from "./CreateRecommendDialog";
import DeleteConfirmation from "./DeleteConfirmation";
const RecommendBox = ({
  recommendProduct,
  fetchRecommends,
  recommendProducts,
  productId,
}) => {
  const [expand, setExpand] = useState(true);

  const [combinations, setCombinations] = useState([]);

  const fetchCombinations = () => {
    getAllCombinations(recommendProduct._id)
      .then(({ combinations }) => setCombinations(combinations))
      .catch(() => {});
  };

  useEffect(() => {
    fetchCombinations();
  }, []);
  useEffect(() => {
    // console.clear();
    // console.log("combinations ", combinations);
  }, [combinations]);

  const [openSearch, setOpenSearch] = useState(false);
  const [selectedCombination, setSelectedCombination] = useState("");
  const onClickEdit = (selectedCombination) => {
    // console.log("onClickEdit ", selectedCombination);
    setSelectedCombination({
      ...selectedCombination,
      product: selectedCombination.product._id,
    });
    setOpenSearch(true);
  };
  return (
    <>
      <ProductSearchDialog
        open={openSearch}
        selectedCombination={selectedCombination}
        onSubmitCb={() => {
          fetchCombinations();
        }}
        recommendId={recommendProduct._id}
        handleClose={() => {
          setOpenSearch(false);
          setSelectedCombination(null);
        }}
        reverseSelectedProduct={() => {
          setSelectedCombination(null);
        }}
        combinations={combinations.map(({ product, ...rest }) => ({
          ...rest,
          product: product._id,
        }))}
        productId={productId}
      />
      <div
        className="box1"
        style={{
          height: expand ? "226px" : "30px",

          // position: "relative",
          transitionDuration: "222ms",
          overflow: "auto",
          // border: "1px solid blue",
          paddingLeft: 18,
        }}
      >
        <Header
          recommendId={recommendProduct._id}
          expand={expand}
          handleExpand={() => setExpand((p) => !p)}
          recommendProduct={recommendProduct}
          postActionCB={fetchRecommends}
          recommendProducts={recommendProducts}
          productId={productId}
        />
        {/* {loremIpsum()} */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            // alignItems: "center",
          }}
        >
          {expand &&
            combinations.map((combination) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  marginRight: 20,
                  marginBottom: 10,
                  // border: "1px solid blue",
                  maxWidth: 50,
                }}
                key={combination._id}
              >
                <div
                  style={{
                    position: "relative",
                    width: 50,
                    height: 50,

                    marginBottom: 10,
                  }}
                >
                  <DeleteComb
                    combinationId={combination._id}
                    postDeleteCB={fetchCombinations}
                  />
                  <img
                    className="smallBox"
                    height="50"
                    width="50"
                    src={
                      Constants.imageUrl + combination.product.poster_image_url
                    }
                    style={{
                      borderRadius: 5,
                      marginLeft: 0,
                      // marginRight: 10,
                      // marginBottom: 8,
                      // border: "1px solid blue",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <IconButton onClick={() => onClickEdit(combination)}>
                      <EditOutlinedIcon
                        style={{
                          color: "#000",
                          fontSize: "22px",

                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "14px",
                    color: "#565656",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    textAlign: "center",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    // border: "1px solid red",
                    wordBreak: "break-all",
                  }}
                >
                  {combination.title}
                </p>
              </div>
            ))}
          <PlusBox onClick={() => setOpenSearch(true)} />
        </div>
      </div>
    </>
  );
};

export default RecommendBox;

const PlusBox = ({ onClick }) => (
  <div
    className="secondBox"
    style={{
      cursor: "pointer",
      marginLeft: 0,
      marginRight: 10,
      marginBottom: 10,
      // margin: 0,
      // margi
      // border: "1px solid red",
      //  top: 0
    }}
    onClick={onClick}
  >
    <AddIcon
      style={{
        marginTop: "14px",
        color: "gray",
        cursor: "pointer",
      }}
    />

    {/* <Typography
  className="default"
  style={{
    marginLeft: "0px",
    width: "55px",
    lineHeight: "19px",
  }}
>
  Add Material
</Typography> */}
  </div>
);

const Header = ({
  handleExpand,
  recommendProduct,
  expand,
  recommendId,
  postActionCB,
  recommendProducts,
  productId,
}) => {
  const [title, setTitle] = useState(recommendProduct.title);
  const [isLoading, setIsLoading] = useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const daleteRecommendation = async () => {
    setIsLoading(true);
    try {
      // console.log("recommendId ", recommendId);
      const data = await deleteRecommend(recommendId);
      postActionCB();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  const [openEditDialog, setOpenEditDialog] = useState(false);
  useEffect(() => {
    // console.log("header was updated ", recommendProduct.title);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        // border: "1px solid red",
        marginBottom: 7,
        // padding
      }}
    >
      <DeleteConfirmation
        open={openDeleteConfirmation}
        handleClose={() => setOpenDeleteConfirmation(false)}
        handleSubmit={async () => {
          await daleteRecommendation();
          setOpenDeleteConfirmation(false);
        }}
      />
      <CreateRecommendDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        productId={productId}
        previousTitles={recommendProducts.map(({ title }) => title)}
        onSubmitCB={(newTitle) => {
          // postActionCB();
          setTitle(newTitle);
        }}
        recommendId={recommendId}
        isEdit={true}
        value={recommendProduct.title}
      />
      <Typography
        style={{
          fontWeight: 600,
          fontSize: 16,
          color: "#2e2e2e",
          textOverflow: "ellipsis",
          overflow: "hidden",
          wordBreak: "no-break",
          whiteSpace: "nowrap",
          flex: 1,
          fontSize: 16,
          paddingRight: 4,
        }}
      >
        {title}
      </Typography>
      <IconButton
        disabled={isLoading}
        size="small"
        onClick={() => {
          setOpenEditDialog(true);
        }}
      >
        <EditOutlinedIcon
          style={{
            color: "#bdbdbd",
            fontSize: "18px",
            cursor: "pointer",
          }}
        />
      </IconButton>
      <IconButton
        disabled={isLoading}
        size="small"
        onClick={() => setOpenDeleteConfirmation(true)}
      >
        <CloseIcon
          style={{
            color: "#bdbdbd",
            fontSize: "18px",
            cursor: "pointer",
            // marginLeft: 5,
          }}
        />
      </IconButton>
      <IconButton
        disabled={isLoading}
        size="small"
        onClick={() => {
          handleExpand();
        }}
      >
        {expand ? (
          <ExpandLessIcon
            style={{
              color: "#bdbdbd",
              // fontSize: "30px",
              cursor: "pointer",
              // marginLeft: 5,
            }}
          />
        ) : (
          <ExpandMoreRoundedIcon
            style={{
              color: "#bdbdbd",
              // fontSize: "30px",
              cursor: "pointer",
              // marginLeft: 5,
            }}
          />
        )}
      </IconButton>
    </div>
  );
};

const DeleteComb = ({ combinationId, postDeleteCB }) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleDelete = () => {
    setOpenDeleteConfirmation(true);
  };

  const deleteCombinationHandle = async () => {
    try {
      await deleteCombination(combinationId);
      postDeleteCB();
    } catch (err) {}
  };
  return (
    <div
      onClick={handleDelete}
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translate(50%,-30%)",
        padding: 5,
        // border: "1px solid red",
        display: "grid",
        placeItems: "center",
      }}
    >
      <DeleteConfirmation
        title={"Are you sure you want to delete this?"}
        open={openDeleteConfirmation}
        handleClose={() => {
          // alert("close");
          setOpenDeleteConfirmation(false);
        }}
        handleSubmit={async () => {
          await deleteCombinationHandle();
          setOpenDeleteConfirmation(false);
        }}
      />
      <CloseIcon
        style={{
          color: "#fff",
          fontSize: "13px",
          // position: "absolute",
          // right: "-7px",
          // top: "-8px",
          // right: 0,
          // top: 0,
          // transform: "translate(50%,-30%)",
          cursor: "pointer",
          background: "gray",
          borderRadius: "10px",
        }}
      />
      {/* <IconButton
    size="small"
    style={{
      background: "#ddd",
      color: "white",
    }}
  >
    <HighlightOffIcon fontSize="small" />
  </IconButton> */}
    </div>
  );
};
