import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../../../routes";

export default class Sidebar extends Component {
  render() {
    return (
      <List
        component="nav"
        style={{ minHeight: "90vh", borderRight: "1px solid #ddd",maxHeight:"90vh",overflowY:"scroll" }}
      >
        {routes.map((route, index) => route.isVisible && (
          <NavLink
            to={route.path}
            key={index}
            style={{ color: "inherit", textDecoration: "none" }}
            className={(isActive) =>
              "XColor" + (!isActive ? " unselected" : "")
            }
            activeClassName={"navactive"}
            activeStyle={{
              fontWeight: "bold",
              color: "#15add1",
            }}
          >
            <ListItem button>
              {/* <ListItemIcon>{<route.icon />}</ListItemIcon> */}
              <ListItemText
                children={
                  <Typography
                    variant={"body2"}
                    style={{ fontSize: 14 }}
                    color="textPrimary"
                    className="XColor"
                  >
                    <div style={{ display: "flex", alignItem: "center" }}>
                      <span>{route.icon}</span>
                      <span
                        className="xColor"
                        style={{ paddingLeft: "10px", paddingTop: "2px" }}
                      >
                        {route.title}
                      </span>
                    </div>
                  </Typography>
                }
                style={{ fontSize: 12 }}
              />
            </ListItem>
          </NavLink>
        ))}
      </List>
    );
  }
}
