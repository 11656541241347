import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  TextField,
  Snackbar,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import Constants from "../App/Variables/Constants";
import init from "../helpers/WindowToken";
import EditIcon from "@material-ui/icons/Edit";
import { Redirect, Link } from "react-router-dom";
import { Form } from "reactstrap";

export default class AddService extends Component {
  state = {
    title: "",
    description: "",
    loading: false,
  };

  addservices = (e) => {
    e.preventDefault();
    let serviceload = {
      title: this.state.title,
      description: this.state.description,
    };
    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios.post(Constants.postUrls.addservices, serviceload).then((resp) => {
        // console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 1500);
        } else {
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 1500);
        }
      });
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/services" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">{this.props.title}</Typography>
            </Grid>
          </Grid>
          {/* <UncontrolledAlert color="success" fade={false}>
              <span className="alert-inner--icon">
                <i className="ni ni-like-2" />
              </span>{" "}
              <span className="alert-inner--text">
                <strong>{this.state.message}!</strong>
              </span>
            </UncontrolledAlert> */}
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"green"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          <div
            // style={{
            //   background: "#5b5757",
            //   padding: "20px",
            //   borderRadius: "10px",
            //   // color: rgba(0, 0, 0, 0.87);
            //   width: "100%",
            //   border: "0",
            //   display: "flex",
            //   position: "relative",
            //   fontSize: ".875rem",
            //   minWidth: "0",
            //   wordWrap: " break-word",
            //   /* background: #FFF; */
            //   boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            //   marginTop: "30px",
            //   borderRadius: "6px",
            //   marginBottom: "30px",
            //   flexDirection: "column",
            //   textAlign: "left",
            // }}
            style={{
              padding: "20px",
              border: "1px solid #afa2a2",
              borderRadius: "7px",
              position: "relative",
              // background:"#3f51b530"
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>
            <span
              style={{
                color: "#afa3a3",
                marginTop: "-8px",
                marginLeft: "26px",
                marginBottom: "35px",
                fontSize: "17px",
                display: "flex",
              }}
            >
              Add Service
            </span>

            <Grid item sm={12} style={{ textAlign: "left", marginTop: "30px" }}>
              <Form onSubmit={this.addservices}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="Title"
                  name="title"
                  variant="outlined"
                  required={true}
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  label="Description"
                  onChange={this.handleInput}
                  name="description"
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <br />
                <Button
                  variant="contained"
                  type="submit"
                  Size={15}
                  color="primary"
                  // disabled={this.state.filledInputs?false:true}
                  style={{ marginRight: "20px", marginTop: "35px" }}
                >
                  {this.state.loading ? "Adding.." : "Add Service"}
                </Button>
                <Link
                  to={{
                    pathname: "/services",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    Size={15}
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Form>
            </Grid>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Fade in={this.state.open}>
              <div
                style={{
                  background: "#fff",
                  width: "50%",
                  padding: "32px",
                }}
              >
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">
                  react-transition-group animates me.
                </p>
              </div>
            </Fade>
          </Modal>
        </Container>
      );
    }
  }
}
