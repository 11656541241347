import React, { forwardRef, useRef, useState } from "react";
import Tab from "./Tabs";
import { boxShadow, borderRadius, spacing } from "./const";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import qrCodeShort from "../../Assets/newIcons/qrCodeShort.png";
import actLogo from "../../Assets/newIcons/actLogo.png";
import { downloadCanvasAsImage } from "../../helpers/utils";
// import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";

import { QRCode as QRCodeGen } from "react-qrcode-logo";

// const QRCodeGen = () => null;
const QRCode = ({ productId }) => {
  const canvas = useRef();
  const [qrLogo, setQrLogo] = useState("");
  const [qrFgColor, setQrFgColor] = useState("#000000");
  const [qrStyle, setQrStyle] = useState("dots");
  return (
    <Tab text={`Customize the size of the QR code for your embedded model`}>
      <div
        style={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "space-between",
          paddingTop: spacing,
          paddingBottom: spacing,
          marginBottom: spacing * 3,
          // height: 400,
        }}
      >
        {" "}
        <div
          style={{
            flex: 0.3,
            boxShadow,
            borderRadius,
            padding: spacing,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: 2 * spacing,
            paddingTop: 2 * spacing,
            alignItems: "center",
            justifyContent: "center",
            // border: "1px solid red",
          }}
        >
          {" "}
          <div
            style={{
              paddingLeft: spacing * 2,
              paddingRight: spacing * 2,
            }}
          >
            <QRImage
              size={250}
              logoWidth={50}
              logoHeight={50}
              productId={productId}
              ref={canvas}
              qrFgColor={qrFgColor}
              qrLogo={qrLogo}
              qrStyle={qrStyle}
            />
          </div>
        </div>
        <div
          style={{
            flex: 0.68,
            overflow: "hidden",
            display: "flex",
            boxShadow,
            borderRadius,
            padding: spacing * 3,
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <span
              style={{
                fontSize: 24,
                color: "#2e2e2e",
                fontWeight: 500,
              }}
            >
              QR code
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: spacing,
                marginRight: spacing,
              }}
            >
              <div
                onClick={() => {
                  setQrStyle("dots");
                }}
                style={{
                  padding: spacing,
                  marginRight: spacing * 2,
                  boxShadow,
                  borderRadius: borderRadius / 2,
                  cursor: "pointer",
                  outline: `4px solid ${
                    qrStyle == "dots" ? "lightblue" : "transparent"
                  }`,
                  transition: "all 200ms",
                }}
              >
                <QRImage
                  size={60}
                  logoWidth={10}
                  logoHeight={10}
                  productId={productId}
                  qrFgColor={qrFgColor}
                  qrLogo={qrLogo}
                  qrStyle={"dots"}
                />
              </div>
              <div
                style={{
                  padding: spacing,
                  marginRight: spacing * 2,
                  boxShadow,
                  borderRadius: borderRadius / 2,
                  cursor: "pointer",
                  outline: `4px solid ${
                    qrStyle == "squares" ? "lightblue" : "transparent"
                  }`,
                  transition: "all 200ms",
                }}
                onClick={() => {
                  setQrStyle("squares");
                }}
              >
                <QRImage
                  size={60}
                  logoWidth={10}
                  logoHeight={10}
                  productId={productId}
                  qrFgColor={qrFgColor}
                  qrLogo={qrLogo}
                  qrStyle={"squares"}
                />
              </div>
            </div>
            <XDivider />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 0.6,
                  paddingRight: spacing * 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: 24,
                      color: "#2e2e2e",
                      fontWeight: 500,
                    }}
                  >
                    Color Code
                  </span>
                </div>
                <div
                  style={{
                    boxShadow,
                    borderRadius: borderRadius / 2,
                    padding: spacing,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",

                    marginTop: spacing,
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: "#4b4b4b",
                      fontSize: 18,
                    }}
                  >
                    {qrFgColor}
                  </span>
                  <label>
                    <input
                      type="color"
                      onChange={(e) => {
                        setQrFgColor(e.target.value);
                      }}
                      value={qrFgColor}
                      style={{
                        borderRadius: borderRadius,
                        boxShadow,
                        width: 50,
                        height: 50,
                        outline: "none",
                        border: 0,
                      }}
                    />
                  </label>
                </div>
              </div>
              <div
                style={{
                  flex: 0.4,
                }}
              >
                <span
                  style={{
                    fontSize: 24,
                    color: "#2e2e2e",
                    fontWeight: 500,
                  }}
                >
                  Logo
                </span>
                <label>
                  <input
                    type="file"
                    name="qrLogo"
                    id="qrLogo"
                    style={{
                      display: "none",
                    }}
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (!file) return;
                      setQrLogo(URL.createObjectURL(file));
                    }}
                  />
                  <span
                    style={{
                      border: 0,
                      cursor: "pointer",
                      outline: "none",
                      padding: spacing,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "transparent",
                      marginTop: spacing,
                      boxShadow,
                      borderRadius: borderRadius / 2,
                      paddingTop: spacing * 1.5,
                      paddingBottom: spacing * 1.5,
                    }}
                  >
                    <span
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <BackupOutlinedIcon fontSize="large" />
                    </span>
                    <span
                      style={{
                        fontSize: 20,
                        color: "#000000",
                      }}
                    >
                      Upload
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: spacing * 3,
          marginBottom: spacing * 3,
        }}
      >
        <DownloadButton
          onClick={() => {
            downloadCanvasAsImage(
              canvas?.current?.canvas?.current?.toDataURL?.("image/png"),
              `Actuality_${productId}`
            );
          }}
        />
      </div>
    </Tab>
  );
};

export default QRCode;
const XDivider = () => (
  <div
    style={{
      width: "100%",
      height: 2,
      border: "0.7px solid #e4e4e4",
      background: "#e4e4e4",
      marginTop: spacing * 2,
      marginBottom: spacing * 2,
    }}
  />
);
const DownloadButton = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      // margin: "auto",
      border: 0,
      outline: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: spacing * 1.5,
      paddingLeft: spacing * 4,
      paddingRight: spacing * 4,
      borderRadius: borderRadius / 1,
      background: "white",
      boxShadow,
      marginRight: spacing,
      color: "#2e2e2e",
      fontSize: 20,
      fontWeight: 500,
      marginTop: spacing,
    }}
  >
    <GetAppIcon />
    <span
      style={{
        marginLeft: spacing / 2,
      }}
    >
      Download
    </span>
  </button>
);
const QRImage = forwardRef(
  (
    { productId, qrFgColor, qrLogo, qrStyle, size, logoWidth, logoHeight },
    canvasRef
  ) =>
    !true ? (
      <img src={qrCodeShort} width={size} height={size} />
    ) : (
      <QRCodeGen
        ref={canvasRef}
        size={size}
        value={`https://actuality.live/modeldynamicviewer/${productId}`}
        fgColor={qrFgColor}
        logoImage={qrLogo}
        logoWidth={logoWidth}
        logoHeight={logoHeight}
        logoOpacity={0.6}
        logoPadding={5}
        eyeRadius={[5, 5, 5]}
        qrStyle={qrStyle}
        // eyeColor="#000"
      />
    )
);
