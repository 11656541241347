import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import React, { Component } from "react";
// import {} from "react-router";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import edit from "../../Assets/Images/edit.png";
import remove from "../../Assets/Images/delete.png";
import confetti from "../../Assets/newIcons/confetti.png";
import Modal from "react-awesome-modal";
import init from "../../helpers/WindowToken";
import AllProducts from "./UserPortal/AllProducts";
import Constants from "../Variables/Constants";
import DraftProducts from "./UserPortal/DraftProducts";
import LiveProducts from "./UserPortal/LiveProducts";
import PendingProducts from "./UserPortal/PendingProducts";
import WIPproducts from "./UserPortal/WIPproducts";
import { scrollToTop } from "../../helpers/utils";
import ShareUser from "../../Components/ShareUser";
import ShareListing from "../../Components/ShareListing";
import AddNewProduct from "../../Components/AddNewProduct";
import Agreement from "./Agreement";
// let { path, url } = useRouteMatch();
var url = window.location.href;

export default class UserPortal extends Component {
  state = {
    products: [],
    categories: [],
    expandMore: false,
    expand: 0,
    scrollIndex: 1,
    pageCount: 1,
    hasNextPage: true,
    page: 1,
    totalpage: 1,
    metaData: {},
    selected: 0,
    showSearchSuggestions: false,
    productStatus: "",
    query: "",
    perPage: "8",
    searching: false,
    userdetail: {},
    openEdit: false,
    userId: window.location.pathname.split("/")[3],
    removeCompanyLogo: false,
    hideTopBar: url.includes("sharelist"),
    isAgreed: true,
  };
  componentDidMount() {
    // console.log(this.props);
    // if (init() === "success") {
    this.getMetaData();
    this.fetchCategories();
    this.getUser();
    // }
    // else {
    //     window.location.href = "/account/login";
    // }
  }
  fetchCategories = () => {
    this.setState({ loading: true });
    // if (init() === "success") {
    let authToken = localStorage.getItem("feel_xr_auth_token");
    // console.log(authToken);
    axios
      .get(
        Constants.getUrls.getUserCategories +
          "?status=" +
          this.state.productStatus +
          "&q=" +
          this.state.query +
          "&user=" +
          this.state.userId,
        { headers: { Authorization: "Bearer " + authToken } }
      )
      .then((res) => {
        // console.log(res);
        this.setState({
          categories: res.data.categories,
          CategoryLength: res.data.categories
            ? res.data.categories.length === 0
              ? true
              : false
            : "",
          loading: false,
        });
      });

    // }
  };
  serachProducts = (e) => {
    this.setState({ searching: true, visible: true, showAll: false });
    if (e.target.value.length > 3) {
      this.setState(
        {
          query: e.target.value,
          showSearchSuggestions: true,
        },
        () => {
          this.fetchCategories();
        }
      );
    } else if (e.target.value.length === 0) {
      this.setState(
        {
          searching: false,
          query: "",
        },
        () => {
          this.fetchCategories();
        }
      );
    }
  };

  getMetaData = () => {
    var user_id = window.location.pathname.split("/")[3];
    // if (init() === "success") {
    let authToken = localStorage.getItem("feel_xr_auth_token");
    axios
      .get(Constants.getUrls.productMetaByUser + this.state.userId, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((res) => {
        // console.log(res);
        this.setState({ metaData: res.data });
      });
    // }
  };
  getUser = () => {
    this.setState({ loader: true });
    // if (init() === "success") {
    axios
      .get(Constants.getUrls.getUserDetails + this.state.userId)
      .then((res) => {
        // console.log(res);
        this.setState({
          userdetail: res.data.data.user,
          isAgreed: res.data.data.user.is_term_accepted === "yes",
          loader: false,
        });
      });
    // }
  };

  getProductsByUser = () => {
    this.setState({ loader: true });
    // if (init() === "success") {
    let authToken = localStorage.getItem("feel_xr_auth_token");
    axios
      .get(
        Constants.getUrls.getProductsByUser +
          "?page=1&limit=100&status=" +
          this.state.productStatus +
          "&user=" +
          this.state.userId,
        { headers: { Authorization: "Bearer " + authToken } }
      )
      .then((res) => {
        // console.log("res res", res);
        this.setState({ products: res.data.user, loader: false });
      });
    // }
  };

  onImageChangeLogo = (event) => {
    // console.log(event.target.files[0]);
    let data = new FormData();
    if (event.target.files[0]) {
      data.append("company_logo", event.target.files[0]);
      let userToken = localStorage.getItem("feel_xr_auth_token");
      // this.setState({ loading: true });
      axios
        .put(Constants.postUrls.updateUserDetail + this.state.userId, data)
        .then((resp) => {
          // console.log(resp);
          if (resp.data.status === "success") {
            // this.setState({
            //     loading: false
            // })
            this.getUser();
          }
        });
    }
  };

  changeCompanyName = (e) => {
    this.setState({
      openEdit: true,
    });
  };

  closeModal = (e) => {
    this.setState({
      openEdit: false,
    });
  };

  updateCompanyName = (e) => {
    e.preventDefault();
    // this.setState({ loading: true });

    let data = new FormData();
    data.append("company_name", this.state.company_name);
    axios
      .put(Constants.postUrls.updateUserDetail + this.state.userId, data)
      .then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            loading: false,
            successMsg: resp.data.message,
            openEdit: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
            this.getUser();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  logout = () => {
    localStorage.clear();
    window.location.href = "/account/logout";
  };

  onRemoveFile = () => {
    this.setState({
      removeCompanyLogo: true,
    });
  };

  removeLogo = () => {
    axios
      .put(Constants.postUrls.removeCompanyLogo + this.state.userId)
      .then((resp) => {
        // console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            removeCompanyLogo: false,
          });
          this.getUser();
        }
      });
  };

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      console.log("on route change");
      if (location.pathname.includes("sharelist")) {
        this.setState({ hideTopBar: true });
      } else {
        this.setState({ hideTopBar: false });
      }
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <Container
        className="containerSpacingdashBoard"
        maxWidth={"lg"}
        style={
          {
            // border: "2px solid red",
          }
        }
      >
        {/* {
              this.state.pageLoad ? window.location.reload(true) : window.location.reload(false)
            } */}
        <Grid container spacing={2} className="userDashboard">
          <Grid
            item
            sm={2}
            className="sidebar"
            style={{
              overflow: "hidden",
              // border: "1px solid blue",
            }}
          >
            <div
              className=""
              style={
                {
                  //  position: "relative"
                }
              }
            >
              <Typography
                className="logoArea"
                style={
                  {
                    // border: "0px solid red",
                  }
                }
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="uploadLogoPlaceuserSide">
                    {this.state.userdetail.company_logo ? (
                      <img
                        src={
                          this.state.userdetail.company_logo
                            ? Constants.imageUrl +
                              this.state.userdetail.company_logo
                            : ""
                        }
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "15px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {this.state.userdetail ? (
                      <Button
                        variant="outlined"
                        size="medium"
                        className="profilePic"
                        component="label"
                        style={{
                          border: "none",
                          width: "50px",
                          position: "absolute",
                          right: "-17%",
                          top: "-2%",
                        }}
                      >
                        {this.state.userdetail.company_logo ? (
                          <>
                            <img
                              src={remove}
                              width="15px"
                              height="15px"
                              alt=""
                              className="prodLogo"
                              onClick={this.onRemoveFile}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={edit}
                              width="15px"
                              height="15px"
                              alt=""
                              className="prodLogo"
                            />
                            <input
                              type="file"
                              onChange={this.onImageChangeLogo}
                              name="profile_pic"
                              hidden
                            />
                          </>
                        )}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Typography
                  style={{
                    position: "relative",
                    top: "62%",
                    // border: "1px solid aqua",
                    margin: 0,
                    overflow: "hidden",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="medium"
                    className="compnyName"
                    component="label"
                    style={{
                      border: "none",
                      width: "100%",
                      position: "relative",
                      top: "-7px",
                      display: "flex",
                      alignItems: "center",
                      padding: "4px 0",
                      boxShadow: "none",
                      marginTop: 4,
                      // justifyContent:'space'
                    }}
                  >
                    {this.state.userdetail.company_name ? (
                      <>
                        <p
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            margin: "20px",
                            padding: 0,
                          }}
                          title={this.state.userdetail.company_name}
                        >
                          {this.state.userdetail.company_name}
                        </p>
                      </>
                    ) : (
                      <>
                        <div>Company Name</div>
                      </>
                    )}{" "}
                    <img
                      src={edit}
                      width="15px"
                      height="15px"
                      alt=""
                      style={{
                        marginLeft: 4,
                      }}
                      // className="compLogo"
                      onClick={this.changeCompanyName}
                    />
                  </Button>
                </Typography>
              </Typography>
              <Typography className="performance"> Performance </Typography>
              <Grid container spacing={2} style={{ textAlign: "left" }}>
                <Grid item sm={6}>
                  <span className="spanTag">Total Views</span>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <span
                    className="spanTag"
                    style={{
                      position: "relative",
                    }}
                  >
                    {this.state.metaData.totalViews
                      ? this.state.metaData.totalViews
                      : 0}
                    <div
                      style={{
                        position: "absolute",
                        top: "-2px",
                        left: "100%",
                        maxHidth: 20,
                        maxHeight: 25,
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      {" "}
                      <img
                        src={confetti}
                        alt=""
                        width="20px"
                        height="25px"
                        style={{
                          marginLeft: 4,
                        }}
                      />
                    </div>
                  </span>
                </Grid>
              </Grid>
              <hr />

              <Typography className="performance"> Products </Typography>

              <Grid container spacing={2} style={{ textAlign: "left" }}>
                <Grid item sm={6}>
                  <NavLink
                    exact
                    to={"/admin/allProducts/" + this.state.userId}
                    className="onHoverLink"
                  >
                    <span
                      className="spanTag"
                      style={{
                        fontWeight:
                          this.state.productStatus === "" ? "bold" : "normal",
                        color: "#2D3436",
                      }}
                      onClick={() =>
                        this.setState({ productStatus: "" }, () => {
                          this.fetchCategories();
                        })
                      }
                    >
                      All Products
                    </span>
                  </NavLink>
                </Grid>
                <Grid item sm={6} style={{ textAlign: "center" }}>
                  <span className="spanTag">
                    {this.state.metaData.all ? this.state.metaData.all : 0}
                  </span>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ textAlign: "left" }}>
                <Grid item sm={6}>
                  <NavLink
                    exact
                    to={"/admin/live/" + this.state.userId}
                    className="onHoverLink"
                  >
                    <span
                      className="spanTag"
                      style={{
                        fontWeight:
                          this.state.productStatus === "published"
                            ? "bold"
                            : "normal",
                        color: "#2D3436",
                      }}
                      onClick={() =>
                        this.setState({ productStatus: "published" }, () => {
                          this.fetchCategories();
                        })
                      }
                    >
                      Live Products
                    </span>
                  </NavLink>
                </Grid>
                <Grid item sm={6} style={{ textAlign: "center" }}>
                  <span className="spanTag">
                    {" "}
                    {this.state.metaData.published
                      ? this.state.metaData.published
                      : 0}{" "}
                  </span>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ textAlign: "left" }}>
                <Grid item sm={6}>
                  <NavLink
                    exact
                    to={"/admin/draft/" + this.state.userId}
                    className="onHoverLink"
                  >
                    <span
                      className="spanTag"
                      style={{
                        fontWeight:
                          this.state.productStatus === "draft"
                            ? "bold"
                            : "normal",
                        color: "#2D3436",
                      }}
                      onClick={() =>
                        this.setState(
                          {
                            productStatus: "draft",
                            categories: this.state.categories,
                          },
                          () => {
                            this.fetchCategories();
                          }
                        )
                      }
                    >
                      Draft Products
                    </span>
                  </NavLink>
                </Grid>
                <Grid item sm={6} style={{ textAlign: "center" }}>
                  <span className="spanTag">
                    {" "}
                    {this.state.metaData.drafted
                      ? this.state.metaData.drafted
                      : 0}{" "}
                  </span>
                </Grid>
              </Grid>

              {/* button for analytics component */}
              <Link
                to={"/admin/analytics/" + this.state.userId}
                style={{
                  textDecoration: "none",
                  color: "#666666",
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                }}
              >
                <Button
                  className="productBtn"
                  style={{
                    width: "70%",
                    fontSize: window.innerWidth < 1100 ? "8px" : "10px",
                    marginTop: "35px",
                    padding: "2px 20px",
                    height: "36px",
                    borderRadius: "4px",
                  }}
                >
                  Overall Analytics
                </Button>
              </Link>
              {/* end of analytics */}
              <hr style={{ color: "#C8C8C8", marginTop: "20px" }} />
              {/* <Link
                to={"/admin/product-create/" + this.state.userId}
                style={{ textDecoration: "none", color: "#666666" }}
              >
                <Button
                  className="productBtn"
                  style={{
                    width: "100%",
                    fontSize: window.innerWidth < 1100 ? "14px" : "18px",
                    marginTop: "35px",
                  }}
                >
                  Add New Product +
                </Button>
              </Link> */}
              <AddNewProduct user_id={this.state.userId} />
              <ShareUser is_share_some={true} user_id={this.state.userId} />
              <Link
                to={"/admin/settings/" + this.state.userId}
                style={{ textDecoration: "none", color: "#666666" }}
              >
                <Button
                  className="productBtn"
                  onClick={() => scrollToTop()}
                  style={{
                    width: "100%",
                    fontSize: window.innerWidth < 1100 ? "14px" : "18px",

                    marginTop: "26px",
                  }}
                >
                  Settings
                </Button>
              </Link>
              <Button
                className="productBtn"
                onClick={this.logout}
                style={{
                  marginTop: "26px",
                  width: "100%",
                  fontSize: window.innerWidth < 1100 ? "14px" : "18px",
                }}
              >
                Logout
              </Button>
            </div>
          </Grid>
          <Grid
            item
            sm={10}
            className="productList"
            style={{ background: "transparent" }}
          >
            <div>
              {!this.state.hideTopBar && (
                <div className="flexWrapper">
                  <NavLink
                    exact
                    to={"/admin/allProducts/" + this.state.userId}
                    activeClassName="bold"
                    style={{ textDecoration: "none", marginBottom: "12px" }}
                  >
                    <Typography
                      className="rowheads"
                      onClick={() =>
                        this.setState({ productStatus: "" }, () => {
                          this.fetchCategories();
                        })
                      }
                      style={{
                        fontSize: window.innerWidth < 1100 ? "18px" : "18px",
                        fontWeight:
                          this.state.productStatus === "" ? "bold" : "normal",
                        color: "#2D3436",
                      }}
                    >
                      All Products
                    </Typography>
                  </NavLink>
                  <NavLink
                    to={"/admin/draft/" + this.state.userId}
                    className="active  "
                    activeClassName="bold"
                    style={{ textDecoration: "none", marginBottom: "12px" }}
                  >
                    <Typography
                      className="rowheads"
                      onClick={() =>
                        this.setState({ productStatus: "draft" }, () => {
                          this.fetchCategories();
                        })
                      }
                      style={{
                        fontSize: window.innerWidth < 1100 ? "18px" : "18px",
                        fontWeight:
                          this.state.productStatus === "draft"
                            ? "bold"
                            : "normal",
                        color: "#2D3436",
                      }}
                    >
                      Draft
                    </Typography>
                  </NavLink>

                  <NavLink
                    to={"/admin/live/" + this.state.userId}
                    activeClassName="bold"
                    style={{ textDecoration: "none", marginBottom: "12px" }}
                  >
                    <Typography
                      className="rowheads"
                      onClick={() =>
                        this.setState({ productStatus: "published" }, () => {
                          this.fetchCategories();
                        })
                      }
                      style={{
                        fontSize: window.innerWidth < 1100 ? "18px" : "18px",
                        fontWeight:
                          this.state.productStatus === "published"
                            ? "bold"
                            : "normal",
                        color: "#2D3436",
                      }}
                    >
                      Live Products
                    </Typography>
                  </NavLink>

                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Search Products"
                      name=""
                      onChange={this.serachProducts}
                      className="searchinput"
                      style={{
                        width: window.innerWidth < 1100 ? "270px" : "394px",
                        marginTop: "-12px",
                      }}
                    />
                    <SearchIcon className="serachIcon" />
                  </div>
                </div>
              )}
              {this.state.searching ? (
                <div>
                  <p>{`Showing results for "` + this.state.query + `"`}</p>
                </div>
              ) : null}

              <Switch>
                <Route path="/admin/allProducts/:id" exact>
                  <AllProducts
                    userId={this.state.userId}
                    categories={this.state.categories}
                    query={this.state.query}
                    productStatus={this.state.productStatus}
                    CategoryLength={this.state.CategoryLength}
                    loading={this.state.loading}
                    searching={this.state.searching}
                    getMetaData={this.getMetaData}
                  />
                </Route>
                <Route path="/admin/sharelist/:id" exact>
                  <ShareListing userId={this.state.userId} />
                </Route>

                <Route path="/admin/draft/:id" exact>
                  <DraftProducts
                    userId={this.state.userId}
                    categories={this.state.categories}
                    query={this.state.query}
                    productStatus={this.state.productStatus}
                    CategoryLength={this.state.CategoryLength}
                    loading={this.state.loading}
                    searching={this.state.searching}
                    getMetaData={this.getMetaData}
                  />
                </Route>

                <Route path="/admin/pending/:id" exact>
                  <PendingProducts
                    userId={this.state.userId}
                    categories={this.state.categories}
                    query={this.state.query}
                    productStatus={this.state.productStatus}
                    CategoryLength={this.state.CategoryLength}
                    loading={this.state.loading}
                    getMetaData={this.getMetaData}
                  />
                </Route>

                <Route path="/admin/live/:id" exact>
                  <LiveProducts
                    userId={this.state.userId}
                    categories={this.state.categories}
                    query={this.state.query}
                    productStatus={this.state.productStatus}
                    CategoryLength={this.state.CategoryLength}
                    loading={this.state.loading}
                    getMetaData={this.getMetaData}
                  />
                </Route>

                <Route path="/admin/Wip/:id" exact>
                  <WIPproducts
                    userId={this.state.userId}
                    categories={this.state.categories}
                    query={this.state.query}
                    productStatus={this.state.productStatus}
                    CategoryLength={this.state.CategoryLength}
                    loading={this.state.loading}
                    getMetaData={this.getMetaData}
                  />
                </Route>
              </Switch>

              {/* {this.state.showSearchSuggestions ? (
                    <div>
                      {this.state.categories.map((singleCate, index) => (
                        <Accordion>
                          <AccordionSummary
                            aria-controls={"panel" + index + 1 + "a-content"}
                            id={"panel" + index + 1 + "a-header"}
                          >
                            <div className="CategoryDiv">
                              <Typography
                                className="Heading"
                                onClick={() => {
                                  this.fetchModels(singleCate._id, index);
                                }}
                                key={index}
                              >
                                <span
                                  style={{
                                    marginRight: "30px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {this.state.selected === index ? (
                                    <RemoveIcon />
                                  ) : (
                                    <AddIcon />
                                  )}
                                </span>
                                {singleCate.title}
                              </Typography>
                            </div>
                          </AccordionSummary>
    
                          <AccordionDetails>
                          <div className="cardWrapper">
                            {this.state.loadingProducts ? (
                              <div
                                style={{ textAlign: "center", marginTop: "80px" }}
                              >
                                <CircularProgress />
                              </div>
                            ) : (
                              <Grid container spacing={3}>
                                {this.state["products" + index]
                                  ? this.state["products" + index].map(
                                      (ele, ind) => (
                                        <Grid item sm={3} key={ind}>
                                          <ProductCard
                                            imgSrc={
                                              ele.poster_image_url
                                                ? Constants.imageUrl + ele.poster_image_url
                                                : modelImg1
                                            }
                                            id={ele._id}
                                            title={ele.title}
                                            views={ele.views}
                                          />
                                        </Grid>
                                      )
                                    )
                                  : null}
                              </Grid>
                            )}
                          </div>
                        </AccordionDetails>
                    
                        </Accordion>
                      ))}
                    </div>
                  ) : null} */}
            </div>

            {/* Remove Company logo */}
            <Modal
              visible={this.state.removeCompanyLogo}
              width="400"
              height="300"
              effect="fadeInUp"
              onClickAway={() => this.setState({ removeCompanyLogo: false })}
            >
              <div
                className="confirmationModal"
                style={{ textAlign: "center" }}
              >
                <Typography>
                  Are you sure you want to remove company logo ?
                </Typography>
                <Button
                  onClick={() => {
                    this.removeLogo();
                    // this.deleteMaterial(
                    //   this.state.deleteMatId,
                    //   this.state.isAvailable
                    // );
                  }}
                  className="DeleteBtn"
                >
                  Remove{" "}
                </Button>
                <Button
                  onClick={() => this.setState({ removeCompanyLogo: false })}
                  className="DeleteBtn"
                  style={{ background: "#bdbdbd" }}
                >
                  Cancel
                </Button>
              </div>
            </Modal>

            <Modal
              visible={this.state.openEdit}
              width="350"
              height="230"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <div className="modalWrapper">
                {/* <h4>Update Company name</h4> */}
                <form action="" onSubmit={this.updateCompanyName}>
                  <Typography>
                    {this.state.filename ? "File updated" : null}
                  </Typography>
                  <br />
                  <div className="contentWrap">
                    <p>Company Name</p>
                    <input
                      type="text"
                      name="company_name"
                      className="modalInput"
                      style={{ marginBottom: "10px" }}
                      // defaultValue={
                      //   this.state.product ? this.state.product.title : ""
                      // }
                      onChange={this.handleInput}
                      required
                    />
                    <br />
                    <Button
                      className="addBtn"
                      type="submit"
                      style={{ width: "100%", marginLeft: "0px" }}
                    >
                      {this.state.loading ? (
                        <CircularProgress color={"white"} size={25} />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>
          </Grid>
        </Grid>
        {!this.state.isAgreed && <Agreement user={this.state.userdetail} />}
      </Container>
    );
  }
}
