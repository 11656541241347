import React from "react";

import edit from "../../Assets/Images/edit.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";
const __EXTRA__ = `Product name not availableProduct name not
                        availableProduct name not available`;

const TopRightBoxes = ({
  product,
  openEditModal,
  enableBuyNow,
  handleVisiblity,
  propState,
}) => {
  return (
    <>
      <div
        className="box1"
        style={{
          position: "relative",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          openEditModal();
        }}
      >
        <div
          style={{
            // border: "1px solid red",
            maxWidth: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingRight: 8,
            }}
          >
            <p
              title={product?.title || ""}
              style={{
                fontWeight: 600,
                fontSize: 16 || "18px",
                lineHeight: "normal",

                margin: 0,
                // textTransform: "capitalize",
                // border: "1px solid red",
                paddingRight: 10,

                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",

                paddingLeft: 10,
                flex: 1,
                color: "#2e2e2e",
              }}
            >
              {product ? product.title : "  Product name not available"}
            </p>

            <img
              src={edit}
              width="20px"
              height="20px"
              style={{
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              alt=""
            />
          </div>
          <p
            title={product?.sub_title || ""}
            style={{
              margin: 0,
              fontSize: "14px",
              lineHeight: "normal",
              fontWeight: 400,
              paddingRight: 10,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingLeft: 10,
              flex: 1,
              marginTop: 0,
              color: "#565656",
            }}
          >
            {product
              ? product.sub_title === "undefined"
                ? ""
                : product.sub_title
              : null}
          </p>
        </div>
      </div>
      <div
        className="box1"
        style={{
          position: "relative",
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        onClick={() => enableBuyNow()}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
              paddingRight: 8,
            }}
          >
            <p
              title={
                product
                  ? product.link_title
                    ? product.link_title
                    : "Buy Now"
                  : "Buy Now"
              }
              style={{
                fontWeight: 600,
                fontSize: 16 || "18px",
                color: "#2e2e2e",
                lineHeight: "normal",

                margin: 0,
                padding: 0,

                // textTransform: "capitalize",
                // border: "1px solid red",
                paddingRight: 10,

                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",

                paddingLeft: 10,
                flex: 1,
              }}
            >
              {product
                ? product.link_title
                  ? product.link_title
                  : "Buy Now"
                : "Buy Now"}
            </p>
            <img
              width="20px"
              height="20px"
              style={{
                cursor: "pointer",
                borderBottom: "1px solid black",
              }}
              src={edit}
              alt=""
            />

            <div
              style={{
                paddingLeft: 10,
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleVisiblity();
              }}
            >
              {propState.visiblity ? (
                <Visibility
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    color: "black",
                  }}
                />
              ) : (
                <VisibilityOff
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    color: "black",
                  }}
                />
              )}
            </div>
          </div>

          <p
            title={
              product
                ? product.link_description
                  ? product.link_description
                  : ""
                : ""
            }
            style={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#565656",
              lineHeight: "normal",

              margin: 0,
              // textTransform: "capitalize",
              padding: 0,
              paddingRight: 10,

              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",

              paddingLeft: 10,
              flex: 1,
            }}
          >
            {product
              ? product.link_description
                ? product.link_description
                : ""
              : ""}
          </p>
        </div>
      </div>
    </>
  );
};

export default TopRightBoxes;
