export const mainDomain = false
  ? "http://localhost:4000/"
  : "https://core.actuality.live/";
//const mainDomain = "https://core.actuality.live/";
//const mainDomain = "http://localhost:3001";
const Constants = {
  imgUrl: mainDomain,
  // imageUrl: "https://coretest.feelxr.co",
  imageUrl: "https://core.actuality.live",
  // imageUrl : "http://localhost:3001",

  statusUrl: mainDomain + "api/status/",
  getUrls: {
    models_for_actuality:
      mainDomain + "api/products/admin/models_for_actuality",
    Services: mainDomain + "api/services",
    canPublishProduct: mainDomain + "api/products/canPublishProduct",
    Blogs: mainDomain + "api/blogs",
    UsersList: mainDomain + "api/auth/users",
    Categories: mainDomain + "api/categories",
    contacts: mainDomain + "api/contacts",
    Products: mainDomain + "api/products",
    varOptions: mainDomain + "api/variantoptions",
    variants: mainDomain + "api/variants",
    searchService: mainDomain + "api/services",
    social: mainDomain + "api/socials",
    getAboutUs: mainDomain + "api/aboutus",
    getPrivacy: mainDomain + "api/privacy",
    getTerms: mainDomain + "api/terms",
    subAdminList: mainDomain + "api/sub_admin/subadmin",
    getUserCategories: mainDomain + "api/admin/user/categories",
    // getPlans: mainDomain + "api/order",
    getPlans: mainDomain + "api/admin/subscriptionStatus/",
    blogs: mainDomain + "api/blogs",
    services: mainDomain + "api/services",
    Categories: mainDomain + "api/categories",
    getComponents: mainDomain + "api/components/",
    getMaterials: mainDomain + "api/materials",
    getUser: mainDomain + "api/auth/user",
    getProducts: mainDomain + "api/products",
    getProductsByUser: mainDomain + "api/admin/products",
    getSingleProduct: mainDomain + "api/products/",
    getUserDetails: mainDomain + "api/admin/userDetails/",
    productMeta: mainDomain + "api/auth/product_meta",
    productMetaByUser: mainDomain + "api/admin/product_meta/",
    variants: mainDomain + "api/variants",
    getUserCat: mainDomain + "api/products/user/categories",
    getSocials: mainDomain + "api/socials",
    getAboutUs: mainDomain + "api/aboutus",
    getPrivacy: mainDomain + "api/privacy",
    getTerms: mainDomain + "api/terms",
    getUserPlans: mainDomain + "api/admin/subscribe/list/",
    getCounts: mainDomain + "api/dashboard",
    getPricing: mainDomain + "api/pricing",
    getAdminModels: mainDomain + "api/models",
    getSubscribeByAdmin: mainDomain + "api/admin/subscribeByAdmin/",
    currentPlanStatus: mainDomain + "api/subscribe/currentPlanStatus",

    // for overall  analysis
    views_user_analysis: mainDomain + "api/adminAnalytics/user_analysis/views/",
    click_user_analysis:
      mainDomain + "api/adminAnalytics/user_analysis/clicks/",
    view_by_top_user_analysis:
      mainDomain + "api/adminAnalytics/user_analysis/view_by_top/",
    view_by_platform_user_analysis:
      mainDomain + "api/adminAnalytics/user_analysis/view_by_platform/",
    getAllAnalysis: mainDomain + "api/adminAnalytics/",

    // for product analysis
    product_analysis_for_views:
      mainDomain + "api/adminAnalytics/product_analysis/views/",
    product_analysis_for_clicks:
      mainDomain + "api/adminAnalytics/product_analysis/clicks/",
    product_analysis_view_top:
      mainDomain + "api/adminAnalytics/product_analysis/view_by_top/",
    product_analysis_view_platform:
      mainDomain + "api/adminAnalytics/product_analysis/view_by_platform/",
    getAll_product_analysis: mainDomain + "api/analytics/product_analysis/",
    getAdminProducts: mainDomain + "api/products/admin/products",
    getSuggestionModels: mainDomain + "api/model_suggestion",
    getShareProducts: mainDomain + "api/share_products",
    share_products: mainDomain + "api/share_products",
    model_suggestions: mainDomain + "api/model_suggestion",
  },
  putUrls: {
    reshuffleCategories: mainDomain + "api/categories/reshuffle",
    // updateRoleAsSubAdmin: mainDomain + "api/sub_admin/updateRole/",
    reshufflePublicModels: mainDomain + "api/products/reshuffle",
    updateUser: mainDomain + "api/admin/updateUserByAdmin/",
  },
  postUrls: {
    templateProducts: mainDomain + "api/templateProducts/",
    duplicateproduct: mainDomain + "api/duplicateproduct/",
    removeCompanyLogo: mainDomain + "api/admin/removeCompanyLogo/",
    updateUserDetail: mainDomain + "api/admin/user/",
    setTrending: mainDomain + "product/setTrendingProduct/",
    //login: mainDomain + "api/auth/signin",
    login: mainDomain + "api/auth/admin_signin",
    addservices: mainDomain + "api/services",
    addBlogs: mainDomain + "api/blogs",
    Userdelete: mainDomain + "api/auth/user",
    deleteUserByAdmin: mainDomain + "api/admin/userDelete/",
    deleteUserByDatabase: mainDomain + "api/admin/user/delete/",
    about: mainDomain + "api/aboutus",
    deleteContact: mainDomain + "api/contacts",
    termsandConditions: mainDomain + "api/terms",
    privacy: mainDomain + "api/privacy",
    UpdateSocials: mainDomain + "api/socials",
    deleteSocial: mainDomain + "api/delete",
    removeProductLogo: mainDomain + "api/admin/removeProductLogo/",

    chargebeePortal: mainDomain + "api/admin/portal/",
    changeStatus: mainDomain + "api/status/",
    contact: mainDomain + "api/contacts",
    // login: mainDomain + "api/auth/signin",
    signUp: mainDomain + "api/auth/signup",
    addproduct: mainDomain + "api/products",
    addUserproduct: mainDomain + "api/admin",
    updateUserproduct: mainDomain + "api/admin/",
    addComponents: mainDomain + "api/components",
    addMaterial: mainDomain + "api/materials",
    addVariants: mainDomain + "api/variants",
    googleLogin: mainDomain + "api/auth/social_login",
    updateProfile: mainDomain + "api/auth/update_profile",
    postViews: mainDomain + "api/products/product_views",
    deleteAllVariants: mainDomain + "api/products/delete_all_variant",
    updateProduct: mainDomain + "api/products/",
    userPlansList: mainDomain + "api/admin/subscribe/list/",
    // chargebeePayment:mainDomain+"api/payment",
    chargebeePayment: mainDomain + "api/chargebeePayment",
    // order:mainDomain+"api/order",
    order: mainDomain + "api/admin/subscribePlanByAdmin/",
    silentLogin: mainDomain + "api/auth/silent_login",
    postPricing: mainDomain + "api/pricing",
    websiteViews: mainDomain + "api/subscribe/websiteViews",
    exportAnalytics: mainDomain + "api/adminAnalytics/exportExcel/",
    productExportAnalytics:
      mainDomain + "api/adminAnalytics/exportExcelByProd/",
    saveFile: mainDomain + "api/saveFile",

    addHotspot: mainDomain + "api/variants/hotspots/",
    addSubAdmin: mainDomain + "api/sub_admin",
    changePassword: mainDomain + "api/sub_admin/reset_pass",
    delSubAdmin: mainDomain + "api/sub_admin/",
    saveFile: mainDomain + "api/saveFile",
    variantFromSuggestion: mainDomain + "api/variants/suggestedModel/",
  },
};
export default Constants;
