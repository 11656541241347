import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../Assets/Images/actualityLogo.png";
// import { AdminPortal } from '@frontegg/react'
class Header extends Component {
  constructor(props) {
    super();

    this.state = {
      userToken: "",
      userDetail: props.userData,
      checkAuth: props.checkAuth,
      userId: window.location.pathname.split("/")[3],
    };
    // console.log(props);
  }

  componentDidMount() {
    // console.log(this.state.userId);
    var user_id = window.location.pathname.split("/")[3];
    // console.log(user_id);
    // console.log(this.props.isAuthenticated);
    let Token = localStorage.getItem("feel_xr_auth_token");
    this.setState(
      {
        userToken: Token,
      },
      () => {
        // console.log(this.state.userToken);
      }
    );
  }

  gotoHomeLink = () => {
    window.location.href = "https://actuality.live/";
  };
  gotoContact = () => {
    window.location.href = "/contact-us";
  };
  gotoBottom = () => {
    let body = document.body,
      html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    window.scroll({ top: height, left: 0, behavior: "smooth" });
  };

  handleProfile = () => {
    // AdminPortal.show();
  };

  render() {
    return (
      <div>
        <AppBar position="static" className="bgTheme navbar" elevation={0}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="h3"
                className="bolder"
                style={{ cursor: "pointer" }}
              >
                <a
                  href="https://actuality.live/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <span>
                    <img src={logo} alt="" width="33%" />
                  </span>{" "}
                </a>
              </Typography>
            </div>
            <ul
              className={
                window.innerWidth > 1100 ? "listItems" : "listItemsResp"
              }
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <a
                  href="https://calendly.com/rishabh_gupta/30min"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#666666",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="h6" className="DemoText">
                    Demo
                  </Typography>
                </a>
                <NavLink
                  to={"/admin/userprofile/" + this.state.userId}
                  // activeClassName="selected"
                  style={{ textDecoration: "none" }}
                >
                  <li style={{ paddingRight: "0px" }}>
                    <Typography variant="h6" className="DemoText">
                      Profile
                    </Typography>
                  </li>
                </NavLink>
                <NavLink
                  to={"/admin/allProducts/" + this.state.userId}
                  // activeClassName="selected"
                  style={{ textDecoration: "none" }}
                >
                  <li style={{ paddingRight: "5px" }}>
                    <Typography variant="h6" className="DemoText">
                      Dashboard
                    </Typography>
                  </li>
                </NavLink>
                {/* 
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Button
                    size={"large"}
                    variant="contained"
                    className="themeBtn txtShadow rounded"
                  >
                    Contact
                  </Button>
                </Link> */}
              </div>
            </ul>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default Header;
