import { Button, Typography } from "@material-ui/core";
import React from "react";

import Modal from "react-awesome-modal";
const common =
  "All Variants of this product will be deleted. Are you sure you want to add more materials ?";

const messages = {
  deleteComponent: common + " delete this component ?",
  addMaterial: common + " add material ?",
  deleteMaterial: common + "  delete this material ?",
};

const VariantsDeleteConfirmation = ({
  visible,
  handleDelete,
  successMsg,
  onClickAway,
  text,
}) => {
  return (
    <Modal
      visible={visible}
      width="400"
      height="300"
      effect="fadeInUp"
      onClickAway={() => onClickAway()}
    >
      <div className="confirmationModal" style={{ textAlign: "center" }}>
        <Typography>{text ? text : common}</Typography>

        <Button
          onClick={() => {
            handleDelete();
          }}
          className="DeleteBtn"
          // style={{
          //   background: "lightgreen",
          //   color: "gray",
          // }}
        >
          Delete
        </Button>
        <Button
          onClick={() => onClickAway()}
          className="DeleteBtn"
          style={{ background: "#bdbdbd" }}
        >
          Cancel
        </Button>
        <Typography style={{ color: "green" }}>{successMsg}</Typography>
      </div>
    </Modal>
  );
};

export default VariantsDeleteConfirmation;
