import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  TextField,
  Typography,
  Snackbar,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import init from "../helpers/WindowToken";
import { Form } from "reactstrap";
import Modal from "react-awesome-modal";

let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

export default class AddCategories extends Component {
  state = {
    name: "",
    email: "",
    username: "",
    password: "",
    loading: false,
    visible: false,
    userId: "",
  };

  // changeRole = (id) => {
  //   if (init() == "success") {
  //     this.setState({ delLoading: true });
  //     axios
  //       .put(Constants.putUrls.updateRoleAsSubAdmin  + this.state.userId)
  //       .then((res) => {
  //         // console.log(res);
  //         if (res.data.status === "success") {
  //           this.setState({
  //             loading: false,
  //             notification: true,
  //             message: res.data.message,
  //           });
  //           setTimeout(() => {
  //             this.setState({
  //               notification: false,
  //               redirect: true,
  //             });
  //           }, 1500)
  //         }
  //       });
  //   }
  // };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  addSubAdmin = (e) => {
    e.preventDefault();
    let subAdminLoad = {
      name: this.state.name,
      email: this.state.email,
      username: this.state.username,
      password: this.state.password,
    };
    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios.post(Constants.postUrls.addSubAdmin, subAdminLoad).then((resp) => {
        //  console.log(resp.data.message);
        if (resp.data.status === "success") {
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 1500);
        } else {
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              // redirect: true,
            });
          }, 1500);
        }
        // else {
        //   if (resp.data.message === "sub_admin_exist") {
        //     this.setState({
        //       loading: false,
        //       notification: true,
        //       message: resp.data.message,
        //     });
        //     setTimeout(() => {
        //       this.setState({
        //         notification: false,
        //         // redirect: true,
        //       });
        //     }, 1500);
        //   }
        //   if (resp.data.message === "user_exist") {
        //     this.setState({
        //       userId: resp.data.user._id
        //     })
        //   this.openModal();
        //   }
        // }
      });
    }
  };
  handleInput = (e) => {
    // console.log(this.state.email);
    // console.log(e.target.name);
    // console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/sub_admin" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">{this.props.title}</Typography>
            </Grid>
          </Grid>
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"green"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          <div
            style={{
              padding: "20px",
              border: "1px solid #afa2a2",
              borderRadius: "7px",
              position: "relative",
              // background:"#3f51b530"
            }}
            // style={{
            //   background: "#5b5757",
            //   padding: "20px",
            //   borderRadius: "10px",
            //   // color: rgba(0, 0, 0, 0.87);
            //   width: "100%",
            //   border: "0",
            //   display: "flex",
            //   position: "relative",
            //   fontSize: ".875rem",
            //   minWidth: "0",
            //   wordWrap: " break-word",
            //   /* background: #FFF; */
            //   boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            //   marginTop: "30px",
            //   borderRadius: "6px",
            //   marginBottom: "30px",
            //   flexDirection: "column",
            //   textAlign: "left",
            // }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, #3f51b5cf, #f500579e) #f500579e)",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>
            <span
              style={{
                color: "#afa3a3",
                marginTop: "-8px",
                marginLeft: "26px",
                marginBottom: "35px",
                fontSize: "17px",
                display: "flex",
              }}
            >
              Add Sub Admin
            </span>

            <Grid item sm={12} style={{ textAlign: "left", marginTop: "30px" }}>
              {/* <CardHeader> */}
              {/* <Typography>
                  Add Category
              </Typography> */}
              {/* </CardHeader> */}
              <Form onSubmit={this.addSubAdmin}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="Name"
                  name="name"
                  required={true}
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="UserName"
                  name="username"
                  required={true}
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="Email"
                  name="email"
                  required={true}
                  type="email"
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="Password"
                  name="password"
                  required={true}
                  variant="outlined"
                  style={{ marginTop: "20px", width: "30%" }}
                />

                <br />
                <Button
                  variant="contained"
                  type="submit"
                  Size={15}
                  color="primary"
                  style={{ marginRight: "20px", marginTop: "35px" }}
                >
                  {this.state.loading ? "Adding.." : "Add Sub Admin"}
                </Button>
                <Link
                  to={{
                    pathname: "/sub_admin",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    Size={15}
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Form>
            </Grid>
          </div>
          {/* update rpde modal */}
          <Modal
            visible={this.state.visible}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div className="ModalWrapper">
              <Typography className="Typo">
                {" "}
                User already exist with role User. Do you want to change the
                role to Sub-Admin ?
              </Typography>

              <Button
                size="medium"
                onClick={() => {
                  this.changeRole();
                }}
                variant="contained"
                color="secondary"
                className="Delbutton"
              >
                Yes
              </Button>

              <Button
                size="medium"
                onClick={() => {
                  this.setState({ visible: false });
                }}
                variant="contained"
                color="primary"
                className="Delbutton"
              >
                {" "}
                No{" "}
              </Button>
              <Typography className="DeleteMsg">
                {" "}
                {this.state.notifyDelete ? this.state.delMsg : ""}{" "}
              </Typography>
            </div>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Fade in={this.state.open}>
              <div
                style={{
                  background: "#fff",
                  width: "50%",
                  padding: "32px",
                }}
              >
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">
                  react-transition-group animates me.
                </p>
              </div>
            </Fade>
          </Modal>
        </Container>
      );
    }
  }
}
