import React, { useEffect, useState } from "react";
import { spacing } from "./const";
import { Checkbox } from "@material-ui/core";

const CategoryBox = ({
  handleCategoryCheck,
  category: categorySelected,
  categories,
}) => {
  return (
    <div
      style={{
        flex: 0.2,
        // border: '1px solid green',
        paddingRight: spacing * 2,
        paddingLeft: 1,
      }}
    >
      <div
        style={{
          fontSize: 20,
          fontWeight: 500,
          color: "#2e2e2e",
          // marginBottom: spacing * 3,
          margin: "auto",
          marginBottom: 48,
          height: 55,
          display: "flex",
          alignItems: "center",
          // border: "1px solid red",
        }}
      >
        Select any Template
      </div>
      <div
        style={{
          border: "1px solid white",
          borderRadius: 14,
          boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
          paddingBottom: spacing,
        }}
      >
        <div
          style={{
            textAlign: "center",
            padding: spacing * 2,
            fontSize: 20,
            fontWeight: 500,
            borderBottom: "1px solid #ddd",
          }}
        >
          Categories
        </div>
        {categories
          .filter(({ status }) => status == "active")
          .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
          .map((category) => (
            <Category
              categorySelected={categorySelected}
              key={category._id}
              category={category}
              handleCategoryCheck={() => handleCategoryCheck(category._id)}
            />
          ))}
      </div>
    </div>
  );
};

const Category = ({ category, handleCategoryCheck, categorySelected }) => {
  const [check, setCheck] = useState(false);
  useEffect(() => {
    setCheck(categorySelected.includes(category._id));
  }, [categorySelected]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        // padding: spacing,
        paddingRight: spacing * 2,
        paddingLeft: spacing * 2,
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontSize: 18,
          fontWeight: 400,
          color: "#565656",
        }}
      >
        {category.title}
      </div>

      <Checkbox
        id="category"
        color="primary"
        onChange={() => {
          setCheck((p) => !p);
          handleCategoryCheck();
        }}
        checked={check}
      />
    </div>
  );
};

export default CategoryBox;
