import {
  Snackbar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  withStyles,
  ListItemText,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";

import API from "../../App/Variables/Constants";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
const CloneButton = ({
  productId,
  openeditLink,
  postDuplicateProduct,
  deleteProduct,
  isPublished,
  openAnalytics,
  openPreview,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [errorMsg, setErrorMsg] = useState("");

  const cloneProduct = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        API.postUrls.duplicateproduct + productId
      );

      if (data.newProductId) {
        setTimeout(() => {
          postDuplicateProduct();
        }, 600);
      } else throw new Error("Unable to find id");
    } catch (err) {
      setErrorMsg(
        err?.response?.data?.message || "Server is busy, please try again!"
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const emptyError = () => setErrorMsg("");

  return (
    <>
      <SimpleSnackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        errorMsg={errorMsg}
        emptyError={emptyError}
      />
      <div
        className="cloneBtnWrapper"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 20,
          background: "#D8D8D8",
          borderBottomLeftRadius: "16px",

          padding: "0.2rem",
        }}
        onClick={(e) => {
          e?.stopPropagation();
          e?.preventDefault();
        }}
      >
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            color: "rgba(0,0,0,1)",
          }}
          size="small"
        >
          <MoreVertIcon fontSize="medium" />
        </IconButton>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <TSEListItem
            onClick={cloneProduct}
            Icon={<FileCopyOutlinedIcon size="small" />}
            text={isLoading ? "Copying" : "Make a Copy"}
          />
          <TSEListItem
            onClick={() => openeditLink(productId)}
            Icon={<EditSharpIcon size="small" />}
            text={"Edit Product"}
          />
          <TSEListItem
            onClick={openAnalytics}
            Icon={<EqualizerOutlinedIcon size="small" />}
            text={"Analytics"}
          />
          <TSEListItem
            onClick={() => deleteProduct(productId)}
            Icon={<DeleteIcon size="small" />}
            text={"Delete"}
          />
          {isPublished ? (
            <TSEListItem
              onClick={() => {
                openPreview();
              }}
              Icon={<VisibilityOutlinedIcon size="small" />}
              text={"Preview"}
            />
          ) : null}
        </StyledMenu>
      </div>
    </>
  );
};

export default CloneButton;
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={5}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

function SimpleSnackbar({ open, setOpen, errorMsg, emptyError }) {
  const handleClose = (event, reason) => {
    event?.stopPropagation();
    event?.preventDefault();

    if (reason === "clickaway") {
      return;
    }
    emptyError();
    setOpen(false);
  };

  return (
    <div
      onClick={(e) => {
        e?.preventDefault();
        e?.stopPropagation();
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message={errorMsg}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

const TSEListItem = ({ onClick, Icon, text, secText, disabled }) => {
  return (
    <MenuItem
      onClick={(e) => {
        if (disabled) {
          e.stopPropagation();
          return;
        }
        onClick(e);
      }}
      style={{
        border: "0px solid aqua",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        zIndex: 10,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      {disabled && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            return;
          }}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 20,
            background: "rgba(0,0,0,0.2)",
            cursor: "not-allowed",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div style={{ width: "100%", border: "1px solid black" }} />
        </div>
      )}
      <ListItemIcon
        style={{
          border: "0px solid blue",
          flex: 0,
          minWidth: 20,
          paddingLeft: "0.5rem",
        }}
      >
        {Icon}
      </ListItemIcon>
      <ListItemText
        primary={text}
        secondary={secText ?? ""}
        style={{
          border: "0px solid red",
          textAlign: "left",
          flex: 1,
          paddingLeft: "1.5rem",
          paddingRight: "2rem",
        }}
      />
    </MenuItem>
  );
};
