import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Snackbar,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Tooltip,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-awesome-modal";

import vector from "../../Assets/Images/Vector.png";
import axios from "axios";
import Constants from "../../App/Variables/Constants";
import { getCombinations } from "../../helpers/utils";
import bulb from "../../Assets/Images/bulb.png"

const MATS_COMPO = "MATS_COMPO";

const VariantsCreatorTable = forwardRef(
  ({ productId, components, userId, showSuggestion, showTemplateModal }, ref) => {
    useImperativeHandle(ref, () => {
      return {
        save: async () => {
          await handleSubmit();
        },
      };
    });

    const [allMatsCombo, setAllMatsCombo] = useState([]);
    const [allPossibleVariants, setAllPossibleVariants] = useState([]);

    useEffect(() => {
      let temp = getCombinations(
        components.filter(({ materials }) => materials.length)
      );
      temp = !temp?.[0]?.length ? [] : temp;
      const variantsCombo2 = temp.map((mat) => ({
        materials: mat,
        [MATS_COMPO]: mat
          .map(({ _id }) => _id)
          .sort()
          .join("-"),
      }));
      setAllPossibleVariants(variantsCombo2);
    }, [components]);

    const canMoveToView = (
      allMatsComboProps = allMatsCombo,
      showLogs = false
    ) => {
      // if (showLogs)
      // console.log(
      //   allMatsComboProps.length,
      //   " and ",
      //   allPossibleVariants.length
      // );
      return (
        allMatsComboProps.length != 0 &&
        allPossibleVariants.length != 0 &&
        allMatsComboProps.length >= allPossibleVariants.length &&
        components.length != 0
      );
    };
    const moveToNextscrenn = () => {
      window.location.href = "/admin/viewer/" + userId + "/" + productId;
    };
    const redirectToViewer = () => {
      if (canMoveToView()) {
        moveToNextscrenn();
      }
    };

    const getVariantsData = async () =>
      axios
        .get(
          Constants.getUrls.variants + "?page=1&limit=10&product=" + productId
        )
        .then((res) => res);

    const getVariants = (cb) => {
      axios
        .get(
          Constants.getUrls.variants + "?page=1&limit=10&product=" + productId
        )
        .then((res) => {
          const allMatsCombotemp = res.data.docs.map(
            ({ materials, _id: variant, model_file_url }) => ({
              [MATS_COMPO]: materials
                .map(({ _id }) => _id)
                .sort()
                .join("-"),
              variant,
              model_file_url,
            })
          );
          setAllMatsCombo(allMatsCombotemp);
          cb && setTimeout(() => cb(), 2000);
        });
    };

    useEffect(() => {
      getVariants();
    }, [components]);

    const [openSnackBar, setOpenSnackBar] = useState("");
    const handleCloseSnackbar = () => setOpenSnackBar("");
    async function handleSubmit() {
      const freshData = await getVariantsData();
      const freshVariants = freshData.data.docs;
      const res = canMoveToView(freshVariants, true);
      if (res) {
        moveToNextscrenn();
      } else {
        setOpenSnackBar("Upload model for all variants");
      }
    }

    const isSaved = (matComb) =>
      allMatsCombo.find((d) => d[MATS_COMPO] == matComb);

    const _openSuggestion = (variant, isSaved, variantId) => {
      showSuggestion(variant, isSaved, variantId);
    }
    const getVariantId = (matComb) => {
      const variantId = allMatsCombo.find((p) => p[MATS_COMPO] == matComb);
      return variantId
    }
    return (
      <>
        <div
          style={{
            overflow: "auto",
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {components
                    .filter(({ materials }) => materials.length)
                    .map((component) => (
                      <TableCell
                        key={component._id}
                        style={{
                          fontSize: 20,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {component?.component_name}
                      </TableCell>
                    ))}
                  <TableCell
                    style={{
                      ...BLANK.style,
                      border: "auto",
                      fontSize: 20,
                      maxWidth: 200,
                    }}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPossibleVariants.map((variant, index) => (
                  <TableRow key={variant._id}>
                    <LeftThreeCells
                      materials={variant.materials}
                      isSaved={isSaved(variant[MATS_COMPO])}
                      showTemplateModal={showTemplateModal}

                    />
                    <GLBUploadCell
                      isSaved={isSaved(variant[MATS_COMPO])}
                      index={index}
                      variant={variant}
                      productId={productId}
                      openSuggestion={_openSuggestion}
                      variantId={getVariantId(variant[MATS_COMPO])}
                      showTemplateModal={showTemplateModal}

                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={!!openSnackBar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={openSnackBar}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </>
    );
  }
);

const __DIM = 60;

const BLANK = {
  style: {
    background: "white",
    border: "0px solid red",
    // maxWidth: "max-content",
    padding: 8,

    // border: "1px solid #ddd",
    paddingLeft: 0,
  },
};

export default VariantsCreatorTable;

const LeftThreeCells = ({ materials, isSaved }) => {
  // console.log("data.variantsUploadeddata.variantsUploaded ", materials);
  return (
    <>
      {materials.map((material) => (
        <TableCell align="left" key={material._id} {...BLANK}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              // border: "1px solid red",
              padding: 10,
            }}
          >
            {material.material_type == "color" ? (
              <div
                style={{
                  objectFit: "contain",
                  minWidth: __DIM,
                  minHeight: __DIM,
                  maxWidth: __DIM,
                  maxHeight: __DIM,
                  borderRadius: "0.3rem",
                  background: material.material_value,
                  boxShadow: `0 4px 4px rgb(0 0 0 / 25%)`,
                }}
              />
            ) : (
              <img
                src={`${Constants.imageUrl}${material.material_value}`}
                height={__DIM}
                width={__DIM}
                alt={material.material_name}
                style={{
                  boxShadow: `0 4px 4px rgb(0 0 0 / 25%)`,
                  borderRadius: "0.3rem",
                }}
              />
            )}{" "}
            <Typography
              style={{
                textAlign: "center",
                fontSize: 20,
                marginTop: 5,
                marginLeft: 10,
                whiteSpace: "nowrap",
                wordBreak: "keep-all",
              }}
            >
              {material.material_name}
            </Typography>
          </div>
        </TableCell>
      ))}
    </>
  );
};

const GLBUploadCell = ({ isSaved: propIsSaved, index, variant, productId, openSuggestion, variantId, showTemplateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(!!propIsSaved);
  const [downloadUrl, setDownloadUrl] = useState(
    propIsSaved ? propIsSaved.model_file_url : ""
  );
  useEffect(() => {
    if (!!propIsSaved != isSaved) setIsSaved(!!propIsSaved);
    // console.log("propIsSaved ", propIsSaved);
  }, [propIsSaved]);
  const handleSubmit = async (e) => {
    const target = e.target;
    if (!target.files.length) return;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("model_file", target.files[0]);
      formData.append("product", productId);
      variant.materials.forEach((m) => {
        formData.append("materials[]", m._id || m);
      });

      let axiosRef = axios;

      let url = Constants.postUrls.addVariants;
      if (isSaved) {
        axiosRef = axiosRef.put;
        url += "/" + propIsSaved.variant;
      } else {
        axiosRef = axiosRef.post;
        url += "/" + productId;
      }
      const { data } = await axiosRef(url, formData);
      if (data?.variant?.model_file_url) {
        setDownloadUrl(data.variant.model_file_url);
        setIsSaved(true);
      }
    } catch (err) {
      // console.log("error from catych block ", err);
    } finally {
      setIsLoading(false);
    }
  };

  const showModelSuggestion = (variant, isSaved, variantId,) => {
    openSuggestion(variant, isSaved, variantId);
  }
  return (
    <TableCell
      style={{
        display: "flex",
        alignItems: "center",
        border: 0,
        // maxWidth: 200,
      }}
      align="center"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // border: "1px solid red",
          width: "100%",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          component="label"
          style={{
            width: "100%",
            marginRight: "10px",
            textTransform: "capitalize",
            fontSize: "16px",
            background: isSaved ? "rgba(146, 180, 211, 0.8)" : "#bdbdbd",
            padding: "0 40px",
            height: __DIM,
            color: "#ffffff",
            borderRadius: 6,
            boxShadow: `0 4px 4px rgb(0 0 0 / 25%)`,
            width: 150,
            border: 0,
          }}
          key={index}
        >
          {isLoading ? (
            <CircularProgress size={30} />
          ) : isSaved ? (
            <CheckIcon
              style={{
                color: "#fff",
                fontSize: "30px",
              }}
            />
          ) : (
            "Upload"
          )}

          <input
            type="file"
            onChange={(e) => {
              handleSubmit(e, variant);
            }}
            name={"model_file" + index}
            hidden
            id="uploaddoc"
            className="imageUpload"
            accept=".glb"
          />
        </Button>
        {isSaved ? (
          <a
            href={`${Constants.imageUrl}${downloadUrl}`}
            // target="_blank"
            // className="uploadbtn"
            download
          >
            <Button
              style={{
                // padding: "20px 40px",
                // color: "#ffffff",
                background: "#bdbdbd",
                borderRadius: 6,
                height: __DIM,
                boxShadow: `0 4px 4px rgb(0 0 0 / 25%)`,
              }}
            >
              <img src={vector} alt="" />
            </Button>
          </a>
        ) : (
          <Button
            style={{
              // padding: "20px 40px",
              height: __DIM,

              // color: "#ffffff",
              borderRadius: 6,
              background: "#bdbdbd",
              boxShadow: `0 4px 4px rgb(0 0 0 / 25%)`,
            }}
          >
            <img src={vector} alt="" />
          </Button>
        )}
        <Tooltip title="Choose 3D Model">
          <Button className="uploadbtn" onClick={() => {
            showModelSuggestion(variant, isSaved, variantId)
          }} style={
            {
              height: __DIM,
              marginTop: 0,
              // color: "#ffffff",
              borderRadius: 6,
              background: '#bdbdbd',
              boxShadow: `0 4px 4px rgb(0 0 0 / 25%)`,
              marginLeft: 10
            }
          }>
            <img src={bulb} width={24} alt="" />
          </Button>
        </Tooltip>
        <Tooltip title="Select Template">
          <Button onClick={() => {
            // console.log(propIsSaved, index, variant, productId, openSuggestion, variantId, showTemplateModal)
            showTemplateModal(variant, variantId)
          }} className="uploadbtn" t style={
            {
              height: __DIM,
              marginTop: 0,
              // color: "#ffffff",
              borderRadius: 6,
              background: '#bdbdbd',
              boxShadow: `0 4px 4px rgb(0 0 0 / 25%)`,
              marginLeft: 10,
              width: 150,
              textTransform: 'capitalize'
            }
          }>
            Template
          </Button>
        </Tooltip>
      </div>
    </TableCell>
  );
};
