import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Constants from "../Variables/Constants";

function Agreement({ user }) {
  const [isTermsChecked, setIsTermsChecked] = useState(null);
  const [isEmailChecked, setIsEmailChecked] = useState(null);
  const [isSaving, setIsSaving] = useState(null);

  const handleTermsCheck = (e) => {
    setIsTermsChecked(e.target.checked);
  };
  const handleEmailCheck = (e) => {
    setIsEmailChecked(e.target.checked);
  };
  const save = () => {
    setIsSaving(true);
    axios
      .put(Constants.putUrls.updateUser + user?._id, {
        is_news: isEmailChecked ? "yes" : "no",
        is_term_accepted: isTermsChecked ? "yes" : "no",
      })
      .then((res) => {
        if (res.data.status === "success") {
          window.location.reload();
        }
      });
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 99999999,
        background: "#fffffff2",
      }}
    >
      <div style={{ width: "40%" }} className="agreementCard">
        <Card style={{ padding: 32 }}>
          <Typography variant="h6">
            Hi <b>{user?.name}</b>,
          </Typography>
          <Typography variant="h6">
            Before we get started, can we send emails and do you agree with
            Actuality's terms?
          </Typography>
          <Box
            marginTop={"12px"}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            style={{ paddingLeft: 0 }}
          >
            <Checkbox
              checked={isTermsChecked}
              onChange={handleTermsCheck}
              name="checkedB"
              color="primary"
              size="small"
            />
            <Typography variant="">
              I have read and accept the{" "}
              <Link style={{ color: "#2d3436" }} to="/terms">
                <b>terms of service</b>
              </Link>{" "}
              and{" "}
              <Link to={"/privacy"} style={{ color: "#2d3436" }}>
                <b>privacy policy</b>
              </Link>{" "}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Checkbox
              checked={isEmailChecked}
              onChange={handleEmailCheck}
              name="checkedB"
              color="primary"
              size="small"
            />
            <Typography variant="">
              I want to receive news about Actuality's services by email.
            </Typography>
          </Box>
          <Button
            className="uploadBtn"
            style={{ fontSize: 14, height: 48 }}
            onClick={save}
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Accept"}
          </Button>
        </Card>
      </div>
    </div>
  );
}

export default Agreement;
