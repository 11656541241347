import {
  AppBar,
  Button,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  ArrowDropDown,
  LockRounded,
  SettingsBrightness,
  WbSunnyTwoTone,
} from "@material-ui/icons";
import React, { Component } from "react";
import logo from "../../../Assets/Images/actualityLogo.png";
import logo1 from "../../../Assets/Images/2.png";

export default class MainAppbar extends Component {
  state = {
    theme: "light",
    showMenu: false,
    anchorEl: null,
    user:{}
  };
  handleClick = (event) => {
    this.setState({ showMenu: true, anchorEl: event.currentTarget });
  };
  handleClose = (event) => {
    this.setState({ showMenu: false, anchorEl: null });
  };

  componentDidMount(){
    const user = JSON.parse(localStorage.getItem("user"));
this.setState({user})
  }
  // applyTheme = () => {
  //   const theme = localStorage.getItem("theme");
  //   localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
  //   window.location.reload();
  // };
  logout = () => {
    localStorage.removeItem("feel_xr_auth_token");
    window.location.href = "/";
  };
  render() {
    const state = this.state;
    return (
      <AppBar position="sticky" color="default" style={{ height: "56px" }}>
        <Toolbar style={{ height: "56px" }}>
          <Grid container>
            <Grid sm="6" item>
              {/* <Typography variant="h6">Actuality</Typography> */}
              <span>
                {this.state.theme === "light" ? (
                  <img
                    src={logo}
                    alt=""
                    width="18%"
                    style={{ color: "#fff" }}
                  />
                ) : (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "cneter",
                    }}
                  >
                    <img src={logo1} alt="" width="7%" height="40" />{" "}
                    <span
                      style={{
                        marginTop: "8px",
                        marginLeft: -6,
                        fontSize: "22px",
                      }}
                    >
                      ctuality
                    </span>
                  </span>
                )}
              </span>
            </Grid>
            <Grid sm="6" item>
              <Grid container justify={"flex-end"} alignItems="baseline">
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleClick}
                  endIcon={<ArrowDropDown />}
                >
                 {this.state.user.role === "admin" ? "Admin" : ""}
                 {this.state.user.role === "sub_admin" ? "Sub Admin" : ""}
                </Button>
                <Menu
                  anchorEl={this.state.anchorEl}
                  id="simple-menu"
                  keepMounted
                  open={this.state.showMenu}
                  onClose={this.handleClose}
                >
                  {/* <MenuItem onClick={this.handleClose}>
                    {" "}
                    <Button
                      onClick={this.applyTheme}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {state.theme === "dark" ? (
                        <>
                          <Typography
                            children={
                              <p style={{ fontSize: 12 }}>Light Mode</p>
                            }
                          />
                          <WbSunnyTwoTone color="action" />
                        </>
                      ) : (
                        <>
                          <Typography
                            children={<p style={{ fontSize: 12 }}>Dark Mode</p>}
                          />
                          <SettingsBrightness color="action" />
                        </>
                      )}
                    </Button>
                  </MenuItem> */}

                  {/* <MenuItem onClick={this.handleClose}>
                    {" "}
                    <Button
                      onClick={this.applyTheme}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {state.theme === "dark" ? (
                        <>
                          <Typography
                            children={
                              <p
                                style={{
                                  fontSize: 12,
                                  color:
                                    state.theme === "dark" ? "#000" : "#fff",
                                }}
                              >
                                Dark Mode
                              </p>
                            }
                          />
                          <WbSunnyTwoTone color="action" />
                        </>
                      ) : (
                        <>
                          <Typography
                            children={
                              <p
                                style={{
                                  fontSize: 12,
                                  color:
                                    state.theme === "dark" ? "#000" : "#fff",
                                }}
                              >
                                Light Mode
                              </p>
                            }
                          />
                          <SettingsBrightness color="action" />
                        </>
                      )}
                    </Button>
                  </MenuItem> */}

                  <MenuItem onClick={this.handleClose}>
                    <Button
                      onClick={this.logout}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography
                        children={
                          <p
                            style={{
                              fontSize: 12,
                              color: state.theme === "light" ? "#000" : "#fff",
                            }}
                          >
                            Logout
                          </p>
                        }
                      />
                      <LockRounded color="action" size={"small"} />
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={this.handleClose}>
                  <a  href="/admin/change_password"
                style={{ textDecoration: "none" }}
              >
                    <Button
                      //onClick={this.logout}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography
                        children={
                          <p
                            style={{
                              fontSize: 12,
                              color: state.theme === "light" ? "#000" : "#fff",
                            }}
                          >
                            Change Password
                          </p>
                        }
                      />
                    
                    </Button></a>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}
