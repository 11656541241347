import { Radio, Typography } from "@material-ui/core";
import React from "react";

const PlacementRadio = ({ onChange, propState, alignItems = "center" }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems,
        // border: "1px solid red",
      }}
    >
      <Typography
        style={{
          fontSize: 15,
          fontWeight: 500,
          color: "#000000",
          //   border: "2px solid #ddd",
          //   alignSelf: "center",
          display: "block",
        }}
      >
        {" "}
        Placement
      </Typography>
      <Radio
        color="default"
        checked={propState.placement == "wall"}
        onChange={() => {
          onChange("wall");
          // this.setState({ placement: "wall" });
        }}
        value="wall"
        name="radio-button-demo"
        inputProps={{ "aria-label": "A" }}
        style={
          {
            //   border: "1px solid red",
          }
        }
      />{" "}
      <Typography
        style={{
          fontSize: 15,
          fontWeight: 500,
          color: "#000000",
          //   border: "1px solid #ddd",
        }}
      >
        Wall
      </Typography>
      <Radio
        color="default"
        checked={propState.placement == "floor"}
        onChange={() => {
          onChange("floor");

          // this.setState({ placement: "floor" });
        }}
        value="floor"
        name="radio-button-demo"
        inputProps={{ "aria-label": "A" }}
      />
      <Typography
        style={{
          fontSize: 15,
          fontWeight: 500,
          color: "#000000",
          //   border: "1px solid #ddd",
        }}
      >
        Floor
      </Typography>
    </div>
  );
};

export default PlacementRadio;
