import { LocalKeys } from "./AppConstants";

// TODO: signIn signOut promises
// isUserAuth
// resource validation

export const authToken = localStorage.getItem(LocalKeys.accessToken) || null;

const AuthsConfig = {};
export default AuthsConfig;

export const isAuth = authToken ? true : false;
