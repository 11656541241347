const arDeviceText = (lang) =>
  lang.toLowerCase() == "french" ? (
    <p
      style={{
        border: "0px solid blue",
        margin: 0,
        padding: 0,
        display: "inline-block",
        // textAlign: "left",
        maxWidth: "100%",
      }}
    >
      * Fonctionne mieux avec
      <a href="https://arcade.ltd/is-my-device-ar-compatible/" target="_blank">
        {` `}ces appareils{` `}
      </a>
    </p>
  ) : (
    <p
      style={{
        border: "0px solid blue",
        margin: 0,
        padding: 0,
        display: "inline-block",
        // textAlign: "left",
        maxWidth: "100%",
      }}
    >
      * Works best with
      <a href="https://arcade.ltd/is-my-device-ar-compatible/" target="_blank">
        {` `}these devices{` `}
      </a>
    </p>
  );
export default arDeviceText;
