import React, { Component } from "react";
import axios from "axios";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
  Snackbar,
  Input,
} from "@material-ui/core";
import { Redirect, Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Constants from "../../Variables/Constants";

export default class UpdateUsers extends Component {
  state = {
    title: "",
    description: "",
    loading: false,
    catId: "",
  };
  componentDidMount() {
    // console.log("update user");
    // console.log(this.props);
    let data = this.props.location.state.data;
    this.setState(
      {
        catId: data._id,
        title: data.title,
        description: data.description,
      },
      () => {
        // console.log(this.state.catId);
      }
    );
  }
  updateUser = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let catLoad = {
      title: this.state.title,
      description: this.state.description,
    };
    // if (init() === "success") {
    axios
      .put(Constants.getUrls.Categories + "/" + this.state.catId, catLoad)
      .then((resp) => {
        // console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
    // }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFile = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
      // blogImage: URL.createObjectURL(e.target.files[0]),
      // showPreview: true
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/users" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">{this.props.title}</Typography>
            </Grid>
          </Grid>
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"green"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          <div
            style={{
              padding: "20px",
              border: "1px solid #afa2a2",
              borderRadius: "7px",
              position: "relative",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>
            <span
              style={{
                color: "#afa3a3",
                marginTop: "-8px",
                marginLeft: "26px",
                marginBottom: "35px",
                fontSize: "17px",
                display: "flex",
              }}
            >
              Update User
            </span>

            <Grid item sm={12} style={{ textAlign: "left", marginTop: "30px" }}>
              {/* <form noValidate autoComplete="off" onSubmit={this.updateUser}> */}
              <form onSubmit={this.updateUser}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  required={true}
                  label="Name"
                  name="name"
                  value={this.state.name}
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  required={true}
                  label="Address"
                  name="address"
                  value={this.state.address}
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  required={true}
                  label="Title"
                  name="title"
                  value={this.state.title}
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  label="Company Name"
                  onChange={this.handleInput}
                  name="company_name"
                  value={this.state.company_name}
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  required={true}
                  label="Profile Role"
                  name="profile_role"
                  value={this.state.profile_role}
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  required={true}
                  label="Industry"
                  name="industry"
                  value={this.state.industry}
                  variant="outlined"
                  style={{ marginRight: "20px", width: "30%" }}
                />
                <Input
                  type="file"
                  onChange={this.handleFile}
                  name="profile_pic"
                  hidden
                  required
                  className="imageUpload"
                />
                <Input
                  type="file"
                  onChange={this.handleFile}
                  name="company_logo"
                  hidden
                  required
                  className="imageUpload"
                />
                <br />
                <Button
                  variant="contained"
                  type="submit"
                  Size={15}
                  color="primary"
                  style={{ marginRight: "20px", marginTop: "35px" }}
                >
                  {this.state.loading ? "Updating.." : "Update User"}
                </Button>
                <Link
                  to={{
                    pathname: "/categories",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    Size={15}
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </form>
            </Grid>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Fade in={this.state.open}>
              <div
                style={{
                  background: "#fff",
                  width: "50%",
                  padding: "32px",
                }}
              >
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">
                  react-transition-group animates me.
                </p>
              </div>
            </Fade>
          </Modal>
        </Container>
        //   </SnackbarProvider>
      );
    }
  }
}
