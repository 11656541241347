import {
  Button,
  Container,
  FormControl,
  Grid,
  Input,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
// import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import React, { Component } from "react";
import Constants from "../App/Variables/Constants";
import init from "../helpers/WindowToken";

// const classes = makeStyles(styles);

export default class AddProduct extends Component {
  state = {
    title: "",
    description: "",
    loading: false,
    posterImage: "",
    modelFile: "",
    is_model_available: "",
    categories: [],
  };
  componentDidMount() {
    this.getcategories();
  }

  addproducts = (e) => {
    e.preventDefault();
    let productLoad = {
      title: this.state.title,
      description: this.state.description,
      posterImage: this.state.posterImage,
      modelFile: this.state.modelFile,
      is_model_available: this.state.is_model_available,
      categories: this.state.categories,
    };
    // console.log(productLoad);
    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios.post(Constants.getUrls.Products, productLoad).then((resp) => {
        // console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 1500);
        }
      });
    }
  };
  getcategories = () => {
    axios.get(Constants.getUrls.Categories).then((resp) => {
      // console.log(resp);
      this.setState({
        categories: resp.data.categories.docs,
      });
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      // <SnackbarProvider maxSnack={3}>
      <Container maxWidth="xl">
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6">{this.props.title}</Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <div
            style={{
              width: "228px",
              position: "relative",
              left: "13%",
              top: "7px",
              borderRadius: "6px",
              background: "Green",
              padding: "13px",
            }}
          >
            <Typography>{this.state.message}!</Typography>
          </div>
        ) : null}
        <Grid item sm={12} style={{ textAlign: "center" }}>
          <form noValidate autoComplete="off" onSubmit={this.addproducts}>
            <TextField
              id="outlined-basic"
              onChange={this.handleInput}
              label="Title"
              name="title"
              variant="outlined"
              style={{ marginRight: "20px", width: "30%" }}
            />
            <TextField
              id="outlined-basic"
              label="Description"
              onChange={this.handleInput}
              name="description"
              variant="outlined"
              style={{ marginRight: "20px", width: "30%" }}
            />
            <label htmlFor=""> Poster Image</label>
            <Input type="file" name="posterImage" />
            <label htmlFor=""> Model File</label>
            <Input type="file" name="is_model_available" />
            <FormControl
              style={{ marginTop: "12px" }}
              fullWidth
              // className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="simple-select"
                // className={classes.selectLabel}
              >
                Main Category
              </InputLabel>
              <Select
                MenuProps={
                  {
                    // className: classes.selectMenu
                  }
                }
                classes={
                  {
                    // select: classes.select
                  }
                }
                inputProps={{
                  name: "categories",
                  id: "simple-select",
                  onChange: this.handleInput,
                }}
              >
                {this.state.categories.map((cat, index) => (
                  <MenuItem
                    // classes={{
                    //   // root: classes.selectMenuItem,
                    //   // selected: classes.selectMenuItemSelected
                    // }}
                    key={index}
                    value={cat.id}
                  >
                    {cat.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <Button
              variant="contained"
              type="submit"
              Size={15}
              color="primary"
              style={{ marginRight: "20px", marginTop: "20px" }}
            >
              {this.state.loading ? "Adding.." : "Add Product"}
            </Button>
          </form>
        </Grid>
      </Container>

      //   </SnackbarProvider>
    );
  }
}
