import { Button, Container, Grid, Switch, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import right from "../../../Assets/Images/Right.png";
import wrong from "../../../Assets/Images/Wrong.png";
import cross from "../../../Assets/Images/cross.png";
import axios from "axios";
import Constants from "../../Variables/Constants";
import Toggle from "react-toggle";

class Pricing extends Component {
  state = {
    plans: [],
    monthlyPlans: [],
    annualPlans: [],
    itemId: "",
    itemPrice: "",
    loadingBeg: false,
    loadingGrow: false,
    loadingEnte: false,
    alreadypurchased: false,
    modalIdAvailable: false,
    toggleStatus: false,
    purchasedPlanPrice: "",
    userId: window.location.pathname.split("/")[3],
    payId: window.location.pathname.split("/")[4],
    modalId: window.location.pathname.split("/")[4],

    dataPrice: [],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    let pId = document.location.href;
    var url = new URL(pId);
    if (this.state.modalId == null) {
      this.setState({
        modalIdAvailable: false,
      });
    } else {
      this.setState({
        modalIdAvailable: true,
      });
    }

    this.getMonthlyPricing();

    this.getAnnualPricing();

    axios.get(Constants.getUrls.getPlans).then((resp) => {
      // console.log(resp);
      if (resp.data.status === "failed") {
        // console.log("else");
      } else {
        this.setState(
          {
            purchasedPlanPrice:
              resp.data.data.subscription.subscription_items[0].amount,
          },
          () => {
            // console.log(this.state.purchasedPlanPrice);
          }
        );
      }
    });

    // this.getPricePlan();
  }

  getMonthlyPricing = () => {
    axios
      .get(
        Constants.getUrls.getPricing +
          "?limit=3&flag=all&status=monthly&plan_status=active"
      )
      .then((res) => {
        // console.log(res);
        if (res.data.data.docs.length > 0) {
          this.setState({
            monthlyPlans: res.data.data.docs,
          });
        }
      });
  };

  getAnnualPricing = () => {
    axios
      .get(
        Constants.getUrls.getPricing +
          "?limit=3&flag=all&status=annual&plan_status=active"
      )
      .then((resp) => {
        if (resp.data.data.docs.length > 0) {
          this.setState({
            annualPlans: resp.data.data.docs,
          });
        }
      });
  };

  // getPricePlan = () =>{
  //     axios.get(Constants.getUrls.getPricing + "?status=" + this.state.toggleStatus + "&flag=all")
  //     .then((resp)=>{
  //     // console.log(resp);
  //       this.setState({
  //         dataPrice : resp.data.data.docs,
  //       },()=>{
  //       // console.log(this.state.dataPrice);
  //       });
  //     })
  //     .catch((err)=>{
  //       if(err){
  //         alert(err);
  //       }
  //     })
  // }

  gotoPortal = () => {
    let payload = {
      redirect_url: "https://admin.actuality.live/userprofile",
    };
    axios.post(Constants.postUrls.chargebeePortal, payload).then((resp) => {
      // console.log(resp);
      this.setState(
        {
          portalUrl: resp.data.portal_session.access_url,
        },
        () => {
          window.open(this.state.portalUrl, "_blank").focus();
        }
      );
    });
  };

  payment = (pricingId) => {
    // console.log(pricingId);
    // console.log(this.state.modalIdAvailable);
    // console.log(this.state.itemId,this.state.itemPrice);
    // if (init() === "success")
    // {
    let pId = document.location.href;
    var url = new URL(pId);
    localStorage.setItem("productId", this.state.modalId);
    localStorage.setItem("pricingId", pricingId);
    let authToken = localStorage.getItem("feel_xr_auth_token");

    axios
      .get(Constants.getUrls.getUserPlans + this.state.userId, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((resp) => {
        // console.log(resp);
        // if (resp.data.orders.docs.length > 0) {
        //   this.setState({
        //     // alreadypurchased: true,
        //     loadingBeg: false,
        //     loadingGrow: false,
        //     loadingEnte: false,
        //   });
        //   this.gotoPortal();
        // }
        // else {
        const payload = {
          pricing_id: pricingId,
          redirectUrl:
            "https://admin.actuality.live/admin/payment_success?user_id=" +
            this.state.userId,
        };
        axios
          .post(Constants.postUrls.chargebeePayment, payload, {
            headers: { Authorization: "Bearer " + authToken },
          })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status === "success") {
              // console.log("dddd");
              window.open(resp.data.result.hosted_page.url, "_blank").focus();
              this.setState({
                loadingBeg: false,
                loadingGrow: false,
                loadingEnte: false,
              });
            } else {
              window.location.href = "/account/login";
              this.setState({
                loadingBeg: false,
                loadingGrow: false,
                loadingEnte: false,
              });
            }
          });
        // }
      });
    // }
    // else
    // {
    //   window.location.href = "/sign-in";
    // }
  };

  handleToggleChange = () => {
    // console.log("hi");
    if (this.state.toggleStatus === true) {
      this.setState({
        toggleStatus: false,
      });
    } else {
      this.setState({
        toggleStatus: true,
      });
    }
    // console.log(this.state.toggleStatus);
  };

  render() {
    return (
      <div>
        <section className="sectionInnerSpacingPricing">
          <Container>
            <section>
              <div>
                <div>
                  <h1 className="plans">Plans And Pricing</h1>
                  <p className="subTitlePlans">
                    30-day free trial, cancel any time.{" "}
                  </p>
                  <span
                    className={
                      window.innerWidth < 1100 ? "toggleArea1100" : "toggleArea"
                    }
                  >
                    <span className="annualPlan">Monthly Plans</span>
                    <span style={{ position: "absolute", top: "6px" }}>
                      {/* <Toggle
                        checked={this.state.toggleStatus ? true : false}
                        onChange={e => this.handleToggleChange(e)}
                        value={this.state.toggleStatus}
                        icons={false}
                      /> */}
                    </span>
                    <Switch
                      checked={this.state.toggleStatus ? true : false}
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      onChange={(e) => this.handleToggleChange(e)}
                      value={this.state.toggleStatus}
                    />
                    <span className="monthlyPlan">Annual Plans</span>
                  </span>
                </div>
              </div>
            </section>

            <section
              className="sectionInnerSpacingAbout2"
              style={{
                marginLeft: window.innerWidth < 1100 ? "-18px" : "0",
                marginTop: "40px",
              }}
            >
              <Grid container spacing={4}>
                {this.state.toggleStatus
                  ? this.state.annualPlans.length > 0 &&
                    this.state.annualPlans.map((data, i) => (
                      <Grid item sm={3}>
                        <div
                          className={
                            window.innerWidth < 1100
                              ? "pricingCard1100"
                              : "pricingCard"
                          }
                        >
                          <div className="imgWrapper">
                            <h1 className="beginner">{data.title}</h1>
                            <h1 className="price">
                              ${data.item_price.substring(0, 3)}
                            </h1>
                            <div className="month">/{data.status}</div>
                            <p className="modelTitle">
                              All the basics for businesses that are just
                              getting started.
                            </p>
                            <Button
                              size={"large"}
                              variant="contained"
                              className="themeBtn txtShadow roundedPlansPricing buyNow"
                              onClick={() => {
                                this.setState(
                                  {
                                    itemId: data.item_id,
                                    // "Beginner-USD-Monthly",
                                    itemPrice: data.item_price * 12,
                                    loadingBeg: true,
                                  },
                                  () => {
                                    this.payment(data._id);
                                  }
                                );
                              }}
                            >
                              {this.state.loadingBeg
                                ? "Loading..."
                                : this.state.purchasedPlanPrice
                                ? this.state.purchasedPlanPrice === 299 ||
                                  this.state.purchasedPlanPrice === 249 * 12
                                  ? "Purchased"
                                  : "Upgrade"
                                : "Buy Now"}
                            </Button>

                            <div className="bottomBorder"></div>

                            <div className="vectorArea">
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={right}
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    {data.no_of_hosted_models} Hosted Models
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={right}
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    {" "}
                                    {data.monthly_views} Monthly Website Views
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={
                                      data.first_model_optimization_free
                                        ? right
                                        : wrong
                                    }
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    First Model Optimization Free
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={
                                      data.installation_support ? right : wrong
                                    }
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    Installation Support
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={data.analytics ? right : wrong}
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    Analytics
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  {/* <span className="crossImg"></span> */}
                                  {data.dedicated_account_manager ? (
                                    <img
                                      src={right}
                                      alt=""
                                      width="12px"
                                      height="12px"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={cross}
                                        alt=""
                                        width="5px"
                                        height="5px"
                                        className="crossImg"
                                      />
                                      <img
                                        src={wrong}
                                        alt=""
                                        width="12px"
                                        height="12px"
                                        style={{ position: "relative" }}
                                      />
                                    </>
                                  )}
                                  <span className="hostedModels">
                                    Dedicated Account Manager
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  {/* <span className="crossImg2"></span> */}
                                  {data.animated_model ? (
                                    <img
                                      src={right}
                                      alt=""
                                      width="12px"
                                      height="12px"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={cross}
                                        alt=""
                                        width="5px"
                                        height="5px"
                                        className="crossImg2"
                                      />
                                      <img
                                        src={wrong}
                                        alt=""
                                        width="12px"
                                        height="12px"
                                        style={{ position: "relative" }}
                                      />
                                    </>
                                  )}
                                  <span className="hostedModels">
                                    Animated Models
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  {/* <span className="crossImg3"></span> */}
                                  {data.white_labeled ? (
                                    <img
                                      src={right}
                                      alt=""
                                      width="12px"
                                      height="12px"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={cross}
                                        alt=""
                                        width="5px"
                                        height="5px"
                                        className="crossImg3"
                                      />
                                      <img
                                        src={wrong}
                                        alt=""
                                        width="12px"
                                        height="12px"
                                        style={{ position: "relative" }}
                                      />
                                    </>
                                  )}
                                  <span className="hostedModels">
                                    White Labeled
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="bottomBorder2"></div>
                            <div className="vectorArea2">
                              <div className="paddingTop2">
                                <div className="hostedModels">
                                  AR Model Optamization starts from
                                </div>
                                <span className="varient">
                                  $150 + $20/Varient
                                </span>
                              </div>
                              <div className="paddingTop2">
                                <span className="hostedModels">
                                  VR starts from{" "}
                                </span>
                                <span className="varient2">$300</span>
                              </div>
                              <div className="paddingTop2">
                                <span className="gamefied">
                                  Gamefied Walkthrough starts <br />{" "}
                                  <span style={{ paddingLeft: "10px" }}>
                                    from <span className="varient2">$4000</span>{" "}
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop2">
                                <span className="gamefied">
                                  Photo Realistic Renderings starts <br />{" "}
                                  <span style={{ paddingLeft: "10px" }}>
                                    from <span className="varient2">$150</span>{" "}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))
                  : this.state.monthlyPlans.length > 0 &&
                    this.state.monthlyPlans.map((data, i) => (
                      <Grid item sm={3}>
                        <div
                          className={
                            window.innerWidth < 1100
                              ? "pricingCard1100"
                              : "pricingCard"
                          }
                        >
                          <div className="imgWrapper">
                            <h1 className="beginner">{data.title}</h1>
                            <h1 className="price">
                              ${data.item_price.substring(0, 3)}
                            </h1>
                            <div className="month">/{data.status}</div>
                            <p className="modelTitle">
                              All the basics for businesses that are just
                              getting started.
                            </p>
                            <Button
                              size={"large"}
                              variant="contained"
                              className="themeBtn txtShadow roundedPlansPricing buyNow"
                              onClick={() => {
                                this.setState(
                                  {
                                    itemId: data.item_id,
                                    itemPrice: data.item_price,
                                    loadingBeg: true,
                                  },
                                  () => {
                                    this.payment(data._id);
                                  }
                                );
                              }}
                            >
                              {this.state.loadingBeg
                                ? "Loading..."
                                : this.state.purchasedPlanPrice
                                ? this.state.purchasedPlanPrice === 299 ||
                                  this.state.purchasedPlanPrice === 249 * 12
                                  ? "Purchased"
                                  : "Upgrade"
                                : "Buy Now"}
                            </Button>

                            <div className="bottomBorder"></div>

                            <div className="vectorArea">
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={right}
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    {data.no_of_hosted_models} Hosted Models
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={right}
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    {data.monthly_views}Monthly Website Views
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={
                                      data.first_model_optimization_free
                                        ? right
                                        : wrong
                                    }
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    First Model Optimization Free
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={
                                      data.installation_support ? right : wrong
                                    }
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    Installation Support
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  <img
                                    src={data.analytics ? right : wrong}
                                    alt=""
                                    width="12px"
                                    height="12px"
                                  />
                                  <span className="hostedModels">
                                    Analytics
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  {/* <span className="crossImg"></span> */}
                                  {data.dedicated_account_manager ? (
                                    <img
                                      src={right}
                                      alt=""
                                      width="12px"
                                      height="12px"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={cross}
                                        alt=""
                                        width="5px"
                                        height="5px"
                                        className="crossImg"
                                      />
                                      <img
                                        src={wrong}
                                        alt=""
                                        width="12px"
                                        height="12px"
                                        style={{ position: "relative" }}
                                      />
                                    </>
                                  )}
                                  <span className="hostedModels">
                                    Dedicated Account Manager
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  {/* <span className="crossImg2"></span> */}
                                  {data.animated_model ? (
                                    <img
                                      src={right}
                                      alt=""
                                      width="12px"
                                      height="12px"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={cross}
                                        alt=""
                                        width="5px"
                                        height="5px"
                                        className="crossImg2"
                                      />
                                      <img
                                        src={wrong}
                                        alt=""
                                        width="12px"
                                        height="12px"
                                        style={{ position: "relative" }}
                                      />
                                    </>
                                  )}
                                  <span className="hostedModels">
                                    Animated Models
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop">
                                <span>
                                  {/* <span className="crossImg3"></span> */}
                                  {data.white_labeled ? (
                                    <img
                                      src={right}
                                      alt=""
                                      width="12px"
                                      height="12px"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={cross}
                                        alt=""
                                        width="5px"
                                        height="5px"
                                        className="crossImg3"
                                      />
                                      <img
                                        src={wrong}
                                        alt=""
                                        width="12px"
                                        height="12px"
                                        style={{ position: "relative" }}
                                      />
                                    </>
                                  )}
                                  <span className="hostedModels">
                                    White Labeled
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="bottomBorder2"></div>
                            <div className="vectorArea2">
                              <div className="paddingTop2">
                                <div className="hostedModels">
                                  AR Model Optamization starts from
                                </div>
                                <span className="varient">
                                  $150 + $20/Varient
                                </span>
                              </div>
                              <div className="paddingTop2">
                                <span className="hostedModels">
                                  VR starts from{" "}
                                </span>
                                <span className="varient2">$300</span>
                              </div>
                              <div className="paddingTop2">
                                <span className="gamefied">
                                  Gamefied Walkthrough starts <br />{" "}
                                  <span style={{ paddingLeft: "10px" }}>
                                    from <span className="varient2">$4000</span>{" "}
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop2">
                                <span className="gamefied">
                                  Photo Realistic Renderings starts <br />{" "}
                                  <span style={{ paddingLeft: "10px" }}>
                                    from <span className="varient2">$150</span>{" "}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}

                <Grid item sm={3}>
                  <div
                    className={
                      window.innerWidth < 1100
                        ? "pricingCard1100"
                        : "pricingCard"
                    }
                  >
                    <div className="imgWrapper">
                      <h1 className="beginner">Build Your Plan</h1>
                      <h1 className="price">Flexible</h1>
                      <div className="month">/month</div>
                      <p className="modelTitle">
                        All the basics for businesses that are just getting
                        started.
                      </p>
                      {/* <div className="buyNow"><span className="buyBtn">Contact Seller</span></div> */}
                      {/* <a
                        href="javascript:void(0)"
                        data-cb-type="checkout"
                        data-cb-item-0="cbdemo_advanced-USD-monthly"
                        data-cb-item-0-quantity="1"
                        style={{ textDecoration: "none" }}
                      > */}
                      <Link to="/contact-us" style={{ textDecoration: "none" }}>
                        <Button
                          size={"large"}
                          variant="contained"
                          className="themeBtn txtShadow roundedPlansPricing buyNow"
                        >
                          Contact Seller
                        </Button>
                      </Link>
                      {/* </a> */}

                      <div className="bottomBorder"></div>

                      <div className="vectorArea">
                        <div className="paddingTop">
                          <span>
                            <img
                              src={right}
                              alt=""
                              width="12px"
                              height="12px"
                            />
                            <span className="hostedModels">
                              Custom Hosted Models
                            </span>
                          </span>
                        </div>
                        <div className="paddingTop">
                          <span>
                            <img
                              src={right}
                              alt=""
                              width="12px"
                              height="12px"
                            />
                            <span className="hostedModels">
                              Custom Monthly Website Views
                            </span>
                          </span>
                        </div>
                        <div className="paddingTop">
                          <span>
                            <img
                              src={right}
                              alt=""
                              width="12px"
                              height="12px"
                            />
                            <span className="hostedModels">
                              First Model Optimization Free
                            </span>
                          </span>
                        </div>
                        <div className="paddingTop">
                          <span>
                            <img
                              src={right}
                              alt=""
                              width="12px"
                              height="12px"
                            />
                            <span className="hostedModels">
                              Installation Support
                            </span>
                          </span>
                        </div>
                        <div className="paddingTop">
                          <span>
                            <img
                              src={right}
                              alt=""
                              width="12px"
                              height="12px"
                            />
                            <span className="hostedModels">
                              Custom Analytics
                            </span>
                          </span>
                        </div>
                        <div className="paddingTop">
                          <span>
                            <img
                              src={right}
                              alt=""
                              width="12px"
                              height="12px"
                            />
                            <span className="hostedModels">
                              Dedicated Account Manager
                            </span>
                          </span>
                        </div>
                        <div className="paddingTop">
                          <span>
                            <img
                              src={right}
                              alt=""
                              width="12px"
                              height="12px"
                            />
                            <span className="hostedModels">
                              Animated Models
                            </span>
                          </span>
                        </div>
                        <div className="paddingTop">
                          <span>
                            <img
                              src={right}
                              alt=""
                              width="12px"
                              height="12px"
                            />
                            <span className="hostedModels">Custom UI/UX</span>
                          </span>
                        </div>
                      </div>
                      <div className="bottomBorder2"></div>
                      <div className="vectorArea2">
                        <div className="paddingTop2">
                          <div className="hostedModels">
                            AR Model Optamization starts from
                          </div>
                          <span className="varient">$100 + $20/Varient</span>
                        </div>
                        <div className="paddingTop2">
                          <span className="hostedModels">VR starts from </span>
                          <span className="varient2">$200</span>
                        </div>
                        <div className="paddingTop2">
                          <span className="gamefied">
                            Gamefied Walkthrough starts <br />{" "}
                            <span style={{ paddingLeft: "10px" }}>
                              from <span className="varient2">$3000</span>{" "}
                            </span>
                          </span>
                        </div>
                        <div className="paddingTop2">
                          <span className="gamefied">
                            Photo Realistic Renderings starts <br />{" "}
                            <span style={{ paddingLeft: "10px" }}>
                              from <span className="varient2">$80</span>{" "}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </section>
          </Container>
        </section>

        {/* Purchase plan modal */}
        <Modal
          visible={this.state.alreadypurchased}
          width="350"
          height="230"
          effect="fadeInUp"
          onClickAway={() => this.setState({ alreadypurchased: false })}
        >
          <div
            className="confirmationModalPublish"
            style={{ textAlign: "center" }}
          >
            <Typography>You already have purchased plan.</Typography>
            <Button
              onClick={() => this.setState({ alreadypurchased: false })}
              className="DeleteBtnPublish"
              style={{ background: "#bdbdbd", marginTop: "30px" }}
            >
              Ok
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Pricing;
