import API from "../../App/Variables/Constants";
import React from "react";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
const ColorMetarial = ({
  material,
  handleEdit,
  onMaterialClick,
  isSelected,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: 20,
        marginBottom: 10,
        maxWidth: 50,
      }}
      key={material._id}
    >
      <div
        style={{
          position: "relative",
          width: 50,
          height: 50,

          marginBottom: 10,
        }}
      >
        <DeleteComb handleEdit={handleEdit} />
        <div
          className="smallBox"
          style={{
            padding: 0,
            margin: 0,
            background: material.material_value,
            cursor: "pointer",
            border: `2px solid rgba(255,255,255,${isSelected() ? "1" : 0})`,
            outline: `2px solid rgba(0,0,0,${isSelected() ? "0.8" : 0})`,
          }}
          onClick={() => onMaterialClick()}
        >
          {material.material_type == "color" ? null : (
            <img
              className="smallBox"
              height="50"
              width="50"
              src={API.imageUrl + material.material_value}
              style={{
                borderRadius: 5,
                marginLeft: 0,
              }}
            />
          )}
        </div>
      </div>
      <p
        style={{
          margin: 0,
          padding: 0,
          fontSize: "14px",
          fontFamily: "Inter",
          textAlign: "center",
          cursor: "pointer",
          // textTransform: "capitalize",
          wordBreak: "break-all",
          fontWeight: 400,
          color: "#565656",
        }}
      >
        {material.material_name}
      </p>
    </div>
  );
};
const DeleteComb = ({ handleEdit }) => {
  return (
    <div
      onClick={handleEdit}
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translate(50%,-40%)",
        display: "grid",
        placeItems: "center",
        borderRadius: "50%",
        outline: "1px solid #ddd",
      }}
    >
      <EditOutlinedIcon
        style={{
          color: "gray",
          fontSize: "11px",
          cursor: "pointer",
          padding: 2,
          borderRadius: "10px",
          background: "white",
        }}
      />
    </div>
  );
};
export default ColorMetarial;
