import React, { useEffect, useState } from 'react';
import SearchBox from './SearchBox';
import ProductsListing from './ProductsListing';
import { Grid } from '@material-ui/core';

const RightContentBox = ({ category, share: shareProp, shareId, user_id }) => {
  const [query, setQuery] = useState('');
  const handleChangeQuery = (p) => setQuery(p);
  console.log(user_id)
  return (
    <Grid item sm={9} >

      <SearchBox handleChangeQuery={handleChangeQuery} query={query} />
      <ProductsListing
        query={query}
        category={category}
        share={shareProp}
        shareId={shareId}
        user_id={user_id}
      />
      {/* </div> */}
    </Grid>
  );
};

export default RightContentBox;
