import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Grid, Tooltip, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Constants from "../../App/Variables/Constants";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";
import GetAppTwoToneIcon from "@material-ui/icons/GetAppTwoTone";
export default function ContactInfoDialog({ user }) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button
        onClick={handleClickOpen("paper")}
        variant="contained"
        color="secondary"
        size="small"
        style={{
          margin: "5px 0",
        }}
        disableElevation
      >
        Show
      </Button>
      {/* <Button onClick={handleClickOpen("body")}>scroll=body</Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Contact Info
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: "1px solid #ddd",
              marginTop: 5,
              paddingTop: 10,
            }}
          >
            <div
              style={{
                paddingRight: 10,
              }}
            >
              <Typography>Name</Typography>
              {/* <div  >Name</div> */}
              <div style={{ fontStyle: "italic" }}>{user.name}</div>
            </div>
            <div>
              <Typography>Email</Typography>
              <div style={{ fontStyle: "italic" }}>{user.email}</div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          dividers
          style={
            {
              // border: "2px solid blue",
            }
          }
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography
              variant="h5"
              align="center"
              style={{
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              Message
            </Typography>

            <Typography
              variant="body1"
              style={{
                padding: "10px 0",
                textAlign: "justify",
              }}
            >
              {user.message}
            </Typography>
          </DialogContentText>
          <FileButtons
            files={user.file_path.filter(
              (file) => !isImage(file) && !isVideo(file)
            )}
          />
          {user.file_path.length ? (
            <TitlebarImageList
              itemData={user.file_path
                .filter((file) => isImage(file) || isVideo(file))
                .sort((a, b) => (isVideo(a) ? 1 : -1))
                .map((file) => `${Constants.imageUrl}${file}`)}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    // width: 1200,
    // border: "1px solid red",
    paddingTop: 0,
    marginTop: 5,
  },
  imageList: {
    width: 800,
    // height: 540,
    paddingTop: 0,
    // border: "1px solid blue",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

const FileButtons = ({ files }) => {
  if (!files.length) return "";
  return (
    <>
      <Typography
        variant="h5"
        align="center"
        style={{
          borderBottom: "1px solid #ddd",
          paddingBottom: "10px",
        }}
      >
        Attachments
      </Typography>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          // alignItems: "center",
          justifyContent: "space-around",
          // padding: 10,
          maxWidth: "100%",
          // border: "1px solid red",
        }}
      >
        {files.map((file) => {
          // get exact name
          let fileName = file.split("/").pop();
          // Get extension
          let extName = fileName.split(".").pop();
          // remove extension
          fileName = fileName.split(".").slice(0, -1).join("");
          // remove extra fillers

          // fileName = fileName.split("__ACTUALITY__");

          // if (fileName.length > 1)
          //   fileName = fileName.slice(0, -1).join("__ACTUALITY__");
          // else fileName = fileName.pop();

          let exactFileName = fileName + "." + extName;
          if (fileName.length > 10) {
            let tempFileName = fileName.substring(0, 5);
            tempFileName = tempFileName + "...";
            tempFileName = tempFileName + fileName.slice(-3);

            fileName = tempFileName;
          }

          fileName += "." + extName;
          return (
            <Tooltip title={exactFileName}>
              <Box
                style={{
                  display: "flex",
                  // wrap: "no-wrap",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  padding: "2px 2px",
                  border: "1px solid blue",
                  borderRadius: "0.3rem",
                  flex: 0.2,
                  marginTop: 10,
                }}
              >
                <span
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    wordBreak: "no-break",
                    flex: "1",
                    whiteSpace: "nowrap",
                    marginRight: 2,
                    marginLeft: 4,
                    color: "blue",
                  }}
                >
                  <a href={Constants.imageUrl + file}>{fileName}</a>
                </span>
                <a
                  href={`${Constants.imageUrl}${file}`}
                  target="_blank"
                  download
                >
                  <IconButton size="small" color="primary">
                    <GetAppTwoToneIcon fontSize="small" />
                  </IconButton>
                </a>
                <a href={`${Constants.imageUrl}${file}`} target="_blank">
                  {" "}
                  <IconButton size="small" color="primary">
                    <LaunchTwoToneIcon fontSize="small" />
                  </IconButton>{" "}
                </a>
              </Box>
            </Tooltip>
          );
        })}
      </Box>
    </>
  );
};

const isImage = (filename) =>
  /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(filename);
const isVideo = (filename) => /\.(mp4|mov|wmv|avi|webm)$/i.test(filename);

function TitlebarImageList({ itemData }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList rowHeight={180} className={classes.imageList}>
        {/* <ImageListItem key="Subheader" cols={2} style={{ height: "auto" }}> */}
        {/* <ListSubheader component="div">December</ListSubheader> */}
        {/* </ImageListItem> */}
        {itemData.map((item) => (
          <ImageListItem
            key={item}
            style={{
              width: "530px",
              margin: "auto",
              height: "398px",
              marginTop: 5,
            }}
          >
            {isImage(item) ? (
              <img src={item} alt={item} width="530px" height="398px" />
            ) : isVideo(item) ? (
              <video controls width="530px" height="398px">
                <source src={item} type="video/mp4" />
              </video>
            ) : null}
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
