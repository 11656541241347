import { Container, Grid } from "@material-ui/core";
import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
class SkeletonUser extends Component {
  render() {
    return (
      <Container className="containerSpacingdashBoard" maxWidth={"lg"}>
        <Grid container spacing={2} className="userDashboard">
          <Grid item sm={2} className="sidebar">
            <Skeleton
              square={true}
              height={123}
              width={123}
              style={{ borderRadius: "10px" }}
            />

            <SkeletonTheme color="#eee">
              <p>
                <Skeleton
                  count={8}
                  height={30}
                  style={{ marginBottom: "10px" }}
                />
              </p>
            </SkeletonTheme>
          </Grid>
          <Grid item sm={10} style={{ paddingLeft: "109px" }}>
            <SkeletonTheme color="#eee">
              <Skeleton
                height={60}
                width={200}
                style={{
                  marginBottom: "61px",

                  borderRadius: "20px",
                }}
              />
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  {" "}
                  <Skeleton
                    count={8}
                    height={30}
                    style={{ marginBottom: "10px" }}
                  />{" "}
                </Grid>
                <Grid item sm={6}>
                  <Skeleton
                    height={30}
                    width={200}
                      style={{ margin: "0px 0px 20px 14px" }}
                  />
                  <br />
                  <Skeleton
                    circle={true}
                    height={123}
                    width={123}
                      style={{ marginLeft: "54px" }}
                  />
                  <br />
                  <Skeleton
                    height={60}
                    width={200}
                    style={{
                      marginTop: "40px",
                      marginLeft: "19px",
                      borderRadius: "20px",
                    }}
                  />
                </Grid>
              </Grid>
            </SkeletonTheme>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default SkeletonUser;
