import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
  Snackbar,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import init from "../helpers/WindowToken";
import { Form } from "reactstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default class AddPricing extends Component {
  state = {
    title: "",
    item_id: "",
    description: "",
    no_of_hosted_models: "",
    monthly_views: "",
    item_price: "",
    first_model_optimization_free: "false",
    installation_support: "false",
    analytics: "false",
    dedicated_account_manager: "false",
    animated_model: "false",
    white_labeled: "false",
    status: "monthly",
    flag: "all",
    loading: false,
  };

  addcats = (e) => {
    e.preventDefault();
    let catLoad = {
      title: this.state.title,
      item_id: this.state.item_id,
      description: this.state.description,
      no_of_hosted_models: this.state.no_of_hosted_models,
      monthly_views: this.state.monthly_views,
      item_price: this.state.item_price,
      first_model_optimization_free: this.state.first_model_optimization_free,
      installation_support: this.state.installation_support,
      analytics: this.state.analytics,
      dedicated_account_manager: this.state.dedicated_account_manager,
      animated_model: this.state.animated_model,
      white_labeled: this.state.white_labeled,
      status: this.state.status,
      flag: this.state.flag,
    };
    // console.log(catLoad);
    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios.post(Constants.postUrls.postPricing, catLoad).then((resp) => {
        // console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 1500);
        }
         else{
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              // redirect: true,
            });
          }, 1500);
        }
      });
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.flag);
      }
    );
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/pricing" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">{this.props.title}</Typography>
            </Grid>
          </Grid>
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"green"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          <div
            style={{
              padding: "20px",
              border: "1px solid #afa2a2",
              borderRadius: "7px",
              position: "relative",
              // background:"#3f51b530"
            }}
            // style={{
            //   background: "#5b5757",
            //   padding: "20px",
            //   borderRadius: "10px",
            //   // color: rgba(0, 0, 0, 0.87);
            //   width: "100%",
            //   border: "0",
            //   display: "flex",
            //   position: "relative",
            //   fontSize: ".875rem",
            //   minWidth: "0",
            //   wordWrap: " break-word",
            //   /* background: #FFF; */
            //   boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            //   marginTop: "30px",
            //   borderRadius: "6px",
            //   marginBottom: "30px",
            //   flexDirection: "column",
            //   textAlign: "left",
            // }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, #3f51b5cf, #f500579e) #f500579e)",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>
            <span
              style={{
                color: "#afa3a3",
                marginTop: "-8px",
                marginLeft: "26px",
                marginBottom: "35px",
                fontSize: "17px",
                display: "flex",
              }}
            >
              Add Price
            </span>

            <Grid item sm={12} style={{ textAlign: "left", marginTop: "30px" }}>
              {/* <CardHeader> */}
              {/* <Typography>
                    Add Category
                </Typography> */}
              {/* </CardHeader> */}
              <Form onSubmit={this.addcats}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="Title"
                  name="title"
                  required={true}
                  variant="outlined"
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginTop: "18px",
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Item Id"
                  onChange={this.handleInput}
                  name="item_id"
                  required={true}
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginTop: "18px",
                  }}
                  variant="outlined"
                />

                {/* <TextField
                    id="outlined-basic"
                    label="Description"
                    onChange={this.handleInput}
                    name="description"
                    style={{ marginRight: "20px", width: "30%" }}
                    variant="outlined"
                  /> */}

                <TextField
                  id="outlined-basic"
                  label="No of hosted models"
                  onChange={this.handleInput}
                  required={true}
                  name="no_of_hosted_models"
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginTop: "18px",
                  }}
                  variant="outlined"
                />

                <TextField
                  id="outlined-basic"
                  label="Monthly views"
                  required={true}
                  onChange={this.handleInput}
                  name="monthly_views"
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginTop: "18px",
                  }}
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  label="Item price"
                  required={true}
                  onChange={this.handleInput}
                  name="item_price"
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginTop: "18px",
                  }}
                  variant="outlined"
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "20px",
                  }}
                >
                  <FormControl component="fieldset" color="default">
                    <FormLabel component="legend">Plan Status :</FormLabel>
                    <RadioGroup
                      color="default"
                      aria-label="true"
                      name="status"
                      value={this.state.status}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        color="default"
                        value={"monthly"}
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        color="default"
                        value={"annual"}
                        control={<Radio />}
                        label="Annual"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl component="fieldset" color="default">
                    <FormLabel component="legend">
                      First model optimization free :
                    </FormLabel>
                    <RadioGroup
                      color="default"
                      aria-label="true"
                      name="first_model_optimization_free"
                      value={this.state.first_model_optimization_free}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        color="default"
                        value={"true"}
                        control={<Radio />}
                        label="Enable"
                      />
                      <FormControlLabel
                        color="default"
                        value={"false"}
                        control={<Radio />}
                        label="Disable"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl component="fieldset" color="default">
                    <FormLabel component="legend">
                      Installation supportee :
                    </FormLabel>
                    <RadioGroup
                      color="default"
                      aria-label="true"
                      name="installation_support"
                      value={this.state.installation_support}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        color="default"
                        value={"true"}
                        control={<Radio />}
                        label="Enable"
                      />
                      <FormControlLabel
                        color="default"
                        value={"false"}
                        control={<Radio />}
                        label="Disable"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl component="fieldset" color="default">
                    <FormLabel component="legend">Analytics :</FormLabel>
                    <RadioGroup
                      color="default"
                      aria-label="true"
                      name="analytics"
                      value={this.state.analytics}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        color="default"
                        value={"true"}
                        control={<Radio />}
                        label="Enable"
                      />
                      <FormControlLabel
                        color="default"
                        value={"false"}
                        control={<Radio />}
                        label="Disable"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "20px",
                  }}
                >
                  <FormControl component="fieldset" color="default">
                    <FormLabel component="legend">
                      Dedicated account manager :
                    </FormLabel>
                    <RadioGroup
                      color="default"
                      aria-label="true"
                      name="dedicated_account_manager"
                      value={this.state.dedicated_account_manager}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        color="default"
                        value={"true"}
                        control={<Radio />}
                        label="Enable"
                      />
                      <FormControlLabel
                        color="default"
                        value={"false"}
                        control={<Radio />}
                        label="Disable"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl component="fieldset" color="default">
                    <FormLabel component="legend">Animated model :</FormLabel>
                    <RadioGroup
                      color="default"
                      aria-label="true"
                      name="animated_model"
                      value={this.state.animated_model}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        color="default"
                        value={"true"}
                        control={<Radio />}
                        label="Enable"
                      />
                      <FormControlLabel
                        color="default"
                        value={"false"}
                        control={<Radio />}
                        label="Disable"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl component="fieldset" color="default">
                    <FormLabel component="legend">White labeled :</FormLabel>
                    <RadioGroup
                      color="default"
                      aria-label="true"
                      name="white_labeled"
                      value={this.state.white_labeled}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        color="default"
                        value={"true"}
                        control={<Radio />}
                        label="Enable"
                      />
                      <FormControlLabel
                        color="default"
                        value={"false"}
                        control={<Radio />}
                        label="Disable"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl component="fieldset" color="default">
                    <FormLabel component="legend">Flag :</FormLabel>
                    <RadioGroup
                      color="default"
                      aria-label="true"
                      name="flag"
                      value={this.state.flag}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        color="default"
                        value={"all"}
                        control={<Radio />}
                        label="All"
                      />
                      <FormControlLabel
                        color="default"
                        value={"custom"}
                        control={<Radio />}
                        label="Custom"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div style={{ marginTop: 10 }}>
                  <label htmlFor="description"> Description</label>
                  <CKEditor
                    style={{ color: "black" }}
                    editor={ClassicEditor}
                    required={true}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log({ event, editor, data });
                      this.setState({
                        description: data,
                      });
                    }}
                    data={this.state.description}
                  />
                </div>
                <br />
                <Button
                  variant="contained"
                  type="submit"
                  Size={15}
                  color="primary"
                  style={{ marginRight: "20px", marginTop: "35px" }}
                >
                  {this.state.loading ? "Adding.." : "Add Price"}
                </Button>

                <Link
                  to={{
                    pathname: "/pricing",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    Size={15}
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Form>
            </Grid>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Fade in={this.state.open}>
              <div
                style={{
                  background: "#fff",
                  width: "50%",
                  padding: "32px",
                }}
              >
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">
                  react-transition-group animates me.
                </p>
              </div>
            </Fade>
          </Modal>
        </Container>
      );
    }
  }
}
