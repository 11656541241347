import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import edit from "../../../Assets/Images/edit.png";
import remove from "../../../Assets/Images/delete.png";
import Modal from "react-awesome-modal";
import Constants from "../../Variables/Constants";
import { scrollToTop } from "../../../helpers/utils";

export default class UserSidebar extends Component {
  state = {
    metaData: {},
    userdetail: {},
    categories: [],
    query: "",
    productStatus: "",
    CategoryLength: false,
    searching: false,
    showCompanyLogo: false,
    openEdit: false,
    userId: window.location.pathname.split("/")[3],
    removeCompanyLogo: false,
  };
  componentDidMount() {
    // console.log(window.innerWidth);
    // console.log(this.state.userId);
    this.getMetaData();
    this.getUser();
  }

  getMetaData = () => {
    // if (init() === "success") {
    let authToken = localStorage.getItem("feel_xr_auth_token");
    axios
      .get(Constants.getUrls.productMetaByUser + this.state.userId, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((res) => {
        // console.log(res);
        this.setState({ metaData: res.data });
      });
    // }
  };
  getUser = () => {
    // if (init() === "success") {
    let authToken = localStorage.getItem("feel_xr_auth_token");
    axios
      .get(Constants.getUrls.getUserDetails + this.state.userId, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((res) => {
        // console.log(res);
        this.setState({ userdetail: res.data.data.user });
      });
    // }
  };
  fetchCategories = () => {
    this.setState({ loading: true });
    // if (init() === "success") {
    let authToken = localStorage.getItem("feel_xr_auth_token");
    axios
      .get(
        Constants.getUrls.getUserCat +
          "?status=" +
          this.state.productStatus +
          "&q=" +
          this.state.query,
        { headers: { Authorization: "Bearer " + authToken } }
      )
      .then((res) => {
        this.setState({
          categories: res.data.categories,
          CategoryLength: res.data.categories.length === 0 ? true : false,
          loading: false,
        });
      });
    // }
  };

  onImageChangeLogo = (event) => {
    // console.log("hi");
    // console.log(event.target.files[0]);
    let data = new FormData();
    if (event.target.files[0]) {
      data.append("company_logo", event.target.files[0]);
      let userToken = localStorage.getItem("feel_xr_auth_token");
      this.setState({ loading: true });
      axios
        .put(Constants.postUrls.updateUserDetail + this.state.userId, data)
        .then((resp) => {
          // console.log(resp);
          if (resp.data.status === "success") {
            this.setState({
              loading: false,
            });
            this.getUser();
          }
        });
    }
  };

  changeCompanyName = (e) => {
    this.setState({
      openEdit: true,
    });
  };

  closeModal = (e) => {
    this.setState({
      openEdit: false,
    });
  };

  updateCompanyName = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();
    data.append("company_name", this.state.company_name);
    axios
      .put(Constants.postUrls.updateUserDetail + this.state.userId, data)
      .then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            loading: false,
            successMsg: resp.data.message,
            openEdit: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
            this.getUser();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  logout = () => {
    localStorage.clear();
    window.location.href = "/account/logout";
  };

  onRemoveFile = () => {
    this.setState({
      removeCompanyLogo: true,
    });
  };

  removeLogo = () => {
    axios
      .put(Constants.postUrls.removeCompanyLogo + this.state.userId)
      .then((resp) => {
        // console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            removeCompanyLogo: false,
          });
          this.getUser();
        }
      });
  };

  render() {
    return (
      <div className="" style={{ position: "relative" }}>
        <Typography className="logoArea">
          <div className="uploadLogoPlaceSidebar">
            {this.state.userdetail.company_logo ? (
              <img
                src={
                  this.state.userdetail.company_logo
                    ? Constants.imageUrl + this.state.userdetail.company_logo
                    : ""
                }
                alt=""
                style={{ width: "100%", height: "100%", borderRadius: "15px" }}
              />
            ) : (
              ""
            )}
            {this.state.userdetail ? (
              <Button
                variant="outlined"
                size="medium"
                className="profilePic"
                component="label"
                style={{
                  border: "none",
                  width: "50px",
                  position: "absolute",
                  right: "-17%",
                  top: "-2%",
                }}
              >
                {this.state.userdetail.company_logo ? (
                  <>
                    <img
                      src={remove}
                      width="15px"
                      height="15px"
                      alt=""
                      className="prodLogo"
                      onClick={this.onRemoveFile}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={edit}
                      width="15px"
                      height="15px"
                      alt=""
                      className="prodLogo"
                    />
                    <input
                      type="file"
                      onChange={this.onImageChangeLogo}
                      name="profile_pic"
                      hidden
                    />
                  </>
                )}
              </Button>
            ) : (
              ""
            )}
          </div>

          <Typography style={{ position: "relative", top: "62%" }}>
            <img
              src={edit}
              width="15px"
              height="15px"
              alt=""
              className="compLogo"
              onClick={this.changeCompanyName}
            />
            {/* <Button
              variant="outlined"
              size="medium"
              className="compnyName"
              component="label"
              style={{
                border: "none",
                width: "100%",
                position: "relative",
                top: "-7px",
              }}
            > */}
              {this.state.userdetail.company_name ? (
                <>
                  <p
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      padding: 0,
                      margin: 0,
                      fontSize: "12px"
                    }}
                  >
                    {this.state.userdetail.company_name}
                  </p>
                </>
              ) : (
                <>
                  <div>Company Name</div>
                </>
              )}
            {/* </Button> */}
          </Typography>
        </Typography>

        <Typography className="performance"> Performance </Typography>
        <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <span className="spanTag">Total Views</span>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {this.state.metaData.totalViews
                ? this.state.metaData.totalViews
                : 0}
            </span>
          </Grid>
        </Grid>
        <hr />

        <Typography className="performance"> Products </Typography>

        <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <NavLink
              exact
              // to="/productlist/allProducts"
              to={"/admin/allProducts/" + this.state.userId}
              className="onHoverLink"
            >
              <span
                className="spanTag"
                onClick={() =>
                  this.setState({ productStatus: "" }, () => {
                    this.fetchCategories();
                  })
                }
              >
                All Products
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {this.state.metaData.all ? this.state.metaData.all : 0}
            </span>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <NavLink
              exact
              // to={{
              //   pathname: "/productlist/live",
              // }}
              to={"/admin/live/" + this.state.userId}
              className="onHoverLink"
            >
              <span
                className="spanTag"
                onClick={() =>
                  this.setState({ productStatus: "published" }, () => {
                    this.fetchCategories();
                  })
                }
              >
                Live Products
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {" "}
              {this.state.metaData.published
                ? this.state.metaData.published
                : 0}{" "}
            </span>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <NavLink exact to="/productlist/wip" className="onHoverLink">
              <span
                className="spanTag"
                onClick={() =>
                  this.setState({ productStatus: "WIP" }, () => {
                    this.fetchCategories();
                  })
                }
              >
                WIP
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {this.state.metaData.wip ? this.state.metaData.wip : 0}
            </span>
          </Grid>
        </Grid> */}

        {/* <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={8}>
            <NavLink exact to="/productlist/pending" className="onHoverLink">
              <span
                className="spanTag"
                onClick={() =>
                  this.setState({ productStatus: "pending" }, () => {
                    this.fetchCategories();
                  })
                }
              >
                Awainting Approval
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={4}>
            <span className="spanTag" style={{ marginLeft: "5px" }}>
              {" "}
              {this.state.metaData.pending ? this.state.metaData.pending : 0}
            </span>
          </Grid>
        </Grid> */}
        <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <NavLink
              exact
              // to="/productlist/draft"
              to={"/admin/draft/" + this.state.userId}
              className="onHoverLink"
            >
              <span
                className="spanTag"
                onClick={() =>
                  this.setState(
                    {
                      productStatus: "draft",
                      categories: this.state.categories,
                    },
                    () => {
                      this.fetchCategories();
                    }
                  )
                }
              >
                Draft Products
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {" "}
              {this.state.metaData.drafted
                ? this.state.metaData.drafted
                : 0}{" "}
            </span>
          </Grid>
        </Grid>
        {/* button for analytics component */}
        <Link
          to={"/admin/analytics/" + this.state.userId}
          style={{
            textDecoration: "none",
            color: "#666666",
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Button
            className="productBtn"
            style={{
              width: "70%",
              fontSize: window.innerWidth < 1100 ? "8px" : "10px",
              marginTop: "35px",
              padding: "2px 20px",
              height: "36px",
              borderRadius: "4px",
            }}
          >
            Overall Analytics
          </Button>
        </Link>
        {/* end of analytics */}
        <hr style={{ color: "#C8C8C8", marginTop: "20px" }} />
        <Link
          to={"/admin/product-create/" + this.state.userId}
          style={{ textDecoration: "none", color: "#666666" }}
        >
          <Button
            className="productBtn"
            style={{
              width: "100%",
              fontSize: window.innerWidth < 1100 ? "14px" : "18px",
              marginTop: "35px",
            }}
          >
            Add New Product +
          </Button>
        </Link>
        <Link
          to={"/admin/settings/" + this.state.userId}
          style={{ textDecoration: "none", color: "#666666" }}
        >
          <Button
            className="productBtn"
            onClick={() => scrollToTop()}
            style={{
              width: "100%",
              fontSize: window.innerWidth < 1100 ? "14px" : "18px",

              marginTop: "26px",
            }}
          >
            Settings
          </Button>
        </Link>
        <Button
          className="productBtn"
          onClick={this.logout}
          style={{
            marginTop: "26px",
            width: "100%",
            fontSize: window.innerWidth < 1100 ? "14px" : "18px",
          }}
        >
          Logout
        </Button>

        {/* Remove Company logo */}
        <Modal
          visible={this.state.removeCompanyLogo}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.setState({ removeCompanyLogo: false })}
        >
          <div className="confirmationModal" style={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to remove company logo ?
            </Typography>
            <Button
              onClick={() => {
                this.removeLogo();
                // this.deleteMaterial(
                //   this.state.deleteMatId,
                //   this.state.isAvailable
                // );
              }}
              className="DeleteBtn"
            >
              Remove{" "}
            </Button>
            <Button
              onClick={() => this.setState({ removeCompanyLogo: false })}
              className="DeleteBtn"
              style={{ background: "#bdbdbd" }}
            >
              Cancel
            </Button>
          </div>
        </Modal>

        <Modal
          visible={this.state.openEdit}
          width="350"
          height="230"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div className="modalWrapper">
            {/* <h4>Update Company name</h4> */}
            <form action="" onSubmit={this.updateCompanyName}>
              <Typography>
                {this.state.filename ? "File updated" : null}
              </Typography>
              <br />
              <div className="contentWrap">
                <p>Company Name</p>
                <input
                  type="text"
                  name="company_name"
                  className="modalInput"
                  style={{ marginBottom: "10px" }}
                  // defaultValue={
                  //   this.state.product ? this.state.product.title : ""
                  // }
                  onChange={this.handleInput}
                  required
                />
                <br />
                <Button
                  className="addBtn"
                  type="submit"
                  style={{ width: "100%", marginLeft: "0px" }}
                >
                  {this.state.loading ? (
                    <CircularProgress color={"white"} size={25} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}
