import { IconButton, Typography } from "@material-ui/core";
import AddCircleOutlineSharpIcon from "@material-ui/icons/AddCircleOutlineSharp";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import VariantsDeleteConfirmation from "../VariantsDeleteConfirmation";
import React, { useState } from "react";
import { useEffect } from "react";
import { scrollToTop } from "../../helpers/utils";

const HotspotContainer = ({
  toggleHotspotAddingInstruction,
  propState,
  onClickEdit,
  uploadAnnot,
}) => {
  const [expand, setExpand] = useState(true);

  const handleExpand = () => {
    setExpand((p) => !p);
  };
  return (
    <>
      <div
        className="box1"
        style={{
          cursor: "pointer",
          // border: "1px solid blue",
          margin: "8px 0",
          maxHeight:
            propState?.selectedVariant?.hotspots?.length && expand
              ? "226px"
              : "38px",
          overflowX: "hidden",
          overflowY: expand ? "auto" : "hidden",
          transitionDuration: "222ms",
          paddingBottom:
            propState?.selectedVariant?.hotspots?.length && expand
              ? "20px"
              : "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // border: "1px solid black",
            padding: "0 10px",
          }}
          onClick={() => {
            toggleHotspotAddingInstruction();
            // scrollToTop();
          }}
        >
          <p
            style={{
              margin: 0,
              padding: 0,
              fontSize: 18,
              fontWeight: 400,
              flex: 1,
              color: "#565656",
              // border: "1px solid aqua",
            }}
          >
            Add Hotspot
          </p>

          <div
            style={{
              margin: "0 8px",
              display: propState?.selectedVariant?.hotspots?.length
                ? "block"
                : "none",
            }}
          >
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleExpand();
              }}
            >
              {expand ? (
                <ExpandLessIcon
                  style={{
                    color: "#565656",
                    fontSize: "30px",
                    cursor: "pointer",
                    // marginLeft: 5,
                  }}
                />
              ) : (
                <ExpandMoreRoundedIcon
                  style={{
                    color: "#565656",
                    fontSize: "30px",
                    cursor: "pointer",
                    // marginLeft: 5,
                  }}
                />
              )}
            </IconButton>
          </div>
          <div
            onClick={() => {
              toggleHotspotAddingInstruction();
            }}
            style={
              {
                // border: "1px solid red",
              }
            }
          >
            <AddCircleOutlineSharpIcon />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            // border: "1px solid red",
            padding: "0 10px",
            boxSizing: "border-box",
            paddingTop: "15px",
            // paddingBottom: "16px",
          }}
        >
          {propState.selectedVariant.hotspots?.map((hotspot) => (
            <div
              key={hotspot._id}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 13px",
                boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                marginBottom: "12px",
                borderRadius: "8px",
              }}
            >
              <Typography
                className="text"
                style={{ flexGrow: 1, fontSize: 18, fontWeight: 400 }}
              >
                {hotspot.title}
              </Typography>

              <EditOutlinedIcon
                style={{
                  margin: "0 0.5rem 0 0.5rem",
                }}
                onClick={() => {
                  scrollToTop();
                  onClickEdit(hotspot.title, hotspot.description, hotspot);
                }}
              />

              <DeleteHotspot
                onDelete={() => {
                  uploadAnnot({
                    hotspotId: hotspot._id,
                    isDelete: true,
                    isEdit: false,
                  });
                }}
              />
            </div>
          ))}
        </div>
      </div>
      {/* hotspot-btns propState.selectedVariant. */}
    </>
  );
};
const DeleteHotspot = ({ onDelete }) => {
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  return (
    <>
      <VariantsDeleteConfirmation
        text="Do you want delete this?"
        onClickAway={() => {
          setOpenDeleteConfirm(false);
        }}
        visible={openDeleteConfirm}
        handleDelete={() => {
          onDelete();
        }}
      />
      <CloseIcon
        onClick={() => setOpenDeleteConfirm(true)}
        style={{
          marginRight: "0.5rem",
        }}
      />
    </>
  );
};
export default HotspotContainer;
