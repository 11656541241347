export const spacing = 10;
export const boxShadow = `0px 0px 8px rgba(0, 0, 0, 0.1)`;
export const borderRadius = 15;

const isBeta = true;

let origin = "https://actuality.live";

const dataOrigin = isBeta ? `data-origin='${origin}'` : "";

export const view_in_3d_btn_short = (productId, embedded_product_url) =>
  `<div id="__actuality_model_div" ${!embedded_product_url && `data-product='${productId}'`} ${dataOrigin}></div><script defer src="${origin}/cdn/embed_share.js"></script>`;

export const view_in_ar_btn_short = (productId, embedded_product_url) =>
  `<div id="__actuality_model_div" ${!embedded_product_url && `data-product='${productId}'`} ${dataOrigin}></div><script defer src="${origin}/cdn/embed_sharear.js"></script>`;

export const view_both_btn_short = (productId, embedded_product_url) =>
  `<div id="__actuality_model_div" ${!embedded_product_url && `data-product='${productId}'`} ${dataOrigin}></div><script defer src="${origin}/cdn/embed_sharevu.js"></script>`;

