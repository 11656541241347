import axios from "./axios";
import Constants, { mainDomain } from "../../App/Variables/Constants";

let isDev = !true;
let MainDomain = `${isDev ? "http://localhost:4000/" : mainDomain}api/admin/`;

let URLS = {
  createRecommend: (productId) => MainDomain + "recommendProducts/" + productId,
  getRecommendProducts: (productId) =>
    MainDomain + "recommendProducts/" + productId,
  getAllCombinations: (recommendId) =>
    MainDomain + "combinations/all/" + recommendId,
  createOrUpdateOrDeleteCombination: (recommendId) =>
    MainDomain + "combinations/" + recommendId,
  deleteRecommend: (recommendId) =>
    MainDomain + "recommendProducts/" + recommendId,
  updateRecommend: (recommendId) =>
    MainDomain + "recommendProducts/" + recommendId,
  deleteCombination: (combinationId) =>
    MainDomain + "combinations/" + combinationId,
};

export const deleteCombination = async (combinationId) => {
  try {
    const { data } = await axios.delete(URLS.deleteCombination(combinationId));

    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || "Server is busy, please try again later"
    );
  }
};

export const updateRecommend = async (recommendId, title) => {
  try {
    const { data } = await axios.put(URLS.updateRecommend(recommendId), {
      title,
    });
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || "Server is busy, please try again later"
    );
  }
};

export const deleteRecommend = async (recommendId) => {
  try {
    const { data } = await axios.delete(URLS.deleteRecommend(recommendId));
    // console.log("log from createOrUpdateOrDeleteCombination ", data);
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || "Server is busy, please try again later"
    );
  }
};

export const createOrUpdateOrDeleteCombination = async (
  recommendId,
  bodyData
) => {
  try {
    const { data } = await axios.post(
      URLS.createOrUpdateOrDeleteCombination(recommendId),
      bodyData
    );
    // console.log("log from createOrUpdateOrDeleteCombination ", data);
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || "Server is busy, please try again later"
    );
  }
};

export const getAllCombinations = async (recommendId) => {
  try {
    const { data } = await axios.get(URLS.getAllCombinations(recommendId));
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || "Server is busy, please try again later"
    );
  }
};

export const createRecommend = async (productId, title) => {
  try {
    const { data } = await axios.post(URLS.createRecommend(productId), {
      title,
    });
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || "Server is busy, please try again later"
    );
  }
};

export const getRecommendProducts = async (productId) => {
  try {
    const { data } = await axios.get(URLS.getRecommendProducts(productId));
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || "Server is busy, please try again later"
    );
  }
};

export const getAllMyProducts = async (query, page = 1) => {
  try {
    const userId = window.location.pathname.split("/").reverse()[1];

    const { data } = await axios.get(
      `${Constants.getUrls.getProductsByUser}?limit=100&user=${userId}&status=published&q=${query}&page=${page}`
    );
    return data;
  } catch (err) {
    return null;
    // console.log("err ", err);
  }
};
