import { Grid } from "@material-ui/core";
import React, { Component } from "react";
// import "";

import { Redirect, Route, Switch } from "react-router-dom";
import AddBlogs from "../../AddViews/AddBlogs";
import AddCategories from "../../AddViews/AddCategories";
import AddPricing from "../../AddViews/AddPricing";
import AddProduct from "../../AddViews/AddProduct";
import AddService from "../../AddViews/AddService";
import AddSocials from "../../AddViews/AddSocials";
import AddVariantOptions from "../../AddViews/AddVariantOptions";
import Addvariants from "../../AddViews/Addvariants";
import UpdateBlogs from "../../UpdateViews/UpdateBlogs";
import UpdateCategory from "../../UpdateViews/UpdateCategory";
import UpdateProduct from "../../UpdateViews/UpdateProduct";
import UpdateService from "../../UpdateViews/UpdateService";
import UpdateSocials from "../../UpdateViews/UpdateSocials";
import UpdateVariantOptions from "../../UpdateViews/UpdateVariantOptions";
import { routes } from "../../routes";
import AuthLoading from "../Auth/AuthLoading";
import MainAppbar from "./Layout/MainAppbar";
import Sidebar from "./Layout/Sidebar";
import UserPortal from "./UserPortal";
import FunctionalModalDynamic from "./UserPortal/FunctionalModalDynamic";
import Header from "./UserPortal/Header";
import PaymentSucces from "./UserPortal/PaymentSucces";
import Pricing from "./UserPortal/Pricing";
import ProductCreate from "./UserPortal/ProductCreate";
import ProductEdit from "./UserPortal/ProductEdit";
import Setting from "./UserPortal/Setting";
import UnPublishModal from "./UserPortal/UnPublishModal";
import UpdateUsers from "./UserPortal/UpdateUsers";
import UserProfile from "./UserPortal/UserProfile";
import Variants from "./Variants";
// import AddPricing from "../../AddViews/AddPricing";
import AddSubAdmin from "../../AddViews/AddSubAdmin";
import AddSuggestionModel from "../../AddViews/AddSuggestionModel";
import ChangePassword from "../../AddViews/ChangePassword";
import AddProductToShare from "../../Components/AddProductToShare";
import CreateShare from "../../Components/CreateShare";
import Templates from "../../Components/Templates";
import UpdatePricing from "../../UpdateViews/UpdatePricing";
import UpdateSuggestionModel from "../../UpdateViews/UpdateSuggestionModel";
import Analitics from "./UserPortal/Analitics";
import ProductsAnalitics from "./UserPortal/ProductsAnalitics";
import ModelTemplate from "./UserPortal/ModelTemplate";

export default class Main extends Component {
  state = {
    loading: true,
    authenticated: false,
    showSidebar: true,
  };

  componentDidMount() {
    var url = window.location.pathname.split("/")[2];
    if (url === "allProducts") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "draft") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "live") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "product-create") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "product-edit") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "userprofile") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "settings") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "viewer") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "modelDynamicviewer") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "pricing") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "payment_success") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "analytics") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "productsanalytics") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "sharelist") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "share") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "addToShare") {
      this.setState({
        showSidebar: false,
      });
    }
    if (url === "templates") {
      this.setState({
        showSidebar: false,
      });
    }
  }

  checkAuth = (status) => {
    if (status) {
      this.setState({ loading: false, authenticated: true });
    } else {
      // this.props.history.push("/login");
      this.setState({ loading: false, authenticated: false });
    }
  };
  render() {
    return (
      <div>
        {this.state.loading ? <AuthLoading checkAuth={this.checkAuth} /> : null}
        {this.state.authenticated ? (
          <>
            {this.state.showSidebar ? (
              <MainAppbar />
            ) : (
              <Header {...this.props} />
            )}

            <Grid container>
              {this.state.showSidebar ? (
                <>
                  <Grid item style={{ position: "fixed", width: "16.666667%" }}>
                    <Sidebar />
                  </Grid>
                  <Grid item xs={2} style={{ margin: "12px 0" }}></Grid>
                </>
              ) : (
                ""
              )}

              {this.state.showSidebar ? (
                <Grid item xs={10} style={{ margin: "12px 0" }}>
                  <Switch defau>
                    {routes.map((route, index) => (
                      <Route
                        exact={route.exact}
                        path={route.path}
                        render={() => <route.component title={route.title} />}
                        key={index}
                      />
                    ))}
                    <Route path="/admin/addservice" component={AddService} />
                    <Route
                      path="/admin/updateservice"
                      component={UpdateService}
                    />

                    <Route path="/admin/addblogs" component={AddBlogs} />
                    <Route path="/admin/updateblogs" component={UpdateBlogs} />
                    <Route
                      path="/admin/addcategories"
                      component={AddCategories}
                    />
                    <Route
                      path="/admin/upadtecategories"
                      component={UpdateCategory}
                    />
                    <Route path="/admin/addproduct" component={AddProduct} />
                    <Route
                      path="/admin/updateproduct"
                      component={UpdateProduct}
                    />
                    <Route
                      path="/admin/addvarinatoptions"
                      component={AddVariantOptions}
                    />
                    <Route
                      path="/admin/updatevarinatoptions"
                      component={UpdateVariantOptions}
                    />
                    <Route path="/admin/addpricing" component={AddPricing} />
                    <Route
                      path="/admin/updatepricing"
                      component={UpdatePricing}
                    />
                    <Route path="/admin/addSocial" component={AddSocials} />
                    <Route
                      path="/admin/updateSocial"
                      component={UpdateSocials}
                    />
                    <Route path="/admin/variants/:id" component={Variants} />
                    <Route path="/admin/addvariants" component={Addvariants} />
                    <Route path="/admin/addsubadmin" component={AddSubAdmin} />
                    <Route
                      path="/admin/change_password"
                      component={ChangePassword}
                    />
                    <Route
                      path="/admin/suggestion-create"
                      component={AddSuggestionModel}
                    />
                    <Route
                      path="/admin/suggestion-update/"
                      component={UpdateSuggestionModel}
                    />
                  </Switch>
                </Grid>
              ) : (
                <Grid item xs={12} style={{ margin: "12px 0" }}>
                  <Switch defau>
                    {routes.map((route, index) => (
                      <Route
                        exact={route.exact}
                        path={route.path}
                        render={() => <route.component title={route.title} />}
                        key={index}
                      />
                    ))}
                    <Route path="/admin/addservice" component={AddService} />
                    <Route
                      path="/admin/updateservice"
                      component={UpdateService}
                    />
                    <Route path="/admin/updateuser" component={UpdateUsers} />
                    <Route
                      path="/admin/allProducts/:id"
                      exact
                      component={UserPortal}
                    />

                    <Route path='/admin/addToShare/:share_id' component={AddProductToShare}
                      exact
                    />
                    <Route
                      path="/admin/draft/:id"
                      exact
                      component={UserPortal}
                    />
                    <Route
                      path="/admin/live/:id"
                      exact
                      component={UserPortal}
                    />
                    <Route
                      path="/admin/sharelist/:id"
                      exact
                      component={UserPortal}
                    />
                    <Route
                      path="/admin/product-create"
                      component={ProductCreate}
                    />
                    <Route path='/admin/share/:user_id' component={CreateShare} exact />
                    <Route path='/admin/templates/:user_id' component={Templates} exact />

                    <Route path="/admin/product-edit" component={ProductEdit} />
                    <Route path="/admin/modeltemplate" component={ModelTemplate} />
                    <Route path="/admin/userprofile" component={UserProfile} />
                    <Route path="/admin/settings" component={Setting} />
                    <Route path="/admin/viewer" component={UnPublishModal} />

                    <Route
                      path="/admin/modelDynamicviewer"
                      component={FunctionalModalDynamic}
                    />

                    <Route path="/admin/pricing" component={Pricing} />

                    <Route
                      path="/admin/payment_success"
                      component={PaymentSucces}
                    />

                    <Route path="/admin/addblogs" component={AddBlogs} />
                    <Route path="/admin/updateblogs" component={UpdateBlogs} />
                    <Route
                      path="/admin/addcategories"
                      component={AddCategories}
                    />
                    <Route
                      path="/admin/upadtecategories"
                      component={UpdateCategory}
                    />
                    <Route path="/admin/addproduct" component={AddProduct} />
                    <Route
                      path="/admin/updateproduct"
                      component={UpdateProduct}
                    />
                    <Route
                      path="/admin/addvarinatoptions"
                      component={AddVariantOptions}
                    />
                    <Route
                      path="/admin/updatevarinatoptions"
                      component={UpdateVariantOptions}
                    />
                    <Route path="/admin/addSocial" component={AddSocials} />
                    <Route
                      path="/admin/updateSocial"
                      component={UpdateSocials}
                    />
                    <Route path="/admin/variants/:id" component={Variants} />
                    <Route path="/admin/addvariants" component={Addvariants} />

                    <Route path="/admin/analytics" component={Analitics} />

                    <Route
                      path="/admin/productsanalytics"
                      component={ProductsAnalitics}
                    />

                  </Switch>
                </Grid>
              )}
            </Grid>
          </>
        ) : !this.state.loading ? (
          <Redirect to="/login" />
        ) : null}
      </div>
    );
  }
}
