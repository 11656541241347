import { Checkbox, Typography } from "@material-ui/core";
import React from "react";
import PasscodeField from "../PasscodeField";

const Passcode = ({
  passcode,
  setPasscode,
  className,
  isProtected,
  toggleIsProtected,
}) => {
  return (
    <>
      <div
        style={{
          border: "0px solid red",
          // padding: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Checkbox
          checked={!!isProtected}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          onClick={() => {
            toggleIsProtected();
          }}
        />
        <div
          className="inlineWrapper"
          style={{
            border: "0px solid aqua",
            // padding: 0,
            // margin: 0,
          }}
        >
          <Typography
            className="buyNow"
            style={{
              padding: 0,
              margin: 0,
              fontWeight: 450,
              fontSize: "0.9rem",
            }}
          >
            I want to secure this product with passcode
          </Typography>
        </div>
      </div>
      {isProtected && (
        <>
          <div
            className="inlineWrapper"
            style={{
              border: "0px solid aqua",
              // padding: 0,
              // margin: 0,
            }}
          >
            <Typography className="buyNow" style={{ padding: 0, margin: 0 }}>
              Enter your passcode
            </Typography>
          </div>
          <PasscodeField
            className={className}
            onChange={(e) => setPasscode(e.target.value)}
            value={passcode}
          />
        </>
      )}
    </>
  );
};

export default Passcode;
