import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles } from "@material-ui/core/styles";
// import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Temp from "./Temp";
import axios from "axios";
import { Typography, IconButton } from "@material-ui/core";

import Constants from "../../App/Variables/Constants";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
const grid = 8;
export default function CategoryShuffleDialog({
  categories: propCategories,
  open,
  handleClose,
  getcategories,
}) {
  let tempPropCategories = propCategories.map((cat, index) => ({
    ...cat,
    seqNo: index,
  }));
  const [categories, setCategories] = useState(tempPropCategories);
  const [isSame, setIsSame] = useState(true);

  useEffect(() => {
    const data = categories.map(({ _id }, index) => ({
      seqNo: index,
      category: _id,
    }));
    let isSame = data
      .map(
        (cat) =>
          cat.seqNo ==
          tempPropCategories.find(({ _id }) => _id == cat.category).seqNo
      )
      .every((val) => val);
    setIsSame(isSame);
  }, [categories]);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const getListStyle = (isDraggingOver) =>
    !true
      ? {}
      : {
          background: isDraggingOver ? "lightblue" : "lightgrey",
          padding: grid,
        };
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : "white",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    marginBottom: 4,
    ...draggableStyle,
  });
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const propsitems = reorder(
      categories,
      result.source.index,
      result.destination.index
    );
    setCategories(propsitems);
  };
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    const payload = categories.map(({ _id }, index) => ({
      seqNo: index,
      category: _id,
    }));
    try {
      //  "http://localhost:4000/api/categories/reshuffle" ||

      const { data } = await axios.put(
        Constants.putUrls.reshuffleCategories,
        payload
      );
      getcategories();
      setMessage({ isError: false, message: "Saved changes" });
    } catch (err) {
      // console.log("err ", err);
      setMessage({
        isError: true,
        message: "Serve is busy please try again!",
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setMessage({});
      }, 1000);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" onClose={() => handleClose()}>
          Change the Sequence
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography gutterBottom variant="subtitle1">
              Drag and Drop the item into there corresponding spots
            </Typography>
            <Box
              style={{
                maxWidth: 400,
                margin: "auto",
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {categories.map((category, index) => (
                        <Draggable
                          key={category._id}
                          draggableId={category._id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ListItem category={category} index={index} />
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
            <Typography
              // color={message.isError ? "error" : "primary"}
              align="center"
              style={{
                margin: "10px",
                color: message.isError ? "red" : "green",
              }}
            >
              {message.message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button> */}
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={isSame || isLoading}
            style={{
              margin: 8,
            }}
          >
            Save Sequence
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ListItem = ({ category, index }) => (
  <>
    <Box
      style={{
        width: 50,
        height: 50,
        display: "grid",
        placeItems: "center",
        background: "gray",
        color: "white",
      }}
    >
      {index + 1}
    </Box>
    <Box
      style={{
        flex: 1,
        paddingLeft: "8px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: 600,
      }}
    >
      {category.title}
    </Box>
    <Box
      style={{
        borderLeft: "1px solid #ddd",
        display: "grid",
        placeItems: "center",
        alignSelf: "stretch",
      }}
    >
      {/* <DragIndicatorIcon fontSize="large" /> */}
      <DragHandleIcon fontSize="large" />
    </Box>
  </>
);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0.3),
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    flex: 1,
    // border: "1px solid red",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    flex: 1,
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="h6"
        style={{
          wordWrap: "break-word",
          border: "0px solid blue",
          flex: 1,
        }}
      >
        {children}
      </Typography>

      {/* {!false && ( */}
      <Box>
        <IconButton
          aria-label="close"
          // className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {/* )} */}
    </MuiDialogTitle>
  );
});
