import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import init from "../helpers/WindowToken";

export default class Addvariants extends Component {
  state = {
    options: {},
    model_file: "",
    loading: false,
  };

  Addvariants = (e) => {
    e.preventDefault();
    let blogload = {
      options: this.state.options,
      model_file: this.state.model_file,
    };
    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios.post(Constants.getUrls.variants, blogload).then((resp) => {
        // console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 1500);
        }
      });
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <Container maxWidth="xl">
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6">{this.props.title}</Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <div
            style={{
              width: "228px",
              position: "relative",
              left: "13%",
              top: "7px",
              borderRadius: "6px",
              background: "Green",
              padding: "13px",
            }}
          >
            <Typography>{this.state.message}!</Typography>
          </div>
        ) : null}
        <Grid item sm={12} style={{ textAlign: "center" }}>
          <form noValidate autoComplete="off" onSubmit={this.Addvariants}>
            <TextField
              id="outlined-basic"
              onChange={this.handleInput}
              label="Options"
              name="options"
              variant="outlined"
              style={{ marginRight: "20px", width: "30%" }}
            />
            <TextField
              id="outlined-basic"
              label="Model file"
              onChange={this.handleInput}
              name="model_file"
              variant="outlined"
              style={{ marginRight: "20px", width: "30%" }}
            />
            <br />
            <Button
              variant="contained"
              type="submit"
              Size={15}
              color="primary"
              style={{ marginRight: "20px", marginTop: "20px" }}
            >
              {this.state.loading ? "Adding.." : "Add Variants"}
            </Button>
          </form>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Fade in={this.state.open}>
            <div
              style={{
                background: "#fff",
                width: "50%",
                padding: "32px",
              }}
            >
              <h2 id="transition-modal-title">Transition modal</h2>
              <p id="transition-modal-description">
                react-transition-group animates me.
              </p>
            </div>
          </Fade>
        </Modal>
      </Container>
      //   </SnackbarProvider>
    );
  }
}
