import React from "react";
import Drawer from "../Drawer";
import SunEditor from "../SunEditor";

const HotspotAddEditor = ({
  open,
  closeEditorDrawer,
  propState,
  uploadAnnot,
  setTitle,
  setDescription,
  resetError,
}) => {
  return (
    <Drawer
      open={
        open
        // true && this.state.openHotspotDrawer
      }
      onClose={closeEditorDrawer}
      style={{
        height: "100vh",
        maxHeight: "100vh",
        left: "60%",
        border: "0px solid red",
        position: "fixed",
        // paddingRight: "0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",

          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          border: "0px solid green",
          paddingRight: "1.5rem",
          paddingLeft: "0.5rem",
        }}
      >
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: 400,
          }}
        >
          Title
        </div>
        <div
          style={{
            width: "100%",
            paddingRight: "0.5rem",
            marginTop: "0.5rem",
            border: "0px solid black",
            display: "flex",
          }}
        >
          <input
            style={{
              width: "100%",
              fontSize: "1.5rem",
              padding: "0.8rem",
              border: "1px solid #ddd",
              outline: "none",
              display: "block",
              borderRadius: "0.3rem",
            }}
            value={propState.newAnnotTitle}
            onChange={(e) => {
              if (propState.titleIsEmpty) resetError(true);
              setTitle(e.target.value);
              // propSetState((pre) => {
              // // console.log("pre ", pre);
              //   return { ...pre, newAnnotTitle: e.target.value };
              // });
            }}
          />
        </div>
        <div
          style={{
            color: "red",
            fontSize: 16,
            padding: "0.5rem 0",
          }}
        >
          {propState.titleIsEmpty ? "Title is required" : ""}
        </div>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: 400,
          }}
        >
          Description
        </div>
        <div
          style={{
            color: "red",
            fontSize: 16,
            padding: "0.5rem 0",
          }}
        >
          {propState.descriptionIsEmpty ? "Description is required" : ""}
        </div>
        <div
          style={{
            width: "100%",
            paddingRight: "0.3rem",
            // margin: "0.5rem 0",
            flexGrow: 1,
            border: "0px solid blue",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              border: "0px solid red",
            }}
          >
            <SunEditor
              value={propState.newAnnotDescription}
              setValue={(e) => {
                if (propState.descriptionIsEmpty) resetError(false);

                setDescription(e);
                // propSetState({ newAnnotDescription: e });
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            // padding: "1rem 0",
            margin: "0.5rem 0",
            border: "0px solid red",
          }}
        >
          <button
            className="btnCommonStyles hotspotBtn"
            onClick={closeEditorDrawer}
            style={{
              padding: "0.5rem",
            }}
          >
            Cancel
          </button>
          <div
            style={{
              width: 15,
            }}
          />

          <button
            className="btnCommonStyles hotspotBtn"
            onClick={uploadAnnot}
            style={{
              padding: "0.5rem",
            }}
          >
            Save
          </button>
        </div>
        {/* <div
          style={{
            width: "100%",
            height: "2rem",
            border: "0px solid red",
          }}
        /> */}
      </div>
    </Drawer>
  );
};

export default HotspotAddEditor;
