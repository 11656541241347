import React, { useEffect, useState } from 'react';

import { IconButton } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import TSEDialog from '../TSEDialog';
import { useMediaHook } from '../useMediaHook';
const linkSchema = {
  title: '',
  href: '',
};

const Links = ({ handleSubmit, shareProduct }) => {
  const isMdOrDown = useMediaHook();

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [links, setLinks] = useState([
    linkSchema,
    linkSchema,
    linkSchema,
    linkSchema,
    linkSchema,
  ]);

  useEffect(() => {
    if (shareProduct.links.length) {
      const tmp = [...shareProduct.links];

      if (tmp.length < 5)
        Array.from(Array(5 - tmp.length)).map((v) => {
          tmp.push(linkSchema);
        });
      setLinks(tmp);
    }
  }, [shareProduct]);

  const handleTitle = (title, index) => {
    let tmp = [...links];
    const lnk = { ...tmp[index], title };
    tmp[index] = lnk;
    setLinks(tmp);
  };

  const handleLink = (href, index) => {
    let tmp = [...links];
    const lnk = { ...tmp[index], href };
    tmp[index] = lnk;
    setLinks(tmp);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    const lnks = links.filter(({ title, href }) => title && href);

    if (!lnks.length) return;
    setLoading(true);
    try {
      await handleSubmit('', lnks);
      setOpen(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // flexWrap: 'wrap',
        width: '100%',
        // background: '#000000',
        // borderRadius: 10,
        color: '#fff',
        paddingTop: 5,
        paddingBottom: 5,
        marginBottom: 15,
        position: 'relative',
        height: shareProduct?.links?.length ? 'auto' : 35,
      }}
    >
      <TSEDialog
        open={open}
        handleClose={() => setOpen(false)}
        maxWidth='600px'
        minWidth='600px'
      >
        <div
          style={{
            zIndex: 9999999,
            padding: 20,
            border: '0px solid red',
            color: '#000',
          }}
        >
          <form onSubmit={onSubmit} style={{ marginTop: 43 }}>
            <div style={{}}>
              {links.map((link, index) => (
                <Link
                  index={index + 1}
                  key={index}
                  link={link}
                  handleTitle={(title) => {
                    handleTitle(title, index);
                  }}
                  handleLink={(link) => {
                    handleLink(link, index);
                  }}
                />
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '24px 0 0 0',
              }}
            >
              <button
                type='submit'
                style={{
                  alignSelf: "center",
                  outline: 0,
                  padding: "10px 20px",
                  border: 0,
                  marginRight: 0,
                  background: " #E1E1E1",
                  boxShadow: "1px 5px 5px rgba(0, 0, 0, 0.1)",
                  borderRadius: 9,
                  color: "#2e2e2e",
                  fontSize: 16,
                  cursor: "pointer",
                  // marginTop: 20,
                }}
              >
                Submit {loading ? ' ...' : ''}
              </button>
            </div>
          </form>
        </div>
      </TSEDialog>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          alignItems: 'center',
          // borderRadius: 8,
          overflow: 'hidden',
          background: '#000',
          borderRadius: 10,
          width: isMdOrDown ? '100%' : shareProduct?.links?.length > 2 ? '100%' : '50%',
        }}
      >
        {!shareProduct?.links.length ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px 30px',
              background: '#000000',
            }}
          >
            <span>Add Links</span>
          </div>
        ) : (
          shareProduct?.links.map((link, index) => (
            <div
              style={{
                // flex: isMdOrDown ? '2' : 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // border: '0.5px solid #ddd',
                padding: '10px 20px',
                background: '#000000',
                flex: 1,
                // borderRadius: 8,
                // margin: 2,
                textDecoration: 'none',
                color: 'white',
                fontSize: 16,
              }}
              key={index}
            >
              <a
                style={{
                  textDecoration: 'none',
                  color: 'white',
                }}
                target='_blank'
                href={link.href}
              >
                <span>{link.title}</span>
              </a>
            </div>
          ))
        )}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '60%',
          right: '50%',
          transform: 'translate(50%)',
          color: '#000',
          borderRadius: '50%',
          background: 'white',
          border: '1px solid #ddd',
        }}
      >
        <IconButton
          size='small'
          style={{ color: 'black', fontSize: 20 }}
          onClick={() => setOpen(true)}
        >
          <AddIcon fontSize='medium' />
        </IconButton>
      </div>
    </div>
  );
};

export default Links;

const Link = ({ link, handleTitle, index, handleLink }) => (
  <div
    style={{
      marginBottom: 12,
    }}
  >
    <div
      style={{
        fontSize: 20,
      }}
    >
      {`Link ${index}`}
    </div>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 9,
      }}
    >
      <input
        type='text'
        style={{
          flex: 0.48,
          border: 0,
          outline: 0,
          padding: 10,
          borderRadius: 8,
          // boxShadow:
          //   'inset 0px -2px 6px rgba(0, 0, 0, 0.25), inset 0px 4px 5px -1px rgba(0, 0, 0, 0.25)',
          border: '1px solid #ddd'
        }}
        value={link.title}
        onChange={(e) => handleTitle(e.target.value)}
        placeholder='Add Link Title'
        maxLength={10}
      />
      <input
        value={link.href}
        onChange={(e) => handleLink(e.target.value)}
        type='url'
        placeholder='Add Link URL'
        style={{
          //   width: '100%',
          flex: 0.48,
          border: 0,
          outline: 0,
          padding: 10,
          borderRadius: 8,
          // boxShadow:
          //   'inset 0px -2px 6px rgba(0, 0, 0, 0.25), inset 0px 4px 5px -1px rgba(0, 0, 0, 0.25)',
          border: '1px solid #ddd'
        }}
      />
    </div>
  </div>
);
