import React from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import Modal from "react-awesome-modal";
import { useState } from "react";

import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import { Button, CircularProgress } from "@material-ui/core";
import axios from "axios";
import Constants from "../../App/Variables/Constants";

const ComponentEdit = ({
  postUpdateCB,
  component_name,
  product,
  compId,
  allComponentTitles,
}) => {
  const [open, setOpen] = useState(false);
  const [componentName, setComponentName] = useState(component_name);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.clear();

    if (component_name == componentName) {
      setMessage("Component is same");
      return;
    }
    const isNotUnique = allComponentTitles
      .filter((c) => c.compId != compId)
      .map(({ component_name }) => component_name?.toLowerCase())
      .includes(componentName.toLowerCase());
    if (isNotUnique) {
      setMessage("This title already taken.");
      return;
    }
    setLoading(true);
    try {
      // console.log({
      //   product,
      //   componentName,
      // });
      const { data } = await axios.put(
        Constants.postUrls.addComponents + "/" + compId,
        { component_name: componentName, product }
      );
      // console.log("data", data);
      if (data.status == "success") {
        postUpdateCB();
        setOpen(false);
      } else {
        setMessage(data.message);
      }
    } catch (err) {
      // console.log("err ", err);
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    if (message) setMessage("");
    setComponentName(e.target.value);
  };
  return (
    <>
      <EditOutlinedIcon
        style={{
          marginLeft: "10px",
          marginTop: "16px",
          cursor: "pointer",
          position: "absolute",
          right: window.innerWidth < 1000 ? "56px" : "64px",
          top: "2px",
          fontSize: "16px",
          color: "#bdbdbd",
        }}
        onClick={() => {
          setOpen(true);
          //   this.setState({
          //     compId: comp._id,
          //     compnentEdit: true,
          //     component_name: comp.component_name,
          //   });
        }}
      />
      <Modal
        visible={open}
        width="300"
        height="350"
        effect="fadeInUp"
        onClickAway={() => setOpen(false)}
      >
        <div className="modalWrapper" style={{ marginTop: "80px" }}>
          {/* <form action="" id="componentForm" onSubmit={handleSubmit}> */}
          <h1 className="modalHead" style={{ fontSize: "18px" }}>
            Edit component details
          </h1>

          <CloseSharpIcon
            style={{ position: "absolute", right: "10px", top: "5px" }}
            onClick={() => setOpen(false)}
          />

          <div className="inputTextWrap">
            <span className="modalText">Name</span>
            <input
              type="text"
              className="modalInput"
              name="compname"
              defaultValue={component_name}
              required
              onChange={handleChange}
            />
          </div>
          <p style={{ color: "rgba(255,0,0,0.6)" }}>{message && message}</p>
          <br />
          <Button className="addBtn" type="submit" onClick={handleSubmit}>
            {loading ? (
              <CircularProgress color={"white"} size={25} />
            ) : (
              "Update Component"
            )}
          </Button>
          {/* </form> */}
        </div>
      </Modal>
    </>
  );
};

export default ComponentEdit;
