import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { spacing } from './const';

import { IconButton } from '@material-ui/core';
import Loader from '../Loader';
import ProductCard from './ProductCard';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import Constants from '../../App/Variables/Constants';
import TSE_axios from '../../TSE_axios';
import { copyDataToClipboard } from '../../helpers/utils';

const ProductsListing = ({ query, category, user_id }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        let url = Constants.getUrls.models_for_actuality;

        url += '?for=template&';
        if (query) url += `q=${query}&`;
        if (category.length > 0) {
          category.forEach(cat => {
            url += `catId[]=${cat}&`;
          })
        }

        const { data } = await TSE_axios.get(url);

        setProducts(data.products);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [query, category]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [showMessage, setShowMessage] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');

  const handleSelectedProducts = (id) => {
    if (showMessage) setShowMessage('');
    if (generatedLink) setGeneratedLink('');
    const tmp = selectedProducts.filter((p) => p != id);
    if (tmp.length == selectedProducts.length) tmp.push(id);
    setSelectedProducts(tmp);
  };
  const hdnaleGenerateLinks = async () => {
    if (!selectedProducts.length) {
      setShowMessage('Select at least one model!');
      return;
    }
    setIsLoading(true);

    try {
      const { data } = await TSE_axios.post(Constants.getUrls.getShareProducts, {
        products: selectedProducts,
      });
      setGeneratedLink(
        `https://actuality.live/myproducts/${data.shareProduct._id}`
      );
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      style={{
        paddingTop: spacing * 2,
      }}
    >
      {!isLoading && !products.length ? (
        <div
          style={{
            width: '100%',
            padding: spacing * 2,
            fontSize: 20,
            textAlign: 'center',
          }}
        >
          No Product exists
        </div>
      ) : isLoading && false ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={3}>
            {products
              // .filter(({ status }) => status == 'published')
              .map((product) => (
                <ProductCard
                  key={product._id}
                  product={product}
                  user_id={user_id}
                  onCheck={() => {
                    handleSelectedProducts(product._id);
                  }}
                  selectedProducts={selectedProducts}
                />
              ))}
          </Grid>

          <div
            style={{
              padding: spacing,
              textAlign: 'center',
              color: 'pink',
            }}
          >
            {showMessage}
          </div>

          {!generatedLink ? null : (
            <div
              style={{
                padding: spacing,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>{generatedLink}</span>
              <IconButton onClick={() => copyDataToClipboard(generatedLink)}>
                <FileCopyOutlinedIcon />
              </IconButton>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export const btnStyle = {
  border: 0,
  outline: 0,
  borderRadius: 16,
  boxShadow: `1px 1px 5px rgba(0, 0, 0, 0.1)`,
  padding: '15px 40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 22,
  width: '30%',
  fontWeigh: 500,
};
export default ProductsListing;
