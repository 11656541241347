import React, { useState, useRef, useEffect } from 'react';
import TSEDialog from '../TSEDialog';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ReactCropper = ({
  children,
  handleImageSubmit,
  image = {
    aspect: 1,
    width: 160,
    unit: '%',
  },
}) => {
  const [file, setFile] = useState({
    filename: '',
    src: '',
    crop: image,
  });

  const [visibleCrop, setVisibleCrop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const handleFileSubmit = async () => {
    if (isLoading) return;
    if (!file.filename) {
      setErrMsg('Select Image');
      return;
    }

    setIsLoading(true);
    try {
      await handleImageSubmit(file.filename);
      handleClose();
    } catch (err) {
      setErrMsg('Unable To upload, please try again');
    } finally {
      setIsLoading(false);
    }
  };
  const imageRef = useRef();
  const onSelectFile = (e) => {
    if (errMsg) setErrMsg('');
    if (isLoading) setIsLoading(false);
    const target = e.target;
    if (!target.files?.length) return;

    if (target.files && target.files.length > 0) {
      setVisibleCrop(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setFile((p) => ({ ...p, src: reader.result }));
      });
      // console.log(this.state.src);
      reader.readAsDataURL(target.files[0]);
    }
  };
  const onImageLoaded = (image) => {
    if (errMsg) setErrMsg('');

    imageRef.current = image;
  };
  const [croppedImageUrl, setCroppedImageUrl] = useState('');

  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            // console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          // window.URL.revokeObjectURL(fileUrl);
          resolve(window.URL.createObjectURL(blob));
        },
        'image/png',
        1
      );
    });
  };
  const getBlobFromUrl = async (myImageUrl) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', myImageUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
        resolve(request.response);
      };
      request.onerror = reject;
      request.send();
    });
  };
  const getDataFromBlob = (myBlob) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(myBlob);
    });
  };

  const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
  };
  const b64toBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: 'image/jpeg' });
    // console.log(blob);
    blobToFile(blob, 'my-image.png');
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var blob = new File([u8arr], filename, { type: mime });

    setFile((p) => ({ ...p, filename: blob }));
  };
  const convertUrlToImageData = async (myImageUrl) => {
    try {
      let myBlob = await getBlobFromUrl(myImageUrl);

      let myImageData = await getDataFromBlob(myBlob);

      b64toBlob(myImageData);
      dataURLtoFile(myImageData, 'newfile.png');
    } catch (err) {
      return null;
    }
  };
  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        'newFile.jpeg'
      );
      setCroppedImageUrl(croppedImageUrl);
      convertUrlToImageData(croppedImageUrl);
    }
  };

  const onCropComplete = (crop) => {
    if (errMsg) setErrMsg('');
    makeClientCrop(crop);
  };

  const handleClose = () => {
    setVisibleCrop(false);
    setFile({
      filename: '',
      src: '',
      crop: {
        aspect: 1,
        width: 160,
        unit: '%',
      },
    });
    imageRef.current = null;
    setIsLoading(false);
    setErrMsg('');
    setCroppedImageUrl('');
  };
  return (
    <>
      {children(onSelectFile)}
      <TSEDialog open={visibleCrop} maxWidth='900px' minWidth='900px'>
        <div
          style={{
            padding: 10,
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              maxWidth: 850,
              minWidth: 850,
              // border: '1px solid green',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {file.crop ? (
              <ReactCrop
                aspect={1}
                keepSelection={true}
                src={file.src}
                crop={file.crop}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={(crop, percentCrop) => {
                  if (errMsg) setErrMsg('');
                  setFile((c) => ({ ...c, crop }));
                }}
              />
            ) : null}
          </div>
          {!errMsg ? null : (
            <div
              style={{
                fontSize: 18,
                fontWeight: 400,
                textAlign: 'center',
                color: 'red',
                fontStyle: 'italic',
              }}
            >
              {errMsg}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              padding: 10,
            }}
          >
            <button
              style={{
                outline: 0,
                padding: '10px 20px',
                border: 0,
                marginRight: 0,
                background: '#E1E1E1',
                boxShadow: '1px 5px 5px rgba(0, 0, 0, 0.1)',
                borderRadius: 9,
                color: '#2e2e2e',
                fontSize: 20,
                cursor: 'pointer',
              }}
              onClick={handleFileSubmit}
            >
              {isLoading ? 'Submit...' : 'Submit'}
            </button>
            <div style={{ width: 20 }} />
            <button
              style={{
                outline: 0,
                padding: '10px 20px',
                border: 0,
                marginRight: 0,
                background: 'rgba(255,0,0,0.5)',
                boxShadow: '1px 5px 5px rgba(0, 0, 0, 0.1)',
                borderRadius: 9,
                fontSize: 20,
                cursor: 'pointer',
                color: 'white',
              }}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </TSEDialog>
    </>
  );
};

export default ReactCropper;
