import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
  Snackbar,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import init from "../helpers/WindowToken";
import { Redirect, Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ForUI from "../AddViews/ForUI";

export default class UpdatePricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      item_id: "",
      sub_title: "",
      description: "",
      no_of_hosted_models: "",
      monthly_views: "",
      item_price: "",
      first_model_optimization_free: false,
      installation_support: false,
      analytics: false,
      dedicated_account_manager: false,
      animated_model: false,
      white_labeled: false,
      status: "monthly",
      flag: "all",
      loading: false,
      _id: "",
      theme: "light",
      forUI: {
        extraPoints: [],
        points: [
          {
            type: "number",
            value: "0 Hosted Models",
            placeholder: "Hosted Models",
            field: "no_of_hosted_models",
            show: true,
          },
          {
            type: "number",
            value: "0 Monthly Website Views",
            placeholder: "Monthly Website Views",

            field: "monthly_views",
            show: true,
          },
          {
            type: "boolean",
            value: "First Model Optimization Free",
            field: "first_model_optimization_free",
            show: false,
          },
          {
            type: "boolean",
            value: "Installation Support",
            field: "installation_support",
            show: false,
          },
          {
            field: "analytics",
            type: "boolean",
            value: "Analytics",
            show: false,
          },
          {
            type: "boolean",
            value: "Animated Models",
            show: false,
            field: "animated_model",
          },
          {
            type: "boolean",
            value: "Dedicated Account Manager",
            field: "dedicated_account_manager",
            show: false,
          },
          {
            type: "boolean",
            value: "White Labelled",
            field: "white_labeled",
            show: false,
          },
        ],
      },
    };
  }
  componentDidMount() {
    let selectId = window.location.pathname.split("/")[3];
    this.setState(
      {
        _id: selectId,
      },
      () => {
        // console.log(this.state._id);
      }
    );
    if (init() === "success") {
      axios.get(Constants.getUrls.getPricing + "/" + selectId).then((resp) => {
        console.log(resp.data);
        if (!resp.data.pricing.forUI) {
          Object.keys(resp.data.pricing).map((key) => {
            // console.log(key, "resp.data.pricing[key] ", resp.data.pricing[key]);
            // if (resp.data.pricing[key] == "true") alert(key);
            resp.data.pricing[key] =
              resp.data.pricing[key] == "true"
                ? true
                : resp.data.pricing[key] == "false"
                ? false
                : resp.data.pricing[key];

            let e = {
              target: {
                value: resp.data.pricing[key],
                name: key,
              },
            };
            // console.log("e ", e);
            this.changeForUI(e);
          });
        }
        this.setState(
          {
            forUI: resp.data.pricing.forUI ?? this.state.forUI,
            title: resp.data.pricing.title,
            sub_title: resp.data.pricing.sub_title,
            item_id: resp.data.pricing.item_id,
            description: resp.data.pricing.description ?? this.state.description,
            no_of_hosted_models: resp.data.pricing.no_of_hosted_models,
            monthly_views: resp.data.pricing.monthly_views,
            item_price: resp.data.pricing.item_price,
            first_model_optimization_free: JSON.stringify(
              resp.data.pricing.first_model_optimization_free
            ),
            installation_support: JSON.stringify(
              resp.data.pricing.installation_support
            ),
            analytics: JSON.stringify(resp.data.pricing.analytics),
            dedicated_account_manager: JSON.stringify(
              resp.data.pricing.dedicated_account_manager
            ),
            animated_model: JSON.stringify(resp.data.pricing.animated_model),
            white_labeled: JSON.stringify(resp.data.pricing.white_labeled),
            status: resp.data.pricing.status,
            flag: resp.data.pricing.flag,
          },
          () => {
            // console.log(this.state.first_model_optimization_free);
          }
        );
      });
    }
  }

  updateCategory = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let catLoad = {
      title: this.state.title,
      sub_title: this.state.sub_title,
      item_id: this.state.item_id,
      description: this.state.description,
      no_of_hosted_models: this.state.no_of_hosted_models,
      monthly_views: this.state.monthly_views,
      item_price: this.state.item_price,
      first_model_optimization_free: this.state.first_model_optimization_free,
      installation_support: this.state.installation_support,
      analytics: this.state.analytics,
      dedicated_account_manager: this.state.dedicated_account_manager,
      animated_model: this.state.animated_model,
      white_labeled: this.state.white_labeled,
      status: this.state.status,
      flag: this.state.flag,
      forUI: this.state.forUI,
    };
    if (init() === "success") {
      axios
        .put(Constants.getUrls.getPricing + "/" + this.state._id, catLoad)
        .then((resp) => {
          // console.log(resp);
          if (resp.data.status === "success") {
            this.setState({
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                redirect: true,
              });
            }, 1000);
            this.setState({
              notification: true,
              loading: false,
            });
          } else {
            this.setState({
              warn: true,
              loading: false,
            });
            setTimeout(() => {
              this.setState({
                warn: false,
              });
            }, 1000);
          }
        });
    }
  };

  changeForUI = (e) => {
    // return;
    let field = e.target.name;
    let dupliacteForUIPoints = this.state.forUI.points;

    let getField = dupliacteForUIPoints.findIndex(
      (point) => point.field == field
    );
    if (getField != -1) {
      if (dupliacteForUIPoints[getField].type == "number") {
        dupliacteForUIPoints[getField].show = true;
        dupliacteForUIPoints[
          getField
        ].value = `${e.target.value} ${dupliacteForUIPoints[getField].placeholder}`;
      } else if (dupliacteForUIPoints[getField].type == "boolean") {
        dupliacteForUIPoints[getField].show =
          e.target.value == "true"
            ? true
            : e.target.value == "false"
            ? false
            : e.target.value;
      }
      this.setState({
        forUI: {
          ...this.state.forUI,
          points: dupliacteForUIPoints,
        },
      });
    }
  };
  handleInput = (e) => {
    this.changeForUI(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChange = (e) => {
    this.changeForUI(e);
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(typeof this.state.first_model_optimization_free);
      }
    );
  };
  addExtraPoint = (point) => {
    // console.log("point ", point);
    let forUI = this.state.forUI;
    if (!forUI.extraPoints) forUI.extraPoints = [];

    forUI.extraPoints.push({ ...point, id: Math.random() });
    // console.log(forUI);
    this.setState({ forUI });
  };
  deletePoint = (id) => {
    // console.clear();
    // console.log("deletePoint");
    let forUI = this.state.forUI;
    forUI.extraPoints = forUI.extraPoints.filter(
      ({ id: idTemp }) => idTemp != id
    );
    // console.log(forUI);
    this.setState({ forUI });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/pricing" />;
    } else {
      return (
        <Container maxWidth="xl" style={{ marginTop: "30px" }}>
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography
                variant="h6"
                style={{
                  marginBottom: "30px",
                  color: this.state.theme === "light" ? "#000" : "#fff",
                }}
              >
                Update Pricing
                {/* {this.props.title} */}
              </Typography>
            </Grid>
          </Grid>
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"green"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          <div
            style={{
              padding: "20px",
              border: "1px solid #afa2a2",
              borderRadius: "7px",
              position: "relative",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>
            <span
              style={{
                color: "#afa3a3",
                marginTop: "-8px",
                marginLeft: "26px",
                marginBottom: "35px",
                fontSize: "17px",
                display: "flex",
              }}
            >
              .{/* Update Category */}
            </span>
            <Grid container style={{}}>
              <Grid
                item
                sm={8}
                style={{
                  textAlign: "left",
                  marginTop: "30px",
                }}
              >
                {/* <form noValidate autoComplete="off" onSubmit={this.updateCategory}> */}
                <form onSubmit={this.updateCategory}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Title"
                    name="title"
                    value={this.state.title}
                    required={true}
                    variant="outlined"
                    disabled
                    style={{
                      marginRight: "20px",
                      width: "30%",
                      marginTop: "18px",
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Sub Title"
                    name="sub_title"
                    value={this.state.sub_title}
                    required={true}
                    variant="outlined"
                    style={{
                      marginRight: "20px",
                      width: "30%",
                      marginTop: "18px",
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Item Id"
                    onChange={this.handleInput}
                    name="item_id"
                    value={this.state.item_id}
                    required={true}
                    disabled
                    style={{
                      marginRight: "20px",
                      width: "30%",
                      marginTop: "18px",
                    }}
                    variant="outlined"
                  />
                  {/* <TextField
                    id="outlined-basic"
                    label="Description"
                    onChange={this.handleInput}
                    name="description"
                    style={{ marginRight: "20px", width: "30%" }}
                    variant="outlined"
                  /> */}
                  <TextField
                    id="outlined-basic"
                    label="No of hosted models"
                    onChange={this.handleInput}
                    required={true}
                    name="no_of_hosted_models"
                    value={this.state.no_of_hosted_models}
                    style={{
                      marginRight: "20px",
                      width: "30%",
                      marginTop: "18px",
                    }}
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Monthly views"
                    required={true}
                    onChange={this.handleInput}
                    name="monthly_views"
                    value={this.state.monthly_views}
                    style={{
                      marginRight: "20px",
                      width: "30%",
                      marginTop: "18px",
                    }}
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Item price"
                    required={true}
                    onChange={this.handleInput}
                    value={this.state.item_price.slice(0, 3)}
                    name="item_price"
                    disabled
                    style={{
                      marginRight: "20px",
                      width: "30%",
                      marginTop: "18px",
                    }}
                    variant="outlined"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      margin: "20px",
                    }}
                  >
                    <FormControl component="fieldset" color="default">
                      <FormLabel component="legend">Plan Status :</FormLabel>
                      <RadioGroup
                        color="default"
                        aria-label="true"
                        name="status"
                        value={this.state.status}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          color="default"
                          disabled
                          value={"monthly"}
                          control={<Radio />}
                          label="Monthly"
                        />
                        <FormControlLabel
                          color="default"
                          disabled
                          value={"annual"}
                          control={<Radio />}
                          label="Annual"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" color="default">
                      <FormLabel component="legend">
                        First model optimization free :
                      </FormLabel>
                      <RadioGroup
                        color="default"
                        aria-label="true"
                        name="first_model_optimization_free"
                        value={this.state.first_model_optimization_free}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          color="default"
                          value={"true"}
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          color="default"
                          value={"false"}
                          control={<Radio />}
                          label="Disable"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" color="default">
                      <FormLabel component="legend">
                        Installation supportee :
                      </FormLabel>
                      <RadioGroup
                        color="default"
                        aria-label="true"
                        name="installation_support"
                        value={this.state.installation_support}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          color="default"
                          value={"true"}
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          color="default"
                          value={"false"}
                          control={<Radio />}
                          label="Disable"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" color="default">
                      <FormLabel component="legend">Analytics :</FormLabel>
                      <RadioGroup
                        color="default"
                        aria-label="true"
                        name="analytics"
                        value={this.state.analytics}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          color="default"
                          value={"true"}
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          color="default"
                          value={"false"}
                          control={<Radio />}
                          label="Disable"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      margin: "20px",
                    }}
                  >
                    <FormControl component="fieldset" color="default">
                      <FormLabel component="legend">
                        Dedicated account manager :
                      </FormLabel>
                      <RadioGroup
                        color="default"
                        aria-label="true"
                        name="dedicated_account_manager"
                        value={this.state.dedicated_account_manager}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          color="default"
                          value={"true"}
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          color="default"
                          value={"false"}
                          control={<Radio />}
                          label="Disable"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" color="default">
                      <FormLabel component="legend">Animated model :</FormLabel>
                      <RadioGroup
                        color="default"
                        aria-label="true"
                        name="animated_model"
                        value={this.state.animated_model}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          color="default"
                          value={"true"}
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          color="default"
                          value={"false"}
                          control={<Radio />}
                          label="Disable"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" color="default">
                      <FormLabel component="legend">White labeled :</FormLabel>
                      <RadioGroup
                        color="default"
                        aria-label="true"
                        name="white_labeled"
                        value={this.state.white_labeled}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          color="default"
                          value={"true"}
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          color="default"
                          value={"false"}
                          control={<Radio />}
                          label="Disable"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" color="default">
                      <FormLabel component="legend">Flag :</FormLabel>
                      <RadioGroup
                        color="default"
                        aria-label="true"
                        name="flag"
                        value={this.state.flag}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          color="default"
                          value={"all"}
                          control={<Radio />}
                          label="All"
                        />
                        <FormControlLabel
                          color="default"
                          value={"custom"}
                          control={<Radio />}
                          label="Custom"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <label htmlFor="description"> Description : </label>
                    <CKEditor
                      style={{ color: "black" }}
                      editor={ClassicEditor}
                      required={true}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        // console.log({ event, editor, data });
                        this.setState({
                          description: data,
                        });
                      }}
                      data={this.state.description}
                    />
                  </div>
                  <br />
                  <Button
                    variant="contained"
                    type="submit"
                    Size={15}
                    color="primary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    {this.state.loading ? "Updating.." : "Update Pricing"}
                  </Button>
                  <Link
                    to={{
                      pathname: "/pricing",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      Size={15}
                      color="secondary"
                      style={{ marginRight: "20px", marginTop: "35px" }}
                    >
                      Cancel
                    </Button>
                  </Link>
                </form>
              </Grid>
              <Grid
                item
                sm={4}
                style={{
                  background: "white",
                  padding: 10,
                }}
              >
                <ForUI
                  propState={this.state}
                  addExtraPoint={this.addExtraPoint}
                  deletePoint={this.deletePoint}
                />
              </Grid>
            </Grid>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Fade in={this.state.open}>
              <div
                style={{
                  background: "#fff",
                  width: "50%",
                  padding: "32px",
                }}
              >
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">
                  react-transition-group animates me.
                </p>
              </div>
            </Fade>
          </Modal>
        </Container>
        //   </SnackbarProvider>
      );
    }
  }
}
