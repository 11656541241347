import React, { useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import embeButtoneIcon from "../../Assets/newIcons/embeButtoneIcon.png";
import embeCodeIcon from "../../Assets/newIcons/embeCodeIcon.png";
import qrCodeIcon from "../../Assets/newIcons/qrCodeIcon.png";
import ViewInButton from "./ViewInButton";

import EmbedCode from "./EmbedCode";
import QRCode from "./QRCode";
import "./style.css";
const ProductSharePopUpCompo = ({
  productId,
  language,
  handleClose,
  modelSrc,
  embedded_product_url,
}) => {
  const [tabNo, setTabNo] = useState(0);
  return (
    <div className="product_share_pop_up">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          //   border: '1px solid red',
        }}
      >
        <IconButton
          onClick={handleClose}
          style={{
            // margin: 20,
            marginRight: 20,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#EDEDED",
          }}
        >
          <TopButton
            text="Embedd Buttons"
            icon={embeButtoneIcon}
            showBorderBottom={tabNo == 0}
            showDivider={true}
            onClick={() => tabNo != 0 && setTabNo(0)}
          />
          <TopButton
            text="Embedd Code"
            icon={embeCodeIcon}
            showBorderBottom={tabNo == 1}
            showDivider={true}
            onClick={() => tabNo != 1 && setTabNo(1)}
          />
          <TopButton
            onClick={() => tabNo != 2 && setTabNo(2)}
            showBorderBottom={tabNo == 2}
            text="QR Code"
            icon={qrCodeIcon}
          />
        </div>
        {tabNo == 1 ? (
          <EmbedCode productId={productId} modelSrc={modelSrc} />
        ) : tabNo == 2 ? (
          <QRCode productId={productId} />
        ) : (
          // <EmbedCode productId={productId} modelSrc={modelSrc} />
          // <QRCode productId={productId} />
          <ViewInButton
            productId={productId}
            embedded_product_url={embedded_product_url}
          />
        )}
      </div>
    </div>
  );
};

export default ProductSharePopUpCompo;

const TopButton = ({ icon, text, showBorderBottom, showDivider, onClick }) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 15,
        position: "relative",
        // border: '1px solid red',
        borderBottom: `2px solid ${showBorderBottom ? "#2e2e2e" : "#fff"}`,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {!showDivider ? null : (
        <div
          style={{
            position: "absolute",
            height: "60%",
            width: "1px",
            right: 0,
            background: "#2e2e2e",
          }}
        />
      )}
      <img src={icon} width={25} height={25} />
      <span
        style={{
          color: "#2e2e2e",
          fontSize: 20,
          fontWeight: 400,
          marginLeft: 10,
        }}
      >
        {text}
      </span>
    </div>
  );
};
