import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import Constants from "../Variables/Constants";

export default class Login extends Component {
  state = {
    email: "",
    password: "",
  };
  login = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let loginLoad = {
      email: this.state.email,
      password: this.state.password,
    };
    // console.log(loginLoad);
    axios.post(Constants.postUrls.login, loginLoad).then((resp) => {
       console.log(resp.data.message);
      //  if(resp.data.user){
         localStorage.setItem("user", JSON.stringify(resp.data.user))
        // }
      if (resp.data.status === "failed") {
        this.setState({
          loading: false,
          message: resp.data.message,
          error: true,
        });
        setTimeout(() => {
          this.setState({
            error: false,
          });
        }, 1000);
      } else {
        localStorage.setItem("feel_xr_auth_token", resp.data.user.accessToken);

        // setTimeout(() => {
        window.location.href = "/";
        // }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      }
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <div className="loginWrapper">
        <Container maxWidth="md">
          <Grid container>
            <Grid item sm={6} style={{ position: "relative", height: "100vh" }}>
              <Paper className="loginCard" elevation={15}>
                <Typography variant="h4" component="h2" gutterBottom>
                  FeelXR
                </Typography>
                <Typography variant="h5" component="h1">
                  Log in
                </Typography>
                <Typography
                  variant="body2"
                  component="h1"
                  gutterBottom
                  color="textSecondary"
                >
                  Continue to admin dashboard
                </Typography>
                <Typography
                  color="primary"
                  variant="body2"
                  style={{ alignSelf: "flex-end" }}
                >
                  Forgot password?
                </Typography>
                <form className="loginFormWrapper" onSubmit={this.login}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    name="email"
                    onChange={this.handleInput}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    name="password"
                    type="password"
                    onChange={this.handleInput}
                  />

                  <Button
                    color="primary"
                    style={{ alignSelf: "flex-start" }}
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    {this.state.loading ? (
                      "Logging In..."
                    ) : this.state.error ? (
                      <span style={{ color: "#BD0600" }}>
                       { this.state.message}
                      </span>
                    ) : (
                      " Log In"
                    )}
                  </Button>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
