import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import Constants from "../Variables/Constants";

import init from "../../helpers/WindowToken";

import {
  Button,
  Snackbar,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EditIcon from "@material-ui/icons/Edit";
import { Link, Redirect } from "react-router-dom";
import SunEditor from "../../Components/SunEditor";

export default class TermsandConditions extends Component {
  state = {
    content: "",
    notification: false,
    loading: false,
    message: "",
    redirect: false,
    descError: false,
    theme: "light",
  };
  componentDidMount() {
    this.getTerms();
  }
  addTerms = () => {
    if (this.state.content === "") {
      this.setState({
        descError: true,
      });
      setTimeout(() => {
        this.setState({
          descError: false,
        });
      }, 3000);
    } else {
      let load = {
        content: this.state.content,
      };
      this.setState({ loading: true });
      if (init() === "success") {
        axios.post(Constants.postUrls.termsandConditions, load).then((resp) => {
          // console.log(resp);
          if (resp.data.status === "success") {
            this.setState({
              loading: false,
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                redirect: true,
              });
            }, 1500);
          }
        });
      }
    }
  };
  getTerms = () => {
    axios.get(Constants.getUrls.getTerms).then((res) => {
      // console.log(res);
      this.setState({
        content: res.data.terms === null ? "" : res.data.terms.content,
        TermId: res.data.terms === null ? null : res.data.terms._id,
      });
    });
  };

  updateTerms = (id) => {
    // console.log(this.state.content);
    if (this.state.content === "") {
      this.setState({
        descError: true,
      });
      setTimeout(() => {
        this.setState({
          descError: false,
        });
      }, 3000);
    } else {
      let load = {
        content: this.state.content,
      };
      this.setState({ loading: true });
      if (init() === "success") {
        axios
          .put(
            Constants.postUrls.termsandConditions + "/" + this.state.TermId,
            load
          )
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status === "success") {
              this.setState({
                loading: false,
                notification: true,
                message: resp.data.message,
              });
              setTimeout(() => {
                // window.location.reload();

                this.setState({
                  notification: false,
                  // redirect: true,
                });
              }, 1500);
            }
          });
      }
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/terms" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography
                variant="h6"
                style={{
                  color: this.state.theme === "light" ? "#000" : "#fff",
                }}
              >
                {this.props.title}
              </Typography>
            </Grid>
          </Grid>
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"green"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          <div
            // style={{
            //   background: "#5b5757",
            //   padding: "20px",
            //   borderRadius: "10px",
            //   // color: rgba(0, 0, 0, 0.87);
            //   width: "100%",
            //   border: "0",
            //   display: "flex",
            //   position: "relative",
            //   fontSize: ".875rem",
            //   minWidth: "0",
            //   wordWrap: " break-word",
            //   /* background: #FFF; */
            //   boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            //   marginTop: "30px",
            //   borderRadius: "6px",
            //   marginBottom: "30px",
            //   flexDirection: "column",
            //   textAlign: "left",
            // }}
            style={{
              padding: "20px",
              border: "1px solid #afa2a2",
              borderRadius: "7px",
              position: "relative",
              marginTop: "37px",
              // background:"#3f51b530"
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>
            <span
              style={{
                color: "#afa3a3",
                marginTop: "-8px",
                marginLeft: "26px",
                marginBottom: "35px",
                fontSize: "17px",
                display: "flex",
              }}
            >
              .{/* Terms and Conditions */}
            </span>
            {/* <CKEditor
              editor={ClassicEditor}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log({ event, editor, data });
                this.setState({
                  content: data,
                });
              }}
              data={this.state.content}
            /> */}
            <SunEditor
              height="100px"
              width="100%"
              value={this.state.content}
              setValue={(e) => {
                this.setState({
                  content: e,
                });
              }}
            />
            {this.state.descError ? (
              <div style={{ color: "red" }}>required*</div>
            ) : (
              ""
            )}
            {/* <CKEditor
              editor={ClassicEditor}
              onChange={(event, editor) => {
                const data = editor.getData();
              // console.log({ event, editor, data });
                this.setState({
                  content: data,
                });
              }}
              data={this.state.content}
            /> */}

            <Button
              variant="contained"
              type="submit"
              Size={15}
              style={{ width: "15%", marginTop: "20px" }}
              color="primary"
              onClick={() =>
                this.state.TermId ? this.updateTerms() : this.addTerms()
              }
            >
              {this.state.loading ? "Updating.." : "Update"}
            </Button>
          </div>{" "}
        </Container>
      );
    }
  }
}
