import axios from "axios";
import Constants from "../App/Variables/Constants";

export const loremIpsum = (n = 50) =>
  [...new Array(isNaN(n) ? 50 : n)]
    .map(
      () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
    )
    .join("\n");
export function getCombinations(arr) {
  if (arr.length === 0) return [[]];
  let [current, ...rest] = arr;
  let combinations = getCombinations(rest);
  return current.materials.reduce(
    (a, string) => [...a, ...combinations.map((c) => [string, ...c])],
    []
  );
}


export const customLegend = (props, data) => {
  const { payload } = props;
  // console.log("payload ", payload);
  return (
    <div
      style={{
        // border: "0px solid red",
        position: "absolute",
        top: 0,
        right: 0,
        width: "max-content",
        paddingRight: 20,
      }}
    >
      {payload.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 6,
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: "50%",
              background: entry.color,
            }}
          />
          <span
            style={{
              color: entry.color,
              // fontSize: 16,
              marginLeft: 5,
            }}
          >
            {`${entry.value} - ${data.reduce(
              (total, row) => row[entry.value] + total,
              0
            )}`}
          </span>
        </div>
      ))}
    </div>
  );
};

const PING_RESOURCE = "/ping.txt";
const TIMEOUT_TIME_MS = 3000;

const timeout = (time, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);
    promise.then(resolve, reject);
  });
};

export const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine;

  //
  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, {
        method: "GET",
        signal,
      })
    );
    return true;
  } catch (error) {
    // Error Log
    console.error(error);

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
};

export const dateAhead = (num) => {
  // console.log("numnumnum ", num);
  const d = new Date(Date.now() + num * 24 * 60 * 60 * 1000);
  const date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  return date;
};

const carte = () => {
  let arr = [
    ["a1", "b1", "c1"],
    ["a2", "b2", "c2"],
    ["a3", "b3", "c3"],
  ];
  let res = [];

  function cartesian(...args) {
    var r = [],
      max = args.length - 1;
    function helper(arr, i) {
      for (var j = 0, l = args[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i == max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    // return r;
    res.push(r);
  }
  cartesian(...arr.map((a) => a));
  // console.log("res ", res);
};

export const scrollToTop = () =>
  window.scrollTo({ top: 0, behavior: "smooth" });

export const canPublishProduct = async (productId) => {
  try {
    const { data } = await axios.get(
      `${Constants.getUrls.canPublishProduct}/${productId}`
    );
    return data.noOfVariantMustBe <= data.variantsUploaded &&
      data.noOfVariantMustBe != 0 &&
      data.variantsUploaded != 0
      ? true
      : false;
  } catch (err) {
    return false;
    // `/product-edit?id=${productId}`;
  }
};
export const btnStyle = {
  outline: "none",
  border: 0,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: 5,
  background: "#ffffff",
  width: 200,
  padding: 10,
  fontSize: 18,
  fontWeight: 500,
};

function copy(e, contentToCopy) {
  e.preventDefault(); // default behaviour is to copy any selected text
  e.clipboardData.setData("text/plain", contentToCopy);
}

export function copyDataToClipboard(contentToCopy) {
  document.addEventListener("copy", (e) => copy(e, contentToCopy));
  try {
    document.execCommand("copy");
  } catch (exception) {
    console.error("Copy to clipboard failed");
  } finally {
    document.removeEventListener("copy", (e) => copy(e, contentToCopy));
  }
}

export const downloadCanvasAsImage = (canvasImg, imageName) => {
  if (!canvasImg || !imageName) return;
  let xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = function () {
    let a = document.createElement("a");
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = imageName + ".png";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  xhr.open("GET", canvasImg); // This is to download the canvas Image
  xhr.send();
};
