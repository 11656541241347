const Constants = {
  appUrls: {
    app: "/app",
    login: "/login",
  },
  authUrls: {
    login: "/api/login",
    logout: "/api/logout",
    register: "/api/register",
  },
  resourceUrls: {
    principle: "/api/user/principle",
    createOrg: "/api/orgs/create",
  },
  testing: {
    randUsers: "/api/temp/users",
  },
  localKeys: {
    accessToken: "feel_xr_auth_token",
    refreshToken: "corexAuthRefresh",
    expiresIn: "corexTTL",
  },
  serverStatus: {
    success: "success",
  },
};

export default Constants;

export const AppUrls = Constants.appUrls;
export const AuthUrls = Constants.authUrls;
export const TestingUrls = Constants.testing;
export const ResourceUrls = Constants.resourceUrls;
export const LocalKeys = Constants.localKeys;
export const ServerStatus = Constants.serverStatus;
