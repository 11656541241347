import {
  Button,
  Container,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constants";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));
function createData(name, calories, fat) {
  return { name, calories, fat };
}
const rowsPerPage = 5;
const page = 1;

export default class VariantOptions extends Component {
  state = {
    title: "",
    description: "",
    getVarOptions: [],
    open: false,
    getBlogs: [],
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
  };
  componentDidMount() {
    this.getVarinatOpt();
  }
  getVarinatOpt = () => {
    axios
      .get(
        Constants.getUrls.varOptions +
          "?page=" +
          this.state.active +
          "&limit=" +
          this.state.perPage
      )
      .then((resp) => {
        // console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            getVarOptions: resp.data.docs,
            total: resp.data.totalDocs,
            from: 1,
            // to: resp.data.data.length,
          },
          () => {
            // console.log(this.state.getVarOptions);
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.varOptions +
              "?page=" +
              this.state.active +
              "&limit=" +
              this.state.perPage
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                getVarOptions: resp.data.docs,
                total: resp.data.totalDocs,
                from: 1,
                to: resp.data.docs.length,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  currentPage = (v) => {
    axios
      .get(
        Constants.getUrls.varOptions +
          "?page=" +
          v +
          "&limit=" +
          this.state.perPage
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          getVarOptions: resp.data.docs,
          total: resp.data.totalDocs,
          from: v * this.state.perPage - (this.state.perPage - 1),
          to:
            v * this.state.perPage > this.state.total
              ? this.state.total
              : v * this.state.perPage,
          active: v,
        });
      });
  };
  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };
  deletevariants = (id) => {
    if (init() == "success") {
      this.setState({ delLoading: true });
      axios.delete(Constants.getUrls.varOptions + "/" + id).then((res) => {
        // console.log(res);
        this.setState({
          notifyDelete: true,
          delLoading: false,
          delMsg: res.data.message,
        });
        setTimeout(() => {
          this.setState({ notifyDelete: false, visible: false }, () => {
            this.getVarinatOpt();
          });
        }, 2000);
      });
    }
  };
  handleStatus = (e, status, id) => {
    if (init() === "success") {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          const payload = {
            status: status,
          };
          // console.log(payload);
          axios
            .post(Constants.statusUrl + id + "?type=variantOptions", payload)
            .then((res) => {
              // console.log(res);
              this.setState({
                notifyStatus: true,
              });
              setTimeout(() => {
                this.setState({ notifyStatus: false }, () => {
                  this.getVarinatOpt();
                });
              }, 1000);
            });
        }
      );
    }
  };
  handleSearch = (e) => {
    if (e.target.value.length > 3) {
      this.setState({ searching: true });
      axios
        .get(
          Constants.getUrls.varOptions +
            "?page=" +
            this.state.active +
            "&limit=" +
            this.state.perPage +
            "&q=" +
            e.target.value
          // this.state.name
        )
        .then((resp) => {
          // console.log(resp);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.pageCount; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              btnIdArray,
              getVarOptions: resp.data.docs,
              total: resp.data.totalDocs,
              from: 1,
              to: resp.data.docs.length,
              searched: true,
              searching: false,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray,
              });
            }
          );
        });
    }
  };
  render() {
    return (
      <Container maxWidth="xl">
        {/* <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          color={"s"}
          autoHideDuration={6000}
          message="I love snacks"
        ></Snackbar> */}
        {this.state.notifyDelete ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            color={"s"}
            autoHideDuration={6000}
            message={this.state.delMsg}
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6">{this.props.title}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-end">
              <FormControl style={{ marginRight: 8 }}>
                <TextField
                  id="outlined-basic"
                  placeholder={"Search " + this.props.title}
                  size="small"
                  variant="outlined"
                  onChange={this.handleSearch}
                />
              </FormControl>

              <Link
                to="/admin/addvarinatoptions"
                style={{ textDecoration: "none" }}
              >
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add Variant Options
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>

                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {(rowsPerPage > 0
                        ? rows.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          ) */}
                  {this.state.getVarOptions.map((varopt) => (
                    <TableRow key={varopt.title}>
                      <TableCell component="th" scope="row">
                        {varopt.title}
                      </TableCell>
                      {/* <TableCell align="right">{row.calories}</TableCell>
                          <TableCell align="right">{row.fat}</TableCell>
                          <TableCell align="right">{row.calories}</TableCell> */}

                      <TableCell align="right">
                        <Switch
                          color="primary"
                          name="checkedB"
                          value={varopt.status}
                          onChange={(e) =>
                            this.handleStatus(e, varopt.status, varopt._id)
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={{
                            pathname:
                              "/admin/updatevarinatoptions/" + varopt._id,
                            state: { data: varopt },
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            variant={"outlined"}
                            color="default"
                            style={{ marginRight: 4 }}
                            size={"small"}
                          >
                            Update
                          </Button>
                        </Link>
                        <Button
                          size={"small"}
                          variant={"outlined"}
                          color="secondary"
                          onClick={() => {
                            this.deletevariants(varopt._id);
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ position: "relative" }}>
                    {/* <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={6}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                    /> */}
                    {this.state.getVarOptions.length === 0 ? (
                      <div>
                        <h6
                          style={{
                            textAlign: "center",
                            marginTop: "30px",
                            fontSize: "14px",
                          }}
                        >
                          No Data Found!
                        </h6>
                      </div>
                    ) : this.state.empty ? null : (
                      <div style={{ marginTop: "20px", marginBottom: "70px" }}>
                        <div style={{ float: "left", marginLeft: "20px" }}>
                          Showing {this.state.from} to {this.state.to} of{" "}
                          {this.state.total} entries.
                        </div>

                        <div
                          style={{
                            float: "right",
                            position: "absolute",
                            right: "47px",
                            top: "5px",
                          }}
                        >
                          <Pagination
                            style={{
                              listStyle: "none",
                              display: "inline-flex",
                            }}
                          >
                            <PaginationItem>
                              <PaginationLink
                                onClick={this.prevPages}
                                tabIndex="-1"
                                style={{
                                  background: "white",
                                  border: "none",
                                  borderRadius: "23px",
                                  height: "34px",
                                  width: "35px",
                                  marginRight: "10px",
                                }}
                              >
                                <ArrowBackIosRoundedIcon
                                  style={{
                                    color: "dimgray",
                                    fontSize: "19px",
                                    marginTop: "2px",
                                  }}
                                />
                                {/* <i className="fa fa-angle-left" /> */}
                                {/* <span className="sr-only">Previous</span> */}
                              </PaginationLink>
                            </PaginationItem>
                            {this.state.filteredBtnIdArray.map((v, i) => (
                              <PaginationItem
                                className={
                                  this.state.active === v ? "active" : ""
                                }
                                key={i}
                              >
                                <PaginationLink
                                  onClick={() => {
                                    this.currentPage(v);
                                  }}
                                  style={{
                                    background: "white",
                                    border: "none",
                                    borderRadius: "23px",
                                    height: "34px",
                                    width: "35px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {v}
                                </PaginationLink>
                              </PaginationItem>
                            ))}
                            <PaginationItem>
                              <PaginationLink
                                onClick={this.nextPages}
                                style={{
                                  background: "white",
                                  border: "none",
                                  borderRadius: "23px",
                                  height: "34px",
                                  width: "35px",
                                  marginRight: "10px",
                                }}
                              >
                                {/* <i className="fa fa-angle-right" /> */}
                                {/* <span className="sr-only">Next</span> */}
                                <ArrowForwardIosRoundedIcon
                                  style={{
                                    color: "dimgray",
                                    fontSize: "19px",
                                    marginTop: "2px",
                                  }}
                                />
                              </PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </div>
                      </div>
                    )}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Container>
    );
  }
}
