import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import imgEmpty from "../../../Assets/Images/image 16.png";
import modelImg1 from "../../../Assets/Images/modelImg1.png";
import Constants from "../../Variables/Constants";
import ProductCard from "./ProductCard";
import Modal from "react-awesome-modal";
import AllProductPaginated from "../../../Components/AllProductPaginated";

class AllProducts extends Component {
  state = {
    products0: [],
    categories: [],
    expandMore: false,
    expand: 0,
    scrollIndex: 1,
    pageCount: 1,
    hasNextPage: true,
    page: 1,
    totalpage: 1,
    metaData: {},
    selected: [],
    showSearchSuggestions: false,
    showAll: true,
    query: "",
    productStatus: "",
    CategoryLength: false,
    perPage: 15,
    testdata: [],
    userId: window.location.pathname.split("/")[3],
    currentModal: false,
    currentStatusMessage: "",
  };

  componentDidMount() {
    axios
      .get(
        Constants.getUrls.currentPlanStatus + "?user_id=" + this.state.userId
      )
      .then((resp) => {
        // console.log(resp.data.status);
        if (resp.data.status === "failed") {
          this.setState({
            currentModal: true,
            currentStatusMessage: resp.data.message,
          });
        }
      });
  }

  fetchCategories = () => {
    // if (init() === "success") {
    let authToken = localStorage.getItem("feel_xr_auth_token");
    axios
      .get(
        Constants.getUrls.getUserCat +
          "?status=" +
          this.state.productStatus +
          "&q=" +
          this.state.query,
        { headers: { Authorization: "Bearer " + authToken } }
      )
      .then((res) => {
        this.setState(
          {
            categories: res.data.categories,
          },
          () => {
            const id =
              this.props.categories.length > 0
                ? this.props.categories[0]._id
                : null;
            this.fetchModels(id, 0);
          }
        );
      });
    // }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categories !== this.props.categories) {
      this.setState({ categories: this.props.categories }, () => {
        const id =
          this.props.categories.length > 0
            ? this.props.categories[0]._id
            : null;
        this.fetchModels(id, 0);
        if (this.state.categories.length > 0) {
          this.state.selected.push(this.state.categories[0]._id);
        }
      });
    }
    if (prevProps.query !== this.props.query) {
      this.setState({ query: this.props.query }, () => {
        const id =
          this.props.categories.length > 0
            ? this.props.categories[0]._id
            : null;
        this.fetchModels(id, 0);
      });
    }
    if (prevProps.productStatus !== this.props.productStatus) {
      this.setState({ productStatus: this.props.productStatus }, () => {
        const id =
          this.props.categories.length > 0
            ? this.props.categories[0]._id
            : null;
        this.fetchModels(id, 0);
      });
    }
    if (prevProps.CategoryLength !== this.props.CategoryLength) {
      this.setState({ CategoryLength: this.props.CategoryLength });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
    if (prevProps.searching !== this.props.searching) {
      this.setState({ searching: this.props.searching }, () => {
        const id =
          this.props.categories.length > 0
            ? this.props.categories[0]._id
            : null;
        this.fetchModels(id, 0);
      });
    }
  }

  componentWillUnmount() {
    var user_id = window.location.pathname.split("/")[3];
    // console.log(user_id);
    this.setState({
      userId: this.state.userId,
    });
    this.setState({
      categories: [],
    });
  }

  deleteProduct = (catId, index) => {
    // console.log(catId);
    this.fetchModels(catId, index);
  };

  fetchModels = (catId, index) => {
    // console.log(catId);
    // console.log(index);
    if (catId) {
      this.setState({ products: [], ["loadingProducts" + index]: true });

      // if (init() === "success") {
      axios
        .get(
          // Constants.getUrls.getProducts +
          Constants.getUrls.getProductsByUser +
            "?page=" +
            this.state.page +
            "&limit=" +
            this.state.perPage +
            "&catId=" +
            catId +
            "&q=" +
            this.props.query +
            "&status=" +
            this.props.productStatus +
            "&user=" +
            this.state.userId
        )
        .then((res) => {
          // console.log("res from fetchModekls ", res.data);
          this.setState({
            ["loadingProducts" + index]: false,
            ["products" + index]: res.data.docs,
            totalpage: res.data.totalPages,
            hasNextPage: res.data.hasNextPage === false ? false : true,
          });
        });
      // }
    }
  };
  seeMoreData = (catId, index) => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.fetchModels(catId, index);
    });
  };
  selectedIndex = (CompId) => {
    if (this.state.selected.includes(CompId)) {
      let index = this.state.selected.indexOf(CompId);
      this.state.selected.splice(index, 1);
    } else {
      this.state.selected.push(CompId);
    }
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <div style={{ textAlign: "center", marginTop: "80px" }}>
            <CircularProgress size={40} color={"info"} />
          </div>
        ) : (
          <div>
            {this.state.CategoryLength ? (
              <div style={{ textAlign: "center", marginTop: "100px" }}>
                <img
                  src={imgEmpty}
                  style={{ width: "150px", height: "150px" }}
                  alt=""
                />
                <Typography style={{ marginBottom: "10px" }}>
                  Nothing yet here
                </Typography>
                <Link
                  to={"/admin/product-create/" + this.state.userId}
                  style={{ textDecoration: "none" }}
                >
                  <Button className="createFirstProductBtn">
                    Create your first product
                  </Button>
                </Link>
              </div>
            ) : (
              <div>
                {this.state.categories
                  ? this.state.categories.map((singleCate, index) => (
                      <Accordion defaultExpanded={index === 0}>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div
                            className="CategoryDiv"
                            onClick={() => this.selectedIndex(singleCate._id)}
                          >
                            <Typography
                              className="Heading"
                              onClick={() => {
                                this.fetchModels(singleCate._id, index);
                              }}
                              key={index}
                            >
                              <span>
                                {this.state.selected.includes(
                                  singleCate._id
                                ) ? (
                                  <RemoveIcon className="expandIcon" />
                                ) : (
                                  <AddIcon className="expandIcon" />
                                )}
                              </span>
                              {singleCate.title}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        {this.state.selected.includes(singleCate._id) ? (
                          <AllProductPaginated
                            loadingProducts={
                              this.state["loadingProducts" + index]
                            }
                            products={this.state["products" + index]}
                            deleteProduct={this.deleteProduct}
                            stateProps={this.state}
                            indexProps={index}
                            singleCate={singleCate}
                            perPage={this.state.perPage}
                            userId={this.state.userId}
                            productStatus={this.props.productStatus}
                            query={this.props.query}
                            page={this.state.page}
                            getMetaData={this.props.getMetaData}
                          />
                        ) : null}
                      </Accordion>
                    ))
                  : null}
              </div>
            )}
          </div>
        )}

        {/* Purchase plan modal */}
        <Modal
          visible={this.state.currentModal}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.setState({ currentModal: false })}
        >
          <div
            className="confirmationModalPublish"
            style={{ textAlign: "center" }}
          >
            <Typography>{this.state.currentStatusMessage}</Typography>
            <Button
              onClick={() =>
                (window.location.href = "/admin/pricing/" + this.state.userId)
              }
              className="DeleteBtnPublish"
              style={{ background: "#bdbdbd" }}
            >
              Click To Purchase Plan
            </Button>
            <Button
              onClick={() =>
                this.setState({ currentModal: false, loading: false })
              }
              className="DeleteBtnPublish"
              style={{ background: "#bdbdbd" }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AllProducts;
