import React, { useEffect, useState } from "react";

import AddCircleOutlineSharpIcon from "@material-ui/icons/AddCircleOutlineSharp";
import { Typography } from "@material-ui/core";
import CreateRecommendDialog from "./CreateRecommendDialog";
import { getRecommendProducts } from "./lib";
import RecommendBox from "./RecommendBox";

const RecommendProduct = ({ productId }) => {
  const [open, setOpen] = useState(false);

  const [recommendProducts, setRecommendProducts] = useState([]);

  const fetchRecommends = () => {
    getRecommendProducts(productId)
      .then(({ recommendProducts }) => setRecommendProducts(recommendProducts))
      .catch(() => {});
  };

  useEffect(() => {
    fetchRecommends();
  }, []);
  useEffect(() => {
    // console.log("recommendProducts ", recommendProducts);
  }, [recommendProducts]);
  return (
    <>
      <MainAddBtn open={open} setOpen={setOpen} />
      {recommendProducts.map((pr) => (
        <RecommendBox
          key={pr._id}
          recommendProduct={pr}
          fetchRecommends={fetchRecommends}
          recommendProducts={recommendProducts}
          productId={productId}
        />
      ))}
      <CreateRecommendDialog
        open={open}
        handleClose={() => setOpen(false)}
        productId={productId}
        previousTitles={recommendProducts.map(({ title }) => title)}
        onSubmitCB={() => {
          fetchRecommends();
        }}
      />
    </>
  );
};

export default RecommendProduct;

const MainAddBtn = ({ open, setOpen, productId }) => {
  // const [open, setOpen] = useState(false);

  const [recommendProducts, setRecommendProducts] = useState([]);

  const fetchRecommends = () => {
    getRecommendProducts(productId)
      .then(({ recommendProducts }) => setRecommendProducts(recommendProducts))
      .catch(() => {});
  };

  useEffect(() => {
    fetchRecommends();
  }, []);

  return (
    <>
      <div
        className="addComp"
        onClick={() => {
          setOpen(true);
          // this.setState({
          //   confirmationComponentAdd: true,
          // })
        }}
        style={{
          cursor: "pointer",
          width: recommendProducts.length === 0 ? "20.3rem" : "100%",
        }}
      >
        <div className="inlineWrapper">
          <Typography
            className="text"
            onClick={() => {
              // this.setState({
              //   confirmationComponentAdd: true,
              // })
            }}
          >
            Add Recommendation
          </Typography>

          <AddCircleOutlineSharpIcon
            style={{
              position: "absolute",
              right: "20px",
              top: "15px",
            }}
          />
        </div>
      </div>
    </>
  );
};
