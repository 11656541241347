import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";

import ScrollDialog from "../ScrollDialog";
import Constants from "../../App/Variables/Constants";
import { getAllMyProducts } from "./lib";
import SearchIcon from "@material-ui/icons/Search";
import CombinationDialog from "./CombinationDialog";
import axios from "axios";

const ProductSearchDialog = ({
  open,
  handleClose: handleCloseSearchBox,
  recommendId,
  selectedCombination,
  combinations = [],
  onSubmitCb,
  productId,
}) => {
  const [products, setProduct] = useState([]);
  const [isMore, setIsMore] = useState(false);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [productTitle, setProductTitle] = useState("");
  useEffect(() => {
    (async () => {
      if (!query?.trim?.()) {
        setProduct([]);
        return;
        // setPage(1);
        // setIsMore(false);
      } else {
        try {
          setIsLoading(true);
          const data = await getAllMyProducts(query + "&nin=" + productId);
          if (!data) return;
          setProduct(data.docs);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [page, query]);
  useEffect(() => {
    // getAllMyProducts();
    // console.log("selectedCombination ", selectedCombination);
  }, [open]);

  const [productSelected, setProductSelected] = useState(
    selectedCombination?.product ?? ""
  );
  useEffect(() => {
    // console.log("productSelected ", productSelected);
  }, [productSelected]);
  useEffect(() => {
    console.clear();
    if (!selectedCombination?.product) return;
    axios
      .get(Constants.getUrls.getProducts + "/" + selectedCombination?.product)
      .then((res) => {
        setProductTitle(res.data.product.title);
      })
      .catch(() => {});
  }, [selectedCombination?.product]);
  const handleClose = () => {
    setProduct([]);
    setQuery("");
    setProductSelected("");
    setPage(1);
    setIsLoading(false);
  };

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={() => {
          handleClose();
          handleCloseSearchBox();
        }}
        title={
          productSelected || selectedCombination?.product
            ? "Select Variants"
            : "Select Product"
        }
      >
        <div
          style={{
            position: "relative",
            minHeight: 700,
            maxHeight: 700,
            minWidth: 700,
            maxWidth: 700,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {productSelected || selectedCombination?.product ? (
            <CombinationDialog
              productTitle={productTitle}
              productId={productSelected || selectedCombination?.product}
              selectedCombination={selectedCombination}
              //
              recommendId={recommendId}
              combinations={combinations}
              //
              goBack={() => setProductSelected("")}
              onSubmitCb={() => {
                onSubmitCb();
                handleClose();
                handleCloseSearchBox();
              }}
              handleClose={() => {
                handleClose();
                handleCloseSearchBox();
              }}
            />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  outline: "none",
                  border: 0,
                  // height: 48,
                  alignSelf: "stretch",
                  boxSizing: "border-box",
                  padding: "10px 20px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    boxShadow: "inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
                    outline: "none",
                    border: 0,
                    height: 50,
                    fontSize: 20,
                    fontWeight: 400,
                    // margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "stretch",
                    // border: "1px solid black",
                    boxSizing: "border-box",
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      width: 48,
                      height: 48,
                      // border: "1px solid red",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <SearchIcon />
                  </div>
                  <input
                    style={{
                      flex: 1,
                      boxSizing: "border-box",
                      height: 44,
                      padding: 0,
                      margin: 0,
                      // alignSelf: "stretch",
                      fontSize: 20,
                      fontWeight: 400,
                      display: "block",
                      width: "100%",
                      // border: "1px solid red",
                      border: 0,
                      outline: "none",
                      paddingRight: 8,
                    }}
                    value={query}
                    type="search"
                    onChange={(e) => {
                      // setPage(1);
                      setQuery(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  // flex: 1,
                  // overflowY: "auto",
                  // paddingRight: 5,
                  // border: "1px solid red",
                  // marginTop: 5,

                  flex: 1,
                  overflowY: "auto",
                  margin: 0,
                  padding: 10,
                  paddingRight: 5,
                  overflowX: "hidden",
                  // border: "1px solid red",
                  marginTop: 5,
                  paddingBottom: 10,
                }}
                onScroll={async (e) => {
                  return;
                  if (!isMore || !query) return;
                  // return;
                  // console.clear();
                  let scrollTop = e.target.scrollTop,
                    offsetHeight = e.target.offsetHeight,
                    scrollHeight = e.target.scrollHeight,
                    isBottom = scrollHeight - (scrollTop + offsetHeight) <= 150;

                  if (isBottom) {
                    setPage((p) => p + 1);
                  }
                }}
              >
                <div
                  style={{
                    zIndex: 10,
                  }}
                >
                  {!products.length && (
                    <Typography
                      variant="body1"
                      style={{
                        textAlign: "center",
                        marginTop: 6,

                        // border: "1px solid red",
                      }}
                    >
                      {isLoading
                        ? "Fetching..."
                        : query
                        ? `No products found for "${query}"!`
                        : "Type to fetch products."}
                    </Typography>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      paddingTop: 10,
                      alignItems: "flex-start",
                    }}
                  >
                    {products.map((product, index) => (
                      <ProductCard
                        index={index}
                        key={product._id}
                        onClick={() => {
                          setProductSelected(product._id);
                          setProductTitle(product.title);
                        }}
                        product={product}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ScrollDialog>
    </>
  );
};
export default ProductSearchDialog;
const ProductCard = ({ product, onClick, index }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      key={product._id}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(!true)}
      style={{
        display: "flex",
        // alignItems: "center",
        // width: "49%",
        width: "100%",
        padding: 5,

        border: "1px solid #ddd",
        borderRadius: "0.3rem",
        // margin: "4",
        // marginLeft: index % 2 != 0 ? "2%" : 0,
        marginBottom: 10,
        // alignSelf: "flex-start",
        border: `1px solid ${isHover ? "rgba(0,0,255,0.6)" : "#ddd"} `,
        transition: "border 200ms",
        cursor: "pointer",
      }}
    >
      <img
        src={Constants.imageUrl + product.poster_image_url}
        width="80"
        height="80"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",

          flexDirection: "column",
          paddingLeft: 10,
          overflow: "hidden",
        }}
      >
        <p
          style={{
            fontSize: 18,
            fontWeight: 800,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: 0,
            margin: 0,
          }}
        >
          {product.title}
        </p>
      </div>
    </div>
  );
};
