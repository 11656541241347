import "@google/model-viewer";
import { Button, Grid, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import axios from "axios";
import QRCode from "qrcode.react";
import qs from "query-string";
import React, { Component, createRef } from "react";
import Modal from "react-awesome-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Lottie from "react-lottie";
import arimage from "../../../Assets/Images/ar_icon.png";
import rotate from "../../../Assets/Images/rotate.png";
import share from "../../../Assets/Images/share.png";
import zoom from "../../../Assets/Images/zoom-in.png";
import loader from "../../../Assets/lottie/3dloader.json";
import Constants from "../../Variables/Constants";
import MobileModelDynamicViewer from "./MobileModelDynamicViewer";
import logo from "../../../Assets/Images/actualityLogo.png";
import remove from "../../../Assets/Images/delete.png";
import arDeviceText from "../../../helpers/arDeviceText";
import AnnotationBtns from "../../../Components/AnnotationBtns";
import HotspotContentDrawer from "../../../Components/HotspotContentDrawer";
const screen = window.screen.width <= 1024 ? "mobile" : "desktop";

export default class ModalDynamic extends Component {
  constructor(props) {
    super(props);
    this.modelRef = createRef();
    this.annotBtnCloseCB = createRef();
    // console.log(this.props);
    this.state = {
      model: {},
      product: {},
      variantIndex: 0,
      revealModel: false,
      mesh: [],
      Components: [],
      frames: [],
      selected_material_id: 0,
      selected_component_id: "",
      showModalBtn: screen === "desktop",
      showVariant: false,
      selectedVariant: {},
      meshes: [],
      Variants: [],
      model_file_url: "",
      ModalImage: "",
      productId: 0,
      variantsCombo: [],
      Component1: [],
      Component2: [],
      visible: false,
      value: "",
      embedCode: "",
      QRCode: "",
      copied: false,
      qrCopy: false,
      embedCopy: false,
      visiblity: true,
      selectedMaterials: [],
      language: "",
      screen: qs.parse(window.location.search).screen
        ? qs.parse(window.location.search).screen
        : screen,
      payId: window.location.pathname.split("/")[4],
      productStatus: "",
      showHotspot: {
        isTrue: false,
        data: {},
      },
    };
  }

  componentDidMount() {
    var payId = window.location.pathname.split("/")[4];
    // console.log(payId);
    axios
      .get(Constants.getUrls.getSingleProduct + this.state.payId)
      .then((resp) => {
        // console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            visiblity: resp.data.product.visibility,
            productStatus: resp.data.product.status,
            language: resp.data.product.language,
          });

          if (this.state.productStatus === "published") {
            this.setState({
              embedCode:
                "<iframe src='" +
                "https://actuality.live/modelDynamicviewer/" +
                this.state.payId +
                "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
              value:
                "https://actuality.live/modelDynamicviewer/" + this.state.payId,
              QRCode:
                "https://actuality.live/modelDynamicviewer/" + this.state.payId,
            });

            this.setModel();
            this.getComponents();
            this.getVariants();
            this.postViews();
          } else {
            // if (init() === "success")
            // {
            this.setState({
              embedCode:
                "<iframe src='" +
                "https://admin.actuality.live/modelDynamicviewer/" +
                this.state.payId +
                "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
              value:
                "https://admin.actuality.live/modelDynamicviewer/" +
                this.state.payId,
              QRCode:
                "https://admin.actuality.live/modelDynamicviewer/" +
                this.state.payId,
            });
            this.setModel();
            this.getComponents();
            this.getVariants();
            this.postViews();
            // }
            // else
            // {
            //   window.location.href = "/pageNotFound"
            // }
          }
        }
      });
  }
  postViews = () => {
    //   // if (init() === "success") {
    //     axios
    //       .post(Constants.postUrls.postViews + "/" + this.state.payId)
    //       .then((res) => {
    //       });
    // const payload = {
    //   prodId : this.state.payId,
    // }
    // axios
    // .put(Constants.postUrls.websiteViews ,payload)
    // .then((res) => {
    // // console.log(res);
    // });
    //   // }
  };
  openModal = () => {
    this.setState({
      visible: true,
    });
  };
  closeModal = () => {
    this.setState({
      visible: false,
    });
  };
  setUpModal = () => {
    var modal = document.getElementById("myModal");
    var modalContent = document.querySelector(".modal-content");

    var btn = document.getElementById("qrtext");

    var span = document.getElementsByClassName("closeBtn")[0];

    if (btn) {
      btn.onclick = function () {
        modal.style.display = "block";
        modalContent.classList.add("slideIn");
      };
    }

    span.onclick = function () {
      modalContent.classList.remove("slideIn");
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modalContent.classList.remove("slideIn");
        modal.style.display = "none";
      }
    };
  };

  setModel = () => {
    axios
      .get(Constants.getUrls.getProducts + "/" + this.state.payId)
      .then((res) => {
        // console.log(res);
        this.setState(
          {
            product: res.data.product,
            revealModel: true,
          },
          () => {
            this.setUpModal();
          }
        );
      });
  };
  getComponents = () => {
    axios
      .get(
        Constants.getUrls.getComponents + this.state.payId + "?page=1&limit=10"
      )
      .then((res) => {
        // console.log(res);
        this.setState(
          {
            Components: res.data.components.docs,
          },
          () => {
            let selectedMaterials = [];
            this.state.Components.forEach((component) => {
              selectedMaterials.push({
                material: component.materials[0]._id,
                component: component._id,
              });
            });
            this.setState({ selectedMaterials });
          }
        );
      });
  };
  getVariants = () => {
    axios
      .get(
        Constants.getUrls.variants +
          "?page=1&limit=10&product=" +
          this.state.payId
      )
      .then((res) => {
        // console.log(res);
        if (res.data.docs.length > 0) {
          this.setState({
            Variants: res.data.docs,
            selectedVariant: res.data.docs[0],
            ModalImage: res.data.docs[0].model_file_url,
          });
        }
      });
  };

  selectMaterial = (matId, compId) => {
    const selectedMaterials = [];
    this.state.selectedMaterials.forEach((item) => {
      selectedMaterials.push({
        material: compId === item.component ? matId : item.material,
        component: item.component,
      });
    });
    this.setState(
      {
        selectedMaterials,
      },
      () => {
        this.applyChanges();
      }
    );
  };

  checkSelected = (matId, comId) => {
    const material = this.state.selectedMaterials.find(
      (item) => item.component === comId && item.material === matId
    );

    return material ? true : false;
  };

  selectVariant = (variant) => {
    this.setState({ selectedVariant: variant });
  };
  applyChanges = () => {
    let filteredVariant = {};
    let totalSelected = this.state.selectedMaterials.length;
    this.state.Variants.forEach((variant) => {
      const materials = variant.materials.filter((material) =>
        this.state.selectedMaterials.some(
          (item) =>
            item.material === material._id &&
            material.component === item.component
        )
      );
      if (materials.length === totalSelected) {
        filteredVariant = variant;
      }
    });
    this.setState({
      selectedVariant: filteredVariant,
      showVariant: true,
    });
  };
  downloadQR = () => {
    const canvas = document.getElementById("QRCode");
    const pngUrl = canvas
      .toDataURL(window.location.href)
      .replace(window.location.href, window.location.href);
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  redirectToUrl = () => {
    if (this.state.product.link_url) {
      window.open(this.state.product.link_url);
    }
  };

  copyUrl = (e) => {
    this.setState({
      copied: true,
    });
    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 2000);
  };

  copyEmbed = (e) => {
    this.setState({
      embedCopy: true,
    });
    setTimeout(() => {
      this.setState({
        embedCopy: false,
      });
    }, 2000);
  };

  render() {
    const model = this.state.model;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div
        className="modelViewer"
        style={{ padding: this.state.screen === "desktop" ? "0 24px" : 0 }}
      >
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {this.state.screen === "desktop" ? (
          <>
            {this.state.revealModel ? (
              <Grid container spacing={4}>
                <HotspotContentDrawer
                  open={!!this.state?.showHotspot?.isTrue}
                  onClose={() => {
                    this.setState({ showHotspot: { isTrue: false, data: {} } });
                    this.annotBtnCloseCB.current();
                  }}
                  title={this.state.showHotspot.data.title}
                  description={this.state.showHotspot.data.description}
                />
                <Grid
                  item
                  sm={9}
                  style={{ height: "100vh", position: "relative" }}
                >
                  <model-viewer
                    ref={(elem) => {
                      this.modelRef.current = elem;
                    }}
                    exposure={model.exposure}
                    scale={1}
                    src={
                      this.state.showVariant
                        ? Constants.imageUrl +
                          this.state.selectedVariant.model_file_url
                        : Constants.imageUrl + this.state.ModalImage
                    }
                    // ios-src={
                    //   this.state.showVariant
                    //     ? Constants.imageUrl + this.state.selectedVariant.model_file_url
                    //     : Constants.imageUrl + this.state.ModalImage
                    // }
                    shadow-intensity={model.shadow_intensity}
                    quick-look-browsers="safari chrome Firefox"
                    camera-orbit="45deg 55deg 2.5m"
                    field-of-view="60deg"
                    min-field-of-view={
                      window.innerWidth < 600 ? "90deg" : "55deg"
                    }
                    max-field-of-view={
                      window.innerWidth < 600 ? "90deg" : "55deg"
                    }
                    camera-controls
                    ar
                    ar-modes="webxr scene-viewer quick-look"
                    ar-placement={"floor"}
                    autoplay
                    style={{
                      background: "#fff",
                      boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                      borderRadius: "15px",
                      height: "90vh",
                      width: "-webkit-fill-available",
                      position: "relative",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <AnnotationBtns
                      annots={this.state?.selectedVariant?.hotspots}
                      openHotspotDrawer={(annot, cb) => {
                        alert("hello");
                        this.annotBtnCloseCB.current = cb;
                        this.modelRef.current.setAttribute(
                          "camera-target",
                          annot.cameraTarget
                        );
                        this.modelRef.current.setAttribute(
                          "camera-orbit",
                          annot.cameraOrbit
                        );
                        this.modelRef.current.setAttribute(
                          "zoom",
                          annot?.zoom || 12
                        );
                        this.setState({
                          showHotspot: {
                            isTrue: true,
                            data: {
                              title: annot.title,
                              description: annot.description,
                            },
                          },
                        });
                      }}
                    />
                    <Typography
                      className="brand"
                      style={{ marginLeft: "20px" }}
                    >
                      {/* <img src={logo} alt="" width="19%" style={{paddingTop:"35px"}}/> */}
                      <div className="modelCompanyLogo">
                        <img
                          src={
                            Constants.imageUrl +
                            this.state.product.user.company_logo
                          }
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </div>
                    </Typography>

                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      slot="poster"
                    >
                      <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                        autoplay
                        loop
                      />
                      <h2 style={{ textAlign: "center" }}>Loading...</h2>
                    </div>
                    <button
                      slot="ar-button"
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: "4px",
                        border: "none",
                        boxShadow:
                          "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                        position: "absolute",
                        top: 12,
                        right: "16px",
                        background: "#fff",
                      }}
                    >
                      <div style={{}}>
                        <img
                          src={arimage}
                          width="20px"
                          alt=""
                          style={{
                            marginRight: "4px",
                            position: "relative",
                            top: 5,
                          }}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            display: "inline-block",
                          }}
                        >
                          {this.state.language === "French"
                            ? "Voir dans votre espace"
                            : "View in your space"}
                        </p>
                      </div>
                    </button>
                    <div className="iconWrapper">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <a
                            href="https://actuality.live"
                            style={{ textDecoration: "none" }}
                          >
                            <h5
                              style={{
                                marginLeft: "15px",
                                color: "#565656",
                                fontWeight: "400",
                                cursor: "pointer",
                                fontSize: "15px",
                              }}
                            >
                              {this.state.language === "French"
                                ? "Alimenté par"
                                : "Powered By"}{" "}
                              By Actuality
                            </h5>
                          </a>
                        </div>
                        {/* <div
                          className="text-center"
                          style={{ marginRight: 12, height: "100%" }}
                        >
                          <img src={rotate} alt="" />
                        </div>
                        <div
                          className="text-center"
                          style={{ marginRight: 4, height: "100%" }}
                        >
                          <img src={zoom} width={"32px"} alt="" />
                        </div> */}
                      </div>
                      <span>
                        <img src={rotate} alt="" />
                        <img src={zoom} width={"32px"} alt="" />
                        <img
                          src={share}
                          className="shareBtn"
                          height={"24px"}
                          alt=""
                          onClick={() => {
                            this.openModal();
                          }}
                        />
                      </span>
                    </div>
                  </model-viewer>
                </Grid>

                <Grid
                  sm={3}
                  style={{ paddingTop: "32px", position: "relative" }}
                >
                  <>
                    <div className="cardFirstPreview">
                      <Typography
                        className="modelTitlePreview"
                        style={{ textTransform: "none" }}
                      >
                        {this.state.product.title}
                      </Typography>
                      {/* <p className="subtitlePreview">Scroll to zoom</p> */}
                      <p className="subtitlePreview">
                        {this.state.product.sub_title === "undefined"
                          ? ""
                          : this.state.product.sub_title}
                      </p>
                      {/* <p className="subtitlePreview">{this.state.product.user.company_name}</p> */}
                    </div>

                    {this.state.visiblity ? (
                      <div
                        className="cardPreview"
                        onClick={() => {
                          this.redirectToUrl();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography
                          gutterBottom
                          className="buyNowPreview"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.redirectToUrl();
                          }}
                        >
                          {this.state.product.link_title
                            ? this.state.product.link_title
                            : "Buy Now"}
                        </Typography>
                        <p
                          className="buy_subPreview"
                          style={{ cursor: "pointer" }}
                        >
                          {this.state.product.link_description
                            ? this.state.product.link_description
                            : "From Someweb.com"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                  <div className="OverFlowComponents">
                    {this.state.Components.map((single, index1) => (
                      <div className="card">
                        <div className="variant_head_wrapper">
                          <Typography gutterBottom className="buyNowPreview">
                            {single.component_name}
                          </Typography>
                        </div>
                        <div className="fixBox">
                          {single.materials[0]._id
                            ? single.materials.map((material, index) => (
                                <div>
                                  {material.material_type === "color" ? (
                                    <>
                                      <div
                                        onClick={() => {
                                          this.selectMaterial(
                                            material._id,
                                            single._id
                                          );
                                        }}
                                        className="paletteBox"
                                        style={{
                                          backgroundColor:
                                            material.material_value,
                                        }}
                                        key={index}
                                      >
                                        {this.checkSelected(
                                          material._id,
                                          single._id
                                        ) ? (
                                          <div className="selected">
                                            <Check style={{ color: "#000" }} />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div
                                        style={{
                                          inlineSize: "58px",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize:
                                              window.innerWidth < 1100
                                                ? "12px"
                                                : "14px",
                                            fontFamily: "Inter",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            // textTransform: "capitalize",
                                            paddingLeft: "0px",
                                            marginTop:
                                              material.material_type === "color"
                                                ? "-2px"
                                                : "-1px",
                                          }}
                                        >
                                          {material.material_name}
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="paletteBox">
                                        <img
                                          onClick={() => {
                                            this.selectMaterial(
                                              material._id,
                                              single._id
                                            );
                                          }}
                                          key={index}
                                          src={
                                            Constants.imageUrl +
                                            material.material_value
                                          }
                                          className="paletteBox"
                                          style={{
                                            marginTop: "0px",
                                            marginLeft: "0px",
                                          }}
                                          alt=""
                                        />
                                        {this.checkSelected(
                                          material._id,
                                          single._id
                                        ) ? (
                                          <div className="selected">
                                            <Check style={{ color: "#000" }} />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div
                                        style={{
                                          inlineSize: "58px",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize:
                                              window.innerWidth < 1100
                                                ? "12px"
                                                : "14px",
                                            fontFamily: "Inter",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            // textTransform: "capitalize",
                                            paddingLeft: "0px",
                                            marginTop:
                                              material.material_type === "color"
                                                ? "-2px"
                                                : "-1px",
                                          }}
                                        >
                                          {material.material_name}
                                        </p>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    ))}
                  </div>

                  {this.state.showModalBtn ? (
                    <div id="qrtext">
                      <img
                        src={arimage}
                        width="24px"
                        alt=""
                        style={{ marginRight: "4px" }}
                      />
                      <p className="view_space_btn">
                        {this.state.language === "French"
                          ? "Voir dans votre espace"
                          : "View in your space"}
                      </p>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </>
        ) : (
          <MobileModelDynamicViewer
            model={this.state.ModalImage}
            product={this.state.product.title}
            Component={this.state.Components}
            selectMaterial={this.selectMaterial}
            checkSelected={this.checkSelected}
            showVariant={this.state.showVariant}
            selectedVariant={this.state.selectedVariant}
            selectVariant={this.selectVariant}
            visibleModal={this.openModal}
            ViewModal={this.setUpModal}
            openLink={this.redirectToUrl}
            UrlTitle={this.state.product.link_title}
            UrlDescription={this.state.product.link_description}
            language={this.state.language}
          />
        )}
        <div id="myModal" className="modal"></div>
        <div className="modal-content">
          <div className="contentArea">
            <h1>
              {this.state.language === "French"
                ? "Comment afficher en réalité augmentée"
                : "How to View in Augmented Reality"}
            </h1>
            <p>
              {this.state.language === "French"
                ? `Scannez ce QR code avec votre téléphone pour visualiser l'objet dans votre espace. L'expérience se lance directement depuis votre navigateur - aucune application requise ! `
                : `Scan this QR code with your phone to view the object in your
              space. The experience launches directly from your browser - no app
              required !`}
            </p>
            <p>
              {arDeviceText(this.state.language)}
              {/* {this.state.language === "French"
                ? `fonctionne mieux avec l'iPhone 12 et supérieur`
                : `*works best with iPhone 12 & above`} */}
            </p>
          </div>
          <div id="qrCodeWrapper">
            <QRCode
              value={
                "https://admin.actuality.live/modelDynamicviewer/" +
                this.state.payId
              }
              style={{ height: 200, width: 200, marginTop: 12 }}
            />
          </div>
          <span className="closeBtn">
            {this.state.language === "French" ? "Proche" : "Close"}
          </span>
        </div>
        <Modal
          visible={this.state.visible}
          width="550"
          height="450"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div className="shareModal web">
            <span
              className="textMain"
              style={{ display: "block", textAlign: "center" }}
            >
              Share
            </span>
            <img
              src={remove}
              width="15px"
              height="15px"
              alt=""
              className="colseModelbtn"
              onClick={() => this.closeModal()}
            />
            {/* <span
              className="textMain"
              style={{ marginLeft: "84px", fontWeight: 400 }}
            >
              For internal testing only
            </span> */}
            <Grid container spacing={2} style={{ position: "relative" }}>
              <Grid item sm={3}>
                {" "}
                <Typography>URL</Typography>{" "}
              </Grid>
              <Grid item sm={7}>
                <input
                  className="input"
                  value={this.state.value}
                  onChange={({ target: { value } }) =>
                    this.setState({ value, copied: false })
                  }
                  style={{
                    width: window.innerWidth < 1100 ? "81%" : "",
                    marginLeft: window.innerWidth < 1100 ? "10px" : "",
                  }}
                />
              </Grid>
              <Grid item sm={2}>
                <CopyToClipboard text={this.state.value} onCopy={this.copyUrl}>
                  <Button className="CopyBtn">Copy</Button>
                </CopyToClipboard>
                {this.state.copied ? (
                  <Typography
                    style={{
                      color: "red",
                      marginTop: window.innerWidth < 1100 ? "" : "40px",
                      marginLeft: window.innerWidth < 1100 ? "" : "22px",
                    }}
                  >
                    Copied.
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ position: "relative" }}>
              <Grid item sm={3}>
                {window.innerWidth < 1100 ? (
                  <Typography>Code</Typography>
                ) : (
                  <Typography>Embed Code</Typography>
                )}
              </Grid>
              <Grid item sm={7}>
                <input
                  type="text"
                  className="input"
                  value={this.state.embedCode}
                  style={{ width: window.innerWidth < 1100 ? "84%" : "" }}
                  onChange={({ target: { embedCode } }) =>
                    this.setState({ embedCopy: false })
                  }
                />
              </Grid>
              <Grid item sm={2}>
                <CopyToClipboard
                  text={this.state.embedCode}
                  onCopy={this.embedCopy}
                >
                  <Button
                    className="CopyBtn"
                    style={{
                      marginLeft: window.innerWidth < 1100 ? "-45px" : "",
                    }}
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
                {this.state.embedCopy ? (
                  <Typography
                    style={{
                      color: "red",
                      marginTop: window.innerWidth < 1100 ? "" : "40px",
                      marginLeft: window.innerWidth < 1100 ? "" : "22px",
                    }}
                  >
                    Copied.
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ position: "relative" }}>
              <Grid item sm={3}>
                {" "}
                <Typography>QR code</Typography>{" "}
              </Grid>
              <Grid item sm={4}>
                <QRCode
                  id="QRCode"
                  value={
                    "https://admin.actuality.live/modelDynamicviewer/" +
                    this.state.payId
                  }
                  style={{ height: 150, width: 150, marginTop: 12 }}
                />
              </Grid>
              <Grid item sm={5}>
                <Button
                  className="CopyBtn"
                  onClick={this.downloadQR}
                  style={{
                    marginTop: window.innerWidth < 1100 ? "122px" : "120px",
                    marginLeft: window.innerWidth < 1100 ? "78px" : "",
                    position:
                      window.innerWidth < 1100 ? "absolute" : "relative",
                  }}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </div>
    );
  }
}
