import { Button, Tooltip, Typography } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import axios from "axios";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import Constants from "../../Variables/Constants";
import analyticsImg from "../../../Assets/Images/data-analytics 1.png";
import eyes from "../../../Assets/Images/eyes.png";
import { Link } from "react-router-dom";
import CloneButton from "../../../Components/CloneButton";
import { canPublishProduct } from "../../../helpers/utils";

export default class ProductCard extends Component {
  state = {
    product0: [],
    some: "",
    Variants: [],
    productId: 0,
    query: "",
    userId: window.location.pathname.split("/")[3],
  };
  componentDidMount() {
    // console.log(this.props);
    this.getVariants();
    // axios.get(Constants.getUrls.getProducts).then((resp) => {
    // // console.log(resp);
    // })
  }
  getVariants = () => {
    axios
      .get(
        Constants.getUrls.variants + "?page=1&limit=10&product=" + this.props.id
      )
      .then((res) => {
        this.setState({
          Variants: res.data.docs,
        });
      });
  };
  openeditLink = async () => {
    console.clear();
    // console.log("this   ", this.props.id);
    const canPublish = await canPublishProduct(this.props.id);
    // console.log("canPublish ", canPublish);
    // return;
    window.open(
      canPublish
        ? `/admin/viewer/${this.state.userId}/${this.props.id}`
        : `/admin/product-edit/${this.state.userId}/${this.props.id}`,
      "_blank"
    );

    return;
    let URL = "";
    if (this.state.Variants.length > 0) URL = "/admin/viewer/";
    else URL = "/admin/product-edit/";

    URL = `${URL}${this.state.userId}/${this.props.id}`;
    window.open(URL, "_blank");
  };
  deleteProduct = (pId) => {
    // this.props.onDelete(this.props.catId);
    // return;
    // if (init() === "success") {
    axios.delete(Constants.postUrls.addproduct + "/" + pId).then((res) => {
      if (res.data.status === "success") {
        this.setState({
          notification: true,
          successMsg: res.data.message,
        });
        this.props.getMetaData();
        setTimeout(() => {
          this.setState({
            notification: false,
            openModal: false,
          });
          this.props.onDelete(this.props.catId, this.props.CardIndex);
        }, 3000);
      } else {
        this.setState({
          notification: true,
          successMsg: res.data.message,
        });
        setTimeout(() => {
          this.setState({
            notification: false,
            openModal: false,
          });
        }, 2000);
      }
    });
    // }
  };

  fetchModels = () => {
    if (this.props.catId) {
      // if (init() === "success") {
      axios
        .get(
          Constants.getUrls.getProducts +
            "?page=" +
            this.props.page +
            "&limit=" +
            this.props.limit +
            "&catId=" +
            this.props.catId +
            "&q=" +
            this.props.query +
            "&status=" +
            this.props.status
        )
        .then((res) => {
          this.setState({
            loadingProducts: false,

            ["products" + this.props.CardIndex]: res.data.docs,
            totalpage: res.data.totalPages,
            hasNextPage: res.data.hasNextPage === false ? false : true,
          });
        });
      // }
    }
  };
  redirectToAnalytics = () => {
    window.location.href = `/admin/productsanalytics/${this.state.userId}/${this.props.id}`;
  };
  postDuplicateProduct = () => {
    this.props.onDelete(this.props.catId, this.props.CardIndex);
    this.props.getMetaData();
  };

  openPreview = () => {
    let notPublished = this.props.publishStatus == "draft";
    let URL = `${
      notPublished ? "/admin" : "https://actuality.live"
    }/modelDynamicviewer/`;
    if (notPublished) URL = URL + `${this.props.userId}/`;
    URL = URL + `${this.props.id}`;
    window.open(URL, "_blank");
  };
  openAnalytics = () => {
    let URL = `/admin/productsanalytics/${this.state.userId}/${this.props.id}`;
    window.open(URL, "_blank");
  };
  render() {
    const props = this.props;
    return (
      <div
        className="productCard"
        style={{
          position: "relative",

          maxWidth: 192,
          margin: "auto",
          marginBottom: 20,
          overflow: "hidden",
          // border: "1px solid black",

          height: "auto",
          // padding: 5,
        }}
      >
        <div className="imgBackground" onClick={() => this.openeditLink()}>
          <div
            className="imgWrapper"
            style={{
              width: 192,
              height: 192,
              position: "relative",
              overflow: "hidden",

              // border: "1px solid red",
            }}
          >
            <CloneButton
              productId={this.props.id}
              openeditLink={this.openeditLink}
              postDuplicateProduct={this.postDuplicateProduct}
              openPreview={this.openPreview}
              deleteProduct={() => {
                this.setState({
                  openModal: true,
                  productId: props.id,
                });
              }}
              isPublished={props.publishStatus === "published"}
              openAnalytics={this.openAnalytics}
            />
            <img
              src={props.imgSrc}
              alt={props.title}
              style={{
                width: "100%",
                height: "100%",
                zIndex: 10,
              }}
            />
          </div>
        </div>

        <div
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 0,
            marginTop: 10,
            // border: "1px solid blue",
          }}
        >
          {/* Product Title Goes Here */}
          <p
            style={{
              fontSize: 18,
              fontWeight: 600,
              textAlign: "left",
              margin: 0,
              padding: 0,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={props.title}
          >
            {props.title}
          </p>

          <DeleteOutlineIcon
            className="deleteIcon"
            onClick={() => {
              this.setState({
                openModal: true,
                productId: props.id,
              });
            }}
          />
        </div>
        <p
          style={{
            fontSize: 14,
            lineHeight: "normal",
            fontWeight: 500,

            padding: 0,
            textAlign: "left",
            color: "#999999",
            // border: "1px solid blue",
            // margin: "4px 0",
            margin: 0,
            marginTop: 6,
          }}
        >
          Views :
          {props.views ? (props.views > 1 ? props.views - 1 : props.views) : 0}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: 0,
            // position: "static",
            // top: "auto",
            // left: "auto",
            // border: "1px solid aqua",
            // marginTop: 7,
          }}
        >
          {" "}
          <p
            style={{
              fontSize: 14,
              fontWeight: 500,
              margin: 0,
              padding: 0,
              textAlign: "left",
              color: "#999999",
              flex: 1,
            }}
          >
            {props.publishStatus === "published"
              ? "Published"
              : "Not Published"}
          </p>
          {props?.publishStatus != "published" ? null : (
            <Tooltip title="Preview">
              <div
                style={{
                  marginLeft: 8,
                  padding: 5,
                  background: "#e3e3e3",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "4px",
                  // border: "1px solid red",
                }}
                onClick={() => this.openPreview()}
              >
                <img
                  src={eyes}
                  alt="img"
                  style={{
                    width: 15,
                    height: 15,
                    position: "static",
                    top: "auto",
                    left: "auto",

                    // border: "1px solid red",
                    transform: "none",
                  }}
                />
              </div>
            </Tooltip>
          )}{" "}
          <Tooltip title="Analytics">
            <div
              style={{
                marginLeft: 8,
                padding: 5,
                background: "#e3e3e3",
                display: "grid",
                placeItems: "center",
                borderRadius: "4px",
                // border: "1px solid blue",
              }}
              onClick={() => this.openAnalytics()}
            >
              <img
                src={analyticsImg}
                alt="img"
                style={{
                  width: 15,
                  height: 15,
                  position: "static",
                  top: "auto",
                  left: "auto",
                  // border: "1px solid red",
                  transform: "none",
                }}
              />
            </div>
          </Tooltip>
        </div>
        {/* <div
          className="imgAnalyticsIcon"
          style={{ left: "69%" }}
          onClick={() => this.openPreview()}
        >
          <img src={eyes} alt="img" style={{ width: 15, height: 15 }} />
        </div> */}

        {/* <div onClick={() => this.openAnalytics()} className="imgAnalyticsIcon">
          <img src={analyticsImg} alt="img" style={{ width: 15, height: 15 }} />
        </div> */}
        {/* {props.views ? (
          <Typography
            className=" modelviews"
            style={{ paddingLeft: "0px", marginTop: "-26px" }}
          >
            Views : {props.views}
          </Typography>
        ) : (
          <Typography
            className=" modelviews"
            style={{ paddingLeft: "0px", marginTop: "-26px" }}
          >
            Views : 0
          </Typography>
        )} */}
        {/* <br /> */}
        {/* {props.publishStatus === "published" ? (
          <Typography
            className="published"
            style={{ marginTop: "-20px", paddingLeft: 0, fontSize: "14px" }}
          >
            Published
          </Typography>
        ) : (
          <Typography
            className="published"
            style={{ marginTop: "-20px", paddingLeft: 0, fontSize: "14px" }}
          >
            Not Published
          </Typography>
        )} */}

        <Modal
          visible={this.state.openModal}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.setState({ openModal: false })}
        >
          <div className="confirmationModal" style={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to delete this product ?
            </Typography>
            <Button
              onClick={() => {
                this.deleteProduct(this.state.productId);
              }}
              className="DeleteBtn"
            >
              Delete
            </Button>
            <Button
              onClick={() => this.setState({ openModal: false })}
              className="DeleteBtn"
              style={{ background: "#bdbdbd" }}
            >
              Cancel
            </Button>
            <p style={{ color: "green", textTransform: "capitalize" }}>
              {this.state.notification ? this.state.successMsg : ""}
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}
