import React, { Component } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AccountBalanceWalletTwoToneIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";

export default class CardBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboard: true,
    };
  }
  render() {
    return (
      <>
        <Grid item lg={3}>
          <Paper elevation={3}>
            <Link
              to={{ pathname: this.props.path, query: this.props.status }}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <Box
                style={{
                  backgroundColor: "#ECF0F1",
                  color: "black",
                  maxHeight: "90px",
                  borderRadius: "5px",
                  width: "100%",
                  padding: "20px 11px",
                  marginBottom: 10,
                }}
              >
                <div
                  className="icons"
                  style={{
                    backgroundColor: this.props.color,
                    boxShadow: `0px 10px 10px -7px rgba(${this.props.shadow},1)`,
                    width: "60px",
                    height: "60px",
                    padding: 10,
                    borderRadius: 4,
                    marginTop: -35,
                    marginLeft: 4,
                  }}
                >
                  {this.props.icon}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    align="left"
                    style={{
                      fontSize: "18px",
                      color: "#111",
                      fontWeight: "bolder",
                      paddingTop: 12,
                    }}
                  >
                    {this.props.name}
                  </Typography>
                  <Typography
                    align="right"
                    style={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      paddingTop: -8,
                    }}
                  >
                    {this.props.value}
                  </Typography>
                </div>
              </Box>
            </Link>
          </Paper>
        </Grid>
      </>
    );
  }
}
