import React, { useEffect, useState } from 'react';
// import { API } from '../../Constants/APIs';
import { NavLink } from 'react-router-dom';
import './style.css';
import { copyDataToClipboard } from '../../helpers/utils';
import copyIcon from '../../Assets/newIcons/copyIcon.png';
import { IconButton, Snackbar } from '@material-ui/core';
import { BiLinkExternal } from 'react-icons/bi';
import Constants from '../../App/Variables/Constants';
import axios from 'axios';

const ShareUser = ({ is_share_some, btnStyle, user_id }) => {
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(user_id);
  const [company_name, setCompany_name] = useState('');
  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    if (!userId) return;
    copyDataToClipboard(
      `https://actuality.live/products/${company_name}/${userId}`
    );
    setOpen(true);
  };

  const checkUser = () => {
    axios
      .get(Constants.getUrls.getUserDetails + user_id)
      .then(({ data }) => {
        const __data = data.data
        setUserId(__data?.user?._id ?? '');
        setCompany_name(
          __data?.user?.company_name?.toLowerCase?.()?.split?.(' ')?.join('-') ??
          ''
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };
  useEffect(() => {
    checkUser();
  }, []);
  if (is_share_some) {
    return (
      <NavLink
        to={`/admin/sharelist/${user_id}`}
        style={{
          textDecoration: 'none',
        }}
      >
        <button
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className='shareUser'
        >
          <span
            style={{
              marginRight: 10,
            }}
          >
            Share Products
          </span>
        </button>
      </NavLink>
    );
  }
  return (
    <>
      <button style={btnStyle} onClick={handleOpen}>
        <span>All Product URL</span>{' '}
        <img src={copyIcon} width='30' height='30' style={{ marginLeft: 10 }} />{' '}
      </button>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={`URL actuality.live/products/${company_name}/${userId} copied`}
        action={
          <React.Fragment>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={() => {
                window.open(
                  `https://actuality.live/products/${company_name}/${userId}`,
                  '_blank'
                );
              }}
            >
              <BiLinkExternal fontSize='small' />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

export default ShareUser;
