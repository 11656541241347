import {
  Backdrop,
  Button,
  Container,
  Fade,
  FormControl,
  Grid,
  Modal,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import React, { Component } from "react";
import Constants from "../Variables/Constants";
import init from "../../helpers/WindowToken";

const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));
function createData(name, calories, fat) {
  return { name, calories, fat };
}
const rowsPerPage = 5;
const page = 1;

export default class Products extends Component {
  state = {
    products: [],
  };
  componentDidMount() {
    this.getProducts();
  }

  getProducts = () => {
    axios
      .get(
        //    "https://coretest.feelxr.co:3000/api/services?page=1&limit=10"
        Constants.getUrls.Products
      )
      .then((resp) => {
        // console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.pageCount; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            products: resp.data.docs,
            total: resp.data.itemCount,
            from: 1,
            // to: resp.data.data.length,
          },
          () => {
            // console.log(this.state.getServices);
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  deleteProduct = (id) => {
    if (init() == "success") {
      this.setState({ delLoading: true });
      axios.delete(Constants.getUrls.Products + "/" + id).then((res) => {
        // console.log(res);
        // this.setState({
        //   notifyDelete: true,
        //   delLoading: false,
        // });
        setTimeout(() => {
          this.setState({ notifyDelete: false, visible: false }, () => {
            this.getProducts();
          });
        }, 2000);
      });
    }
  };
  handleStatus = (e, status, id) => {
    if (init() === "success") {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          const payload = {
            status: status,
          };
          // console.log(payload);
          axios
            .post(Constants.statusUrl + id + "?type=products", payload)
            .then((res) => {
              // console.log(res);
              this.setState({
                notifyStatus: true,
              });
              setTimeout(() => {
                this.setState({ notifyStatus: false }, () => {
                  this.getProducts();
                });
              }, 1000);
            });
        }
      );
    }
  };
  render() {
    return (
      <Container maxWidth="xl">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          color={"s"}
          autoHideDuration={6000}
          message="I love snacks"
        ></Snackbar>
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6">{this.props.title}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-end">
              <FormControl style={{ marginRight: 8 }}>
                <TextField
                  size="small"
                  placeholder={"Search " + this.props.title}
                  id="input-with-icon-adornment"
                />
              </FormControl>
              <Link
                to="/admin/addproduct"
                // size="small"
                // onClick={() => {
                //   this.setState({ open: true });
                // }}
                // variant="contained"
                // color="primary"
              >
                Add Product
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>

                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {(rowsPerPage > 0
                        ? rows.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          ) */}
                  {this.state.products.map((product) => (
                    <TableRow key={product.title}>
                      <TableCell component="th" scope="row">
                        {product.title}
                      </TableCell>
                      {/* <TableCell align="right">{row.calories}</TableCell>
                          <TableCell align="right">{row.fat}</TableCell>
                          <TableCell align="right">{row.calories}</TableCell> */}

                      <TableCell align="right">
                        <Switch
                          color="primary"
                          name="checkedB"
                          value={product.status}
                          onChange={(e) =>
                            this.handleStatus(e, product.status, product._id)
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={{
                            pathname: "/admin/updateproduct/" + product._id,
                            state: { data: product },
                          }}
                        >
                          <Button
                            variant={"outlined"}
                            color="default"
                            style={{ marginRight: 4 }}
                            size={"small"}
                          >
                            Update
                          </Button>
                        </Link>
                        <Button
                          size={"small"}
                          variant={"outlined"}
                          color="secondary"
                          onClick={() => {
                            this.deleteProduct(product._id);
                          }}
                        >
                          Delete
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/variants/" + product._id,
                            // state: { data: product },
                          }}
                        >
                          <Button
                            size={"small"}
                            variant={"outlined"}
                            color="secondary"
                          >
                            Variant
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={6}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Fade in={this.state.open}>
            <div
              style={{
                background: "#fff",
                width: "50%",
                padding: "32px",
              }}
            >
              <h2 id="transition-modal-title">Transition modal</h2>
              <p id="transition-modal-description">
                react-transition-group animates me.
              </p>
            </div>
          </Fade>
        </Modal>
      </Container>
    );
  }
}
