import React, { forwardRef, useEffect } from "react";
import useBodyScrollLock from "../../helpers/useBodyScrollLock";

import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
const TSEDialog = forwardRef(
  (
    {
      open,
      children,
      maxWidth = "85vw",
      minWidth = "auto",
      maxHeight = "85vh",
      minHeight = "auto",
      onClickOverlay,
      handleClose,
    },
    shareButtonRef
  ) => {
    const { lockScroll, unLockScroll } = useBodyScrollLock();

    useEffect(() => {
      if (open) {
        lockScroll();
      } else {
        unLockScroll();
      }
    }, [open]);
    return (
      <div
        className="__TSE_DIALOG"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0,0,0,0.7)",
          display: "grid",
          placeItems: "center",
          zIndex: 99999999,
          transform: `scale(${open ? 1 : 0})`,
          transition: "all 300ms",
          boxSizing: "border-box",
          cursor: "pointer",
          transition: "all 300ms",
        }}
        onClick={() => onClickOverlay && onClickOverlay?.()}
      ><div
        style={{
          cursor: "auto",
          maxWidth: maxWidth,
          minWidth: minWidth,
          maxHeight: maxHeight,
          minHeight: minHeight,
          background: "white",
          zIndex: 99999999,
          overflow: "hidden",
          borderRadius: 10,
          boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
          transition: "all 300ms",
          position: 'relative'
        }}
      >
          <div
            onClick={(e) => {
              // console.clear();
              e.stopPropagation();
            }}
            style={{
              cursor: "auto",
              maxWidth: maxWidth,
              minWidth: minWidth,
              maxHeight: maxHeight,
              minHeight: minHeight,
              background: "white",
              zIndex: 99999999,
              overflowX: "hidden",
              overflowY: "auto",
              borderRadius: 10,
              boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
              transition: "all 300ms",
            }}
          >
            {" "}
            {!handleClose ? null : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  // padding: 15,
                  position: 'absolute',
                  right: 8,
                  top: 8
                }}
              >
                <IconButton
                  size="larger"
                  onClick={() => {
                    handleClose?.();
                  }}
                >
                  <HighlightOffIcon fontSize="large" />
                </IconButton>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default TSEDialog;
