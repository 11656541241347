import { IconButton } from "@material-ui/core";
import React from "react";

import actualityLogoMobile from "../../../../Assets/newIcons/actualityLogoMobile.png";
import leftClick from "../../../../Assets/newIcons/leftClick.png";
import rightClick from "../../../../Assets/newIcons/rightClick.png";
import CloseIcon from "@material-ui/icons/Close";

const HotspotAddingInstructions = ({
  openHotspotInstruction,
  toggleHotspotAddingInstruction,
  closeHotspotInstructions,
}) => {
  return (
    <div
      className={
        openHotspotInstruction
          ? "openHotspotInstruction show"
          : "openHotspotInstruction"
      }
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        if (openHotspotInstruction) {
          // alert("outermost");
          closeHotspotInstructions(true);
        }
      }}
    >
      <div
        onClick={(e) => {
          e?.stopPropagation?.();
          e?.preventDefault?.();
          // alert("box");
        }}
        className={
          openHotspotInstruction
            ? "hotspotInstructions show"
            : "hotspotInstructions"
        }
        style={{
          // maxWidth: 600,
          // width: 600,
          boxShadow: "1px 2px 5px rgba(0,0,0,0.1)",
          borderRadius: 18,
          background: "#fff",
          border: "1px solid #ddd",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1.6rem 5rem",
          marginBottom: "1.5rem",
        }}
      >
        {" "}
        <div
          style={{
            width: "100%",
            padding: 10,
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 0,
            zIndex: 2147483645,
          }}
        >
          <div
            style={{
              border: "1px solid #ddd",
              borderRadius: "50%",
              transform: "translateX(100%)",
            }}
          >
            <IconButton
              style={{
                zIndex: 999999,
                border: "0px solid red",
              }}
              onClick={() => {
                // alert("cencel");
                closeHotspotInstructions(true);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div
          style={{
            fontSize: 32,
            fontWeight: 500,
            color: "#555555",
            marginBottom: "1.5rem",
          }}
        >
          How to add hotspots
        </div>
        <div
          style={{
            display: "flex",
            margin: "2rem 0",
            border: "0px solid red",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "1rem",
              border: "0px solid green",
            }}
          >
            <img src={leftClick} width={100} height={110} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1.5rem",
                border: "0px solid aqua",
              }}
            >
              <div style={{ fontSize: "1.2rem", color: "#555555" }}>
                Rotate the object 360
                {/* <sup> */}
                <span>&#176;</span>
                {` `}
                {/* </sup> */}
                by
              </div>
              <div style={{ fontSize: "1.2rem", color: "#555555" }}>
                holding click and dragging
              </div>
              <div style={{ fontSize: "1.2rem", color: "#555555" }}>
                your mouse
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "1rem",
              alignItems: "center",
              border: "0px solid green",
            }}
          >
            <img src={rightClick} width={100} height={110} />
            <div
              style={{
                width: "235px",
                marginTop: "1.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "0px solid aqua",
              }}
            >
              <div style={{ fontSize: "1.2rem", color: "#555555" }}>
                Right click at any point on
              </div>
              <div style={{ fontSize: "1.2rem", color: "#555555" }}>
                the 3D model to add
              </div>
              <div style={{ fontSize: "1.2rem", color: "#555555" }}>
                hotspot
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => toggleHotspotAddingInstruction(true)}
          className="btnCommonStyles"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem 6rem",
            lineHeight: 1,
            fontSize: 20,
            borderRadius: "0.6rem",
            marginBottom: "2rem",
            marginTop: "2rem",
            fontWeight: 600,
            color: "#000",
            // margin: 0,
          }}
        >
          Got It
        </button>
        <img src={actualityLogoMobile} height={16} />
      </div>
    </div>
  );
};

export default HotspotAddingInstructions;
