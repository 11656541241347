import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  Snackbar,
  CircularProgress,
  Switch,
  Radio,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import Modal from "react-awesome-modal";
import { MultiSelect } from "react-multi-select-component";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Constants from "../../Variables/Constants";
import FileResizer from "react-image-file-resizer";
import Passcode from "../../../Components/Passcode";
import PlacementRadio from "../../../Components/PlacementRadio";
import queryString from "query-string";

const query_params = queryString.parse(window.location.search);
console.log(query_params);

function gcd(a, b) {
  // return (b == 0) ? a : gcd (b, a%b);
  return 100 * Math.abs((a - b) / ((a + b) / 2));
}

function calculateRatio(num_1, num_2) {
  let num = 0;
  for (num = num_2; num > 1; num--) {
    if (num_1 % num == 0 && num_2 % num == 0) {
      num_1 = num_1 / num;
      num_2 = num_2 / num;
    }
  }
  var ratio = num_1 + ":" + num_2;
  return ratio;
}

const resizeFile = (file) =>
  new Promise((resolve) => {
    // console.log(file);
    FileResizer.imageFileResizer(
      file,
      800,
      600,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export default class ProductCreate extends Component {
  state = {
    urlId: "",
    searching: false,
    categories: [],
    catIds: [],
    image: "",
    title: "",
    productId: 0,
    filename: "",
    data: {},
    productadded: false,
    imgWidth: 0,
    imgHeight: 0,
    link_url: "",
    link_title: "",
    link_description: "",
    uploadJPG: false,
    visibleCrop: false,
    src: null,

    crop: {
      aspect: 1,
      width: 100,
      // width: 80,
      unit: "%",
    },
    imgPreview: false,
    uploadModel: false,
    userId: window.location.pathname.split("/")[3],
    language: "English",
    placement: "floor",
    embedded_product_url: "",
  };
  componentDidMount() {
    // if (init() === "success") {
    axios
      .get(Constants.getUrls.Categories + "?page=1&limit=10&status=active")
      .then((res) => {
        // console.log(res);
        res.data.categories.docs.forEach((item) => {
          this.state.categories.push({
            label: item.title,
            value: item._id,
          });
        });
        this.setState(
          {
            categories: this.state.categories,
          },
          () => {
            if (
              query_params.suggestion_model === "true" &&
              query_params.model
            ) {
              this.fetchSingleSuggModel();
            }
            // console.log(this.state.categories);
          }
        );
      });
    // }
  }

  fetchSingleSuggModel = () => {
    axios
      .get(Constants.getUrls.getSuggestionModels + "/" + query_params.model)
      .then((res) => {
        const model = res.data.model;
        const catIds = model.categories.map((cat) => ({
          value: cat._id,
          label: cat.title,
        }));
        const glb_file_name =
          model.model_file_url.split("/")[
            model.model_file_url.split("/").length - 1
          ];
        console.log(glb_file_name);
        this.setState({
          title: model.title,
          catIds,
          imgPreview: true,
          croppedImageUrl: Constants.imageUrl + model.poster_image_url,
          glb_file: { name: glb_file_name },
        });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };

  update = (e) => {
    e.preventDefault();
    if (this.state.catIds.length === 0) {
      this.setState({ warn1: true });
    } else {
      this.setState({ loading: true });
      let data = new FormData();
      data.append("title", this.state.title);
      if (this.state.filename) {
        data.append("posterImage", this.state.filename);
      }
      if (this.state.glb_file.type) {
        data.append("model_file", this.state.glb_file);
      }
      this.state.catIds.forEach((cat) => {
        data.append("categories[]", cat.value);
      });

      axios
        .put(
          Constants.getUrls.getSuggestionModels + "/" + query_params.model,
          data
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            window.location.href = "/suggestionmodels";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //
  createProduct = (e) => {
    e.preventDefault();
    // console.log(this.state.filename);
    if (this.state.filename === "") {
      this.setState({
        warning: true,
      });
    } else {
      if (this.state.catIds.length === 0) {
        this.setState({ warn1: true });
      } else {
        this.setState({ loading: true });
        let data = new FormData();
        data.append("title", this.state.title);
        console.log("first");
        data.append("embedded_product_url", this.state.embedded_product_url);
        data.append("placement", this.state.placement);

        //
        if (this.state.is_protected && !this.state?.passcode?.trim()?.length) {
          this.setState({
            warning: true,
          });
          return;
        }

        data.append("is_protected", !!this.state.is_protected);

        data.append(
          "passcode",
          this.state.is_protected ? this.state.passcode : ""
        );

        if (this.state.filename) {
          data.append("posterImage", this.state.filename);
        }
        this.state.catIds.forEach((cat) => {
          data.append("categories[]", cat.value);
        });
        data.append("language", this.state.language);
        data.append("userId", this.state.userId);
        data.append("flag", "admin");
        data.forEach((val, key) => {
          // console.log(`data[${key}] => `, val);
        });
        if (query_params.suggestion_model) {
          data.append("model_file", this.state.glb_file);
        }
        const url = query_params.suggestion_model
          ? Constants.getUrls.getSuggestionModels
          : Constants.postUrls.addUserproduct;
        // return;
        // if (init() === "success") {
        axios
          .post(url, data)
          .then((resp) => {
            console.log(resp.data.status);
            if (resp.data.status === "success") {
              this.setState({
                // uploadModel: true,
                notification: true,
                loading: true,
                successMsg: resp.data.message,
                productId: resp.data?.product?._id,
                data: resp.data?.product,
                productadded: true,
              });
              setTimeout(() => {
                this.setState({ notification: false });
                // document.getElementById("ClearForm").reset();
                // document.ClearForm.reset();
                window.location.href = query_params.suggestion_model
                  ? "/suggestionmodels"
                  : "/admin/product-edit/" +
                    this.state.userId +
                    "/" +
                    resp.data.product._id;
              }, 2000);
            } else {
              this.setState({
                notification: true,
                successMsg: resp.data.message,
                loading: false,
              });
              setTimeout(() => {
                this.setState({ notification: false });
              }, 2000);
            }
          })
          .catch((err) => {
            if (err) {
              console.log("error from product create ", err);
              this.setState({
                notification: true,
                successMsg: "Server is busy, Please try again!",
                loading: false,
              });
              setTimeout(() => {
                this.setState({ notification: false });
              }, 2000);
            }
          });

        // }
      }
    }
  };

  handleFile = (e) => {
    const target = e.target;
    if (!target.files.length) return;
    // console.log(e.target.files[0]);
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      var fileUpload = document.getElementById("uploadFile");

      var regex = /(\.jpg|\.jpeg|\.png|\.PNG)$/i;
      if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof fileUpload.files != "undefined") {
          var reader = new FileReader();
          var scope = this;
          reader.readAsDataURL(fileUpload.files[0]);
          reader.onload = function (e) {
            var image = new Image();

            image.src = e.target.result;

            image.onload = function () {
              var height = this.height;
              var width = this.width;
              scope.setState({
                visibleCrop: true,
              });

              // if (height > 600 || width > 800) {
              //   alert("Height and Width must not exceed 800px and 600px.");
              //   return false;
              // }
              scope.setState({ uploadJPG: true });
              return true;
            };
          };
        } else {
          alert("This browser does not support HTML5.");
          return false;
        }
      } else {
        var thisvar = this;
        alert("Please upload file having extensions .jpeg/.jpg/.png/ only.");
        thisvar.setState({ uploadJPG: false });
        return false;
      }
    });
  };
  handleGLBFile = (e) => {
    console.log(e.target.files);
    this.setState(
      {
        [e.target.name]: e.target.files[0],
      },
      () => {
        console.log(this.state.glb_file);
      }
    );
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
      this.convertUrlToImageData(croppedImageUrl);
      // console.log(this.imageRef);
      // console.log(crop);
      // console.log(croppedImageUrl);
      // this.blobToFile(croppedImageUrl, "my-image.png");
    }
  }

  getBlobFromUrl = (myImageUrl) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open("GET", myImageUrl, true);
      request.responseType = "blob";
      request.onload = () => {
        resolve(request.response);
      };
      request.onerror = reject;
      request.send();
    });
  };

  getDataFromBlob = (myBlob) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(myBlob);
    });
  };

  convertUrlToImageData = async (myImageUrl) => {
    try {
      let myBlob = await this.getBlobFromUrl(myImageUrl);
      // console.log(myBlob)
      let myImageData = await this.getDataFromBlob(myBlob);
      // console.log(myImageData)
      this.b64toBlob(myImageData);
      this.dataURLtoFile(myImageData, "newfile.png");
    } catch (err) {
      // console.log(err);
      return null;
    }
  };

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var blob = new File([u8arr], filename, { type: mime });
    // console.log(blob);
    this.setState({
      filename: blob,
    });
  }

  //Usage example:
  // var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt');
  // console.log(file);

  blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    // console.log(theBlob);
    // this.convertedFile(theBlob)
  }

  convertedFile(theBlob) {
    var myBlob = new Blob();

    //do stuff here to give the blob some data...

    var myFile = this.blobToFile(theBlob, "my-image.png");
    // console.log(myFile);
  }

  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: "image/jpeg" });
    // console.log(blob);
    this.blobToFile(blob, "my-image.png");
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  }

  onSelectFile = (e) => {
    const target = e.target;
    if (!target?.files?.length) return;
    // console.log(this.state.visibleCrop);
    // console.log(e.target.files);
    // console.log(e.target.files.length);
    if (target.files && target.files.length > 0) {
      this.setState(
        {
          visibleCrop: true,
        },
        () => {
          // console.log(this.state.visibleCrop);
        }
      );
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      // console.log(this.state.src);
      reader.readAsDataURL(target.files[0]);
    }
  };

  handleInput = (e) => {
    // console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <div className="productUpload">
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={true}
            style={{ bottom: "40px", textTransform: "capitalize" }}
            color={"#bdbdbd"}
            autoHideDuration={6000}
            message={this.state.successMsg}
          ></Snackbar>
        ) : null}
        <Grid
          container
          spacing={2}
          className="PreeditWrapper"
          style={{ marginTop: "25px" }}
        >
          {this.state.uploadModel ? (
            <>
              <Grid item sm={3}></Grid>
              <Grid item sm={6} style={{ padding: "50px" }}>
                <Typography className="addIcon">+</Typography>
                {this.state.productadded ? (
                  <Link
                    to={{
                      pathname:
                        "/admin/product-edit" +
                        "/" +
                        this.state.userId +
                        this.state.productId,
                    }}
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Button
                      onClick={() => {
                        this.go_ToEdit();
                      }}
                      className="uploadBtn"
                      disabled={this.state.productadded ? true : false}
                      style={{
                        cursor: this.state.productadded
                          ? "pointer"
                          : "not-allowed",
                      }}
                    >
                      Upload 3D files
                    </Button>
                  </Link>
                ) : (
                  <Button
                    className="uploadBtn"
                    disabled={this.state.productadded ? true : false}
                    style={{
                      cursor: this.state.productadded
                        ? "pointer"
                        : "not-allowed",
                    }}
                  >
                    Upload 3D files
                  </Button>
                )}

                <Typography className="acceptedText">
                  Accepted file types:.glb
                </Typography>
                <p className="normalText">
                  Use this option if you have 3D files in .glb format for all
                  the
                  <br /> varients.
                </p>

                <div style={{ position: "relative", marginBottom: "35px" }}>
                  <hr className="hrLine" />
                  <Typography className="OrText">OR</Typography>
                </div>
                {this.state.productadded ? (
                  <Link
                    to={{
                      pathname: "/Contact",
                      state: { id: this.state.productId },
                    }}
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    {" "}
                    <Button
                      className="uploadBtn"
                      disabled={this.state.productadded ? true : false}
                      style={{
                        cursor: this.state.productadded
                          ? "pointer"
                          : "not-allowed",
                      }}
                    >
                      Contact Us
                    </Button>
                  </Link>
                ) : (
                  <Button
                    className="uploadBtn"
                    disabled={this.state.productadded ? true : false}
                    style={{
                      cursor: this.state.productadded
                        ? "pointer"
                        : "not-allowed",
                    }}
                  >
                    Contact Us
                  </Button>
                )}

                <Typography
                  className="normalText"
                  style={{ borderBottom: "none" }}
                >
                  If you do not have 3D files in .glb format, please contact our
                  digital <br /> asset creation team and we will get back to you
                  within one <br /> business day
                </Typography>
              </Grid>
              <Grid item sm={3}></Grid>
            </>
          ) : (
            <>
              <Grid item sm={4}></Grid>
              <Grid item sm={4}>
                <div className="editRightWrapper">
                  <form
                    action=""
                    id="ClearForm"
                    name="ClearForm"
                    onSubmit={
                      query_params.model ? this.update : this.createProduct
                    }
                  >
                    <div className="box1 ">
                      <div className="inlineWrapper">
                        <Typography
                          className="buyNow"
                          style={{
                            marginBottom: "-0.2rem",
                          }}
                        >
                          Product Name
                        </Typography>
                      </div>
                      <br />
                      <input
                        className="Input"
                        name="title"
                        required
                        style={{
                          width: "97%",
                        }}
                        value={this.state.title}
                        onChange={this.handleInput}
                      />

                      <Typography className="buyNow">Categories</Typography>

                      <MultiSelect
                        options={this.state.categories}
                        value={this.state.catIds}
                        onChange={(data) => {
                          console.log(data);
                          this.setState({ catIds: data }, () => {});
                        }}
                        className="InputSelect"
                        labelledBy={"Categories"}
                        style={{
                          width: "97%",
                        }}
                      />
                      {!this.state.catIds.length > 0 ? (
                        <div>
                          {this.state.warn1 ? (
                            <span style={{ color: "red" }}>*required </span>
                          ) : null}
                        </div>
                      ) : null}
                      <div
                        className="inlineWrapper"
                        style={{
                          margin: 0,
                          marginTop: 10,
                          padding: 0,
                        }}
                      >
                        <Typography
                          className="buyNow"
                          style={{
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          Embed Product URL
                        </Typography>
                      </div>
                      <input
                        className="Input"
                        name="embedded_product_url"
                        style={{
                          margin: 0,
                          // paddingTop: 0,
                          // width: "100%",
                          marginTop: 10,
                        }}
                        onChange={this.handleInput}
                      />
                      {!query_params.suggestion_model && (
                        <>
                          <Typography
                            className="buyNow"
                            style={{
                              marginBottom: "-0.2rem",
                            }}
                          >
                            Select Language
                          </Typography>
                          <select
                            name="language"
                            id=""
                            className="Input"
                            style={{ width: "97%" }}
                            onChange={this.handleInput}
                          >
                            <option value="English">English</option>
                            <option value="French">French</option>
                          </select>
                          <br />
                        </>
                      )}
                      <Typography className="buyNow">
                        {" "}
                        Upload Documents
                      </Typography>
                      <Button
                        variant="outlined"
                        size="medium"
                        className="browseBtn"
                        component="label"
                        style={{
                          border: "1px solid #ddd",
                          width: "100%",
                          background: "#ebebeb",
                          textTransform: "capitalize",
                          cursor: "pointer",
                        }}
                      >
                        Upload Image
                        {/* <AspectRatio ratio="4/3" style={{ maxWidth: '100px' }}> */}
                        <input
                          type="file"
                          // onChange={this.handleFile}
                          onChange={this.onSelectFile}
                          name="filename"
                          hidden
                          accept="image/*"
                          id="uploadFile"
                        />
                        {/* </AspectRatio> */}
                      </Button>
                      {!this.state.uploadJPG ? (
                        <p style={{ fontSize: "13px" }}>
                          {/* Accepted file types .jpg ,.jpeg, .png and size 182 x 137 */}
                          Accepted file types .jpg ,.jpeg, .png and size 800 x
                          600 px
                        </p>
                      ) : null}
                      {this.state.imgPreview
                        ? croppedImageUrl && (
                            <img
                              alt="Crop"
                              style={{
                                width: "100%",
                                position: "relative",
                                marginBottom: 12,
                              }}
                              src={croppedImageUrl}
                            />
                          )
                        : ""}
                      {query_params.suggestion_model && (
                        <Button
                          variant="outlined"
                          size="medium"
                          className="browseBtn"
                          component="label"
                          style={{
                            border: "1px solid #ddd",
                            width: "100%",
                            background: "#ebebeb",
                            textTransform: "capitalize",
                            cursor: "pointer",
                            marginBottom: 12,
                          }}
                        >
                          Upload Model File(.glb)
                          {/* <AspectRatio ratio="4/3" style={{ maxWidth: '100px' }}> */}
                          <input
                            type="file"
                            // onChange={this.handleFile}
                            onChange={this.handleGLBFile}
                            name="glb_file"
                            hidden
                            accept=".glb"
                            id="uploadFile"
                          />
                          {/* </AspectRatio> */}
                        </Button>
                      )}
                      {this.state.glb_file && (
                        <p style={{ fontSize: "13px" }}>
                          {this.state.glb_file?.name}
                        </p>
                      )}
                      {!query_params.suggestion_model && (
                        <>
                          <PlacementRadio
                            propState={this.state}
                            onChange={(placement) =>
                              this.setState({ placement })
                            }
                          />
                          <Passcode
                            isProtected={!!this.state.is_protected}
                            toggleIsProtected={() =>
                              this.setState((pre) => ({
                                ...pre,
                                is_protected: !!!pre?.is_protected,
                              }))
                            }
                            passcode={this.state.passcode || ""}
                            setPasscode={(passcode) =>
                              this.setState({ passcode })
                            }
                          />
                        </>
                      )}
                    </div>

                    {!this.state.filename ? (
                      <Typography
                        className="textbelowInput"
                        style={{ color: "red" }}
                      >
                        {this.state.warning
                          ? "* Upload image for product"
                          : null}
                      </Typography>
                    ) : (
                      <Typography className="textbelowInput">
                        {this.state.filename && this.state.uploadJPG
                          ? this.state.filename.name
                          : null}
                      </Typography>
                    )}

                    {this.state.loading ? (
                      <Button className="saveBtn" type="submit">
                        <CircularProgress size={32} color="white" />
                      </Button>
                    ) : query_params.model ? (
                      <Button className="saveBtn" type="submit">
                        Update
                      </Button>
                    ) : (
                      <Button className="saveBtn" type="submit">
                        Continue
                      </Button>
                    )}
                  </form>
                  {window.innerWidth < 1100 ? (
                    <Modal
                      visible={this.state.visibleCrop}
                      width="700"
                      minHeight="1020"
                      effect="fadeInUp"
                      onClickAway={() => this.setState({ visibleCrop: false })}
                    >
                      <Grid
                        container
                        spacing={2}
                        className="PreeditWrapper"
                        style={{ marginTop: "25px" }}
                      >
                        <Grid
                          item
                          sm={12}
                          style={{
                            padding: "20px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {src && (
                            <ReactCrop
                              src={src}
                              crop={crop}
                              ruleOfThirds
                              onImageLoaded={this.onImageLoaded}
                              onComplete={this.onCropComplete}
                              onChange={this.onCropChange}
                            />
                          )}
                        </Grid>
                        {/* <Grid item sm={6} style={{padding: "20px"}}>
                      {croppedImageUrl && (
                        <img alt="Crop" style={{ width: "100%", height:"430px", position: "relative", top: "60px" }} src={croppedImageUrl} />
                      )}
                    </Grid> */}
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="PreeditWrapper"
                        style={{ marginTop: "25px" }}
                      >
                        <Grid item sm={6} style={{ padding: "20px" }}></Grid>
                        <Grid item sm={6} style={{ padding: "20px" }}>
                          <span>
                            <button
                              style={{ cursor: "pointer" }}
                              className="saveBtnCrop"
                              onClick={() => {
                                this.setState({
                                  visibleCrop: false,
                                  imgPreview: true,
                                });
                              }}
                            >
                              Save
                            </button>
                          </span>

                          <span>
                            <button
                              className="cancelBtnCrop"
                              onClick={() => {
                                this.setState(
                                  {
                                    visibleCrop: false,
                                    imgPreview: false,
                                  },
                                  () => {
                                    // console.log(this.state.visibleCrop);
                                  }
                                );
                              }}
                            >
                              Cancel
                            </button>
                          </span>
                        </Grid>
                      </Grid>
                    </Modal>
                  ) : (
                    <Modal
                      visible={this.state.visibleCrop}
                      // width="700"
                      width="1100"
                      height="650"
                      effect="fadeInUp"
                      onClickAway={() => this.setState({ visibleCrop: false })}
                    >
                      <Grid
                        container
                        spacing={2}
                        className="PreeditWrapper"
                        style={{ marginTop: "25px" }}
                      >
                        <Grid
                          item
                          sm={12}
                          style={{
                            padding: "20px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {src && (
                            <ReactCrop
                              src={src}
                              crop={crop}
                              ruleOfThirds
                              onImageLoaded={this.onImageLoaded}
                              onComplete={this.onCropComplete}
                              onChange={this.onCropChange}
                            />
                          )}
                        </Grid>
                        {/* <Grid item sm={6} style={{padding: "20px"}}>
                      {croppedImageUrl && (
                        <img alt="Crop" style={{ width: "100%", height:"430px", position: "relative", top: "60px" }} src={croppedImageUrl} />
                      )}
                    </Grid> */}
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="PreeditWrapper"
                        style={{ marginTop: "25px" }}
                      >
                        {/* <Grid item sm={6} style={{padding: "20px"}}></Grid> */}
                        <Grid item sm={12} style={{ padding: "20px" }}>
                          <span>
                            {/* <button style={{cursor:"pointer"}} className="saveBtnCrop" onClick={() => {
                          this.setState({
                            visibleCrop: false,
                            imgPreview: true
                          })
                        }}>Save</button> */}
                            <button
                              className="saveCroppedImg"
                              onClick={() => {
                                this.setState({
                                  visibleCrop: false,
                                  imgPreview: true,
                                });
                              }}
                            >
                              Save
                            </button>
                          </span>
                          <span>
                            {/* <button className="cancelBtnCrop" 
                        onClick={() => {
                          this.setState({
                            visibleCrop: false,
                            imgPreview: false
                          }, () => {
                          // console.log(this.state.visibleCrop);
                          })
                        }}>Cancel</button> */}
                            <button
                              className="cancelCroppedImg"
                              onClick={() => {
                                this.setState(
                                  {
                                    visibleCrop: false,
                                    imgPreview: false,
                                  },
                                  () => {
                                    // console.log(this.state.visibleCrop);
                                  }
                                );
                              }}
                            >
                              Cancel
                            </button>
                          </span>
                        </Grid>
                      </Grid>
                    </Modal>
                  )}
                </div>
              </Grid>
              <Grid item sm={4}></Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
}
