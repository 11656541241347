import {
  Backdrop,
  Button,
  Container,
  Fade,
  FormControl,
  Grid,
  // Modal,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constants";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import Modal from "react-awesome-modal";
import {
  ArrowForwardIos,
  ArrowBackIos,
  ThreeSixtyTwoTone,
} from "@material-ui/icons";
import PublicModelShuffleDialog from "../../Components/PublicModelShuffleDialog";
const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));
function createData(name, calories, fat) {
  return { name, calories, fat };
}
const rowsPerPage = 5;
const page = 1;

export default class AdminPricing extends Component {
  state = {
    open: false,
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    pagingCounter: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    adminProductData: [],
    open: false,
    delMsg: "",
    visible: false,
    theme: "light",

    // PAGINATION STATES
    search: "",
    firstpage: 0,
    page: 1,
    secondpage: 0,
    totalPage: 0,
    limit: 10,
    nextPage: false,
    prevPage: false,
    pagingCounter: 1,
    totalDocs: 0,
    forshow: 0,
    filteredModels: [],

    // PAGINATION
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    this.getAdminModels();
  }
  getAdminModels = () => {
    axios
      .get(
        Constants.getUrls.getAdminProducts +
          "?page=" +
          this.state.page +
          "&limit=" +
          this.state.perPage +
          "&wish[]=title&&wish[]=seqNo&wish[]=category&wish[]=status&wish[]=user" +
          "&is_delete=false" +
          "&wish[]=is_public_model"
      )
      .then((resp) => {
        // console.log(resp);

        this.setState(
          {
            adminProductData: resp.data.data.docs,
            firstpage: resp.data.data.prevPage,
            secondpage: resp.data.data.nextPage,
            page: resp.data.data.page,
            totalPage: resp.data.data.totalPages,
            nextPage: resp.data.data.hasNextPage,
            prevPage: resp.data.data.hasPrevPage,
            forshow: resp.data.data.prevPage,
            limit: resp.data.data.limit,
            pagingCounter: resp.data.data.pagingCounter,
            totalDocs: resp.data.data.totalDocs,
            // PAGINATION
            //

            // PAGINATION
          },
          () => {
            // console.log(this.state.adminProductData);
          }
        );
        // let btnIdArray = [];
        // for (let i = 1; i <= resp.data.categories.totalPages; i++) {
        //   btnIdArray.push(i);
        // }
        // this.setState(
        //   {
        //     fromInc: 1,
        //     active: 1,
        //     toInc: 5,
        //     btnIdArray,
        //     categories: resp.data.categories.docs,
        //     total: resp.data.categories.totalDocs,
        //     from: 1,
        //     to: resp.data.categories.docs.length,
        //     pagingCounter : resp.data.categories.pagingCounter
        //   },
        //   () => {
        //   // console.log(this.state.getServices);
        //     let filteredBtnIdArray = this.state.btnIdArray.filter(
        //       (v) => v >= this.state.fromInc && v <= this.state.toInc
        //     );
        //     this.setState({
        //       filteredBtnIdArray,
        //     });
        //   }
        // );
      });
    axios
      .get(
        Constants.getUrls.getAdminProducts +
          "?page=" +
          1 +
          "&limit=" +
          100 +
          "&wish[]=title&&wish[]=seqNo&wish[]=category&wish[]=status&wish[]=user" +
          "&is_delete=false" +
          "&wish[]=is_public_model"
      )
      .then((resp) => {
        // console.log(resp);
        const filteredModels = resp.data.data.docs.filter(
          (model) => model.is_public_model === "yes"
        );
        this.setState({
          filteredModels,
        });
      });
  };
  // handleRows start

  handleRows = (value) => {
    this.setState(
      {
        limit: value,
        page: 1,
      },
      () => {
        this.getAdminModels();
      }
    );
  };

  // handleRows end

  // PAGINATION BUTTONS

  handleNext = () => {
    // console.log("click");
    if (this.state.nextPage === true) {
      // console.log(this.state.nextPage);

      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.getAdminModels();
        }
      );
    }
  };

  handlePrev = () => {
    if (this.state.prevPage === true)
      this.setState(
        {
          page: this.state.page - 1,
        },
        () => {
          this.getAdminModels();
        }
      );
  };

  btnCilck = (i) => {
    // console.log(i);
    this.setState(
      {
        page: i,
      },
      () => {
        this.getAdminModels();
      }
    );
  };
  deletePro = (id) => {
    if (init() == "success") {
      this.setState({ delLoading: true });
      axios.delete(Constants.getUrls.Products + "/" + id).then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          this.setState({
            notifyDelete: true,
            delMsg: res.data.message,
            delLoading: false,
          });
        }

        setTimeout(() => {
          this.setState({ notifyDelete: false, visible: false }, () => {
            this.getAdminModels();
          });
        }, 2000);
      });
    }
  };
  handleStatus = (e, id) => {
    //alert(this.state.status + " " + id);
    const payload = {
      status: e.target.checked === true ? "yes" : "no",
    };
    // console.log(payload);
    axios
      .post(Constants.statusUrl + id + "?type=is_public_model", payload)
      .then((res) => {
        // console.log(res);
        this.setState({
          notification: true,
          message: res.data.message,
        });
        setTimeout(() => {
          this.setState({
            notification: false,
          });
          this.getAdminModels();
          // window.location.reload();
        }, 1000);
      });
  };
  handleSearch = (e) => {
    if (e.target.value.length > 1) {
      this.setState({ searching: true });
      axios
        .get(
          Constants.getUrls.getAdminProducts +
            "?page=" +
            this.state.page +
            "&limit=" +
            this.state.perPage +
            "&q=" +
            e.target.value
          // this.state.name
        )
        .then((resp) => {
          // console.log(resp);
          this.setState({
            adminProductData: resp.data.data.docs,
            // PAGINATION
            firstpage: resp.data.data.prevPage,
            secondpage: resp.data.data.nextPage,
            page: resp.data.data.page,
            totalPage: resp.data.data.totalPages,
            nextPage: resp.data.data.hasNextPage,
            prevPage: resp.data.data.hasPrevPage,
            forshow: resp.data.data.prevPage,
            limit: resp.data.data.limit,
            pagingCounter: resp.data.data.pagingCounter,
            totalDocs: resp.data.data.totalDocs,

            // PAGINATION
          });
          // let btnIdArray = [];
          // for (let i = 1; i <= resp.data.pageCount; i++) {
          //   btnIdArray.push(i);
          // }
          // this.setState(
          //   {
          //     btnIdArray,
          //     categories: resp.data.categories.docs,
          //     total: resp.data.categories.totalDocs,
          //     to: resp.data.categories.docs.length,

          //     from: 1,
          //     searched: true,
          //     searching: false,
          //   },
          //   () => {
          //     let filteredBtnIdArray = this.state.btnIdArray.filter(
          //       (v) => v >= this.state.fromInc && v <= this.state.toInc
          //     );
          //     this.setState({
          //       filteredBtnIdArray,
          //     });
          //   }
          // );
        });
    } else {
      this.getAdminModels();
    }
  };
  openModal = () => {
    this.setState({
      visible: true,
    });
  };
  render() {
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Container maxWidth="xl">
        {/* <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={true}
            color={"s"}
            autoHideDuration={6000}
            message="I love snacks"
          ></Snackbar> */}
        {/* {this.state.notifyDelete ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"s"}
              autoHideDuration={6000}
              message={this.state.delMsg}
            ></Snackbar>
          ) : null} */}
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            color={"green"}
            autoHideDuration={6000}
            message={this.state.message}
          ></Snackbar>
        ) : null}

        <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
          <Grid item xs={3}>
            <Typography
              variant="h6"
              style={{ color: this.state.theme === "light" ? "#000" : "#fff" }}
            >
              {this.props.title}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid container justify="flex-end">
              <a style={{ textDecoration: "none"}}><Button
                size="medium"
                onClick={() => this.setState({ openShuffleWindow: true })}
                variant="contained"
                color="primary"
                style={{
                  marginRight: "5px",
                }}
                // disableElevation
              >
                Reshuffle
              </Button></a>
              {this.state.openShuffleWindow ? (
                <PublicModelShuffleDialog
                  open={this.state.openShuffleWindow}
                  handleClose={() => {
                    this.setState({ openShuffleWindow: false });
                  }}
                  publicmodel={this.state.filteredModels}
                  getpublicmodel={this.getAdminModels}
                />
              ) : null}
              {this.state.adminProductData.length > 0 && (
                <a
                  href={
                    "/admin/allProducts/" + this.state.adminProductData[0]?.user
                  }
                  style={{ textDecoration: "none", marginRight: 8 }}
                >
                  <Button
                    size="medium"
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                    variant="contained"
                    color="primary"
                  >
                    See All Products
                  </Button>
                </a>
              )}
              <FormControl style={{ marginRight: 8 }}>
                <TextField
                  id="outlined-basic"
                  placeholder={"Search " + this.props.title}
                  size="small"
                  variant="outlined"
                  onChange={this.handleSearch}
                />
                {/* <TextField
                    size="small"
                    placeholder={"Search " + this.props.title}
                    id="input-with-icon-adornment"
                    onClick={this.handleSearch}
                  /> */}
              </FormControl>
              <a
                href={
                  "/admin/product-create/" +
                  this.state.adminProductData[0]?.user
                }
                style={{ textDecoration: "none" }}
              >
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add Public Model
                </Button>
              </a>

              {/* <Link to="/admin/addpricing" style={{ textDecoration: "none" }}>
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add price
                </Button>
              </Link> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.no</TableCell>
                    <TableCell>Title</TableCell>
                    {/* <TableCell>Category</TableCell> */}
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.adminProductData.map((adpro, i) => (
                    <TableRow key={adpro._id}>
                      <TableCell width={100}>
                        {this.state.pagingCounter + i}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {adpro.title}
                      </TableCell>
                      {/* <TableCell >{adpro.category}</TableCell> */}
                      <TableCell align="right">
                        <Switch
                          color="primary"
                          name="checkedB"
                          checked={
                            adpro.is_public_model === "yes" ? true : false
                          }
                          // value={cat.status}
                          onChange={(e) => this.handleStatus(e, adpro._id)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <a
                          href={
                            "/admin/modelDynamicviewer/" +
                            adpro.user +
                            "/" +
                            adpro._id
                          }
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          <Button
                            variant={"outlined"}
                            color="primary"
                            // style={{ marginRight: 4, backgroundColor:"#f0eded", padding:"7px" }}
                            size={"small"}
                            id="signInUser"
                          >
                            View Product
                          </Button>
                        </a>

                        <a
                          href={
                            "/admin/product-edit/" +
                            adpro.user +
                            "/" +
                            adpro._id
                          }
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          <Button
                            variant={"outlined"}
                            color="primary"
                            // style={{ marginRight: 4, backgroundColor:"#f0eded", padding:"7px" }}
                            size={"small"}
                            id="signInUser"
                          >
                            Edit Product
                          </Button>
                        </a>

                        {/* <Link
                          to={{
                            pathname: "/admin/allProducts/" + adpro._id,
                            state: { data: adpro },
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            variant={"outlined"}
                            color="default"
                            style={{ marginRight: 4 }}
                            size={"small"}
                          >
                            View Product
                          </Button>
                        </Link> */}
                        <Button
                          size={"small"}
                          variant={"outlined"}
                          color="secondary"
                          id="signInUser"
                          onClick={() => {
                            this.setState({
                              visible: true,
                              deleteId: adpro._id,
                            });
                            // this.deleteadpro(adpro._id);
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                </TableBody>
                {/* <TableFooter>
                    <TableRow style={{ position: "relative" }}>
                      {this.state.categories.length === 0 ? (
                        <div>
                          <h6
                            style={{
                              textAlign: "center",
                              marginTop: "30px",
                              fontSize: "14px",
                            }}
                          >
                            No Data Found!
                          </h6>
                        </div>
                      ) : this.state.empty ? null : (
                        <div style={{ marginTop: "20px", marginBottom: "70px" }}>
                          <div style={{ float: "left", marginLeft: "20px" }}>
                            Showing {this.state.from} to {this.state.to} of{" "}
                            {this.state.total} entries.
                          </div>
  
                          <div
                            style={{
                              float: "right",
                              position: "absolute",
                              right: "47px",
                              top: "5px",
                            }}
                          >
                            <Pagination
                              style={{
                                listStyle: "none",
                                display: "inline-flex",
                              }}
                            >
                              <PaginationItem>
                                <PaginationLink
                                  onClick={this.prevPages}
                                  tabIndex="-1"
                                  style={{
                                    background: "#dedddd",
                                    border: "none",
                                    borderRadius: "23px",
                                    height: "34px",
                                    width: "35px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <ArrowBackIosRoundedIcon
                                    style={{
                                      color: "dimgray",
                                      fontSize: "19px",
                                      marginTop: "2px",
                                    }}
                                  /> */}
                {/* <i className="fa fa-angle-left" /> */}
                {/* <span className="sr-only">Previous</span> */}
                {/* </PaginationLink>
                              </PaginationItem>
                              {this.state.filteredBtnIdArray.map((v, i) => (
                                <PaginationItem
                                  className={
                                    this.state.active === v ? "active" : ""
                                  }
                                  key={i}
                                >
                                  <PaginationLink
                                    onClick={() => {
                                      this.currentPage(v);
                                    }}
                                    style={{
                                      background:this.state.active === v ? "#9fa8da" : "#dedddd",
                                      border: "none",
                                      borderRadius: "23px",
                                      height: "34px",
                                      width: "35px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {v}
                                  </PaginationLink>
                                </PaginationItem>
                              ))}
                              <PaginationItem>
                                <PaginationLink
                                  onClick={this.nextPages}
                                  style={{
                                    background: "#dedddd",
                                    border: "none",
                                    borderRadius: "23px",
                                    height: "34px",
                                    width: "35px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {/* <i className="fa fa-angle-right" /> */}
                {/* <span className="sr-only">Next</span> */}
                {/* <ArrowForwardIosRoundedIcon
                                    style={{
                                      color: "dimgray",
                                      fontSize: "19px",
                                      marginTop: "2px",
                                    }}
                                  />
                                </PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          </div>
                        </div>
                      )}  */}
                {/* {this.state.categories.length === 0 ? (
                        <div>
                          <h6
                            style={{
                              textAlign: "center",
                              marginTop: "30px",
                              fontSize: "14px",
                            }}
                          >
                            No Data Found!
                          </h6>
                        </div>
                      ) : this.state.empty ? null : (
                        <div style={{ marginTop: "20px" }}>
                          <div style={{ float: "left" }}>
                            Showing {this.state.from} to {this.state.to} of{" "}
                            {this.state.total} entries.
                          </div>
  
                          <div style={{ float: "right" }}>
                            <Pagination>
                              <PaginationItem>
                                <PaginationLink
                                  onClick={this.prevPages}
                                  tabIndex="-1"
                                >
                                  <i className="fa fa-angle-left" />
                                  <span className="sr-only">Previous</span>
                                </PaginationLink>
                              </PaginationItem>
                              {this.state.filteredBtnIdArray.map((v, i) => (
                                <PaginationItem
                                  className={
                                    this.state.active === v ? "active" : ""
                                  }
                                  key={i}
                                >
                                  <PaginationLink
                                    onClick={() => {
                                      this.currentPage(v);
                                    }}
                                  >
                                    {v}
                                  </PaginationLink>
                                </PaginationItem>
                              ))}
                              <PaginationItem>
                                <PaginationLink onClick={this.nextPages}>
                                  <i className="fa fa-angle-right" />
                                  <span className="sr-only">Next</span>
                                </PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          </div>
                        </div>
                      )} */}
                {/* <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                      /> */}
                {/* </TableRow>
                  </TableFooter> */}
              </Table>
              {/* PAGINATION TABLE START */}
              {this.state.totalDocs !== 0 ? (
                <TableRow
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  {this.state.theme === "light" ? (
                    <div style={{ marginTop: 20 }}>
                      <p>
                        Showing {this.state.pagingCounter} to{" "}
                        {this.state.totalDocs >
                        this.state.limit * this.state.page
                          ? this.state.limit + this.state.pagingCounter - 1
                          : this.state.pagingCounter +
                            (this.state.totalDocs -
                              this.state.limit * this.state.forshow) -
                            1}{" "}
                        of {this.state.totalDocs} Entries
                      </p>
                    </div>
                  ) : (
                    <div style={{ marginTop: 20 }}>
                      <p style={{ color: "#fff" }}>
                        Showing {this.state.pagingCounter} to{" "}
                        {this.state.totalDocs >
                        this.state.limit * this.state.page
                          ? this.state.limit + this.state.pagingCounter - 1
                          : this.state.pagingCounter +
                            (this.state.totalDocs -
                              this.state.limit * this.state.forshow) -
                            1}{" "}
                        of {this.state.totalDocs} Entries
                      </p>
                    </div>
                  )}

                  <TableCell></TableCell>
                  <TableCell>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-5"
                        size="small"
                        style={{
                          margin: 3,
                          marginTop: 10,
                          height: 30,
                          width: 20,
                          // borderRadius: "50%",
                        }}
                        disabled={this.state.prevPage === false}
                        onClick={this.handlePrev}
                        // Hidden
                      >
                        <ArrowBackIos size="small" />
                      </Button>{" "}
                      {this.state.totalPage < 6
                        ? [...Array(this.state.totalPage)].map((data, i) => {
                            return (
                              <Button
                                variant={
                                  this.state.page === i + 1
                                    ? "contained"
                                    : "outlined"
                                }
                                color={
                                  this.state.page === i + 1
                                    ? "primary"
                                    : "inherit"
                                }
                                size="small"
                                value={i + 1}
                                onClick={() => this.btnCilck(i + 1)}
                                style={{
                                  margin: 3,
                                  marginTop: 10,
                                  height: 30,
                                  width: 20,
                                  // borderRadius: "100%",
                                  padding: 2,
                                }}
                              >
                                {i + 1}
                              </Button>
                            );
                          })
                        : (this.state.firstpage > 0 ? (
                            <Button
                              variant={
                                this.state.firstpage === this.state.page
                                  ? "contained"
                                  : "outlined"
                              }
                              color={
                                this.state.firstpage === this.state.page
                                  ? "primary"
                                  : "inherit"
                              }
                              size="small"
                              value={this.state.firstpage}
                              onClick={() =>
                                this.btnCilck(this.state.firstpage)
                              }
                              style={{
                                margin: 3,
                                marginTop: 10,
                                height: 30,
                                width: 20,
                                // borderRadius: "100%",
                                padding: 2,
                              }}
                            >
                              {this.state.firstpage}
                            </Button>
                          ) : null)(
                            <Button
                              variant={
                                this.state.page ? "contained" : "outlined"
                              }
                              color={this.state.page ? "primary" : "inherit"}
                              size="small"
                              value={this.state.page}
                              onClick={() => this.btnCilck(this.state.page)}
                              style={{
                                margin: 3,
                                marginTop: 10,
                                height: 30,
                                width: 20,
                                // borderRadius: "100%",
                                padding: 2,
                              }}
                            >
                              {this.state.page}
                            </Button>
                          )(
                            this.state.secondpage > 0 ? (
                              <Button
                                variant={
                                  this.state.secondpage === this.state.page
                                    ? "contained"
                                    : "outlined"
                                }
                                color={
                                  this.state.secondpage === this.state.page
                                    ? "primary"
                                    : "inherit"
                                }
                                size="small"
                                value={this.state.secondpage}
                                onClick={() =>
                                  this.btnCilck(this.state.secondpage)
                                }
                                style={{
                                  margin: 3,
                                  marginTop: 10,
                                  height: 30,
                                  width: 20,
                                  // borderRadius: "100%",
                                  padding: 2,
                                }}
                              >
                                {this.state.secondpage}
                              </Button>
                            ) : null
                          )(
                            this.state.page + 1 ===
                              this.state.totalPage ? null : this.state
                                .nextPage === false ? null : (
                              <Button
                                // variant={"outlined"}
                                color={"inherit"}
                                size="small"
                                value={this.state.secondpage + 1}
                                onClick={() =>
                                  this.btnCilck(this.state.secondpage + 1)
                                }
                                style={{
                                  margin: 3,
                                  marginTop: 10,
                                  height: 30,
                                  width: 20,
                                  fontSize: 30,
                                  // borderRadius: "100%",
                                  padding: 2,
                                }}
                              >
                                ...
                              </Button>
                            )
                          )({
                            /* {this.state.totalPage === this.state.page ? null : (
                          <Button
                            variant={
                              this.state.totalPage === this.state.page
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              this.state.totalPage === this.state.page
                                ? "primary"
                                : "inherit"
                            }
                            size="small"
                            value={this.state.totalPage}
                            onClick={() => this.btnCilck(this.state.totalPage)}
                            style={{
                              margin: 3,
                              marginTop: 10,
                              height: 30,
                              width: 20,
                              // borderRadius: "100%",
                              padding: 2,
                            }}
                          >
                            {this.state.totalPage}
                          </Button>
                        )} */
                          })}
                      <Button
                        variant="contained"
                        color="primary"
                        className="mx-5"
                        disabled={this.state.nextPage === false}
                        size="small"
                        style={{
                          margin: 3,
                          marginTop: 10,
                          height: 30,
                          width: 20,
                          // borderRadius: "50%",
                        }}
                        onClick={this.handleNext}
                      >
                        <ArrowForwardIos size="small" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <div style={{ padding: 8 }}>Data not available</div>
              )}
              {/* PAGINATION TABLE END */}
            </TableContainer>
          </Paper>
        </Grid>

        {/* <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Fade in={this.state.open}>
              <div
                style={{
                  background: "#fff",
                  width: "50%",
                  padding: "32px",
                }}
              >
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">
                  react-transition-group animates me.
                </p>
              </div>
            </Fade>
          </Modal> */}

        <Modal
          visible={this.state.visible}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div className="ModalWrapper">
            <Typography className="Typo">
              {" "}
              Are you sure you want to delete <br /> this model ?
            </Typography>

            <Button
              size="medium"
              onClick={() => {
                this.deletePro(this.state.deleteId);
              }}
              variant="contained"
              color="secondary"
              className="Delbutton"
            >
              Delete
            </Button>

            <Button
              size="medium"
              onClick={() => {
                this.setState({ visible: false });
              }}
              variant="contained"
              color="primary"
              className="Delbutton"
            >
              {" "}
              Cancel{" "}
            </Button>
            <Typography className="DeleteMsg">
              {" "}
              {this.state.notifyDelete ? this.state.delMsg : ""}{" "}
            </Typography>
          </div>
        </Modal>
      </Container>
    );
  }
}
