import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Constants from "../../Variables/Constants";
import SkeletonUser from "./SkeletonUser";
import UserSidebar from "./UserSidebar";
export default class UserProfile extends Component {
  state = {
    userdetail: {},
    filename: "",
    name: "",
    email: "",
    profile_role: "",
    title: "",
    company_name: "",
    profile_pic: "",
    company_logo: "",
    address: "",
    industry: "",
    notification: false,
    successMsg: "",
    loading: false,
    profile_pic_url: {},
    loader: true,
    userId: window.location.pathname.split("/")[3],
  };
  componentDidMount() {
    // console.log("hi");
    this.getUser();
  }
  getUser = () => {
    var user_id = window.location.pathname.split("/")[3];
    this.setState({ loader: true });
    // if (this.props.isAuthenticated===true) {
    // if (init() === "success") {
    let authToken = localStorage.getItem("feel_xr_auth_token");
    axios
      .get(Constants.getUrls.getUserDetails + this.state.userId, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((res) => {
        // console.log(res);
        this.setState({ userdetail: res.data.data.user, loader: false });
      });
    // }
  };
  onImageChange = (event) => {
    this.setState({
      profile_pic_url: event.target.files[0],
    });
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ profile_pic: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  onImageChangeLogo = (event) => {
    this.setState({
      company_logo_url: event.target.files[0],
    });
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ company_logo: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.result });
  };
  updateProfile = (e) => {
    let data = new FormData();
    data.append(
      "name",
      this.state.name
        ? this.state.name
        : this.state.userdetail.name
        ? this.state.userdetail.name
        : ""
    );
    data.append(
      "email",
      this.state.email
        ? this.state.email
        : this.state.userdetail.email
        ? this.state.userdetail.email
        : ""
    );
    data.append(
      "profile_role",
      this.state.profile_role
        ? this.state.profile_role
        : this.state.userdetail.profile_role
        ? this.state.userdetail.profile_role
        : ""
    );
    data.append(
      "title",
      this.state.title
        ? this.state.title
        : this.state.userdetail.title
        ? this.state.userdetail.title
        : ""
    );
    data.append(
      "company_name",
      this.state.company_name
        ? this.state.company_name
        : this.state.userdetail.company_name
        ? this.state.userdetail.company_name
        : ""
    );
    if (this.state.profile_pic_url) {
      data.append(
        "profile_pic",
        this.state.profile_pic_url
          ? this.state.profile_pic_url
          : this.state.userdetail.profile_pic_url
      );
    }
    if (this.state.company_logo_url) {
      data.append(
        "company_logo",
        this.state.company_logo_url
          ? this.state.company_logo_url
          : this.state.userdetail.company_logo
      );
    }
    data.append(
      "address",
      this.state.address
        ? this.state.address
        : this.state.userdetail.address
        ? this.state.userdetail.address
        : ""
    );
    data.append(
      "industry",
      this.state.industry
        ? this.state.industry
        : this.state.userdetail.industry
        ? this.state.userdetail.industry
        : ""
    );

    let userToken = localStorage.getItem("feel_xr_auth_token");
    this.setState({ loading: true });
    axios
      // .put(Constants.postUrls.updateProfile, data, {
      //   headers: { Authorization: "Bearer " + userToken },
      // })
      .put(Constants.postUrls.updateUserDetail + this.state.userId, data)
      .then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 4000);
          this.getUser();
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 4000);
        }
      });
  };
  logout = () => {
    localStorage.clear();
    window.location.href = "/account/logout";
  };

  handlechange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <Container className="containerSpacingdashBoard" maxWidth={"lg"}>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={true}
            style={{ bottom: "40px" }}
            color={"#bdbdbd"}
            autoHideDuration={6000}
            message={this.state.successMsg}
          ></Snackbar>
        ) : null}
        {this.state.loader ? (
          <SkeletonUser />
        ) : (
          <Grid container spacing={2} className="userDashboard">
            <Grid item sm={2} className="sidebar">
              <UserSidebar />
            </Grid>
            <Grid item sm={10} style={{ paddingLeft: "105px" }}>
              <Grid container spacing={2}>
                <Grid item sm={9}>
                  <Link
                    to={"/admin/settings/" + this.state.userId}
                    style={{ textDecoration: "none", color: "#666666" }}
                  >
                    <Button
                      className="SettingBtn"
                      style={{ marginLeft: "-10px", marginTop: "30px" }}
                    >
                      <ArrowBackRoundedIcon
                        style={{
                          marginRight:
                            window.innerWidth < 1000 ? "8px" : "15px",
                          marginLeft:
                            window.innerWidth < 1000 ? "-2px" : "-13px",
                        }}
                      />
                      Settings
                    </Button>
                  </Link>
                  <Typography className="representTitle">
                    <PersonOutlineOutlinedIcon className="userIcon" /> Company
                    Representative Profile
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    style={{
                      padding:
                        window.innerWidth < 1030 ? "10px 10px" : "10px 25px",
                    }}
                  >
                    <Grid item sm={7}>
                      <Grid container spacing={2} className="inputWrapper">
                        <Grid item sm={3} style={{ textAlign: "right" }}>
                          <p style={{ marginTop: "11px" }}>Name</p>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            className="Input"
                            name="name"
                            required
                            defaultValue={
                              this.state.userdetail
                                ? this.state.userdetail.name
                                : null
                            }
                            onChange={this.handlechange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="inputWrapper">
                        <Grid item sm={3} style={{ textAlign: "right" }}>
                          <p style={{ marginTop: "11px" }}>Title</p>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            className="Input"
                            name="title"
                            required
                            defaultValue={
                              this.state.userdetail
                                ? this.state.userdetail.title
                                : null
                            }
                            onChange={this.handlechange}
                          />
                        </Grid>
                      </Grid>{" "}
                      <Grid container spacing={2} className="inputWrapper">
                        <Grid item sm={3} style={{ textAlign: "right" }}>
                          <p style={{ marginTop: "11px" }}>Role</p>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            className="Input"
                            name="profile_role"
                            required
                            defaultValue={
                              this.state.userdetail
                                ? this.state.userdetail.profile_role
                                : null
                            }
                            onChange={this.handlechange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="inputWrapper">
                        <Grid item sm={3} style={{ textAlign: "right" }}>
                          <p style={{ marginTop: "11px" }}>Email</p>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            className="Input"
                            name="email"
                            required
                            defaultValue={
                              this.state.userdetail
                                ? this.state.userdetail.email
                                : null
                            }
                            onChange={this.handlechange}
                            readOnly
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={5}>
                      <div className="ProfilePictureSection">
                        <Typography
                          className="text"
                          style={{
                            width: window.innerWidth < 1100 ? "127%" : "",
                            marginLeft: window.innerWidth < 1100 ? "33px" : "",
                          }}
                        >
                          {" "}
                          Profile Picture
                        </Typography>

                        {this.state.profile_pic ? (
                          <img
                            src={this.state.profile_pic}
                            width="120px"
                            height="120px"
                            style={{
                              borderRadius: "61.5px",
                              marginLeft:
                                window.innerWidth < 1100 ? "82px" : "",
                            }}
                            alt=""
                          />
                        ) : this.state.userdetail ? (
                          this.state.userdetail.profile_pic_url ? (
                            <img
                              src={
                                Constants.imageUrl +
                                this.state.userdetail.profile_pic_url
                              }
                              width="120px"
                              height="120px"
                              style={{
                                borderRadius: "61.5px",
                                marginLeft:
                                  window.innerWidth < 1100 ? "82px" : "",
                              }}
                              alt=""
                            />
                          ) : (
                            <div className="profileright"></div>
                          )
                        ) : (
                          <div className="profileright"></div>
                        )}
                        <Button
                          variant="outlined"
                          size="medium"
                          className="profilePic"
                          component="label"
                          style={{
                            marginTop: "11px",
                            border: "none",
                            width: window.innerWidth < 1100 ? "104%" : "",
                            marginLeft: window.innerWidth < 1100 ? "56px" : "",
                          }}
                        >
                          Upload new
                          <input
                            type="file"
                            onChange={this.onImageChange}
                            name="profile_pic"
                            hidden
                          />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Typography className="representTitle">
                    <PeopleAltOutlinedIcon className="userIcon" /> Company
                    Details
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      padding:
                        window.innerWidth < 1100 ? "10px 10px" : "10px 25px",
                    }}
                  >
                    <Grid item sm={7}>
                      <Grid container spacing={2} className="inputWrapper">
                        <Grid item sm={3} style={{ textAlign: "right" }}>
                          <p style={{ marginTop: "11px", marginLeft: "-50px" }}>
                            {window.innerWidth < 1100 ? "Name" : "Company Name"}
                          </p>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            className="Input"
                            name="company_name"
                            required
                            defaultValue={
                              this.state.userdetail
                                ? this.state.userdetail.company_name
                                : null
                            }
                            onChange={this.handlechange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="inputWrapper">
                        <Grid item sm={3} style={{ textAlign: "right" }}>
                          <p style={{ marginTop: "11px" }}>Industry</p>
                        </Grid>
                        <Grid item sm={9}>
                          <input
                            className="Input"
                            name="industry"
                            required
                            defaultValue={
                              this.state.userdetail
                                ? this.state.userdetail.industry
                                : null
                            }
                            onChange={this.handlechange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="inputWrapper">
                        <Grid item sm={3} style={{ textAlign: "right" }}>
                          <p style={{ marginTop: "11px" }}>Address</p>
                        </Grid>
                        <Grid item sm={9}>
                          <textarea
                            className="Input"
                            name="address"
                            required
                            style={{ height: "96px" }}
                            onChange={this.handlechange}
                            defaultValue={
                              this.state.userdetail
                                ? this.state.userdetail.address
                                : null
                            }
                          />
                        </Grid>
                      </Grid>

                      <Button
                        className="saveChanges"
                        style={{
                          marginLeft: "148px",
                          marginTop: "9px",
                          width: "61%",
                        }}
                        onClick={this.updateProfile}
                      >
                        {this.state.loading ? (
                          <CircularProgress size={48} color="white" />
                        ) : (
                          "Save Changes"
                        )}
                      </Button>
                    </Grid>
                    <Grid item sm={5}>
                      <div className="ProfilePictureSection">
                        <Typography
                          className="text"
                          style={{
                            width: window.innerWidth < 1100 ? "127%" : "",
                            marginLeft: window.innerWidth < 1100 ? "33px" : "",
                          }}
                        >
                          {" "}
                          Company Logo
                        </Typography>

                        {this.state.company_logo ? (
                          <img
                            src={this.state.company_logo}
                            width="120px"
                            height="120px"
                            style={{
                              borderRadius: "61.5px",
                              marginLeft:
                                window.innerWidth < 1100 ? "82px" : "",
                            }}
                            alt=""
                          />
                        ) : this.state.userdetail ? (
                          this.state.userdetail.company_logo ? (
                            <img
                              src={
                                Constants.imageUrl +
                                this.state.userdetail.company_logo
                              }
                              width="120px"
                              height="120px"
                              style={{
                                borderRadius: "61.5px",
                                marginLeft:
                                  window.innerWidth < 1100 ? "82px" : "",
                              }}
                              alt=""
                            />
                          ) : (
                            <div className="profileright"></div>
                          )
                        ) : (
                          <div className="profileright"></div>
                        )}
                        <Button
                          variant="outlined"
                          size="medium"
                          className="profilePic"
                          component="label"
                          style={{
                            marginTop: "30px",
                            border: "none",
                            width: window.innerWidth < 1100 ? "104%" : "",
                            marginLeft: window.innerWidth < 1100 ? "56px" : "",
                          }}
                        >
                          Upload new
                          <input
                            type="file"
                            onChange={this.onImageChangeLogo}
                            name="company_logo"
                            hidden
                          />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    );
  }
}
