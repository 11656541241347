import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
  Snackbar,
  Input,
} from "@material-ui/core";
import init from "../helpers/WindowToken";
import EditIcon from "@material-ui/icons/Edit";
import { Redirect, Link } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default class UpdateBlogs extends Component {
  state = {
    loading: false,
    blogId: "",
    title: "",
    sub_title: "",
    embeded_link: "",
    blog_image: null,
    short_description: "",
    description: "",
    imgError: false,
    shortDescError: false,
    descError: false,
    blogImage: null,
    showPreview: false,
  };

  componentDidMount() {
    let data = this.props.location.state.data;
    this.setState({ blogId: data._id });
    axios.get(Constants.getUrls.Blogs + "/" + data._id).then((res) => {
      // console.log(res);
      this.setState({
        title: res.data.blog.title,
        sub_title: res.data.blog.sub_title,
        embeded_link: res.data.blog.embeded_link,
        blogImage: res.data.blog.blog_img_url
          ? Constants.imageUrl + res.data.blog.blog_img_url
          : Constants.imageUrl + this.state.blog_image,
        short_description: res.data.blog.short_description,
        description: res.data.blog.description,
      });
    });
  }
  updateblogs = (e) => {
    e.preventDefault();
    if (this.state.short_description === "") {
      this.setState({
        shortDescError: true,
      });
      setTimeout(() => {
        this.setState({
          shortDescError: false,
        });
      }, 3000);
    } else if (this.state.description === "") {
      this.setState({
        descError: true,
      });
      setTimeout(() => {
        this.setState({
          descError: false,
        });
      }, 3000);
    } else {
      this.setState({
        loading: true,
      });
      let data = new FormData();
      data.append("title", this.state.title);
      data.append("sub_title", this.state.sub_title);
      data.append("embeded_link", this.state.embeded_link);
      data.append("blog_image", this.state.blog_image);
      data.append("short_description", this.state.short_description);
      data.append("description", this.state.description);
      if (init() === "success") {
        axios
          .put(Constants.postUrls.addBlogs + "/" + this.state.blogId, data)
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status === "success") {
              this.setState({
                loading: false,
                notification: true,
                message: resp.data.message,
              });
              setTimeout(() => {
                this.setState({
                  redirect: true,
                });
              }, 1000);
              this.setState({
                notification: true,
                loading: false,
              });
            } else {
              this.setState({
                //warn: true,
                loading: false,
                notification: true,
                message: resp.data.message,
              });
              setTimeout(() => {
                this.setState({
                  //warn: false,
                  notification: false,
                });
              }, 1000);
            }
          });
      }
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
      blogImage: URL.createObjectURL(e.target.files[0]),
      showPreview: true,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/blogs" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">{this.props.title}</Typography>
            </Grid>
          </Grid>
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"green"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          <div
            // style={{
            //   background: "#5b5757",
            //   padding: "20px",
            //   borderRadius: "10px",
            //   // color: rgba(0, 0, 0, 0.87);
            //   width: "100%",
            //   border: "0",
            //   display: "flex",
            //   position: "relative",
            //   fontSize: ".875rem",
            //   minWidth: "0",
            //   wordWrap: " break-word",
            //   /* background: #FFF; */
            //   boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            //   marginTop: "30px",
            //   borderRadius: "6px",
            //   marginBottom: "30px",
            //   flexDirection: "column",
            //   textAlign: "left",
            // }}
            style={{
              padding: "20px",
              border: "1px solid #afa2a2",
              borderRadius: "7px",
              position: "relative",
              // background:"#3f51b530"
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>
            <span
              style={{
                color: "#afa3a3",
                marginTop: "-8px",
                marginLeft: "26px",
                marginBottom: "35px",
                fontSize: "17px",
                display: "flex",
              }}
            >
              Update Blog
            </span>
            <Grid item sm={12} style={{ textAlign: "left", marginTop: "30px" }}>
              {/* <form noValidate autoComplete="off" onSubmit={this.updateblogs}> */}
              <form onSubmit={this.updateblogs}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  required={true}
                  label="Title"
                  name="title"
                  value={this.state.title}
                  variant="outlined"
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  required={true}
                  label="Sub Title"
                  name="sub_title"
                  value={this.state.sub_title}
                  variant="outlined"
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  required={true}
                  label="Embeded Link"
                  name="embeded_link"
                  value={this.state.embeded_link}
                  variant="outlined"
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
                {/* <Button
                  variant="outlined"
                  size="medium"
                  className="uploadbtn"
                  component="label"
                  style={{
                    width: "30%",
                    marginRight: "10px",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    padding: "13px",
                  }}
                >
                  {this.state.blog_image ? "Upload Blog Image*" : "Select Blog Image*"}
                  <input
                    type="file"
                    onChange={this.handleFile}
                    name="blog_image"
                    hidden
                    required
                    className="imageUpload"
                  />
                </Button> */}
                <Input
                  type="file"
                  onChange={this.handleFile}
                  name="blog_image"
                  hidden
                  className="imageUpload"
                />
                {/* {
                  this.state.showPreview ?  */}
                <img
                  id="image"
                  src={this.state.blogImage}
                  alt="img"
                  width="53px"
                  height="54px"
                  style={{
                    position: "absolute",
                    marginLeft: "64px",
                    marginTop: "1px",
                  }}
                />
                {/* : null
                } */}
                {this.state.imgError ? (
                  <div style={{ color: "red" }}>Please Select Image</div>
                ) : (
                  ""
                )}
                <br />
                <br />
                <label htmlFor="short_description">Short Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={this.state.short_description}
                  required={true}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    this.setState({
                      short_description: data,
                    });
                  }}
                />
                {this.state.shortDescError ? (
                  <div style={{ color: "red" }}>required*</div>
                ) : (
                  ""
                )}
                <br />
                <br />
                <label htmlFor="description">Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={this.state.description}
                  required={true}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    this.setState({
                      description: data,
                    });
                  }}
                />
                {this.state.descError ? (
                  <div style={{ color: "red" }}>required*</div>
                ) : (
                  ""
                )}
                <br />
                <Button
                  variant="contained"
                  type="submit"
                  Size={15}
                  style={{ marginRight: "20px", marginTop: "35px" }}
                  color="primary"
                >
                  {this.state.loading ? "Updating.." : "Update Blog"}
                </Button>
                <Link
                  to={{
                    pathname: "/blogs",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    Size={15}
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </form>
            </Grid>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Fade in={this.state.open}>
              <div
                style={{
                  background: "#fff",
                  width: "50%",
                  padding: "32px",
                }}
              >
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">
                  react-transition-group animates me.
                </p>
              </div>
            </Fade>
          </Modal>
        </Container>
      );
    }
  }
}
