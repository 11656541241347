import { Animation, ValueScale } from "@devexpress/dx-react-chart";
import {
  ArgumentAxis,
  Chart,
  LineSeries,
  SplineSeries,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import {
  SupervisorAccount,
  CategoryOutlined,
  SettingsOutlined,
  BookOutlined,
  RecentActorsOutlined,
  LinkOutlined,
} from "@material-ui/icons";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Constants from "../Variables/Constants";
import CardBox from "./Card";

const generateData = (start, end, step) => {
  const data = [];
  for (let i = start; i < end; i += step) {
    data.push({
      splineValue: Math.sin(i) / i,
      lineValue: (i / 15) ** 2.718 - 0.2,
      argument: i,
    });
  }

  return data;
};
export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: generateData(1.5, 3, 1),
      usersCount: 0,
      categoriesCount: 0,
      servicesCount: 0,
      blogsCount: 0,
      theme: "light",
      contactsCount: 0,
    };
  }

  componentDidMount() {
    axios.get(Constants.getUrls.getCounts).then((resp) => {
      // console.log(resp);
      if (resp.data.status === "success") {
        this.setState({
          usersCount: resp.data.usersCount,
          categoriesCount: resp.data.categoriesCount,
          servicesCount: resp.data.servicesCount,
          blogsCount: resp.data.blogsCount,
          contactsCount: resp.data.contactsCount ?? 0,
        });
      }
    });
  }

  render() {
    const { data: chartData } = this.state;

    return (
      <>
        <Container maxWidth={"xl"}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography
                variant="h6"
                style={{
                  color: this.state.theme === "light" ? "#000" : "#fff",
                }}
              >
                {this.props.title}
              </Typography>
            </Grid>
            <Grid item xs={8}></Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 50 }}>
            <CardBox
              name={" Total Users"}
              value={this.state.usersCount}
              icon={<SupervisorAccount style={{ fontSize: "40" }} />}
              color={"#d1cdce"}
              shadow={"	209,	205,	206"}
              path="/users"
            />

            {/* <CardBox
              name={" Active Users"}
              value={this.state.usersCount}
              icon={<SupervisorAccount style={{ fontSize: "40" }} />}
              color={"#d1cdce"}
              shadow={"	209,	205,	206"}
              path="/users"
            />

            <CardBox
              name={" Deactive Users"}
              value={this.state.usersCount}
              icon={<SupervisorAccount style={{ fontSize: "40" }} />}
              color={"#d1cdce"}
              shadow={"	209,	205,	206"}
              path="/users"
            /> */}

            <CardBox
              name={" Total Categories"}
              value={this.state.categoriesCount}
              icon={<CategoryOutlined style={{ fontSize: "40" }} />}
              color={"#d1cdce"}
              shadow={"209,	205,	206"}
              path="/categories"
            />

            <CardBox
              name={"Total Services"}
              value={this.state.servicesCount}
              icon={<SettingsOutlined style={{ fontSize: "40" }} />}
              color={"#d1cdce"}
              shadow={"209,	205,	206"}
              path="/services"
            />

            <CardBox
              name={"Total Blogs"}
              value={this.state.blogsCount}
              icon={<BookOutlined style={{ fontSize: "40" }} />}
              color={"#d1cdce"}
              shadow={"209,	205,	206"}
              path="/blogs"
            />

            <CardBox
              name={" Total Contacts"}
              value={this.state.contactsCount ? this.state.contactsCount : 0}
              icon={<RecentActorsOutlined style={{ fontSize: "40" }} />}
              color={"#d1cdce"}
              shadow={"209,	205,	206"}
              path="/contacts"
            />
            <CardBox
              name={" Total Social Links"}
              value={this.state.Links ? this.state.Links : 0}
              icon={<LinkOutlined style={{ fontSize: "40" }} />}
              color={"#d1cdce"}
              shadow={"209,	205,	206"}
              path="/socialLinks"
            />

            {/* <Grid item xs={3}>
              <Paper style={{width:"65%", marginTop:"25px"}}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography style={{ fontSize: "16",textAlign:"center" }} color="text.secondary" gutterBottom>
                      Total Users
                    </Typography>
                  </CardContent>
                  <CardActions style={{textAlign:"center",display:"block"}}>
                    <a href="/users" style={{textDecoration:"none"}}>
                      <Button size="small" style={{fontWeight:"700",fontSize:"16px",padding:"5px 50px"}}>{this.state.usersCount}</Button>
                    </a>
                  </CardActions>
                </Card>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper style={{width:"65%", marginTop:"25px"}}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography style={{ fontSize: "16",textAlign:"center" }} color="text.secondary" gutterBottom>
                      Total Categories
                    </Typography>
                  </CardContent>
                  <CardActions style={{textAlign:"center",display:"block"}}>
                    <a href="/categories" style={{textDecoration:"none"}}>
                      <Button size="small" style={{fontWeight:"700",fontSize:"16px",padding:"5px 50px"}}>{this.state.categoriesCount}</Button>
                    </a>
                  </CardActions>
                </Card>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper style={{width:"65%", marginTop:"25px"}}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography style={{ fontSize: "16",textAlign:"center" }} color="text.secondary" gutterBottom>
                      Total Services
                    </Typography>
                  </CardContent>
                  <CardActions style={{textAlign:"center",display:"block"}}>
                    <a href="/services" style={{textDecoration:"none"}}>
                      <Button size="small" style={{fontWeight:"700",fontSize:"16px",padding:"5px 50px"}}>{this.state.servicesCount}</Button>
                    </a>
                  </CardActions>
                </Card>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper style={{width:"65%", marginTop:"25px"}}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography style={{ fontSize: "16",textAlign:"center" }} color="text.secondary" gutterBottom>
                      Total Blogs
                    </Typography>
                  </CardContent>
                  <CardActions style={{textAlign:"center",display:"block"}}>
                    <a href="/blogs" style={{textDecoration:"none"}}>
                      <Button size="small" style={{fontWeight:"700",fontSize:"16px",padding:"5px 50px"}}>{this.state.blogsCount}</Button>
                    </a>
                  </CardActions>
                </Card>
              </Paper>
            </Grid> */}
          </Grid>

          <Grid item xs={4}>
            <Paper style={{ marginTop: "60px" }}>
              <Chart data={chartData}>
                <ValueScale name="sale" />
                <ValueScale name="total" />

                <ArgumentAxis />
                <ValueAxis />
                <LineSeries
                  name="line"
                  valueField="lineValue"
                  argumentField="argument"
                />
                <SplineSeries
                  name="spline"
                  valueField="splineValue"
                  argumentField="argument"
                />
                <Animation />
              </Chart>
            </Paper>
          </Grid>
        </Container>
      </>
    );
  }
}
