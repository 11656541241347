import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
  Snackbar,
  Input,
} from "@material-ui/core";
import init from "../helpers/WindowToken";
import EditIcon from "@material-ui/icons/Edit";
import { Redirect, Link } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Form } from "reactstrap";
let fd = new FormData();
export default class AddBlogs extends Component {
  state = {
    title: "",
    sub_title: "",
    description: "",
    loading: false,
    short_description: "",
    embeded_link: "",
    blog_image: "",
    imgError: false,
    shortDescError: false,
    descError: false,
    blogImage: null,
    showPreview: false,
  };

  addblogs = (e) => {
    e.preventDefault();
    if (this.state.blog_image === "") {
      this.setState({
        imgError: true,
      });
      setTimeout(() => {
        this.setState({
          imgError: false,
        });
      }, 3000);
    } else if (this.state.short_description === "") {
      this.setState({
        shortDescError: true,
      });
      setTimeout(() => {
        this.setState({
          shortDescError: false,
        });
      }, 3000);
    } else if (this.state.description === "") {
      this.setState({
        descError: true,
      });
      setTimeout(() => {
        this.setState({
          descError: false,
        });
      }, 3000);
    } else {
      this.setState({
        loading: true,
      });
      fd.append("title", this.state.title);
      fd.append("sub_title", this.state.sub_title);
      fd.append("embeded_link", this.state.embeded_link);
      fd.append("blog_image", this.state.blog_image);
      fd.append("short_description", this.state.short_description);
      fd.append("description", this.state.description);
      // console.log(fd);
      if (init() === "success") {
        axios.post(Constants.postUrls.addBlogs, fd).then((resp) => {
          //console.log(resp);
          if (resp.data.status === "success") {
            this.setState({
              loading: false,
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                redirect: true,
              });
            }, 1500);
          } else {
            this.setState({
              loading: false,
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                // redirect: true,
              });
            }, 1500);
          }
        });
      }
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFile = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
      blogImage: URL.createObjectURL(e.target.files[0]),
      showPreview: true,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/blogs" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">{this.props.title}</Typography>
            </Grid>
          </Grid>
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"green"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          <div
            style={{
              // background: "#5b5757",
              padding: "20px",
              borderRadius: "10px",
              // color: rgba(0, 0, 0, 0.87);
              width: "100%",
              border: "0",
              display: "flex",
              position: "relative",
              fontSize: ".875rem",
              minWidth: "0",
              wordWrap: " break-word",
              /* background: #FFF; */
              boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
              marginTop: "30px",
              borderRadius: "6px",
              marginBottom: "30px",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>
            <span
              style={{
                // color: "White",
                marginTop: "-55px",
                marginLeft: "78px",
                marginBottom: "35px",
                fontSize: "17px",
              }}
            >
              .{/* Add Blog */}
            </span>
            <Grid item sm={12} style={{ textAlign: "left", marginTop: "30px" }}>
              <Form onSubmit={this.addblogs}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="Title"
                  name="title"
                  variant="outlined"
                  required={true}
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="Sub Title"
                  name="sub_title"
                  variant="outlined"
                  required={true}
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
                {/* <label htmlFor=""> Embeded File</label> */}
                <TextField
                  id="outlined-basic"
                  onChange={this.handleInput}
                  label="Embeded Link"
                  name="embeded_link"
                  variant="outlined"
                  required={true}
                  style={{
                    marginRight: "20px",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
                {/* <Button
                  variant="outlined"
                  size="medium"
                  className="uploadbtn"
                  component="label"
                  style={{
                    width: "30%",
                    marginRight: "10px",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    padding: "13px",
                  }}
                >
                  {this.state.blog_image ? "Select Blog Image*" : "Select Blog Image*"}
                  <input
                    type="file"
                    onChange={this.handleFile}
                    name="blog_image"
                    hidden
                    required
                    className="imageUpload"
                  />
                </Button> */}
                <Input
                  type="file"
                  onChange={this.handleFile}
                  name="blog_image"
                  hidden
                  required
                  className="imageUpload"
                />
                {this.state.showPreview ? (
                  <img
                    id="image"
                    src={this.state.blogImage}
                    alt="img"
                    width="53px"
                    height="54px"
                    style={{
                      position: "absolute",
                      marginLeft: "63px",
                      marginTop: "1px",
                    }}
                  />
                ) : null}
                {this.state.imgError ? (
                  <div style={{ color: "red" }}>Please Select Image</div>
                ) : (
                  ""
                )}

                <br />
                <br />
                <label htmlFor="short_description">Short Description</label>
                <CKEditor
                  style={{ color: "black" }}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    this.setState({
                      short_description: data,
                    });
                  }}
                  data={this.state.short_description}
                />
                {this.state.shortDescError ? (
                  <div style={{ color: "red" }}>required*</div>
                ) : (
                  ""
                )}
                <br />
                <label htmlFor="description">Description</label>
                <CKEditor
                  style={{ color: "black" }}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    this.setState({
                      description: data,
                    });
                  }}
                  data={this.state.description}
                />
                {this.state.descError ? (
                  <div style={{ color: "red" }}>required*</div>
                ) : (
                  ""
                )}
                <br />

                <Button
                  variant="contained"
                  type="submit"
                  Size={15}
                  color="primary"
                  style={{ marginRight: "20px", marginTop: "35px" }}
                >
                  {this.state.loading ? "Adding.." : "Add Blogs"}
                </Button>
                <Link
                  to={{
                    pathname: "/blogs",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    Size={15}
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Form>
            </Grid>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Fade in={this.state.open}>
              <div
                style={{
                  background: "#fff",
                  width: "50%",
                  padding: "32px",
                }}
              >
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">
                  react-transition-group animates me.
                </p>
              </div>
            </Fade>
          </Modal>
        </Container>
      );
    }
  }
}
