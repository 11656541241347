import {
  Button,
  Container,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Person, Settings } from "@material-ui/icons";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import PaymentIcon from "@material-ui/icons/Payment";
import axios from "axios";
import ClearIcon from "@material-ui/icons/Clear";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import UserSidebar from "./UserSidebar";
import Constants from "../../Variables/Constants";
import Lottie from "react-lottie";
import subscriptionLottie from "../../../Assets/lottie/dataNotFound.json";
import { dateAhead } from "../../../helpers/utils";

export default class Setting extends Component {
  state = {
    plans: [
      {
        item_id: "Beginner-USD-Monthly",
        title: "Beginner Plan",
        no_of_models: 20,
        is_first_model_free: true,
        ar_360_view_fee: "$300",
        platform_fee: "$1200",
      },
      {
        item_id: "Growth-USD-Monthly",
        title: "Growth Plan",
        no_of_models: 50,
        is_first_model_free: true,
        ar_360_view_fee: "$300",
        platform_fee: "$1200",
      },
      {
        item_id: "Enterprise-USD-Monthly",
        title: "Enterprise Plan",
        no_of_models: 100,
        is_first_model_free: true,
        ar_360_view_fee: "$300",
        platform_fee: "$1200",
      },
    ],
    planName: "",
    purchasedDate: "",
    nextBillingDate: "",
    amount: "",
    nodatafound: false,
    cardNumber: "",
    cardNumber2: "",
    gateway: "",
    card_type: "",
    customer_id: "",
    paymentSetting: false,
    portalUrl: "",
    userId: window.location.pathname.split("/")[3],
    modalId: "",
    planId: "",
    page: "1",
    perPage: "20",
    usedModels: "",
    remainingModels: "",
    totalModels: "",
    subscriptionStatus: "active",
    no_of_views_left: "",
    no_of_models_left: "",
    no_of_days_left: "",
    monthly_views: "",
    no_of_hosted_models: "",
    prdStatus: "",
  };
  componentDidMount() {
    const el = document.createElement("script");
    // console.log(el);
    axios
      .get(Constants.getUrls.getSubscribeByAdmin + this.state.userId)
      .then((resp) => {
        // console.log(resp);
        if (resp.data.updatedOrders) {
          if (
            resp.data.updatedOrders.order_summary.content.subscription
              .subscription_items.length > 0
          ) {
            var date = new Date(
              resp.data.updatedOrders.order_summary.content.subscription.next_billing_at
            );
            var nextBillingDate = date.toString();
            // console.log(date);
            this.setState({
              planName:
                resp.data?.updatedOrders?.order_summary?.content?.subscription
                  ?.subscription_items[0]?.item_price_id,
              planId:
                resp?.data?.updatedOrders?.order_summary?.content?.subscription
                  ?.subscription_items[0]?.item_price_id,
              purchasedDate: resp?.data?.updatedOrders?.createdAt?.slice(0, 10),
              nextBillingDate: nextBillingDate,
              amount:
                resp?.data?.updatedOrders?.order_summary?.content?.subscription
                  ?.subscription_items[0]?.amount,
              // cardNumber : resp?.data?.updatedOrders?.order_summary?.content?.card?.iin,
              // cardNumber2 : resp?.data?.updatedOrders?.order_summary?.content?.card?.last4,
              cardNumber: resp?.data?.data?.card?.masked_number || "",
              gateway:
                resp?.data?.updatedOrders?.order_summary?.content?.card
                  ?.gateway,
              card_type:
                resp?.data?.updatedOrders?.order_summary?.content?.card
                  ?.card_type || "",
              customer_id:
                resp?.data?.updatedOrders?.order_summary?.content?.card
                  ?.customer_id,
              subscriptionStatus: resp?.data?.updatedOrders?.order_status,
              no_of_views_left: resp?.data?.updatedOrders?.no_of_views_left,
              no_of_models_left: resp?.data?.updatedOrders?.no_of_models_left,
              no_of_days_left: resp?.data?.updatedOrders?.no_of_days_left,
              monthly_views:
                resp?.data?.updatedOrders?.pricing_id?.monthly_views,
              no_of_hosted_models:
                resp?.data?.updatedOrders?.pricing_id?.no_of_hosted_models,
              prdStatus: resp?.data?.updatedOrders?.pricing_id?.status,
            });
          } else {
            this.setState({
              nodatafound: true,
            });
          }
        } else {
          this.setState({
            nodatafound: true,
          });
        }
      });
  }

  logout = () => {
    localStorage.clear();
    window.location.href = "/account/logout";
  };

  handleProfile = () => {
    // AdminPortal.show();
  };

  gotoPortal = async () => {
    let payload = {
      redirect_url: "https://admin.actuality.live/userprofile",
    };
    try {
      const { data } = await axios.post(
        Constants.postUrls.chargebeePortal + this.state.userId,
        payload
      );
      this.setState(
        {
          portalUrl: data.portal_session.access_url,
        },
        () => {
          window.open(this.state.portalUrl, "_blank").focus();
        }
      );
    } catch (err) {
      // alert("err" + JSON.stringify(err?.data || {}, null, 4));
      // console.log("err ", err);
    } finally {
      // alert("finally");
    }
    // axios
    //     .post(Constants.postUrls.chargebeePortal + this.state.userId, payload)
    //     .then((resp) => {
    //       alert("res");
    //     // console.log(resp);
    //       this.setState(
    //         {
    //           portalUrl: resp.data.portal_session.access_url,
    //         },
    //         () => {
    //           window.open(this.state.portalUrl, "_blank").focus();
    //         }
    //       );
    //     })
    //     .catch((err) => {
    //     })
    //     .finally(() => {
    //     });
    //   alert("the end");
  };
  showPaymentSetting = (toggle = true) => {
    this.setState({
      paymentSetting: toggle,
    });
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: subscriptionLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Container
        className="containerSpacingdashBoard"
        maxWidth={"lg"}
        style={{
          border: "0px solid red",
        }}
      >
        <Grid container spacing={2} className="userDashboard">
          <Grid item sm={2} className="sidebar">
            <UserSidebar />
          </Grid>
          <Grid
            item
            sm={10}
            style={{
              paddingLeft: window.innerWidth < 1000 ? "75px" : "105px",
              marginTop: "35px",
            }}
          >
            <Link
              to={"/admin/userprofile/" + this.state.userId}
              style={{ textDecoration: "none", color: "#666666" }}
            >
              <Button
                className="profileBtn"
                style={{ width: window.innerWidth < 1000 ? "330px" : "405px" }}
                onClick={this.handleProfile}
              >
                <Person
                  style={{
                    marginRight: window.innerWidth < 1000 ? "8px" : "15px",
                    marginLeft: window.innerWidth < 1000 ? "-2px" : "-13px",
                    width: "40px",
                    height: "39px",
                  }}
                />
                Profile
              </Button>
            </Link>

            <Button
              className="profileBtn"
              style={{ width: window.innerWidth < 1000 ? "330px" : "405px" }}
              onClick={this.showPaymentSetting}
            >
              <span style={{ marginRight: "30px" }}>
                <Settings className="personIcon" />
              </span>
              Payment Settings
            </Button>

            {this.state.paymentSetting ? (
              <>
                <Grid
                  item
                  sm={10}
                  style={{
                    marginLeft: window.innerWidth < 1000 ? "75px" : "15px",
                    marginTop: "35px",
                  }}
                >
                  {this.state.nodatafound ? (
                    <div className="purchaseSetting">
                      <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                        autoplay
                        loop
                      />
                      Currently, you don't have any subscription plan.
                      <a
                        className="subscribe"
                        href={"/pricing?modalId=" + this.state.modalId}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          className="profileBtn"
                          style={{
                            width: window.innerWidth < 1000 ? "330px" : "405px",
                            fontSize: 20,
                            marginTop: 20,
                          }}
                          // onClick={this.showPaymentSetting}
                        >
                          Click here for Subscription plan.
                        </Button>
                      </a>
                    </div>
                  ) : (
                    <div className="detailBack">
                      <Grid
                        container
                        spacing={2}
                        style={{
                          padding: 15,
                          paddingTop: 0,
                        }}
                      >
                        {" "}
                        <Grid
                          item
                          sm={12}
                          style={{
                            border: "0px solid red",
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: 0,
                            // border: "1px solid red",
                          }}
                        >
                          <IconButton
                            style={
                              {
                                // border: "1px solid blue",
                              }
                            }
                            aria-label="delete"
                            size="large"
                            onClick={() => {
                              this.showPaymentSetting(false);
                            }}
                          >
                            <ClearIcon fontSize="medium" />
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          sm={
                            this.state.subscriptionStatus === "active" ? 4 : 6
                          }
                        >
                          <h4>Plan Details</h4>
                        </Grid>
                        {this.state.subscriptionStatus === "active" ? (
                          <Grid item sm={4}>
                            <Button
                              className="cancelPlanBtn"
                              style={{
                                width:
                                  window.innerWidth < 1000 ? "200px" : "200px",
                                position: "relative",
                                left: "65px",
                                top: "10px",
                              }}
                              onClick={() => {
                                window.location.href =
                                  "/admin/pricing/" + this.state.userId;
                              }}
                            >
                              Upgrade plan
                            </Button>
                          </Grid>
                        ) : null}
                        {this.state.subscriptionStatus === "active" ? (
                          <Grid item sm={4}>
                            <Button
                              className="cancelPlanBtn"
                              style={{
                                width:
                                  window.innerWidth < 1000 ? "200px" : "200px",
                                position: "relative",
                                left: "25px",
                                top: "10px",
                              }}
                              onClick={this.gotoPortal}
                            >
                              Manage Subscription
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item sm={6}>
                            <Button
                              className="cancelPlanBtn"
                              style={{
                                width:
                                  window.innerWidth < 1000 ? "200px" : "200px",
                                position: "relative",
                                left: "165px",
                                top: "10px",
                              }}
                              onClick={this.gotoPortal}
                            >
                              Manage Subscription
                            </Button>
                          </Grid>
                        )}
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">Plan name</h5>
                          <Typography className="infoItemPlanDetail">
                            {this.state.planName}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">Purchased Date</h5>
                          <Typography className="infoItemPlanDetail">
                            {this.state.purchasedDate}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">Total Amount</h5>
                          <Typography className="infoItemPlanDetail">
                            ${this.state.amount.toString().slice(0, -2)}
                            {/* ${this.state.amount.toString()} */}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">Total Hosted Models</h5>
                          <Typography className="infoItemPlanDetail">
                            {this.state.no_of_hosted_models}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">
                            Uploaded Hosted Models
                          </h5>
                          <Typography className="infoItemPlanDetail">
                            {this.state.no_of_hosted_models -
                              this.state.no_of_models_left}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">
                            Remaining Hosted Models
                          </h5>
                          <Typography className="infoItemPlanDetail">
                            {this.state.no_of_models_left}
                          </Typography>
                        </Grid>
                        <Grid item sm={4} className="colmds">
                          <h5 className="infoItemPlan">Subscription Status</h5>
                          {this.state.subscriptionStatus === "active" ? (
                            <Typography
                              className="infoItemPlanDetail"
                              style={{ color: "rgb(4 21 234 / 86%)" }}
                            >
                              Active
                            </Typography>
                          ) : (
                            <Typography
                              className="infoItemPlanDetail"
                              style={{ color: "red" }}
                            >
                              Cancelled
                            </Typography>
                          )}
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan"> Renewal Date</h5>
                          <Typography className="infoItemPlanDetail">
                            {dateAhead(this.state.no_of_days_left)}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">
                            Total
                            {this.state.prdStatus === "monthly"
                              ? "Monthly"
                              : "Annual"}
                            Views Left
                          </h5>
                          <Typography className="infoItemPlanDetail">
                            {this.state.no_of_views_left}
                          </Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <h4>Card Details</h4>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">Card Number</h5>
                          <Typography className="infoItemPlanDetail">
                            {this.state.cardNumber + this.state.cardNumber2}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">Card Type</h5>
                          <Typography className="infoItemPlanDetail">
                            {this.state.card_type}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className="infoItemPlan">Customer Id</h5>
                          <Typography className="infoItemPlanDetail">
                            {this.state.customer_id}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}
