import { IconButton } from "@material-ui/core";
import React, { useState } from "react";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const styleInput = {
  width: `96%`,
  height: "37px",
  border: `1px solid #ddd`,
  background: `#ffffff`,
  borderRadius: "5px",
  outline: "none",
  paddingLeft: "10px",
};

const PasscodeField = ({
  onChange,
  className,
  value,
  style = {},
  placeholder,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div
      style={{
        position: "relative",
        // border: "1px solid blue",
        margin: "1rem 0",
        marginBottom: 0,
      }}
    >
      <input
        className={className ? className : "Input"}
        name="title"
        type={showPassword ? "text" : "password"}
        required
        // pattern="^\s-"
        value={value}
        placeholder={placeholder || ""}
        onChange={onChange}
        style={{
          marginBottom: "1rem",
          margin: 0,
          ...styleInput,
          ...style,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          right: 10,
          transform: "translateY(-50%)",
        }}
      >
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            setShowPassword((p) => !p);
          }}
        >
          {showPassword ? (
            <VisibilityOffIcon fontSize="inherit" />
          ) : (
            <VisibilityIcon fontSize="inherit" />
          )}
        </IconButton>
      </div>
    </div>
  );
};
export default PasscodeField;
