import { Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { spacing } from './const';

import { IconButton } from '@material-ui/core';
import Loader from '../Loader';
import ProductCard from './ProductCard';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import InfiniteScroll from 'react-infinite-scroll-component';
import Constants from '../../App/Variables/Constants';
import TSE_axios from '../../TSE_axios';
import { copyDataToClipboard, scrollToTop } from '../../helpers/utils';

const ProductsListing = ({ query, category, share, shareId, user_id }) => {
  const scrollRef = useRef(null)
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        // scrollRef.current.scroll({
        //   top: 0, behavior: 'smooth'
        // })
        setIsLoading(true)
        setProducts([])
        setPage(1)
        let url = Constants.getUrls.getProducts;
        if (query || category) url += '?';
        if (query) url += `q=${query}&`;
        // if (category) url += `catId=${category}`;
        if (category) console.log(category);
        var catParams = ""
        if (category) {
          category.forEach(cat => {
            catParams += `catId[]=${cat}&`
          })
        }
        url += catParams;
        const { data } = await TSE_axios.get(`${url}&status=published&limit=16&page=1&user=${user_id}`);
        setIsLoading(false)
        setProducts(data.docs);
        setHasMore(data.hasNextPage)

      } catch (err) {
      } finally {
      }
    })();
  }, [query, category]);

  useEffect(() => {
    (async () => {
      try {
        let url = Constants.getUrls.getProducts;
        if (query || category) url += '?';
        if (query) url += `q=${query}&`;
        // if (category) url += `catId=${category}`;
        if (category) console.log(category);
        var catParams = ""
        if (category) {
          category.forEach(cat => {
            catParams += `catId[]=${cat}&`
          })
        }
        url += catParams;
        const { data } = await TSE_axios.get(`${url}&user=${user_id}&status=published&limit=16&page=${page}`);
        setIsLoading(false)
        setProducts([...products, ...data.docs]);
        setHasMore(data.hasNextPage)
      } catch (err) {
      } finally {
      }
    })();
  }, [page]);

  const [selectedProducts, setSelectedProducts] = useState(
    share?.products.map(({ product, ...rest }) => ({
      ...rest,
      product: product._id ?? product,
    })) || []
  );

  const [showMessage, setShowMessage] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');

  const handleSelectedProducts = (product) => {
    if (showMessage) setShowMessage('');
    if (generatedLink) setGeneratedLink('');
    const tmp = selectedProducts.filter((p) => p.product != product.product);

    if (tmp.length == selectedProducts.length) tmp.push(product);

    setSelectedProducts(tmp);
  };
  const handleGenerateLinks = async () => {
    if (isSubmitting || isLoading) return;
    if (!selectedProducts.length) {
      setShowMessage('Select at least one model!');
      setTimeout(() => {
        setShowMessage('');
      }, 3000);
      return;
    }
    setIsSubmitting(true);

    try {
      const { data } = await TSE_axios.put(
        `${Constants.getUrls.getShareProducts}/${shareId}`,
        {
          products: selectedProducts,
          user: user_id
        }
      );
      window.location.href = '/admin/share/' + user_id + '?share_id=' + data.shareProduct._id;
    } catch (err) {
    } finally {
      setIsSubmitting(false);
    }
  };
  const fetchNext = () => {

    setPage(page + 1)
  }
  return (
    <div
      style={{
        paddingTop: spacing * 2,
      }}
    >
      {!isLoading && !products.length ? (
        <div
          style={{
            width: '100%',
            padding: spacing * 2,
            fontSize: 20,
            textAlign: 'center',
          }}
        >
          No Product exists
        </div>
      ) : isLoading && false ? (
        <Loader />
      ) : (
        <>
          <InfiniteScroll
            ref={scrollRef}
            dataLength={products.length}
            next={fetchNext}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            height={"64vh"}
          >
            <Grid container spacing={3}>

              {products
                .map((product, i) => (
                  <ProductCard
                    key={i}
                    product={product}
                    onCheck={() => {
                      handleSelectedProducts({
                        product: product._id,
                        title: product.title,
                        sub_title: product.sub_title,
                      });
                    }}
                    selectedProducts={selectedProducts}
                  />
                ))}
            </Grid>
          </InfiniteScroll>


          <div
            style={{
              padding: spacing,
              textAlign: 'center',
              color: 'red',
              padding: '15px',
            }}
          >
            {showMessage}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '25px 0',
            }}
          >
            <button
              style={{ ...btnStyle, background: '#E1E1E1', color: '#2E2E2E' }}
              onClick={() => {
                scrollToTop();
                setSelectedProducts([]);
              }}
            >
              Clear
            </button>
            <div
              style={{
                width: 15,
              }}
            />
            <button
              onClick={handleGenerateLinks}
              style={{ ...btnStyle, background: '#BDBDBD', color: '#ffffff' }}
            >
              Continue {isSubmitting ? '...' : ''}
            </button>
          </div>

          {!generatedLink ? null : (
            <div
              style={{
                padding: spacing,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>{generatedLink}</span>
              <IconButton onClick={() => copyDataToClipboard(generatedLink)}>
                <FileCopyOutlinedIcon />
              </IconButton>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export const btnStyle = {
  border: 0,
  outline: 0,
  borderRadius: 16,
  boxShadow: `1px 1px 5px rgba(0, 0, 0, 0.1)`,
  padding: '15px 40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 22,
  width: '30%',
  fontWeigh: 500,
};
export default ProductsListing;