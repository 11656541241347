import React, { Component } from "react";
import {
  CategoryOutlined,
  DashboardOutlined,
  BookOutlined,
  InfoOutlined,
  GroupOutlined,
  RecentActorsOutlined,
  AssignmentOutlined,
  SecurityOutlined,
  LinkOutlined,
  SettingsOutlined,
  AttachMoney,
} from "@material-ui/icons";

import CategoryIcon from "@material-ui/icons/Category";
import AboutUs from "./AboutUs";
import Blogs from "./App/Main/Blogs";
import Categories from "./App/Main/Categories";
import Contacts from "./App/Main/Contacts";
import Dashboard from "./App/Main/Dashboard";
import Products from "./App/Main/Products";
import Services from "./App/Main/Services";
import Users from "./App/Main/Users";
import VariantOptions from "./App/Main/VariantOptions";
import TermsandConditions from "./App/Main/TermsandConditions";
import PrivacyPolicy from "./App/Main/PrivacyPolicy";
import Social from "./App/Main/Social";
import AdminPricing from "./App/Main/AdminPricing";
import AdminModel from "./App/Main/AdminModel";
import SubAdmin from "./App/Main/SubAdmin";
import SuggestionModel from "./App/Main/SuggestionModel";
const local_user = localStorage.getItem("user");
var user;
if( local_user !="undefined"){
   user = JSON.parse(local_user);
}
export const routes = [
  {
    title: "Dashboard",
    path: "/",
    component: Dashboard,
    icon: <DashboardOutlined />,
    exact: true,
    isVisible: true

  },
  // {
  //   title: "Products",
  //   path: "/products",
  //   component: Products,
  //   icon: <CategoryIcon />,
  //   exact: true,
 // isVisible: true
  // },
  {
    title: "Categories",
    path: "/categories",
    component: Categories,
    icon: <CategoryOutlined />,
    exact: true,
    isVisible: true
  },
  {
    title: "Services",
    path: "/services",
    component: Services,
    icon: <SettingsOutlined />,
    exact: true,
    isVisible: true
  },
  {
    title: "Blogs",
    path: "/blogs",
    component: Blogs,
    icon: <BookOutlined />,
    exact: true,
    isVisible: true
  },
  {
    title: "AboutUs",
    path: "/aboutus",
    component: AboutUs,
    icon: <InfoOutlined />,
    exact: true,
    isVisible: true
  },
  {
    title: "Users",
    path: "/users",
    component: Users,
    icon: <GroupOutlined />,
    exact: true,
    isVisible: true
  },
  {
    title: "Sub Admin",
    path: "/sub_admin",
    component: SubAdmin,
    icon: <GroupOutlined />,
    exact: true,
    isVisible: user?.role === "sub_admin"? false : true
  },
  {
    title: "Contacts",
    path: "/contacts",
    component: Contacts,
    icon: <RecentActorsOutlined />,
    exact: true,
    isVisible: true
  },
  // {
  //   title: "Variant Options",
  //   path: "/variantoptions",
  //   component: VariantOptions,
  //   // icon: <CategoryOutlined />,
  //   exact: true,
//  isVisible: true
  // },
  {
    title: "Terms and Conditions",
    path: "/terms",
    component: TermsandConditions,
    icon: <AssignmentOutlined />,
    exact: true,
    isVisible: true
  },
  {
    title: "Privacy Policy",
    path: "/privacy",
    component: PrivacyPolicy,
    icon: <SecurityOutlined />,
    exact: true,
    isVisible: true
  },
  {
    title: "Social Links",
    path: "/socialLinks",
    component: Social,
    icon: <LinkOutlined />,
    exact: true,
    isVisible: true
  },
  {
    title: "Plans & Pricing",
    path: "/pricing",
    component: AdminPricing,
    icon: <AttachMoney />,
    exact: true,
    isVisible: true
  },
  {
    title: "Public Models",
    path: "/adminModels",
    component: AdminModel,
    icon: <AttachMoney />,
    exact: true,
    isVisible: true
  },
  {
    title: "Suggestion Models",
    path: "/suggestionmodels",
    component: SuggestionModel,
    icon: <AttachMoney />,
    exact: true,
    isVisible: true
  },
];
