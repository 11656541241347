import { Button, Typography } from "@material-ui/core";
import React, { Component } from "react";
import success from "../../../Assets/lottie/lf30_editor_rxhvowbd.json";
import tick from "../../../Assets/Images/tick_circle.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Constants from "../../Variables/Constants";
import { constants } from "buffer";
class PaymentSucces extends Component {
  state = {
    isStopped: true,
    showTick: true,
    openModal: true.valueOf,
    userFirstName: "",
    userLastName: "",
    item: "",
    price: "",
    user_Id: "",
  };
  componentDidMount() {
    let authToken = localStorage.getItem("feel_xr_auth_token");
    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var payId = url.searchParams.get("id");
    var userId = url.searchParams.get("user_id");
    let productId = localStorage.getItem("productId");
    let pricingId = localStorage.getItem("pricingId");
    this.setState(
      {
        user_Id: userId,
      },
      () => {
        // console.log(this.state.user_Id);
      }
    );
    // console.log(payId);
    const payload = {
      page_id: payId,
      pricing_id: pricingId,
    };
    // console.log(payload);
    axios
      .get(Constants.getUrls.getSubscribeByAdmin + userId)
      .then((respdata) => {
        // console.log(respdata);
        if (respdata.data.status == "failed") {
          axios
            .post(Constants.postUrls.order + userId, payload, {
              headers: { Authorization: "Bearer " + authToken },
            })
            .then((resp) => {
              if (resp.data.status === "success") {
                // console.log(resp);
                axios
                  .get(Constants.getUrls.getSubscribeByAdmin + userId)
                  .then((resp) => {
                    // console.log(resp);
                    this.setState({
                      userFirstName: resp.data.updatedOrders.user.name,
                      item: resp.data.updatedOrders.order_summary.content
                        .subscription.subscription_items[0].item_price_id,
                      price:
                        resp.data.updatedOrders.order_summary.content
                          .subscription.subscription_items[0].amount,
                      showTick: true,
                      isStopped: false,
                    });
                    if (productId) {
                      const payload = {
                        status: "published",
                      };
                      axios
                        .post(
                          Constants.postUrls.changeStatus +
                            productId +
                            "?type=product",
                          payload
                        )
                        .then((statusResp) => {
                          // console.log(statusResp);
                          // if (statusResp.data.status === "success") {
                          //   window.location.href = "/modelDynamicviewer/" + payId
                          // }
                        });
                    }
                  });
              }
            });
        } else {
          if (respdata.data.updatedOrders.page_id === payId) {
            setTimeout(() => {
              window.location.href = "/admin/allProducts/" + userId;
            }, 0);
          } else {
            axios
              .post(Constants.postUrls.order + userId, payload, {
                headers: { Authorization: "Bearer " + authToken },
              })
              .then((resp) => {
                if (resp.data.status === "success") {
                  // console.log(resp);
                  axios
                    .get(Constants.getUrls.getSubscribeByAdmin + userId)
                    .then((resp) => {
                      // console.log(resp);
                      this.setState({
                        userFirstName: resp.data.updatedOrders.user.name,
                        item: resp.data.updatedOrders.order_summary.content
                          .subscription.subscription_items[0].item_price_id,
                        price:
                          resp.data.updatedOrders.order_summary.content
                            .subscription.subscription_items[0].amount,
                        showTick: true,
                        isStopped: false,
                      });
                      if (productId) {
                        const payload = {
                          status: "published",
                        };
                        axios
                          .post(
                            Constants.postUrls.changeStatus +
                              productId +
                              "?type=product",
                            payload
                          )
                          .then((statusResp) => {
                            // console.log(statusResp);
                            // if (statusResp.data.status === "success") {
                            //   window.location.href = "/modelDynamicviewer/" + payId
                            // }
                          });
                      }
                    });
                }
              });
          }
        }
      });
  }

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: success,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className="Lottiewrapper">
        {
          this.state.showTick ? (
            <img src={tick} width="200px" height="200px" alt="" />
          ) : null
          // <Lottie
          //   options={defaultOptions}
          //   height={300}
          //   width={300}
          //   isStopped={this.state.isStopped}
          // />
        }
        <Typography variant={"h5"} className="typo">
          {" "}
          Hello{" "}
          <span style={{ textTransform: "capitalize" }}>
            {this.state.userFirstName}
          </span>{" "}
          {this.state.userLastName}, You have successfully purchased{" "}
          {this.state.item} plan of amount $
          {this.state.price.toString().slice(0, -2)}.{" "}
          {/* Hello Shivani Game, You have successfully purchased Beginner-USD-Monthly plan of amount Rs.299. */}
        </Typography>

        {/* <Link to="/productlist/allProducts" style={{ textDecoration: "none" }}> */}
        <a
          href={"/admin/allProducts/" + this.state.user_Id}
          style={{ textDecoration: "none" }}
        >
          <Button
            size={"large"}
            variant="contained"
            className="themeBtnSuccess txtShadow rounded"
            onClick={() => {
              let productId = localStorage.setItem("productId", "");
              let pricingId = localStorage.setItem("pricingId", "");
            }}
          >
            Go To Dashboard
          </Button>
        </a>

        {/* <Modal
          visible={this.state.openModal}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.setState({ openModal: false })}
        >
          <div className="confirmationModal" style={{ textAlign: "center" }}>
            <Typography>
              Hello User, You have successfully purchased Beginner Plan.
            </Typography>
            
            <Button
              onClick={() => this.setState({ openModal: false })}
              className="DeleteBtn"
              style={{ background: "#bdbdbd", border: "none", marginTop: "50px" }}
            >
              Close
            </Button>
            <p style={{ color: "green", textTransform: "capitalize" }}>
              {this.state.notification ? this.state.successMsg : ""}{" "}
            </p>
          </div>
        </Modal> */}
      </div>
    );
  }
}

export default PaymentSucces;
