import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {
  IconButton,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";

const paperStyle = makeStyles({
  "MuiDialog-paperScrollPaper": {
    minHeight: "calc(100%-64px)",
  },
});
export default function ScrollDialog({
  open,
  handleClose,
  children,
  onClose,
  onSubmit,
  title,
}) {
  const classes = paperStyle();
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        // classes={{
        //   "MuiDialog-paperScrollPaper": classes["MuiDialog-paperScrollPaper"], // class name, e.g. `classes-nesting-root-x`
        //   // label: classes.label, // class name, e.g. `classes-nesting-label-x`
        // }}
      >
        <DialogTitle id="scroll-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent
          style={{
            // border: "1px solid red",
            padding: 0,
            paddingBottom: 4,
          }}
          // dividers={true}
          // classes={{
          //   "MuiDialog-paperScrollPaper": classes["MuiDialog-paperScrollPaper"], // class name, e.g. `classes-nesting-root-x`
          //   // label: classes.label, // class name, e.g. `classes-nesting-label-x`
          // }}
        >
          {/* <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          > */}
          {children}
          {/* </DialogContentText> */}
        </DialogContent>
        {onClose && onSubmit && (
          <DialogActions>
            {onClose && (
              <Button
                onClick={() => {
                  onClose();
                }}
                color="primary"
              >
                Cancel
              </Button>
            )}
            {onSubmit && (
              <Button onClick={onSubmit} color="primary">
                Submit
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
