import React, { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import "./Custom.css";
import Login from "./App/Auth/Login";
import Main from "./App/Main/Main";
// import { Online, Offline } from "react-detect-offline";
import Lottie from "react-lottie";
import offlineTemp from "./Assets/offline.json";

var time = new Date().getHours();
const _theme = localStorage.getItem("theme")
  ? localStorage.getItem("theme")
  : time > 18
    ? "light"
    : "light";

function App() {
  const prefersDarkMode = useMediaQuery(
    "(prefers-color-scheme:" + _theme + " )"
  );

  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // type: prefersDarkMode ?  "light":"light",
          type: "light",
        },
      }),
    [prefersDarkMode]
  );

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: offlineTemp,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [onlineStatus, setOnlineStatus] = useState(true);

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });
    window.addEventListener("online", () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });
      window.removeEventListener("online", () => {
        setOnlineStatus(true);
      });
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      {onlineStatus ? (
        <>
          {" "}
          <CssBaseline />
          <BrowserRouter>
            <Route path="/login" component={Login} />
            <Main />
          </BrowserRouter>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: window.innerHeight,
          }}
        >
          <Lottie
            options={defaultOptions}
            autoplay
            height={"auto"}
            width={"50%"}
          />
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
